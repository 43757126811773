<!-- {{ news | async | json }} -->
<!-- {{ newskey }} -->
<!-- {{ nbCarTotal }} -->

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<div class="press fx-100 fx-row fx-wrap fx-start-start"
	*ngIf="news | async as news; else loading"
	[ngStyle]="{'padding-bottom':'10px'}">
		<div class="fx-19 fx-10-xs" fxFlex.sm="15" 
			[ngStyle.xs]="{'margin':'20px 5px 0 0'}"
			[ngStyle.sm]="{'margin':'20px 5px 0 0'}">
				<img *ngIf="news.urlthumb; else nonewspic" src="{{ news.urlthumb }}" class="news-pic">
				<ng-template #nonewspic>
					<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogo_white_cut_square_png_1524218452323.jpg?alt=media&token=6b855c4e-97be-462f-97d3-2cc0d6c61390" class="news-pic" [ngClass]="instrumentColor">
				</ng-template>
		</div>
		<div class="fx-80 fx-80-xs fx-row fx-wrap" fxFlex.sm="80" [ngStyle]="{'padding-left':'5px'}">
			<div class="fx-100 fx-col">
				{{ getNumberOfCaracterInArticleContent(news.content.length) }}
				<strong>{{ news.title }}</strong>
				<span *ngIf="news.subtitle" class="news-subtitle">{{ news.subtitle }}</span>
				<p *ngIf="news.content.length>nbCarShown; else fullnews" innerHTML="{{ (news.content | slice:0:nbCarShown)+'...' }}"></p>
				<ng-template #fullnews>
					<p innerHTML="{{news.content}}"></p>				
				</ng-template>
			</div>
			<div class="fx-100 fx-row fx-wrap" >
				<div class="fx-50">
					<i *ngIf="news.date">{{ news.date | date }}</i>
				</div>
				<div>
					<button mat-icon-button color="black" type="button"
						(click)="gotoExternalHref(news.link)"
						*ngIf="news.link">
							<mat-icon>link</mat-icon>
					</button>	
					<button mat-icon-button color="black" type="button"
						*ngIf="nbCar>=200" 
						(click)="changeNbCarShown(nbCarShown,nbCarTotal)">
							<mat-icon *ngIf="nbCarShown==300">add</mat-icon>
							<mat-icon *ngIf="nbCarShown==nbCarTotal">remove</mat-icon>
					</button>	
				</div>
			</div>
		</div>
</div>