<div class="fx-100 fx-row fx-wrap"  [ngStyle]="{'margin-bottom':'50px'}">
	
	<div class="fx-100">
		<br><br>
		<h4>
			Routing Details
		</h4>

		<ng-template #loading>				
			<br><br>
			<mat-spinner color="warn"></mat-spinner>
		</ng-template>

	</div>

	<div *ngIf="routingDetails | async; let routingDetails; else loading" class="fx-100 fx-row fx-wrap">

		<div class="fx-100" *ngIf="showAddDetailsButton" [ngStyle]="{'margin':'10px 0 0'}">
			<button mat-raised-button color="black" type="button"
	    matTooltip="Add a tour detail" matTooltipPosition="before"			
	    (click)="showAddDetailsForm=true;showAddDetailsButton=false">
	      Add Routing Detail
	  	</button>
	  </div>

		<div class="fx-100" *ngIf="showAddDetailsForm" [ngStyle]="{'padding-bottom':'10px'}">
			<form #newTourDetailForm="ngForm" (ngSubmit)="onSubmit(newTourDetailForm)">
				<mat-form-field class="fx-15">
					<mat-label> Choose a date </mat-label>
				  <input matInput readonly required
				  	[matDatepicker]="datepicker" 
						ngModel #date="ngModel"
						name="date">
				  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
				  <mat-datepicker #datepicker [startAt]="startdate"></mat-datepicker>
				</mat-form-field>
				&nbsp;

				<mat-form-field class="fx-10">
					<mat-label> Hours </mat-label>
				  <mat-select required
				  	ngModel #hour="ngModel"
						name="hour">
				    <mat-option *ngFor="let hour of hours" 
				    	[value]="hour">
				      {{ hour }}
				    </mat-option>
				  </mat-select>
				</mat-form-field>
				&nbsp;			
				<mat-form-field class="fx-10">
					<mat-label> Minutes </mat-label>
						<mat-select required
							ngModel #time="ngModel"
							name="minute">
							<mat-option *ngFor="let minute of minutes" 
								[value]="minute">
								{{ minute }}
							</mat-option>
						</mat-select>
				</mat-form-field>					
				&nbsp;		
				<mat-form-field class="fx-60 full-width">
					<mat-label> Details </mat-label>
					<input matInput required
						ngModel #details="ngModel"
						name="details">
				</mat-form-field>
				<div class="fx-10">
					<button mat-mini-fab color="warn" 
						type="submit"
						[disabled]="!newTourDetailForm.form.valid">
			      <mat-icon class="iconbut">check</mat-icon>
			  	</button>
			  	&nbsp;
					<button mat-mini-fab color="black" 
						type="button"
						>
			      <mat-icon (click)="showAddDetailsForm=false;showAddDetailsButton=true" class="iconbut">clear</mat-icon>
			  	</button>				  				
			  </div>
			</form>
		</div>		
  		

		<div class="fx-100 fx-row fx-wrap" >
			<div class="fx-100" [ngStyle]="{'padding':'10px 0'}">
				<strong>{{ startdate | date }}</strong><br>
			</div>
			<div class="fx-row fx-100 fx-center-center hoverline"
				*ngFor="let details of routingDetails">
				<div class="fx-15" [ngStyle]="{'padding-left':'10px'}">{{ details.date | date }}</div>
				<div class="fx-10"><strong>{{details.time}}</strong></div>
				<div class="fx-65">{{ details.details }} </div>
				<div class="fx-10 fx-ai-end" FlexAlign="end">
			      <mat-icon class="iconbut" (click)="removeRoutingDetails(details)">clear</mat-icon>
				</div>
			</div>
			<div class="fx-100" [ngStyle]="{'padding-top':'5px'}">			
				<strong>{{ enddate | date }}</strong>
			</div>
		</div>


		<div *ngIf="launchTheDropArtistDataFunctionOnce">
			<div *ngIf="artist | async; let artist">
				<div *ngIf="artist.email">{{ dropArtistEmailInTour(artist.email) }}</div>
				<div *ngIf="!artist.email" color="warn" [ngStyle]="{'color':'#e90b18','padding':'50px 0 0 0px'}"><strong>Please provide the email artist.</strong></div>
			</div>
		</div>

	</div>

</div>