import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { VenueService } from './../shared/venue.service';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss']
})
export class VenueListComponent implements OnInit {

  venueslist: Observable<any[]>;

  constructor(private venueService: VenueService) { }

  ngOnInit() {
  	this.venueslist = this.venueService.getVenuesList();
  }

  deleteVenue(venue) {
  	this.venueService.deleteVenue(venue);
  }

}
