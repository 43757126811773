import { AuthService } from './../../core/auth.service';
import { LogClass } from './../../shared/logclass';
import { Instrument } from './../../public/shared/instrument';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/compat/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Role } from './role';
import { Observable, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { UploadService } from './../../upload/shared/upload.service';
import { deleteField } from 'firebase/firestore';

@Injectable()
export class MemberService {

  members: Observable<any[]>;
  member: Observable<any>;
  agent: Observable<any>;
  changedMember: Observable<any>;
  roles: Observable<any>;
  role: Observable<any>;
  instruments: Observable<any>;
  instrument: Observable<any>;

  artistsKeys: any;
  memberCollection: AngularFirestoreCollection<unknown>;
  uploadCollection: AngularFirestoreCollection<unknown>;
  userCollection: AngularFirestoreCollection<unknown>;
  pressCollection: AngularFirestoreCollection<unknown>;
  mediaCollection: AngularFirestoreCollection<unknown>;
  artistCollection: AngularFirestoreCollection<unknown>;
  agentCollection: AngularFirestoreCollection<unknown>;
  liveMemberCollection: AngularFirestoreCollection<unknown>;
  liveArtistsCollection: AngularFirestoreCollection<unknown>;
  logClass: LogClass;

  private cachedArtistList: any[] | null = null;
  private cachedArtistsForTour: any[] | null = null;
  private cachedLiveArtistsList: any[] | null = null;
  private cachedMembersList: any[] | null = null;
  private cachedUsersList: any[] | null = null;
  private cachedMediaList: any[] | null = null;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private auth: AuthService) {
      this.userCollection         = this.afs.collection('users');
      this.uploadCollection       = this.afs.collection('uploads');
      this.memberCollection       = this.afs.collection('members', ref => ref.orderBy('lastname'));
      this.mediaCollection        = this.afs.collection('medias');
      this.liveMemberCollection   = this.afs.collection('members', ref => ref.where('isonline', '==' , '1'));
      this.pressCollection        = this.afs.collection('press', ref => ref.orderBy('lastname'));
      this.artistCollection       = this.afs.collection('members', ref => ref
                                      .where('role', '==', 'Artist')
                                      .orderBy('firstname'));
      this.liveArtistsCollection  = this.afs.collection('members', ref => ref
                                      .where('role', '==', 'Artist')
                                      .where('isonline', '==' , true)
                                      .orderBy('lastname'));
      this.agentCollection        = this.afs.collection('members', ref => ref
                                      .where('role', '==', 'Agent')
                                      .where('ismember', '==', true)
                                      .orderBy('firstname'));
      this.logClass = new LogClass(this.auth, this.afs);
  }




////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
///////////////////  G E T  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

  getMembersList(): Observable<any[]> {
    if (this.cachedMembersList) {
      // Retourne les données du cache si disponibles
      return of(this.cachedMembersList);
    }

    // Sinon, récupère les données depuis Firestore
    return this.memberCollection.get().pipe(
      map((arr) => {
        this.cachedMembersList = arr.docs.map((snap) => ({
          ...(snap.data() as any),
          $key: snap.id,
        }));
        return this.cachedMembersList;
      })
    );
  }

  // Méthode pour rafraîchir les données si nécessaire
  refreshMembersList(): Observable<any[]> {
    this.cachedMembersList = null;
    return this.getMembersList();
  }

  getUsersList(): Observable<any[]> {
    if (this.cachedUsersList) {
      return of(this.cachedUsersList); // Retourne le cache si disponible
    }
  
    // Sinon, fait une écoute sur Firestore
    return this.userCollection.snapshotChanges().pipe(
      map((arr: any) => {
        this.cachedUsersList = arr.map((snap: any) => ({
          ...(snap.payload.doc.data() as any),
          $key: snap.payload.doc.id,
        }));
        return this.cachedUsersList;
      })
    );
  }
  
  // Méthode pour forcer un rafraîchissement
  refreshUsersList(): Observable<any[]> {
    this.cachedUsersList = null;
    return this.getUsersList();
  }

  getMediaList(): Observable<any[]> {
    if (this.cachedMediaList) {
      return of(this.cachedMediaList); // Retourne les données du cache si disponibles
    }
  
    // Sinon, récupère les données depuis Firestore
    return this.mediaCollection.snapshotChanges().pipe(
      map((arr: any) => {
        this.cachedMediaList = arr.map((snap: any) => ({
          ...(snap.payload.doc.data() as any),
          $key: snap.payload.doc.id,
        }));
        return this.cachedMediaList;
      })
    );
  }
  
  // Méthode pour rafraîchir les données si nécessaire
  refreshMediaList(): Observable<any[]> {
    this.cachedMediaList = null;
    return this.getMediaList();
  }

  getArtistNameList(): Observable<any[]> {
    if (this.cachedArtistList) {
      // Retourne le cache si disponible
      return of(this.cachedArtistList);
    }

    // Sinon faire une requête à Firestore
    return this.artistCollection.get().pipe(
      map((arr) => {
        this.cachedArtistList = arr.docs.map((snap: any) => ({
          artistdefaultimg: snap.data().imgcarrousel1,
          fullname: `${snap.data().firstname} ${snap.data().lastname}`,
          $key: snap.id,
        }));
        return this.cachedArtistList;
      })
    );
  }

  getListOfArtisForTour(query?) {
    if (this.cachedArtistsForTour) {
      // Retourne le cache si disponible
      return of(this.cachedArtistsForTour);
    }

    // Sinon, fait une requête Firestore
    return this.artistCollection.get().pipe(
      map((arr) => {
        this.cachedArtistsForTour = arr.docs.map((snap: any) => ({
          fullname: `${snap.data().firstname} ${snap.data().lastname}`,
          firstname: snap.data().firstname,
          lastname: snap.data().lastname,
          phone: snap.data().phone ? snap.data().phone : null,
          email: snap.data().email ? snap.data().email : null,
          $key: snap.id,
        }));
        return this.cachedArtistsForTour;
      })
    );
  }


  getLiveArtistsList(): Observable<any[]> {
    if (this.cachedLiveArtistsList) {
      // Retourne le cache si disponible
      return of(this.cachedLiveArtistsList);
    }
  
    // Écoute en temps réel Firestore
    return this.liveMemberCollection.snapshotChanges().pipe(
      map((arr: any) => {
        this.cachedLiveArtistsList = arr.map((snap: any) => ({
          ...snap.payload.doc.data(),
          isagents: snap.payload.doc.data().agents ? Object.values(snap.payload.doc.data().agents) : 0,
          isinstruments: snap.payload.doc.data().instruments ? Object.values(snap.payload.doc.data().instruments) : 0,
          $key: snap.payload.doc.id,
        }));
        return this.cachedLiveArtistsList;
      })
    );
  }  




  getMemberKeyInUrl(firstname, lastname: string) {
    return this.afs.collection('members', ref => ref.where('lastname', '==', lastname)
                   .where('firstname', '==', firstname).limit(1)).snapshotChanges().pipe(
                    map(arr => {
                      return arr.map(snap => Object
                        .assign(snap.payload.doc.data(),
                                {$key: snap.payload.doc.id},
                                ));
                    })
                   );
  }



  getPressInMember(key: string) {
    return this.afs.collection('members').doc(key)
                    .collection('press', ref => ref.orderBy('date', 'desc')).snapshotChanges().pipe(
                      map((arr: any) => {
                        return arr.map(snap => Object
                          .assign(snap.payload.doc.data(),
                                  {$key: snap.payload.doc.id},
                                  {date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
                                  ));
                      })
                    );
  }

  getNewsInMember(key: string) {
    return this.afs.collection('members').doc(key)
                    .collection('news', ref => ref.orderBy('date', 'desc')).snapshotChanges().pipe(
                      map((arr: any) => {
                        return arr.map(snap => Object.assign(
                          snap.payload.doc.data(),
                          {
                            $key: snap.payload.doc.id,
                            date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null,
                          }));
                      })
                    );
  }

  getMediaInMember(key: string) {
    return this.afs.collection('members').doc(key).collection('media').snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(), {$key: snap.payload.doc.id}));
      })
    );
  }

  getArtistsWithEventKey(key: string) {
    return this.afs.collection('events').doc(key).collection('artists').snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(), {$key: snap.payload.doc.id}));
      })
    );
  }




  getMemberWithKey(key: string): Observable<Role> {
    const memberPath = `members/${key}`;
    this.member = this.afs.doc<any>(memberPath)
      .get().pipe(
        map(action => {
          const $key = action.id;
          // const birthdate2
          const data = { $key, ...action.data() };
          return data;
        })
      );
    return this.member;
  }


  getArtistWithKey(key: string): Observable<any> {
    const eventPath = `members/${key}`;
    this.member = this.afs.doc<any>(eventPath)
      .get().pipe(
        map((action: firebase.default.firestore.DocumentSnapshot<any>) => {
          const data = Object.assign(action.data(),
          {
            birthdate: action.data().birthdate ? action.data().birthdate.toDate() : null,
            date:      action.data().date ? action.data().date.toDate() : null,
            arrdiscog: action.data().discographie ? Object.values(action.data().discographie) : null,
            $key:      action.id
          });
          return data;
        })
      );
    return this.member;
  }

  getAgentsList() {
    return this.agentCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );
  }

  getArtistsList() {
    return this.liveArtistsCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }) );
      })
    );
  }

  getAllArtistsList() {
    return this.artistCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }) );
      })
    );
  }

  getArtistInAgentList(agentKey) {
    return this.afs.collection('members', ref => ref.where('role', '==', 'Artist')
    .where('agentkeys', 'array-contains', agentKey) ).get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }) );
      })
    );
  }

  getYearFromMembersEvent(memberkey) {
    const users$ = this.afs.collection('members').doc(memberkey).collection('events', ref => ref.orderBy('date')).valueChanges();
    const timestamp$ = [];
    return users$.pipe(
     map((array) => {
        array.forEach((value, key) => {
          if (value.date) { timestamp$.push(new Date(value.date.seconds * 1000).getFullYear()); }
         });
         const distinctTimestamp = Array.from(new Set(timestamp$)) ;
        return distinctTimestamp;
     }),
    );
  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// C R E A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

   importMemberFromJson(member, num, totalmember) {
      const batch = this.afs.firestore.batch();
      const membersList   = {};
      const agentList     = {};
      const agents        = member.agents ? member.agents : null;
      const birthday      = member.birthday ? member.birthday : null;
      const discographie  = member.discographie ? member.discographie : null;
      const discoList     = {};
      const pressList     = {};
      const press         = member.press ? member.press : null;
      const MEMBER_REF  = this.afs.firestore.collection('members').doc();
      const MEMBER_KEY  = MEMBER_REF.id;
      const medias       = member.youtube ? member.youtube : null ;
      const mediaList    = {} ;

      if (agents) {
        // tslint:disable-next-line:forin
        for ( const agent in agents) {
            agentList[agent] =  agents[agent];
        }
        membersList['agents']  = agentList;
      }
      membersList['birthday']          =  birthday;
      membersList['biolonghtmlen']     =  member.biolonghtmlen ? member.biolonghtmlen : null;
      membersList['biolonghtmlfr']     =  member.biolonghtmlfr ? member.biolonghtmlfr : null;
      membersList['bioshorthtmlen']    =  member.bioshorthtmlen ? member.bioshorthtmlen : null;
      membersList['bioshorthtmlfr']    =  member.bioshorthtmlfr ? member.bioshorthtmlfr : null;
      membersList['city']              =  member.city ? member.city : null;
      if (discographie) {
         // tslint:disable-next-line:forin
         for (const disco in discographie) {
            discoList[disco] = discographie[disco];
         }
      }
      membersList['discographie']           = discoList ;
      membersList['email']                  = member.email ? member.email : null;
      membersList['firstname']              = member.firstname ? member.firstname : null;
      membersList['generalmanagement']      = member.generalmanagement ? member.generalmanagement : null;
      membersList['generalmanagementemail'] = member.generalmanagementemail ? member.generalmanagementemail : null;
      membersList['generalmanagementname']  = member.generalmanagementname ? member.generalmanagementname : null;
      membersList['hireslink']              = member.hireslink ? member.hireslink : null;
      membersList['img']                        = member.img ? member.img : null;
      membersList['imgcarrousel1']              = member.imgcarrousel1 ? member.imgcarrousel1 : null;
      membersList['imgcarrousel1credit']        = member.imgcarrousel1credit ? member.imgcarrousel1credit : null;
      membersList['imgcarrousel2']              = member.imgcarrousel2 ? member.imgcarrousel2 : null;
      membersList['imgcarrousel2credit']        = member.imgcarrousel2credit ? member.imgcarrousel2credit : null;
      membersList['imgcarrousel3']              = member.imgcarrousel3 ? member.imgcarrousel3 : null;
      membersList['imgcarrousel3credit']        = member.imgcarrousel3credit ? member.imgcarrousel3credit : null;
      membersList['imgcarrousel4']              = member.imgcarrousel4 ? member.imgcarrousel4 : null;
      membersList['imgcarrousel4credit']        = member.imgcarrousel4credit ? member.imgcarrousel4credit : null;
      membersList['imgcredit']                  = member.imgcredit ? member.imgcredit : null;
      membersList['isonline']                   = member.isonline ? member.isonline : null;
      membersList['ismedia']                    = member.ismedia ? member.ismedia : null;
      membersList['isevents']                   = member.isevents ? member.isevents : null;
      membersList['instrument']                 = member.instrument ? member.instrument : null;
      membersList['instruments']                = member.instruments ? member.instruments : null;
      membersList['kajimotomanagement']               = member.kajimotomanagement ? member.kajimotomanagement : null;
      membersList['kajimotomanagementbeijingemail']   = member.kajimotomanagementbeijingemail ?
                                                        member.kajimotomanagementbeijingemail : null;
      membersList['kajimotomanagementbeijingname']    = member.kajimotomanagementbeijingname ? member.kajimotomanagementbeijingname : null;
      membersList['kajimotomanagementemail']          = member.kajimotomanagementemail ? member.kajimotomanagementemail : null;
      membersList['kajimotomanagementparisname']      = member.kajimotomanagementparisname ? member.kajimotomanagementparisname : null;
      membersList['kajimotomanagementphone']          = member.kajimotomanagementphone ? member.kajimotomanagementphone : null;
      membersList['kajimotomanagementphonemobile']    = member.kajimotomanagementphonemobile ? member.kajimotomanagementphonemobile : null;
      membersList['kajimotomanagementphoneoffice']    = member.kajimotomanagementphoneoffice ? member.kajimotomanagementphoneoffice : null;
      membersList['kajimotomanagementrepresentation'] = member.kajimotomanagementrepresentation ?
                                                        member.kajimotomanagementrepresentation : null;
      membersList['kajimotomanagementtokyoemail']     = member.kajimotomanagementtokyoemail ?
                                                        member.kajimotomanagementtokyoemail : null;
      membersList['kajimotomanagementtokyoname']      = member.kajimotomanagementtokyoname ?
                                                        member.kajimotomanagementtokyoname : null;
      membersList['lastname']                         = member.lastname ? member.lastname : null;
      membersList['office']                           = member.office ? member.office : null;
      membersList['phone']                            = member.phone ? member.phone : null;
      membersList['profilepic'] = member.profilepic ? member.profilepic : null;
      membersList['role']       = member.role ? member.role : null;
      membersList['timestamp']  = Date.now();
      membersList['street']     = member.street ? member.street : null;
      membersList['website']    = member.website ? member.website : null;
      membersList['zip']        = member.zip ? member.zip : null;
     // console.log(mediaList);
     // console.log(member);
      if (membersList) {
          this.memberCollection.doc(MEMBER_KEY)
          .set(membersList)
          .then(function() {console.log('Document successfully written!', num, ' / ', totalmember, member.lastname, membersList); })
          .catch(function(error) {console.error('Error writing document: ', error); });

          if (press) {
            // tslint:disable-next-line:forin
            for (const presse in press) {

              const PRESS_REF  = this.afs.firestore.collection('press').doc();
              const PRESS_KEY  = PRESS_REF.id;

              const value: any = press[presse];
              const pressData = {
                  artistKey : MEMBER_KEY,
                  content   : value.content,
                  date      : value.date,
                  info      : value.info,
                  presskey  : PRESS_KEY,
                  timestamp : value.timestamp,
                  title     : value.title };
                  pressList[PRESS_KEY] = pressData;
                  // batch.set(PRESS_REF,pressList);
                  this.pressCollection.doc(PRESS_KEY)
                  .set(pressData)
                  .then(function() {console.log('Document successfully written!', pressData); })
                  .catch(function(error) {console.error('Error writing document: ', error); });

                   this.afs.firestore.collection('members').doc(MEMBER_KEY).collection('press').doc(PRESS_KEY)
                   .set(pressData)
                   .then(function() {console.log('Document successfully written!'); })
                   .catch(function(error) {console.error('Error writing document: ', error); });
            }
           //  membersList['press'] = pressList;
          }

           if (medias) {
            // tslint:disable-next-line:forin
            for (const media in medias) {

              const MEDIA_REF  = this.afs.firestore.collection('medias').doc();
              const MEDIA_KEY  = MEDIA_REF.id;

              const value: any = medias[media];
              const mediaData = {
                  artistKey : MEMBER_KEY,
                  fullname  : member.lastname + '  ' + member.firstname,
                  link      : value,
                  timestamp : Date.now(),
                  src       : 'youtube',
                };
                  mediaList[MEDIA_KEY] = value;
                //  batch.set(MEDIA_REF,mediaData);
                this.mediaCollection.doc(MEDIA_KEY)
                .set(mediaData)
                .then(function() {console.log('Document successfully written!', mediaData); })
                .catch(function(error) {console.error('Error writing document: ', error); });

                this.afs.firestore.collection('members').doc(MEMBER_KEY).collection('media').doc(MEDIA_KEY)
                .set(mediaData)
                .then(function() {console.log('Document successfully written!'); })
                .catch(function(error) {console.error('Error writing document: ', error); });
            }
          }
      }
   }





  createMember(newMemberForm: NgForm, createandbind?) {
    const db = this.afs.firestore;
    const batch = this.afs.firestore.batch();
    const roleKey = newMemberForm.value.pickedMemberRole.$key;
    const roleTitle = newMemberForm.value.pickedMemberRole.title;
    const newMemberData           = {};
    newMemberData['timestamp']  = Date.now();
    newMemberData['ismember']   = false ;
    newMemberData['firstname']  = newMemberForm.value.newMemberfirstname ? newMemberForm.value.newMemberfirstname : null;
    newMemberData['lastname']   = newMemberForm.value.newMemberlastname ? newMemberForm.value.newMemberlastname : null;
    newMemberData['email']      = newMemberForm.value.newMemberemail ? newMemberForm.value.newMemberemail : null;
    newMemberData['phone']      = newMemberForm.value.newMemberphone ? newMemberForm.value.newMemberphone : null;
    newMemberData['instrument'] = newMemberForm.value.selectedInstrus ? newMemberForm.value.selectedInstrus : null;
    newMemberData['instruments'] = newMemberForm.value.selectedInstrus ? [newMemberForm.value.selectedInstrus.name] : null ;
    newMemberData['role']       = newMemberForm.value.pickedMemberRole ? newMemberForm.value.pickedMemberRole.title : null;
    newMemberData['instrumentcat']   = newMemberForm.value.selectedInstrus ? [newMemberForm.value.selectedInstrus.category] : null ;

    newMemberData['biolonghtmlen']  = '...';
    newMemberData['biolonghtmlfr']  = '...';
    newMemberData['bioshorthtmlen']  = '...';
    newMemberData['bioshorthtmlfr']  = '...';

    let nbAgent;

    // IF ARTIST
    if (roleKey === '1') {

      // Publish Variables
      newMemberData['isonline'] = false;
      newMemberData['ismedia'] = false;
      newMemberData['isevent'] = false;

      nbAgent = newMemberForm.value.selectedAgents ? newMemberForm.value.selectedAgents.length : 0;
      if (nbAgent) {
        const memberAgents = {};
        for (let i = 0; i < nbAgent; i++) {
          const keyagent = newMemberForm.value.selectedAgents[i].$key;
          const memberAgent = {};
          memberAgent['key'] = keyagent;
          memberAgent['fullname'] = `${newMemberForm.value.selectedAgents[i].firstname} ${newMemberForm.value.selectedAgents[i].lastname}`;
          memberAgents[keyagent] = {};
          memberAgents[keyagent] = memberAgent;
         // newMemberData['agents'] = { memberAgents } ;
        }
         newMemberData['agents'] = memberAgents;
      }
    }
    // INSERT MEMBER
    const newMemberKey = db.collection('members').doc().id;
    const memberRef = this.afs.collection('members').doc(newMemberKey).ref;
    batch.set(memberRef, newMemberData);

    if (roleKey === '1') {
      if (nbAgent) {
        for (let i = 0; i < nbAgent; i++) {
          const keyagent = newMemberForm.value.selectedAgents[i].$key;
            const artistInAgentRef = this.afs.collection('members')
                                    .doc(keyagent)
                                    .collection('members')
                                    .doc(newMemberKey).ref;
            batch.set( artistInAgentRef, newMemberData) ;
        }
      }
    }
    batch.commit()
    .then(function() {
      console.log('Batch Commited');
    })
    .catch(function(err) {
      console.error(err);
    });
    this.logClass.createLog(this.afs, 'createMember', newMemberKey);


    if (!createandbind) { this.router.navigate(['/member$/member/' + newMemberKey]); }
    return newMemberKey ;

  }


  addInstruTableInMember(member) {
    const batch = this.afs.firestore.batch();
    const memberkey          = member.$key ? member.$key : null;
    const instrument         = member.instrument ? member.instrument : null;
    const updateField        = {} ;
    const table              = [];
    table.push(instrument);
    updateField['instruments'] = table;
    const memberRef = this.afs.collection('members').doc(memberkey ).ref;
    batch.update(memberRef, updateField);
    batch.commit()
    .then(function() {
      console.log('Batch Commited');
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs, 'addInstruTableInMember', memberkey, 'instruments');
  }









////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// D E L E T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


 removeArtistInMember(member, artist) {
    const agentKey            = member.$key;
    const userKey             = member.userId;
    const artistKey           = artist.$key;
    const batch               = this.afs.firestore.batch();
    const updateField         = {} ;
    const updateFieldAgent    = {} ;
    const artistRef           = this.afs.collection('members').doc(agentKey)
                                        .collection('artists').doc(artistKey ).ref;
    const agentRef            = this.afs.collection('members').doc(artistKey)
                                        .collection('agents').doc(agentKey).ref;
    const rootAgentRef        = this.afs.collection('members').doc(agentKey).ref;
    const rootArtistRef       = this.afs.collection('members').doc(artistKey).ref;
    const userRef             = this.afs.collection('users').doc(userKey).ref;
    const artistkeys          = member.artistkeys;
    const agentkeys           = artist.agentkeys;

    const indexArtist         = artistkeys.indexOf(artistKey);
    const indexAgent         = agentkeys.indexOf(agentKey);

    artistkeys.splice(indexArtist, 1);
    agentkeys.splice(indexAgent, 1);
    updateField['artistkeys'] = artistkeys ;
    updateFieldAgent['agentkeys'] = agentkeys ;

    batch.delete(artistRef );
    batch.delete(agentRef );
    batch.set(rootAgentRef, updateField, {merge: true});
    batch.set(rootArtistRef, updateFieldAgent, {merge: true});
    batch.set(userRef, updateField, {merge: true});
    batch.commit().then(function() {console.log('Batch Delete'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'removeArtistInMember', artistKey);
  }



  removeUser(agent, artist) {
    if (agent.artistkeys.length === 1 ) {
        this.unbindUser(agent, artist);
        this.removeArtistInMember(agent, artist) ;

    } else {
        this.removeArtistInMember(agent, artist) ;
    }
  }



  unbindUser(agent, artist) {
    const batch   = this.afs.firestore.batch();
    const userkey  = agent.userId;
    const agentkey = agent.$key ;
    const updateFieldUser   = {} ;
    const updateFieldAgent   = {} ;
    updateFieldUser['role'] = 'pending';
    updateFieldAgent['ismember'] = false;
    const userRef = this.afs.collection('users').doc(userkey).ref;
    const agentRef = this.afs.collection('members').doc(agentkey).ref;
    batch.update( userRef, {['memberkey']: firebase.default.firestore.FieldValue.delete()});
    batch.update( agentRef, {['userId']: firebase.default.firestore.FieldValue.delete()});
    batch.set(userRef, updateFieldUser, {merge: true}) ;
    batch.set(agentRef, updateFieldAgent, {merge: true}) ;
    batch.commit().then(function() {console.log('Batch Delete'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'unbindUser', userkey);
  }



  removeMediaFromArtist(media, memberkey) {
    const mediakey = media.$key;
    const batch = this.afs.firestore.batch();

    const mediaCollectionRef = this.afs.collection('medias').doc(mediakey).ref;
    const mediaInMemberCollectionRef = this.afs.collection('members')
                                        .doc(memberkey).collection('media')
                                        .doc(mediakey).ref;
    batch.delete(mediaCollectionRef);
    batch.delete(mediaInMemberCollectionRef);

    batch.commit()
    .then(function() {console.log('Batch Commited'); })
    .catch(function(err) {console.error(err); });

    this.logClass.createLog(this.afs, 'removeMediaFromArtist', mediakey);
  }



  deleteMember(member): void {
    console.log(member);
    const db        = this.afs.firestore;
    const batch     = this.afs.firestore.batch();
    const memberkey   = member.$key;
    const memberRef = this.memberCollection.doc(memberkey).ref;
    batch.delete( memberRef);
    batch.commit()
    .then(function() {
      console.log('Data delete');
      window.location.reload();
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs, 'deleteMember', memberkey);

  }



  removeInstruInMember(member) {

    const memberKey            = member.$key;
    const instrument             = member.instrument ? member.instrument : null;
    const batch                = this.afs.firestore.batch();
    const memberRef            = this.afs.collection('members').doc(memberKey).ref;
    const updateField          = {} ;
    const table                  = [];
    table.push(instrument);
    updateField['instruments'] = table;
    batch.update( memberRef, {['instruments ']: firebase.default.firestore.FieldValue.delete()});
    batch.update(memberRef, updateField);

    batch.commit().then(function() {console.log('Batch Delete'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'removeInstruInMember', memberKey);

  }










////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// U P D A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  addArtist(artist, agent, batch) {
    const artistKey = artist.$key;
    const agentKey  = agent.$key;
    const agentUserId = agent.userId;
    const updateArtistField = {} ;
    const setArtistField = {} ;
    const artistKeys = agent.artistkeys ? agent.artistkeys : [] ;
    const agentKeys = artist.agentkeys ? artist.agentkeys : [] ;
    if (artistKeys.indexOf(artistKey) === -1) {
      artistKeys.push(artistKey);
      updateArtistField['artistkeys']  = artistKeys;
    }
    if (agentKeys.indexOf(agentKey) === -1) {
         agentKeys.push(agentKey);
         setArtistField['agentkeys']  = agentKeys;
    }
    const agentRef = this.afs.collection('members').doc(agentKey).collection('artists').doc(artistKey).ref;
    const rootAgentRef = this.afs.collection('members').doc(agentKey).ref;
    const rootArtistRef = this.afs.collection('members').doc(artistKey).ref;
    const userRef = this.afs.collection('users').doc(agentUserId).ref;
    const artistRef = this.afs.collection('members').doc(artistKey).collection('agents').doc(agentKey).ref;
    batch.set(agentRef, artist, {merge: true});
    batch.set(rootArtistRef, setArtistField, {merge: true});
    batch.set(artistRef, agent, {merge: true});
    batch.set(rootAgentRef, updateArtistField , {merge: true});
    batch.set(userRef, updateArtistField , {merge: true});
    this.logClass.createLog(this.afs, 'addArtist', artistKey);
  }



  addOneArtistInAgentCollection(artist, agent) {
    const batch   = this.afs.firestore.batch();
    this.addArtist(artist, agent, batch);
    batch.commit().then(function() {console.log('Batch Commited'); });
  }

  addAllartistInAgentCollection(artists, agent) {
    const batch   = this.afs.firestore.batch();
    for (let i = 0; i < artists.length ; i++) {
      this.addArtist(artists[i], agent, batch);
   }
   batch.commit().then(function() {console.log('Batch Commited: All data inserted'); });
  }



  isMember(member) {
    const batch               = this.afs.firestore.batch();
    const memberkey           = member.$key ? member.$key : null;
    const instrument          = member.instrument ? member.instrument : null;
    const updateField         = {} ;
    const table               = [];
    table.push(instrument);
    // updateField['instruments'] = table;
    updateField['ismember'] = false;
    const memberRef         = this.afs.collection('members').doc(memberkey ).ref;
    batch.set(memberRef, updateField, {merge: true});
    batch.commit()
    .then(function() {
      console.log('Batch Commited');
    })
    .catch(function(err) {
      console.error(err);
    });
    this.logClass.createLog(this.afs, 'isMember', memberkey, 'ismember' );
  }

  getMembersIsNotUserList() {
    return this.afs.collection('members', ref => ref.where('ismember', '==', false)).snapshotChanges().pipe(map(arr => {
      return arr.map(snap => Object.assign(
        snap.payload.doc.data(), { $key: snap.payload.doc.id }) );
    }));
  }

  getAgentsIsNotUserList() {
    return this.afs.collection('members', ref => ref
      .where('role', '==', 'Agent').where('ismember', '==', false)).get().pipe(map(arr => {
      return arr.docs.map(snap => Object.assign(
        snap.data(), { $key: snap.id }) );
    }));
  }


  bindUserMember(member, user) {
    const batch   = this.afs.firestore.batch();
    const memberKey = member.$key;
    const userKey  = user.$key;
    const memberRef = this.afs.collection('members').doc(memberKey).ref;
    const usertRef = this.afs.collection('users').doc(userKey).ref;
    const updateUserField   = {} ;
    const updateMemberField = {} ;
    updateUserField['memberkey'] = memberKey;
    updateUserField['memberfullname'] = `${member.firstname} ${member.lastname}`;
    updateUserField['role'] = member.role.toLowerCase();
    updateMemberField['userId']  = userKey;
    updateMemberField['ismember']  = true;
    batch.set(usertRef, updateUserField, {merge: true});
    batch.set(memberRef, updateMemberField, {merge: true});
    batch.commit().then(function() {console.log('Batch Commited'); });
    this.logClass.createLog(this.afs, 'bindUserMember', userKey);
  }





  updateMember(member, field, value, type?) {
    const batch   = this.afs.firestore.batch();
    const db      = this.afs.firestore;
    const memberKey = member.$key;
    // const agentKey  = member.role.agents ? member.role.agents.key : 0;
    // const artistKey = member.role.artists ? member.role.artists.key : 0;
    const roleKey   = member.role.key;
    const instrument$ = member.instruments ? member.instruments : ['default'];
    const categorie$ = member.instrumentcat ? member.instrumentcat : ['default'];
    const memberRef = db.collection('members').doc(memberKey);

    console.log(value);
    if (field === 'instruments') {
      if (type === 'default') {
           batch.update(memberRef, 'instrument', value);
           instrument$.splice(0, 1, value.name);
           categorie$.splice(0, 1, value.category);
           const arrayCat =  categorie$;
           batch.update(memberRef, 'instrumentcat', arrayCat);
           value = instrument$;
       } else {
           instrument$.splice(1, 1, value.name);
           categorie$.splice(1, 1, value.category);
           value =  instrument$;
           const arrayCat =  categorie$;
           batch.update(memberRef, 'instrumentcat', arrayCat);
       }
    }
    batch.update(memberRef, `${field}`, value);
    if (roleKey === '1') {
      const instruments = member.instruments ? member.instruments : 0;
      // Update Member Field in Agents Nodes
      const agents = member.agents ? member.agents : 0;
      if (agents) {
        // tslint:disable-next-line:forin
        for (const agentkey in agents) {
           const  memberInAgentRef  = db.collection('member').doc(agentkey).collection('members').doc(memberKey);
           batch.update(memberInAgentRef, `${field}`, value);
        }
      }
    }
     batch.commit().then(function() {console.log('Batch Commited'); });
     this.logClass.createLog(this.afs, 'updateMember', memberKey, field);
  }









  // ADD PICTURE FILE TO MEMBER
  addImageFileToMember(member, type, credit, url, path: number) {

    const db                  = this.afs.firestore;
    const batch               = this.afs.firestore.batch();
    const memberImgCollection = this.afs.collection('members').doc(member.$key).ref;
    const uploadRef           = this.afs.firestore.collection('uploads').doc();
    const uploadkey           = uploadRef.id;
    const emptypath =  path ? path : 1;
    let nextemptypath: number;
    if (emptypath < 5) { nextemptypath = emptypath + 1; } else { nextemptypath = 1; }

    const newUploadFile       = {};
    newUploadFile['type']     = 'Album Cover';
    newUploadFile['url']      = url;
    newUploadFile['uploadkey'] = uploadkey;
    newUploadFile['timestamp'] = Date.now();

    batch.set(uploadRef, newUploadFile);

    if (type === 'carousel') {
      const urlPath             =  emptypath && url ? url : null;
      const urlImgCredit        =  credit ? credit : null;

      batch.update(memberImgCollection, `imgcarrousel${emptypath}`, urlPath);
      batch.update(memberImgCollection, `imgcarrousel${emptypath}credit`, urlImgCredit);
      batch.update(memberImgCollection, `imgcarouempty`, nextemptypath);
    }
    if (type === 'profile') {
        batch.update(memberImgCollection, `profilepic`, url);
    }
    if (type) {
        batch.commit().then(function() {console.log('image uploaded'); })
        .catch(function(err) {console.error(err); });

        this.logClass.createLog(this.afs, 'addImageFileToMember', member.$key);
    }
    this.router.navigate(['./../../member$/member/' + member.$key ]);
  }

  // ADD PICTURE FILE TO MEMBER
  addDiscFileToMember(member, name, link, url) {

    const db                  = this.afs.firestore;
    const batch               = this.afs.firestore.batch();
    const memberImgCollection = this.afs.collection('members').doc(member.$key).ref;

    const uploadRef           = this.afs.firestore.collection('uploads').doc();
    const uploadkey           = uploadRef.id;

    const newDiskData         = {};
    newDiskData['link']       = link;
    newDiskData['srce']       = 'Album Cover';
    newDiskData['name']        = name;
    newDiskData['url']        = url;
    newDiskData['uploadkey']  = uploadkey;
    newDiskData['timestamp']  = Date.now();

    batch.set(uploadRef, newDiskData);
    batch.update(memberImgCollection, `discographie.${uploadkey}`, newDiskData);
    batch.commit().then(function() {console.log('image uploaded'); }).catch(function(err) {console.error(err); });
    this.logClass.createLog(this.afs, 'addDiscFileToMember', member.$key);
    this.router.navigate(['./../../member$/member/' + member.$key ]);
  }








  // REMOVE CAROUSEL PICTURE
  removeCarouselPictureWithLegend(member, nb, path) {
    console.log(member, nb, path);

    const memberkey = member.$key;

    const batch = this.afs.firestore.batch();

    const memberCollectionRef = this.afs.collection('members').doc(memberkey).ref;

    //for (let i = nb; i <= 5; i++) {
      batch.update(memberCollectionRef, {['imgcarrousel' + nb] : null});
      batch.update(memberCollectionRef, {['imgcarrousel' + nb + 'credit'] : null});
    //}
    batch.update(memberCollectionRef, `imgcarouempty`, nb);

    batch.commit()
    .then(function() {console.log('Batch Commited'); })
    .catch(function(err) {console.error(err); });

    this.logClass.createLog(this.afs, 'removeCarouselPictureWithLegend', memberkey);

  }




  // Default error handling for all actions
  private handleError(error) {
    console.log(error);
  }




}
