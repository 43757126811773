import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';
import { PublicService } from '../shared/public.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-artist-event',
  templateUrl: './artist-event.component.html',
  styleUrls: ['./artist-event.component.scss']
})
export class ArtistEventComponent implements OnInit {

	@Input() eventkey: string;

	getevent:any;artists:any;
  id: any;
  key: string;
  profil: string;
  check = false;
  key2: string;
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private ps: PublicService
		) { 
      this.key = this.route.snapshot.paramMap.get('key');
      this.key2 = this.route.snapshot.paramMap.get('key2');
      this.profil = this.route.snapshot.paramMap.get('profil');
      if(this.id){
        console.log(this.id.length);
      }
     
    }

  ngOnInit() {
  	// this.getevent = this.eventService.getEventWithKey(this.eventkey);
    this.artists 	= this.eventService.getArtistsEvent(this.eventkey);
  }
  checkId(){
    if(this.id.length === 20) {
        this.check = true;
    }else{
      this.check = false;
    }

  }

}
