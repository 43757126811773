import { AuthService } from './../core/auth.service';

// import { FirebaseApp } from 'angularfire2';
// import * as firebase from 'firebase/app';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { tap, switchMap, take, map, timestamp, catchError, finalize, retry, takeUntil, filter, startWith} from 'rxjs/operators';
import { of, throwError, Observable, interval, timer } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { AngularFirestore } from '@angular/fire/compat/firestore';

export class LogClass {
   user: any;
   subscription: any;
   constructor(private auth: AuthService, private fs: AngularFirestore) {
    }
    createLog(afs: AngularFirestore, operation: string, collectionkey: string, field?: string) {
        // const  logref    =  this.fs.firestore.collection('logs').doc();
        //  const logkey   = logref.id;
        //  const user = this.auth.userOnline ;
        //  console.log("On est au niveau de la fonction createlog du Logclass.ts ");

        //  if (field) {
        //     afs.collection('logs').doc(logkey).set({
        //      function :  operation,
        //      field_update: field,
        //      userid   : user.uid,
        //      userdisplayname: user.displayName,
        //      idcollection: collectionkey,
        //      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        //      url: window.location.href
        //     });
   
        //  } else {
        //     afs.collection('logs').doc(logkey).set({
        //      function : operation,
        //      userid   : user.uid,
        //      userdisplayname: user.displayName,
        //      idcollection: collectionkey,
        //      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        //      url: window.location.href
        //     });
        //     console.log('URL:' + window.location.href);
        //  }
          //  this.subscription = this.getThelastTimestampInLog(afs, this.auth.afAuth).subscribe((reponse) => {
          //     if (reponse) {
          //       }
          //     },
          //     (error) => {
          //       // console.error('Oops:', error.message);
          //       },
          //       () => {
          //         // console.log(`We're done here!`); 
          //       }
          //     );
          //    // subscription.unsubscribe();
    }

    getThelastTimestampInLog(afs: AngularFirestore, afAuth: AngularFireAuth) {
      const timerInterval = interval(1000).pipe(startWith(0));
      const timer$ = timer(25000);
      return timerInterval.pipe(
       // takeUntil(()),
        switchMap(() => {
          return afAuth.authState.pipe(switchMap(user => {
             //
             if (user) {
               if (user.uid) {
                 // console.log(user.uid);
                  return afs.collection('logs', ref => ref.where('userid', '==', user.uid)
                                                         .orderBy('timestamp', 'desc')
                                                         .limit(1)).valueChanges();
               } else {
                 return of(null);
               }
             } else {
               return of(null);
             }
          }));
        }),
        filter((value) => value && value.length > 0),
        map((value: Object) => {
          if (value) {
            const seconds = new Date().getTime() / 1000;
            const roundSeconds = Math.round(seconds);
            const logtimestamp = value[0].timestamp.seconds ;
            const secondVariation =  roundSeconds - logtimestamp ;
            const data = {data: value,
                          variation: secondVariation};
            return data;
          }
          // else {
          //   return of(null);
          // }
         // return value;
        }),
        catchError((err, source$) => {
          return source$;
      }),
      tap((value: any) => {
        if (value) {
          const userId = value.data[0].userid;
          const userRef: any = afs.doc(
            `users/${userId}`
          );
          if (value.variation > 20) {
             return userRef.set({online : false}, { merge: true });
          } else {
            return userRef.set({online : true}, { merge: true });
          }
        }
        //  else {
        //   return of(null);
        // }
      }),
       takeUntil((timer$))
      );
    }

     // If error, console log and notify user
   private handleError(error: Error) {
      console.error(error);
      console.log(error.message, 'error');
   }
}
