<!-- {{ press | async | json }} -->
<!-- {{ presskey }} -->
<!-- {{ nbCarTotal }} -->

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<div class="fx-100 press fx-row fx-wrap fx-start-start"
	*ngIf="press; else loading"
	[ngStyle]="{'padding-bottom':'10px'}">
		<div class="fx-100" [ngStyle]="{'padding':'0 0 10px'}">
			<!-- {{ getNumberOfCaracterInArticleContent(press.content.length) }} -->
			<strong>{{ press.title }}</strong>
		</div>
		<div class="fx-100" *ngIf="press && press.content">
			<p *ngIf="press.content.length>nbCarShown; else fullpress" innerHTML="{{ (press.content | slice:0:nbCarShown)+'...' }}"></p>
			<ng-template #fullpress>
				<p innerHTML="{{press.content}}"></p>				
			</ng-template>
		</div>
		<div class="fx-80" [ngStyle]="{'padding-top':'5px'}">
			<i><span *ngIf="press.info">{{ press.info }} - </span>{{ press.date | date }}</i>
		</div>
		<div class="fx-20 fx-end-start">
			<button mat-icon-button color="black" type="button"
				*ngIf="nbCar>=200"
				(click)="changeNbCarShown(nbCarShown,nbCarTotal)">
					<mat-icon *ngIf="nbCarShown==300">add</mat-icon>
					<mat-icon *ngIf="nbCarShown==nbCarTotal">remove</mat-icon>
			</button>				
		</div>
</div>