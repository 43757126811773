import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'events-routing-program',
  templateUrl: './events-routing-program.component.html',
  styleUrls: ['./events-routing-program.component.scss']
})
export class EventsRoutingProgramComponent implements OnInit {
	
	@Input() eventkey: string;

	getevent:any;program:any;
  
  
  
  constructor(
  	private eventService: EventService
  	) { }

  ngOnInit() {
		this.getevent = this.eventService.getEventWithKey(this.eventkey);
		this.program  = this.eventService.getProgramWithEventKey(this.eventkey);  	
  }

  checkNextComposer(compo,prevcompo) {
  	if(prevcompo) {
  		if(compo.composerlastname == prevcompo.composerlastname) return false;
  		else return true;
		}
  	else return true;
  }

}
