import { AuthService } from './../../core/auth.service';
import { Component, OnInit , Input, Inject } from '@angular/core';
import { MemberService } from '../../members/shared/member.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management-child',
  templateUrl: './management-child.component.html',
  styleUrls: ['./management-child.component.scss']
})

export class ManagementChildComponent implements OnInit {
  @Input() agentData: any;
  artists: any;
  showAddButton: Boolean = true;
  selectedMember: any;
  showSelect: boolean;
  artistsList: any;
  data: any;

  constructor(private memberService: MemberService,
              public auth: AuthService,
              public dialog: MatDialog,
              private router: Router) {

  }

  ngOnInit() {
    this.artists = this.memberService.getArtistInAgentList(this.agentData.$key);
  }

  removeArtist(artist) {
    this.memberService.removeArtistInMember(this.agentData, artist);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogManagementComponent, {
      width: '650px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'filterEventsDialogPanel',
      data: {
          agentData: this.agentData
       }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showSelected() {
    this.selectedMember = this.agentData;
    this.showSelect = true;
    console.log(this.agentData.lastname);
  }
}


@Component({
  selector: 'app-dialog-management',
  templateUrl: 'dialog-management.html',
  styleUrls: ['./dialog-management.scss']
})
export class DialogManagementComponent implements OnInit {

  artistsList: any;
  agentData: any;

  constructor(
    private ms: MemberService,
    private router: Router,
    private auth: AuthService,
    public dialogRef: MatDialogRef<DialogManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.artistsList = this.ms.getArtistsList();
      this.agentData = this.data.agentData ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }
  
  insertAgentInMember(artist) {
    this.ms.addOneArtistInAgentCollection(artist, this.agentData);
    this.onNoClick();
  }

  insertAllArtistInMember(artists) {
    this.ms.addAllartistInAgentCollection(artists, this.agentData);
    this.onNoClick();
  }

}
