import { AuthService } from './../../core/auth.service';
import { LogClass } from './../../shared/logclass';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Composer } from './composer';


import { map } from 'rxjs/operators';

@Injectable()
export class ComposerService {

  composerCollection        : AngularFirestoreCollection<unknown>;
  compositionCollection     : AngularFirestoreCollection<unknown>;
  allCompositionCollection  : AngularFirestoreCollection<unknown>;
  programCollection         : AngularFirestoreCollection<unknown>;
  eventCollection           : AngularFirestoreCollection<unknown>;

  composers: Observable<any[]>; 
  composer:  Observable<any>;
  addCompoError: any;
  logClass: LogClass;

  constructor(
    private afs   : AngularFirestore,
    private router: Router,
    private auth  : AuthService) {

    this.composerCollection       = this.afs.collection('composers', ref => ref.orderBy('lastname'));
    this.compositionCollection    = this.afs.collection('compositions', ref => ref.orderBy('title'));
    this.allCompositionCollection = this.afs.collection('compositions', ref => ref.orderBy('composerkey'));
    this.programCollection        = this.afs.collection('programs');
    this.eventCollection          = this.afs.collection('events');
    this.logClass                 = new LogClass(this.auth, this.afs);

  }


/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// G E T 
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


//// FIRESTORE

  getComposersList() {
    return this.composerCollection.get().pipe(
      map((arr) => {
        return arr.docs.map((snap:any) => Object.assign(
          snap.data(), 
          // const pointRef: Observable<any> = this.afs.object(`composers/${snap.payload.doc.id}/compositions`).valueChanges();
          { compositions: snap.data().compositions?Object.entries(snap.data().compositions):null},        
          { $key: snap.id }) );
      })
    );
  }

  getCompositionsList() {
    return this.allCompositionCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );
  }

  getComposerWithKey(key: string): Observable<Composer> {
    const composerPath = `composers/${key}`;
    this.composer      = this.afs.doc<any>(composerPath).get().pipe(
      map(action => {
        const $key     = action.id;
        // const arrcompositions = action.payload.data().compositions?Object.entries(action.payload.data().compositions):null;        
        // const arrcompositions = null;        
        const data     = { $key, ...action.data() };
        return data;
      })
    );
    return this.composer ;
  }

  getCompositionsWithComposerkey(key: string) {
    return this.afs.collection('composers').doc(key).collection('compositions', ref => ref.orderBy('title')).get().pipe(
      map(arr => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );    
  }











/////////////////////////////////////////////////////////////////////////////////
  //////// C R E A T E
/////////////////////////////////////////////////////////////////////////////////

  createComposer(newComposerForm: NgForm): any {
    var newComposerData = {}
    newComposerData['timestamp'] = Date.now();
    newComposerData['firstname'] = newComposerForm.value.newComposerfirstname?newComposerForm.value.newComposerfirstname:null;
    newComposerData['lastname'] = newComposerForm.value.newComposerlastname?newComposerForm.value.newComposerlastname:null;
    // console.log(newComposerData);  

    const composerref  = this.afs.firestore.collection('composers').doc();
    const commposerkey = composerref.id;

    this.composerCollection.doc(commposerkey)
    .set(newComposerData)
    .then(function() {console.log("Document successfully written!", newComposerData);})
    .catch(function(error) {console.error("Error writing document: ", error);});

    this.logClass.createLog(this.afs,'createComposer',commposerkey);


    // this.router.navigate(['/composers']);  
    return commposerkey;
  }

  createCommposerFromProgram(newComposerForm: NgForm): any {
    var composerkey              = this.createComposer(newComposerForm);
    // var composerkey = 0;
    var newComposerData          = {}    
    newComposerData['$key']      = composerkey?composerkey:null;
    newComposerData['firstname'] = newComposerForm.value.newComposerfirstname?newComposerForm.value.newComposerfirstname:null;
    newComposerData['lastname']  = newComposerForm.value.newComposerlastname?newComposerForm.value.newComposerlastname:null;    
    return newComposerData;
  }


  addCompositionToComposerFromProgram(event,composition,composer) {

    var composerkey                          = composer.$key;
    var eventkey                             = event.$key?event.$key:null;
    var eventtitle                           = event.title?event.title:null;

    var programkey                           = event.programkey?event.programkey:null;
    if(!programkey) {
      const programref                       = this.afs.firestore.collection('programs').doc();
      programkey                             = programref.id;
    }

    const compositionref                     = this.afs.firestore.collection('compositions').doc();
    const compositionkey                     = compositionref.id;

    var newCompositionsData                  = {}
    newCompositionsData['timestamp']         = Date.now();
    newCompositionsData['title']             = composition.viewModel;
    newCompositionsData['compositionkey']    = compositionkey;
    newCompositionsData['composerkey']       = composerkey;
    newCompositionsData['composerfirstname'] = composer.firstname;
    newCompositionsData['composerlastname']  = composer.lastname;

    var batch          = this.afs.firestore.batch();

    var compositionRef = this.compositionCollection.doc(compositionkey).ref;    
    batch.set(compositionRef, newCompositionsData);

    var composerRef    = this.composerCollection.doc(composerkey).collection('compositions').doc(compositionkey).ref;    
    batch.set(composerRef, newCompositionsData);

    console.log(programkey);

    newCompositionsData['compositiontitle'] = composition.viewModel;
    newCompositionsData['compositionkey']   = compositionkey;
    newCompositionsData['eventkey']         = eventkey;
    newCompositionsData['programkey']       = programkey;
    newCompositionsData['eventtitle']       = eventtitle;    

    var programRef = this.programCollection.doc(programkey).collection('compositions').doc(compositionkey).ref; 
    batch.set(programRef, newCompositionsData); 

    var eventRef = this.eventCollection.doc(eventkey).collection('program').doc(compositionkey).ref; 
    batch.set(eventRef, newCompositionsData);

    batch.commit().then(function() {console.log(newCompositionsData)}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'addCompositionToComposerFromProgram',compositionkey);
    this.router.navigate(['/event$/event/'+eventkey]);    
  }




/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  ///////// U P D A T E
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


  updateComposerField(composer,field,value): void {
    console.log(composer);
    var composerkey = composer.$key?composer.$key:0;
    var updateData = {};
    updateData[`${field}`] = value;
    if (composerkey) {
      this.composerCollection.doc(composerkey).update(updateData); 
      this.logClass.createLog(this.afs,' updateComposerField', composerkey, field);     
    }
    else { console.log('composerkey undefined')}
  }


/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// D E L E T E 
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


  deleteCompositionFromComposer(composerkey,compositionkey): void {
    this.deleteComposition(composerkey,compositionkey);
  }

  deleteCompositionFromCompositionList(composition): void {
    var composerkey     = composition?composition.composerkey:null;
    var compositionkey  = composition?composition.$key:null;
    this.deleteComposition(composerkey,compositionkey);
  }

  deleteComposition(composerkey,compositionkey) {
    var batch = this.afs.firestore.batch();

    var compositionRef = this.compositionCollection.doc(compositionkey).ref;    
    batch.delete(compositionRef);   

    var deleteCompositionFromComposerRef = this.composerCollection.doc(composerkey).collection('compositions').doc(compositionkey).ref;
    batch.delete(deleteCompositionFromComposerRef);   

    batch.commit().then(function() {console.log('Batch Delete' + compositionkey)});  
    this.logClass.createLog(this.afs, 'deleteComposition', compositionkey);    
  }  

  deleteComposer(composer): void {
    var composerkey = composer.$key;
    this.composerCollection.doc(composerkey).delete();
    this.logClass.createLog(this.afs, 'deleteComposer', composerkey);
    // this.deleteAllCompositionsOfAComposer(composerkey);
  }



/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// I M P O R T
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////



  importCompositions(compositions,composer):void {

    var composerkey = composer.$key;
    var compositionsValue = compositions.value;
    var nbOfLine = (compositionsValue.match(/\n/g)||[]).length;

    for(var i=0;i<=nbOfLine;i++)
    {
      var line = compositionsValue.split('\n')[i];
      var newCompositionsData = {}
      newCompositionsData['timestamp'] = Date.now();
      newCompositionsData['title'] = line;
      newCompositionsData['composerkey'] = composerkey;
      newCompositionsData['composerfirstname'] = composer.firstname;
      newCompositionsData['composerlastname'] = composer.lastname;

      if(line) {

        const commositionref  = this.afs.firestore.collection('compositions').doc();
        const compositionkey  = commositionref.id;

        newCompositionsData['compositionkey'] = compositionkey;

        var batch = this.afs.firestore.batch();

        var compositionRef = this.compositionCollection.doc(compositionkey).ref;    
        batch.set(compositionRef, newCompositionsData );

        var composerRef = this.composerCollection.doc(composerkey).collection('compositions').doc(compositionkey).ref; 
        batch.set(composerRef, newCompositionsData);    

        batch.commit().then(function() {console.log('Batch Commited')});

      }
      else console.log("Composition not imported to DB");
    }
  }


  importComposerFromJson(i,t,composer): any {
    
    var composerData              = {}
    composerData['timestamp']     = composer.timestamp;
    composerData['firstname']     = composer.firstname.trim();
    composerData['lastname']      = composer.lastname.trim();

    var compositions              = composer.compositions?composer.compositions:0;
    var newCompositionsList       = {};

    const composerref  = this.afs.firestore.collection('composers').doc();
    const composerkey  = composerref.id;

    // var batch = this.afs.firestore.batch();    

    this.composerCollection.doc(composerkey)
    .set(composerData)
    .then(function() {console.log("Document successfully written!",i,'/',t, composerData);})
    .catch(function(error) {console.error("Error writing document: ", error);});

    if(compositions) { 

      for (var compo in compositions) {

        var compovalue = compositions[compo];
        var compokey = compo;

        const compositionref  = this.afs.firestore.collection('compositions').doc();
        const compositionkey  = compositionref.id;

        // Create Object For Member SubNode
        newCompositionsList[compositionkey] = compovalue;

        // Create Object For Composition Node
        var newComposition = {};
        newComposition["title"]             = compovalue;
        newComposition["composerkey"]       = composerkey;
        newComposition["composerfirstname"] = composer.firstname.trim();
        newComposition["composerlastname"]  = composer.lastname.trim();
        newComposition["timestamp"]         = Date.now();
        newComposition["compositionkey"]    = compositionkey;

        this.compositionCollection.doc(compositionkey)
        .set(newComposition)
        .then(function() {console.log("Document successfully written!", newComposition);})
        .catch(function(error) {console.error("Error writing document: ", error);});

        this.composerCollection.doc(composerkey).collection('compositions').doc(compositionkey)
        .set(newComposition)
        .then(function() {console.log("Document successfully written!", newComposition);})
        .catch(function(error) {console.error("Error writing document: ", error);});

        // console.log(compovalue, compokey);
      }
      console.log(i);
    }
  }



  
  // Dumb Functions
  emptyField(n: string) {
    if(!n || n=='') return '';
    else return n;
  }

}