import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../../core/auth.service';
import { switchMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventService } from './../shared/event.service';

@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.scss']
})
export class TourDetailComponent implements OnInit {

  tour:  Observable<any>;
  tourkey: string;
  userOnline: any;
  showSendEmailButton = true;

  endpoint = 'https://us-central1-kajimotomusic-prod.cloudfunctions.net/httpEmail';

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private http: HttpClient,
    public  auth: AuthService,
    public dialog: MatDialog) { }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
        this.tourkey = params['id'];
    });

    this.tour = this.route.paramMap.pipe(switchMap((params: ParamMap) => this.eventService.getTourWithKey(params.get('id'))));

  }

  updateTourField(tour, field, value) {
    this.eventService.updateTourField(tour, field, value, this.userOnline);
  }

  goBack(): void {
    this.location.back();
  }

  sendUserToController(user) {
    this.userOnline = user;
  }

  deleteTour(tour) {
    this.eventService.deleteTour(tour);
    this.tour = null;
    this.location.back();
  }

  checkIsRouting(is) {
    if (is === true) { return 'warn'; } else { return 'black'; }
  }

  checkIsRoutingBeforeUpdate(tour) {
    if (tour.isrouting === true) { this.updateTourField(tour, 'isrouting', 'false'); } else {
      this.updateTourField(tour, 'isrouting', true); }
  }


  confirmeSendingEmail(): void {
    const dialogRef = this.dialog.open(ConfirmationEmailComponent, {
       width: '400px',
       backdropClass: 'filterEventsDialog',
       panelClass: 'searchDialogPanel',
       data: this.tour
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  confirmeDeleteTour(): void {
    const dialogRef = this.dialog.open(DeleteTourComponent, {
      width: '400px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'searchDialogPanel',
      data: this.tour
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }



}







@Component({
  selector: 'app-confirmation-email',
  templateUrl: 'confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.scss']
})
export class ConfirmationEmailComponent implements OnInit {

  endpoint = 'https://us-central1-kajimotomusic-prod.cloudfunctions.net/httpEmail';
  tour: any;
  showSendEmailButton = true;

  constructor(
    // private ms: MemberService,
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmationEmailComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.tour = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendEmail(email, tourkey, tourtitle, lastname, firstname?) {
    const data = {
      toEmail    : email,
      lastname   : lastname,
      firstname  : firstname ? firstname : '',
      sentence   : 'Please click on the link to get your next itinerary',
      tourkey    : tourkey,
      tourtitle  : tourtitle,
      toururl    : `https://kajimotomusicparis.com/event$/routing/${tourkey}`
    };

    this.http.post(this.endpoint, data)
      .subscribe(
        (data) => {
          console.log('data', data);
        },
        (err) => console.error(err),
        () => {
          console.log('Success');
          // this.eventService.addRoutingHistory(tourkey,email);
        }
      );
  }

  removeSendEmail() {
    this.showSendEmailButton = false;
    setTimeout(() => this.showSendEmailButton = true, 3500);
    this.onNoClick();
  }


  ngOnInit() {
  }
}








@Component({
  selector: 'app-delete-tour',
  templateUrl: 'delete-tour.component.html',
  styleUrls: ['./delete-tour.component.scss']
})
export class DeleteTourComponent implements OnInit {

  tour: any;

  constructor(
    // private ms: MemberService,
    private router: Router,
    public dialogRef: MatDialogRef<DeleteTourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
                             private eventService: EventService) {

    this.tour = data;
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  removeTour(tour) {
      this.eventService.deleteTour(tour);
      this.onNoClick();
  }
 
  ngOnInit() {
  }
}


