import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

// FIREBASE

import { ComposerDetailComponent } from './../composer-detail/composer-detail.component';
import { ComposerListComponent } from './../composer-list/composer-list.component';
import { ComposerFormComponent } from './../composer-form/composer-form.component';
import { ComposerService } from './composer.service';

import { CompositionListComponent } from './../composition-list/composition-list.component';
import { CompositionLengthComponent } from './../composition-length/composition-length.component';
import { CompositionRoutingModule } from './composition-routing.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    AngularMaterialModule,
    AngularMaterialModule,
    CompositionRoutingModule
  ],
  declarations: [
	  ComposerListComponent,
	  ComposerFormComponent,
    ComposerDetailComponent,
    CompositionListComponent,
    CompositionLengthComponent
  ],
  providers: [
  	ComposerService
  ]
})
export class ComposerModule { }
