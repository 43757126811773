<!-- <pre>{{ goodUser | async | json }}</pre> -->
<!-- <h1>{{ (member | async)?.role.key }}</h1> -->
<!-- <pre>{{ instruments | async | json }}</pre>  -->


<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<!-- <mat-card *ngIf="dev"> -->
<div class="fx-col" *ngIf="member | async as member">
	<div class="fx-col" *ngIf="auth.user | async as user">	
		<div class="fx-col" *ngIf="checkIfArtistManagedByagent(member.agentkeys,user.memberkey) || user.role=='admin';else noFound">
			<h1 class="fx-100">{{ member.firstname }} {{ member.lastname }} </h1>
			<div class="fx-col fx-100">
				<!-- ------------------------------------------------------------------------- -->
				<!-- O F F I C E -->				
				<div class="fx-col">
					<mat-radio-group class="office-radio" color="warn"
						[(ngModel)]="member.office"
						(ngModelChange)="updateField(member,'office',member.office)">
					  <mat-radio-button value="tokyo" color="warn">Tokyo</mat-radio-button>
					  <mat-radio-button value="paris" color="warn">Paris</mat-radio-button>
					  <mat-radio-button value="beijing" color="warn">Beijing</mat-radio-button>
					</mat-radio-group>
					<br><br>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- I N S T R U M E N T S -->
				<div class="fx-col" *ngIf="member.role=='Artist'">		
					<div>
						<hr><h2 (click)="isInstruOpen = !isInstruOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Instruments</h2>
					</div>
					<div *ngIf="isInstruOpen" class="div-pad" [ngStyle]="{'padding-bottom':'40px'}">
						<h3>
							Default Instrument : 
							<strong *ngIf="member.instrument">{{ member.instrument.name }}</strong>
							<button (click)="seeInstrumentMenuSelect = !seeInstrumentMenuSelect" mat-icon-button aria-label="Edit Member Instrument">
								<mat-icon *ngIf="!seeInstrumentMenuSelect">edit</mat-icon>
								<mat-icon *ngIf="seeInstrumentMenuSelect">close</mat-icon>
							</button>						
						</h3>									
						
						<mat-form-field *ngIf="seeInstrumentMenuSelect" class="width-100">
							<mat-label> Change Default Instrument</mat-label>
							<mat-select  
								[(ngModel)]="member.instrument"
								(ngModelChange)="updateField(member,'instruments',member.instrument,'default')">
									<mat-option *ngFor="let instrument of instruments | async" [value]="instrument">
											<strong>{{ instrument.name | titlecase }}</strong> | {{ instrument.category | titlecase}}
									</mat-option>
							</mat-select>
						</mat-form-field>

						<div>
							<button  mat-button color="black" 
								*ngIf="!member.instruments[1]&&!seeSecondInstrumentMenuSelect"
								(click)="seeSecondInstrumentMenuSelect = true">
									<mat-icon aria-hidden="true">add</mat-icon> Add Second Instrument
							</button>
						</div>	

						<h3 *ngIf="member.instruments[1]">
							All Instruments : 
							<strong><span>1-</span> {{ member.instruments[0] }} / <span>2-</span> {{ member.instruments[1] }}</strong>
							<button (click)="seeSecondInstrumentMenuSelect = !seeSecondInstrumentMenuSelect" mat-icon-button aria-label="Edit Member Instrument">
								<mat-icon *ngIf="!seeSecondInstrumentMenuSelect" matTooltip="Edit Second Instrument" matTooltipPosition="after">edit</mat-icon>
								<!-- <mat-icon *ngIf="seeSecondInstrumentMenuSelect" color="warning" matTooltip="Delete Second Instrument" matTooltipPosition="after">delete</mat-icon> -->
								<mat-icon *ngIf="seeSecondInstrumentMenuSelect">close</mat-icon>
							</button>							
						</h3>			

						<mat-form-field *ngIf="seeSecondInstrumentMenuSelect" class="width-100">
							<mat-label>Add Second Instrument </mat-label>
							<mat-select  
								[(ngModel)]="member.instrument[1]"
								(ngModelChange)="updateField(member, 'instruments', member.instrument[1], 'other')">
									<mat-option *ngFor="let instrument of instruments | async" [value]="instrument">
											<strong>{{ instrument.name | titlecase }}</strong> | {{ instrument.category | titlecase}}
									</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>								
				<!-- ------------------------------------------------------------------------- -->
				<!-- B A S I C     I N F O -->	
				<div class="fx-col">												
					<div>
						<hr><h2 (click)="isInfoOpen = !isInfoOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Basic Info</h2>
					</div>
					<div *ngIf="isInfoOpen" class="fx-row fx-wrap"  [ngStyle]="{'padding':'30px 10px'}">
						<mat-form-field class="fx-49">
							<mat-label>First name </mat-label>
						  <input matInput   required
							  [(ngModel)]="member.firstname"
							  (ngModelChange)="updateField(member,firstnameField,member.firstname)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">	
							<mat-label>Last name </mat-label>					
						  <input matInput   required
						  [(ngModel)]="member.lastname"
						  (ngModelChange)="updateField(member,'lastname',member.lastname)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Nationality </mat-label>						
						  <input matInput  
						  [(ngModel)]="member.nationality"
						  (ngModelChange)="updateField(member,'nationality',member.nationality)">
						</mat-form-field>
						&nbsp;
						<mat-form-field  class="fx-49">
							<mat-label>Birth Date </mat-label>
						  <input matInput   disabled
						  [matDatepicker]="picker"
						  [(ngModel)]="member.birthdate"
						  (ngModelChange)="updateField(member,'birthdate',member.birthdate)">
						  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						  <mat-datepicker #picker disabled="false"></mat-datepicker>
						</mat-form-field>
						<br>
						<br>
						<mat-form-field class="fx-49">
							<mat-label>Email </mat-label>
						  <input matInput   type="email"
						  [(ngModel)]="member.email"
						  (ngModelChange)="updateField(member,emailField,member.email)">
					  </mat-form-field>
					  &nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Website </mat-label>						
						  <input matInput   
						  [(ngModel)]="member.website"
						  (ngModelChange)="updateField(member,websiteField,member.website)">
						</mat-form-field>
					  &nbsp;					  
						<mat-form-field class="fx-49">
							<mat-label> Phone</mat-label>
						  <input matInput mat-maxlength="3"  
						  [(ngModel)]="member.phone"
						  (ngModelChange)="updateField(member,phoneField,member.phone)">
						</mat-form-field>  	
					  &nbsp;		
						<mat-form-field class="fx-49">
							<mat-label>Phone n°2 </mat-label>
						  <input matInput mat-maxlength="3"  
						  [(ngModel)]="member.phone2"
						  (ngModelChange)="updateField(member,'phone2',member.phone2)">
						</mat-form-field>  										
						<div class="fx-100">
							<h3>First address</h3>
						</div>
						<mat-form-field class="fx-49">
							<mat-label>Street </mat-label>
						  <input matInput   
						  [(ngModel)]="member.street"
						  (ngModelChange)="updateField(member,streetField,member.street)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Zip </mat-label>
						  <input matInput   
						  [(ngModel)]="member.zip" maxlength="10"
						  (ngModelChange)="updateField(member,zipField,member.zip)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>City </mat-label>
						  <input matInput  
						  [(ngModel)]="member.city"
						  (ngModelChange)="updateField(member,cityField,member.city)">
						</mat-form-field> 
						&nbsp;  				
						<mat-form-field class="fx-49">
							<mat-label>Country </mat-label>
						  <input matInput  
						  [(ngModel)]="member.country"
						  (ngModelChange)="updateField(member,'country',member.country)">
						</mat-form-field>
						<div class="fx-100">
							<h3>Second address</h3>
						</div>
						<mat-form-field class="fx-49">
							<mat-label>Street </mat-label>
						  <input matInput   
						  [(ngModel)]="member.address2street"
						  (ngModelChange)="updateField(member,'address2street',member.address2street)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label> Zip</mat-label>
						  <input matInput   
						  [(ngModel)]="member.address2zip" maxlength="10"
						  (ngModelChange)="updateField(member,'address2zip',member.address2zip)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label> City</mat-label>
						  <input matInput  
						  [(ngModel)]="member.address2city"
						  (ngModelChange)="updateField(member,'address2city',member.address2city)">
						</mat-form-field> 
						&nbsp;  				
						<mat-form-field class="fx-49">
							<mat-label>Country </mat-label>
						  <input matInput  
						  [(ngModel)]="member.address2country"
						  (ngModelChange)="updateField(member,'address2country',member.address2country)">
						</mat-form-field>
						<mat-form-field class="fx-100">
							<mat-label>Comments & Remarks </mat-label>
							<textarea matInput rows="5" 
								  
								[(ngModel)]="member.comment" 
								(ngModelChange)="updateField(member,'comment',member.comment)"></textarea>
						</mat-form-field>	
					</div>
				</div>				
				<!-- ------------------------------------------------------------------------- -->
				<!-- P R O F I L E   P I C T U R E  &  C A R R O U S S E L -->
				<div class="fx-col" *ngIf="user.role=='admin'">
					<div *ngIf="member.role=='Artist'">
						<hr><h2 (click)="isImagesOpen = !isImagesOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Photos</h2>
					</div>
					<div *ngIf="member.role=='Artist'" [hidden]="!isImagesOpen" class="div-pad">

						<h3>Profile Picture</h3>
					  <button mat-raised-button type="button" [routerLink]="['./../../../uploadfile', 'profile',member.$key]">
						  <mat-icon>add</mat-icon> Upload New Profile Picture
					  </button>
						<br>
						<img src="{{ member.profilepic }}" class="profile-pic">
				
						<h3>Carousel Images</h3>				
					  <button mat-raised-button type="button" [routerLink]="['./../../../uploadfile', 'carousel',member.$key]">
						  <mat-icon>add</mat-icon> Upload New Carousel Picture
					  </button>
				
						<div class="show-pic-carou">
							<div *ngIf="member.imgcarrousel1">
								<img src="{{ member.imgcarrousel1 }}"><br>
								<mat-icon (click)="removeCarouselPic(member,1,member.imgcarrousel1)">clear</mat-icon>
								<span>Credit : <strong>{{ member.imgcarrousel1credit }}</strong></span>
							</div>
							<div *ngIf="member.imgcarrousel2">
								<img src="{{ member.imgcarrousel2 }}"><br>
								<mat-icon (click)="removeCarouselPic(member,2,member.imgcarrousel2)">clear</mat-icon>
								<span>Credit : <strong>{{ member.imgcarrousel2credit }}</strong></span>
							</div>
							<div *ngIf="member.imgcarrousel3">
								<img src="{{ member.imgcarrousel3 }}"><br>
								<mat-icon (click)="removeCarouselPic(member,3,member.imgcarrousel3)">clear</mat-icon>
								<span>Credit : <strong>{{ member.imgcarrousel3credit }}</strong></span>
							</div>
							<div *ngIf="member.imgcarrousel4">
								<img src="{{ member.imgcarrousel4 }}"><br>
								<mat-icon (click)="removeCarouselPic(member,4,member.imgcarrousel4)">clear</mat-icon>
								<span>Credit : <strong>{{ member.imgcarrousel4credit }}</strong></span>
							</div>
							<div *ngIf="member.imgcarrousel5">
								<img src="{{ member.imgcarrousel5 }}"><br>
								<mat-icon (click)="removeCarouselPic(member,5,member.imgcarrousel5)">clear</mat-icon>
								<span>Credit : <strong>{{ member.imgcarrousel5credit }}</strong></span>
							</div>
						</div>
						
						<br><br><h3>Press Material</h3>
				
						<mat-form-field class="full-width">	
							<mat-label> Dropbox Link for Hi-res Material</mat-label>					
						  <input matInput   
						  [(ngModel)]="member.hireslink"
						  (ngModelChange)="updateField(member,'hireslink',member.hireslink)">
						</mat-form-field>	
					</div>
				</div>					
				<!-- ------------------------------------------------------------------------- -->
				<!-- M A N A G E M E N T & C O N T A C T -->						
				<div class="fx-col" *ngIf="member.role=='Artist'">
					<div>
						<hr><h2 (click)="isManagementOpen = !isManagementOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Management</h2>
					</div>
					<div *ngIf="isManagementOpen" class="fx-row fx-wrap"  [ngStyle]="{'padding':'30px 10px'}">								
						<mat-form-field class="fx-49">
							<mat-label> General Management : Name</mat-label>
						  <input matInput   
							  [(ngModel)]="member.generalmanagementname"
							  (ngModelChange)="
							  updateField(member,'generalmanagementname',member.generalmanagementname)">
						</mat-form-field>
						&nbsp;				
						<mat-form-field class="fx-49">
							<mat-label>Représentation </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementrepresentation"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementrepresentation',member.kajimotomanagementrepresentation)">
						</mat-form-field>	
						&nbsp;				
						<mat-form-field class="fx-49">
							<mat-label> Kajimoto Management Tokyo : Name</mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementtokyoname"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementtokyoname',member.kajimotomanagementtokyoname)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Tokyo : Email </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementtokyoemail"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementtokyoemail',member.kajimotomanagementtokyoemail)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Beïjing : Name </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementbeijingname"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementbeijingname',member.kajimotomanagementbeijingname)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Beïjing : Email </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementbeijingemail"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementbeijingemail',member.kajimotomanagementbeijingemail)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Paris : Name </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementparisname"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementparisname',member.kajimotomanagementparisname)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Paris : Email </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementemail"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementemail',member.kajimotomanagementemail)">
						</mat-form-field>	
						&nbsp;		
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Paris : Phone Office </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementphoneoffice"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementphoneoffice',member.kajimotomanagementphoneoffice)">
						</mat-form-field>
						&nbsp;
						<mat-form-field class="fx-49">
							<mat-label>Kajimoto Management Paris : Mobile Phone </mat-label>
						  <input matInput   
							  [(ngModel)]="member.kajimotomanagementphonemobile"
							  (ngModelChange)="
							  updateField(member,'kajimotomanagementphonemobile',member.kajimotomanagementphonemobile)">
						</mat-form-field>					
					</div>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- B I O G R A P H Y -->
				<div class="fx-col" *ngIf="member.role=='Artist'">				
					<div>
						<hr><h2 (click)="isBioOpen = !isBioOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Biography</h2>
					</div>
					<div *ngIf="isBioOpen" class="fx-col">
						<div class="fx-row fx-wrap"  [ngStyle]="{'padding':'10px'}">
						  <button mat-raised-button type="button" class="ml-0" 
								  *ngIf="butVisOn" 
							  (click)="
										  bioFormInput=true;longBioFormInput=false;
										  butVisOff=true;butVisOn=false;
										  butLongVisOff=false;butLongVisOn=true;
										  ">
							  <mat-icon>visibility</mat-icon> Edit Short Biography
						  </button>
						  <button mat-raised-button type="button" class="ml-0"
								  *ngIf="butVisOff"
							  (click)="bioFormInput=false;butVisOff=false;butVisOn=true">
							  <mat-icon>visibility_off</mat-icon> Hide Short Biography
						  </button>
					
						  <button mat-raised-button type="button" class="ml-0" 
								  *ngIf="butLongVisOn" 
							  (click)="
										  longBioFormInput=true;bioFormInput=false;
										  butLongVisOff=true;
										  butLongVisOn=false;
										  butVisOff=false;butVisOn=true
										  ">
							  <mat-icon>visibility</mat-icon> Edit Long Biography
						  </button>
						  <button mat-raised-button type="button" class="ml-0"
								  *ngIf="butLongVisOff"
							  (click)="
										  longBioFormInput=false;
										  butLongVisOff=false;butLongVisOn=true;
										  butVisOff=false;butVisOn=true
										  ">
							  <mat-icon>visibility_off</mat-icon> Hide Long Biography
						  </button>
						  <button mat-raised-button type="button" class="ml-0" color="warn"
								*ngIf="butLongVisOff||butVisOff"
								[disabled]="isShortBioEnough(member.bioshorthtmlen)||isShortBioEnough(member.bioshorthtmlfr)"
							  (click)="
											saveBiography(member, member.bioshorthtmlfr, member.biolonghtmlfr, member.bioshorthtmlen, member.biolonghtmlen);
										  bioFormInput=false;longBioFormInput=false;
										  butLongVisOff=false;butLongVisOn=true;
										  butVisOff=false;butVisOn=true
										  ">
							  <mat-icon>save</mat-icon> Save Biography
						  </button>
						</div>
						<div [ngStyle]="{'padding':'10px'}">     
						  <div id="bio-forms-input"	*ngIf="bioFormInput">
								<p>French Biography (Short Version)</p>
								<ngx-wig 
									placeholder="French Biography (Short Version)"
									[buttons]="'bold, italic, link'"
									[(ngModel)]="member.bioshorthtmlfr">
								</ngx-wig>
						  	<span class="bio-count" *ngIf="member.bioshorthtmlfr" [ngClass]="{'car-wrong':4000 < member.bioshorthtmlfr.length}">{{ member.bioshorthtmlfr.length }} < 4000 </span> 

								<p>English Biography (Short Version)</p>
								<ngx-wig 
									placeholder="English Biography (Short Version)"
									[buttons]="'bold, italic, link'"
									[(ngModel)]="member.bioshorthtmlen">
								</ngx-wig>
						  	<span class="bio-count" *ngIf="member.bioshorthtmlen" [ngClass]="{'car-wrong':4000 < member.bioshorthtmlen.length}">{{ member.bioshorthtmlen.length }} < 4000 </span> 							
						  </div>
				
						  <div id="bio-forms-input"	*ngIf="longBioFormInput">
								<p>French Biography (Long Version)</p>
								<ngx-wig 
									placeholder="French Biography (Long Version)"
									[buttons]="'bold, italic, link'"
									[(ngModel)]="member.biolonghtmlfr">
								</ngx-wig>							
								<p>English Biography (Long Version)</p>
								<ngx-wig 
									placeholder="English Biography (Long Version)"
									[buttons]="'bold, italic, link'"
									[(ngModel)]="member.biolonghtmlen">
								</ngx-wig>
						  </div>
							<br>
						  <button mat-raised-button type="button" class="ml-0" color="warn"
								  *ngIf="butLongVisOff||butVisOff"
									[disabled]="isShortBioEnough(member.bioshorthtmlen)||isShortBioEnough(member.bioshorthtmlfr)"
							  	(click)="
											saveBiography(member, member.bioshorthtmlfr, member.biolonghtmlfr, member.bioshorthtmlen, member.biolonghtmlen);
										  bioFormInput=false;longBioFormInput=false;
										  butLongVisOff=false;butLongVisOn=true;
										  butVisOff=false;butVisOn=true
										  ">
							  <mat-icon>save</mat-icon> Save Biography
						  </button>
						</div>
					</div>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- P R E S S -->
				<div class="fx-col" *ngIf="member.role=='Artist'">							
					<div>
						<hr><h2 (click)="isPressOpen = !isPressOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Press</h2>
					</div>
					<div class="fx-col" *ngIf="isPressOpen">
						<div>
							<button mat-button [routerLink]="['./../../../press$/press', member.$key]" color="black">
								<mat-icon aria-hidden="true">add</mat-icon> Add Press
						  </button>		
							<button mat-button color="black" 
								*ngIf="!member.ispress"
								(click)="updateField(member,'ispress',true)">
									<mat-icon>grid_on</mat-icon> Publish Press Online
							</button>
							<button mat-button color="warn" 
								*ngIf="member.ispress"
								(click)="updateField(member,'ispress',false)">
									<mat-icon>grid_off</mat-icon> Remove Press Offline
							</button>
						</div>
						<div class="fx-col" *ngIf="press | async as press">
							<div *ngFor="let press of press"
								class="fx-row fx-wrap fx-start-start" 
								[ngStyle]="{'padding':'20px 10px'}">
									<div class="fx-20 fx-col" [ngStyle]="{'font-size':'0.9em'}">
										<span [ngStyle]="{'font-weight':'bold'}">{{ press.date | date }}</span>
										<span [ngStyle]="{'font-weight':'bold'}">{{ press.title }} </span>
										<span>{{ press.info }} </span>
									</div>
									<div class="fx-70" [ngStyle]="{'font-size':'0.9em'}">
										<span innerHTML="{{ 	
											(press.content.length>600) ? 
											(press.content | slice:0:600)+'...':(press.content) 
										}}" *ngIf="press && press.content">
										</span>
									</div>
									<div class="fx-10">
										<button mat-icon-button color="black" class="black-hover"
											[routerLink]="['./../../../press$/pressdetail', press.$key]">
											<mat-icon>info</mat-icon>
										</button>								
										<button mat-icon-button color="warn"
											(click)="deletePress(press)">
											<mat-icon>delete</mat-icon>
										</button>
									</div>									
							</div>
						</div>				
					</div>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- N E W S -->
				<div class="fx-col" *ngIf="user.role=='admin' && member.role=='Artist'">							
					<div (click)="isNewsOpen = !isNewsOpen">
						<hr><h2><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>News</h2>
					</div>
					<div class="fx-col" *ngIf="isNewsOpen">
						<div>
							<button mat-button routerLink="./../../../new$/newsform" color="black">
								<mat-icon aria-hidden="true">add</mat-icon> Add News
						  </button>
							<button mat-button color="black"
								*ngIf="!member.isnews"
								(click)="updateField(member,'isnews',true)"><mat-icon>speaker_notes_on</mat-icon>
								Publish News Online
							</button>
							<button mat-button color="warn" 
								*ngIf="member.isnews"
								(click)="updateField(member,'isnews',false)"><mat-icon>speaker_notes_off</mat-icon>
								Remove News Offline
							</button>
						</div>
						<div class="fx-col fx-100" [ngStyle]="{'padding':'20px 10px'}">
							<mat-list dense role="list" *ngIf="news | async as news" >
								<mat-list-item role="listitem" class="news-list" 
								*ngFor="let new of news">
								<span class="fx-row">
									<button mat-icon-button color="black" [routerLink]="['./../../../new$/newsdetail', new.$key]">
										<mat-icon>info</mat-icon>
									</button>
									<button mat-icon-button color="warn"
										*ngIf="new.isonline"
										(click)="updateNewsField(new,'isonline',false)">
										<mat-icon>visibility</mat-icon>
									</button>
									<button mat-icon-button color="black"
										*ngIf="new.isonline==false"
										(click)="updateNewsField(new,'isonline',true)">
										<mat-icon>visibility_off</mat-icon>
									</button>								
									<p matline>
										<strong>
											{{ new.date | date }} : 
											{{ new.title }}&nbsp;:&nbsp;{{ new.info }} 									
										</strong>
										{{ 	
											(new.content.length>600) ? 
											(new.content | slice:0:600)+'...':(new.content) 
										}}
									</p>
								</span>
								</mat-list-item>
							</mat-list>
							<br>
						</div>
					</div>			
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- D I S C O G R A P H I E -->
				<div class="fx-col" *ngIf="user.role=='admin' && member.role=='Artist'">				
					<div>
						<hr><h2 (click)="isDiscogOpen = !isDiscogOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Discography</h2>					
					</div>
					<div *ngIf="isDiscogOpen" class="fx-col">
						<div>
							<button mat-button 
								[routerLink]="['./../../../uploadfile', 'discogs',member.$key]" color="black">
								<mat-icon aria-hidden="true">add</mat-icon> Add New Album
							</button>
							<button mat-button color="black" 
								*ngIf="!member.isdisco"
								(click)="updateField(member,'isdisco',true)">
									<mat-icon>album</mat-icon> Publish Media Online
							</button>
							<button mat-button color="warn" 
								*ngIf="member.isdisco"
								(click)="updateField(member,'isdisco',false)">
									<mat-icon>disc_full</mat-icon> Remove Media Offline
							</button>
						</div>				
						<div>				
						  <mat-grid-list class="discog-margin" *ngIf="member.arrdiscog" cols="4" rowHeight="1:1">
								<mat-grid-tile class="arist-discog"
								  *ngFor="let disc of member.arrdiscog">
								  <mat-grid-tile-header>
									  <mat-icon (click)='deleteDisk(disc,member.$key)'>clear</mat-icon>    	
								  </mat-grid-tile-header>
								  <a href="{{ disc.link }}" target="_blank">
									<img src="{{ disc.url }}" class="responsiveimg discog-img">
								  </a>
								</mat-grid-tile>
						  </mat-grid-list>
						</div> 
					</div>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- M E D I A -->
				<div class="fx-col">				
					<div *ngIf="member.role=='Artist'">
						<hr><h2  (click)="isMediaOpen = !isMediaOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Media</h2>									
					</div>
						
					<div *ngIf="member.role=='Artist'" [hidden]="!isMediaOpen">
						<button mat-button [routerLink]="['./../../../uploadmedia', member.$key]" color="black">
							<mat-icon aria-hidden="true">add</mat-icon> Add Media
						</button>
			
						<button mat-button color="black" 
							matTooltip="Publish Media Online" matTooltipPosition="after"
							*ngIf="!member.ismedia"
							(click)="updateField(member,'ismedia',true)">
								<mat-icon>live_tv</mat-icon> Publish Medias Online
						</button>
						<button mat-button color="warn" 
							matTooltip="Remove Media Offline" matTooltipPosition="after"
							*ngIf="member.ismedia"
							(click)="updateField(member,'ismedia',false)">
								<mat-icon>tv_off</mat-icon> Remove Medias Offline
						</button>					
						<br><br>

						<div [hidden]="!isMediaOpen&&media" *ngIf="media | async as media">
						  <div class="arist-media" *ngFor="let media of media">		
								<mat-icon (click)="removeMedia(media,member.$key)" aria-hidden="true">close</mat-icon>
							  <iframe [src]="('https://www.youtube.com/embed/' + media.link + '?rel=0&amp;controls=0') | safe"  allow="autoplay; encrypted-media" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
				<!-- ------------------------------------------------------------------------- -->
				<!-- E V E N T S -->
				<div class="fx-col">				
					<div *ngIf="member.role=='Artist'">
						<hr><h2  (click)="isEventOpen = !isEventOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>Events</h2>								
					</div>
					<div class="fx-100" *ngIf="member.role=='Artist'&&isEventOpen">								
					  <button mat-button [routerLink]="['./../../../event$/eventform']" type="button" class="ml-0">
						  <mat-icon>add</mat-icon> New Event
					  </button>
					  <button mat-button *ngIf="!showAllEvents" (click)="showAllEvents = !showAllEvents;showYears=false" type="button" class="ml-0">
						  <mat-icon>today</mat-icon> All Events
					  </button>					  
					  <button mat-button *ngIf="!showYears" (click)="showYears = !showYears;showAllEvents=false"  type="button" class="ml-0">
						  <mat-icon>today</mat-icon> Select Year
					  </button>				

						<button mat-button color="black" 
							matTooltip="Publish Artist Events Online" matTooltipPosition="after"
							*ngIf="!member.isevent"
							(click)="updateField(member,'isevent',true)">
								<mat-icon>grid_on</mat-icon> Publish Events Online
						</button>
						<button mat-button color="warn" 
							matTooltip="Remove Artist Events From Website" matTooltipPosition="after"
							*ngIf="member.isevent"
							(click)="updateField(member,'isevent',false)">
								<mat-icon>grid_off</mat-icon> Remove Events Offline
						</button>	
					
					</div>
					<div *ngIf="showYears&&isEventOpen"
						class="fx-row fx-wrap fx-100 fx-gap-10"  
						[ngStyle]="{'padding':'10px 10px 30px 10px'}">
						<div class="fx-20 year-button fx-center-center"
							[ngStyle]=""
							*ngFor="let year of timestamp | async"
							[routerLink]="['./../../../events', member.$key,year,member.profilepic,member.lastname+' '+member.firstname]">
							{{year}}
						</div>						
					</div>

					<div *ngIf="showAllEvents&&isEventOpen" [ngStyle]="{'padding-bottom':'20px'}">
						
						<mat-list dense role="list" *ngIf="events | async as events">
							<mat-list-item role="listitem" class="event-list"
							*ngFor="let event of events">
								<span class="fx-row">
									<button mat-icon-button color="black" class="black-hover"
									[routerLink]="['./../../../event$/event', event.$key]">
										<mat-icon>info</mat-icon>
									</button>
									<p matline [ngClass]="{'event-online':event.isonline}">
										<span *ngIf="event.date">
											<strong>{{ event.date | date }}</strong>&nbsp; {{ event.time }} -&nbsp;
										</span>
										<span>
											<strong>Title: </strong>{{ event.title }} - 
										</span>
										<span *ngIf="event.genre">
											<strong>Genre:</strong> {{ event.genre }} 
										</span>
										<span *ngIf="event.comment">
										 - 
											<strong>Comment: </strong>
											{{ 	
												(event.comment.length>200) ? 
												(event.comment | slice:0:200)+'...':(event.comment) 
											}}									
										</span>				
									</p>
								</span>
							</mat-list-item>
						</mat-list>	

					</div>
				</div>					
			</div>								
			<div class="fx-100">
				<hr><br>
				<div class="fx-100" class="fx-row fx-wrap div-pad">
					<button mat-fab 
						routerLink="./../../profile" color="black">
						<mat-icon>home</mat-icon>
					</button>	

					 		
					<button mat-fab matTooltip="Delete Forever" matTooltipPosition="after"
						(click)="deleteMember(member)" color="warn">
						<mat-icon>delete_forever</mat-icon>
					</button> 
					

					<button mat-fab color="black" 
						matTooltip="Publish News Online" matTooltipPosition="after"
						*ngIf="!member.isnews"
						(click)="updateField(member,'isnews',true)"><mat-icon>speaker_notes_on</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove News Offline" matTooltipPosition="after"
						*ngIf="member.isnews"
						(click)="updateField(member,'isnews',false)"><mat-icon>speaker_notes_off</mat-icon>
					</button>


					<button mat-fab color="black" 
						matTooltip="Publish Press Online" matTooltipPosition="after"
						*ngIf="!member.ispress"
						(click)="updateField(member,'ispress',true)"><mat-icon>grid_on</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove Press Offline" matTooltipPosition="after"
						*ngIf="member.ispress"
						(click)="updateField(member,'ispress',false)"><mat-icon>grid_off</mat-icon>
					</button>


					<button mat-fab color="black" 
						matTooltip="Publish Artist Events Online" matTooltipPosition="after"
						*ngIf="!member.isevent"
						(click)="updateField(member,'isevent',true)"><mat-icon>calendar_today</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove Artist Events From Website" matTooltipPosition="after"
						*ngIf="member.isevent"
						(click)="updateField(member,'isevent',false)"><mat-icon>event</mat-icon>
					</button>	


					<button mat-fab color="black" 
						matTooltip="Publish Media Online" matTooltipPosition="after"
						*ngIf="!member.ismedia"
						(click)="updateField(member,'ismedia',true)"><mat-icon>live_tv</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove Media Offline" matTooltipPosition="after"
						*ngIf="member.ismedia"
						(click)="updateField(member,'ismedia',false)"><mat-icon>tv_off</mat-icon>
					</button>	


					<button mat-fab color="black" 
						matTooltip="Publish Discography Online" matTooltipPosition="after"
						*ngIf="!member.isdisco"
						(click)="updateField(member,'isdisco',true)"><mat-icon>album</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove Discography Offline" matTooltipPosition="after"
						*ngIf="member.isdisco"
						(click)="updateField(member,'isdisco',false)"><mat-icon>disc_full</mat-icon>
					</button>			


					<button mat-fab 
						routerLink="./../../../artist/{{ member.$key }}" color="black"
						matTooltip="Go to Artist Public Page" matTooltipPosition="after">
						<mat-icon>remove_red_eye</mat-icon>
					</button>


					<button mat-fab color="black" 
						matTooltip="Publish Artist Online" matTooltipPosition="after"
						*ngIf="!member.isonline"
						(click)="updateField(member,'isonline',true)"><mat-icon>check</mat-icon>
					</button>
					<button mat-fab color="warn" 
						matTooltip="Remove Artist From Website" matTooltipPosition="after"
						*ngIf="member.isonline"
						(click)="updateField(member,'isonline',false)"><mat-icon>clear</mat-icon>
					</button>
				</div>
			</div>
		</div>					
	</div>
</div>


<ng-template #noFound>				
		<br><br>
		<br><br>
		<br><br>
			<mat-card-header>
				<mat-card-title [ngStyle]="{'padding':'20px'}"><strong>Access Denied</strong> </mat-card-title>
			</mat-card-header>	
		<br><br>
		<br><br>
		<br><br>
</ng-template>