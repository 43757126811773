<!-- <pre>{{ members | async | json }}</pre>  -->

<div class="fx-row fx-wrap" >
	<div class="fx-col">
		<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">
		<h1>Kajimotomusic members Paris</h1>
	</div>
	<div class="fx-end-center add-button fx-10 fx-fill" [ngStyle]="{'margin-right':'30px','flex':'10%'}">
		<button mat-icon-button routerLink="./../memberform" color="black">
			<mat-icon>add</mat-icon>
		</button>
	</div>
</div> 

<mat-card appearance="outlined" class="noshadow">
	<mat-card-content *ngIf="members | async; let members">
		<br><br>
		<span [ngStyle]="{'color':'#ccc','margin-left':'10px','font-size':'0.8em','padding':'5px 10px'}">{{ members.length }} members</span>
		<div class="list-container">
		  <div *ngFor="let member of members; let i = index" class="item-member">
		   <!-- [routerLink]="['./../member', member.$key]">  -->
			<mat-icon style="padding: 15px;" matListIcon [ngClass]="{'green':member.ismember}">person</mat-icon>

			<p class="fx-col fx-80" style="font-size: .8em;" matLine> 
				<span><strong>{{member.firstname}} {{member.lastname}}</strong></span>
				<!-- <span>{{member.timestamp | date}} </span> -->
				<span *ngIf="member.role"><strong>{{member.role}}</strong>&nbsp;&nbsp;</span>
				<span *ngIf="member.instrument">{{ member.instrument.name | titlecase }} <i>({{ member.instrument.category | titlecase }})</i> </span>
			</p>
				
			<button mat-icon-button [routerLink]="['./../member', member.$key]">
	        	<mat-icon>info</mat-icon>
	     	</button>
								
			<button *ngIf="member.ismember!=true&&member.ismember!=false" mat-icon-button color="warn" 
			(click)="isMember(member)">
				<mat-icon>add</mat-icon>
			</button> 	
				
				
				<!-- Delete Member with instrument -->
				<button mat-icon-button class="fx-end-center" color="warn"						
				(click)="openConfirmationDialog(member)">
					<mat-icon>remove_circle</mat-icon>
		    	</button>
	
			

				<!-- 				
				<button mat-icon-button color="warn" 
					(click)="addInstruInMember(member)">
					<mat-icon>add</mat-icon>
				</button> 
				-->		    
		  	
			</div>
		</div>
	</mat-card-content>
</mat-card>