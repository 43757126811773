import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  page: Observable<any>;

	title_1: string;
	title_2: string;
	title_3: string;
	creditphoto: string;

	imgkey: string;
	imgname: string;
	imgurl: string;

	imglogokey: string;
	imglogoname: string;
	imglogourl: string;

	txtInEn: boolean = true;
	txtInFr: boolean = false;
	titleInFr: boolean = false;
	titleInEn: boolean = true;

	txt_en: string;

  constructor(
  	public  auth: AuthService,
  	private titleService: Title,
		private ps: PublicService,
		private meta : Meta) { }

	setTitle( newTitle: string) { this.titleService.setTitle( newTitle ); }

  ngOnInit() {

    this.setTitle('Kajimoto Music Paris | À propos | Message du président Masahide Kajimoto');

		this.title_1 = "Message of";
		this.title_2 = "Masahide Kajimoto";
		this.title_3 = "President";
		this.creditphoto = "Eisuke Miyoshi";

		this.imgkey = "-L2zgQDWWT4JFJ0mZsuc";
		this.imgname = "Masa-Kajimoto.jpg";
		this.imgurl = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2FMasa-Kajimoto.jpg?alt=media&token=052c36ad-7467-4d91-b359-6d2e81f96d6e";

		this.imglogokey = "-L3-ARQZ07dzuZa2xImh";
		this.imglogoname = "logo-kajimoto-aboutus.png";
		this.imglogourl = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830";

		this.page = this.ps.getPageVariables('words');

		this.meta.addTags([
			{ name: 'twitter:card', content: 'summary' },
			{ name: 'og:url', content: `https://kajimotomusicparis.com/words` },
			{ name: 'og:title', content: 'About Us - Message of Masahide Kajimoto President in a few words' },
			{ name: 'og:description', content: `Message of Masahide Kajimoto President, to present the company he has founded and developed`,  },
			// tslint:disable-next-line:max-line-length
			{ name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2FMasa-Kajimoto.jpg?alt=media&token=a07f6b8d-0659-4ff1-82ca-98d907e1b4de` },
			// tslint:disable-next-line:max-line-length
			{ name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2FMasa-Kajimoto.jpg?alt=media&token=a07f6b8d-0659-4ff1-82ca-98d907e1b4de`},
      { name: 'description', content: 'Find all the last news of Kajimoto Music Paris Office.' },
      { name: 'title', content: 'Kajimoto Music Paris | À propos | Message du président Masahide Kajimoto' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: 'Kajimoto Music' },
      { name: 'twitter:title', content: 'Kajimoto Music Paris | À propos | Message du président Masahide Kajimoto' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:description', content: `Message of Masahide Kajimoto President, to present the company he has founded and developed` },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2FMasa-Kajimoto.jpg?alt=media&token=a07f6b8d-0659-4ff1-82ca-98d907e1b4de` },
      { name: 'twitter:url', content: `https://kajimotomusicparis.com/words`  },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'msapplication-starturl', content: '/' },
			{ name: 'theme-color', content: '#f48c5b' },
			{name : 'keywords',  content : 'abouts us'},
			{ name : 'url', content: `https://kajimotomusicparis.com/words`}
		// tslint:disable-next-line:indent
		]);
  }

  updatePageField(page, field, value) {
    this.ps.updatePage(page, field, value);
  }

}
