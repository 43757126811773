<!-- <pre>{{ press | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<div *ngIf="press | async as press" class="fx-row fx-wrap" >
	<h1 class="fx-100">{{ press.title }}</h1>
	<h2 class="fx-100">
		<span *ngIf="press.date">{{ press.date | date }}</span>
		&nbsp;&nbsp;
		<span *ngIf="press.info">{{ press.info }}</span>
	</h2>

	<div class="fx-100" [ngStyle]="{'padding':'20px 20px 0px'}">
		<mat-form-field class="full-width">
			<input matInput venueholder="Press Title" required
				[(ngModel)]="press.title"
				(ngModelChange)="updateField(press,'title',press.title)">
		</mat-form-field>
	</div>
	<div class="fx-100" [ngStyle]="{'padding':'0 20px 20px'}">
		<mat-form-field class="full-width">
			<input matInput venueholder="Press Information" required
				[(ngModel)]="press.info"
				(ngModelChange)="updateField(press,'info',press.info)">
		</mat-form-field>			
	</div>
	
	<div class="fx-100" [ngStyle]="{'padding':'5px 20px 0'}">
		<ngx-wig 
			placeholder="Press Content"
			[buttons]="'bold, italic, link'"
			[(ngModel)]="press.content">
		</ngx-wig>
	</div>

  <button mat-raised-button type="button" color="warn"
  	[ngStyle]="{'margin':'0 20px'}"
		[disabled]="!press.content || !press.title"
	  (click)="savePressOnClick(press,'content',press.content)">
	  	<mat-icon>save</mat-icon> Save Press Content And Go Back to Artist
  </button>

</div>