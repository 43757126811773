import { ComposerService } from './../shared/composer.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-composition-length',
  templateUrl: './composition-length.component.html',
  styleUrls: ['./composition-length.component.scss']
})

export class CompositionLengthComponent implements OnInit {
  
  @Input()  composerkey: string;
  compositionslist: any;
  constructor(private cs: ComposerService) { }

  ngOnInit() {
      this.compositionslist = this.cs.getCompositionsWithComposerkey(this.composerkey);
  }
}
