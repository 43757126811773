
<div *ngIf="event | async; let events" >
	<div *ngIf="artists | async; let artist">
			<div *ngIf="programs | async; let program">
					<strong *ngIf="showYesButton">Do you really want to duplicate this event?</strong> 
					<strong *ngIf="!showYesButton">Duplication in progress...</strong>
					<br>
					<button mat-button (click)="showYesButton=false;duplicateEvent(events,artist,program)" cdkFocusInitial *ngIf="showYesButton">YES</button>	
					<button mat-button (click)="onNoClick()" *ngIf="showYesButton">NO</button>		
			</div>
	</div>	
</div>