
<div *ngIf="isLocalCookies&&isCookies=='true'" class="cookietxt fx-row fx-wrap fx-100">
    <div class="fx-90">
      <p> {{cookies}}
		<span class="buttons">
			<span (click)="updateCookies()">Ok</span>
			<span (click)="openDialog('cookies')">En savoir plus</span>
		</span>
      </p>
    </div>
    <!-- <div class="fx-10">
      <button mat-icon-button (click)="updateCookies()">
        <mat-icon [ngStyle]="{'color':'#fff'}">close</mat-icon>
      </button>  
    </div> -->
</div>


<footer class="fx-100 fx-col footer">
	<div class="content fx-row fx-wrap">
	  <div class="fx-40-xs fx-15 fx-center-start fx-start-start-xs menu-footer">
			<mat-list role="list">
				<mat-list-item role="listitem" routerLink="/artists">
					<span class="menu-footer">Artists</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/touring">
					<span class="menu-footer">Touring</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/events">
					<span class="menu-footer">Events</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/offices">
					<span class="menu-footer">Offices</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/words">
					<span class="menu-footer">About Us</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/history">
					<span class="menu-footer">History</span>
				</mat-list-item>
				<mat-list-item role="listitem" routerLink="/contact">
					<span class="menu-footer">Contact</span>
				</mat-list-item>
			</mat-list>
		</div>
	  <div class="fx-start-start fx-15 fx-40-xs">
			<address>
				<h4>Japan</h4>
				<h5>Tokyo</h5>
				<p>6-4-1 Ginza, <br>
				Chuo-Ku, Tokyo 104-0061, Japan <br>
				<a href="tel:+81335740969">Tel: + 81.3.3574.0969</a></p>
			</address>
		</div>
	  <div class="fx-start-start fx-15 fx-40-xs">
			<address>
				<h4>France</h4>
				<h5>Paris</h5>
				<p>27 boulevard St Michel <br>
				75005 Paris FRANCE<br>
				<a href="tel:+33142199265">Tel: +33(0)1 42.19.92.65</a><br>
				<a href="tel:+33142199212">Fax: +33(0)1 42.19.92.12</a></p>
			</address>
		</div>
	  <div class="fx-start-start fx-15 fx-40-xs">
			<address>
				<h4>China</h4>
				<h5>Beijing</h5>
				<p>No. 37, Luanqing Hutong, <br>
				Dongcheng District, <br>
				Beijing, P.R.China <br>
				<a href="861067058895">Tel: +86 10 6705 8895</a></p>
			</address>
		</div>
	  <div class="fx-start-start fx-15 fx-40-xs">
			<mat-list role="list" class="menu-footer">
				<mat-list-item role="listitem" (click)="openDialog('credits')">
					<span class="menu-footer">CREDITS PHOTO</span>
				</mat-list-item>
				<mat-list-item role="listitem" (click)="gotoExternalHref('https://mailchi.mp/2daad7799979/kajimotomusicparis-newsletter')">
					<span class="menu-footer">NEWSLETTER</span>
				</mat-list-item>
				<!-- <mat-list-item role="listitem" (click)="openDialog('jobs')">JOBS</mat-list-item> -->
				<mat-list-item role="listitem" (click)="openDialog('cookies')">
					<span class="menu-footer">COOKIES</span>
				</mat-list-item>
				<mat-list-item role="listitem" (click)="openDialog('terms')">
					<span class="menu-footer">TERMS</span>
				</mat-list-item>
				<mat-list-item role="listitem" (click)="openDialog('legal')">
					<span class="menu-footer">LEGAL</span>
				</mat-list-item>
				<mat-list-item role="listitem">
					<span class="package-info">
						<span>© KAJIMOTO 2024 - </span>
						<span>Version {{ version }}</span>
					</span>
				</mat-list-item>
			</mat-list>
		</div>				
		<div
			class="fx-100 fx-center-start">
				<mat-icon class="but-top-footer fx-center-center" (click)="scrollToTop()">keyboard_arrow_up</mat-icon>
				<br><br><br>
		</div>			
	</div>
</footer>
