<!-- <pre>{{ news | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<h1>News Details</h1>

<div *ngIf="news | async as news" class="nocss" [ngStyle]="{'padding':'10px'}">

	<h2><span *ngIf="news.artistfullname"> <strong>{{ news.artistfullname }}</strong> News</span> : <strong *ngIf="news.title">{{ news.title }}</strong></h2>

	<div>

		<mat-form-field class="full-width">
			<mat-label> Title</mat-label>
			<input matInput maxLength="50"
				[(ngModel)]="news.title"
				(ngModelChange)="updateField(news,'title',news.title)">
			<mat-hint *ngIf="news.title" align="end" aria-live="polite">{{news.title.length}} / 50</mat-hint>
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label>Subtitle </mat-label>
			<input matInput maxLength="50"
				[(ngModel)]="news.subtitle"
				(ngModelChange)="updateField(news,'subtitle',news.subtitle)">
			<mat-hint *ngIf="news.subtitle" align="end" aria-live="polite">{{news.subtitle.length}} / 50</mat-hint>			
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label> Link</mat-label>
			<input matInput 
				[(ngModel)]="news.link"
				(ngModelChange)="updateField(news,'link',news.link)">
		</mat-form-field>		

		<!-- <mat-form-field>
			<mat-label> Date </mat-label>
		  <input matInput [matDatepicker]="picker" 
		   required readonly 
		  	[(ngModel)]="news.date"
		  	(ngModelChange)="updateField(news,'date',news.date)">
		  <mat-datepicker-toggle matSuffix type="button" [for]="picker"></mat-datepicker-toggle>
		  <mat-datepicker #picker></mat-datepicker>
		</mat-form-field> -->

		<mat-checkbox [(ngModel)]="isthumbnews" class="question" color="warn">Ajouter une image d'aperçu</mat-checkbox>

		<mat-checkbox class="question"
			[(ngModel)]="news.isonhp" 
			(ngModelChange)="updateField(news,'isonhp',news.isonhp)" color="warn">
					Afficher cette actualité sur la page d'accueil
		</mat-checkbox>


		<mat-radio-group class="question" color="black"
			*ngIf="news.isonhp"
			[(ngModel)]="news.homeposition"
			(ngModelChange)="updateField(news,'homeposition',news.homeposition)">
		  <mat-radio-button value="hpdown" color="warn">...dans les actualités de la partie infèrieure &nbsp;&nbsp;&nbsp;</mat-radio-button>
		  <mat-radio-button value="hpup" color="warn">...dans le carrousel de la partie supèrieure &nbsp;&nbsp;&nbsp;</mat-radio-button>
		</mat-radio-group>


		<mat-radio-group class="question" color="black"
			*ngIf="(news.isonhp&&news.homeposition=='hpup') || (news.isonhp&&news.homeposition=='hpdown')"
			[(ngModel)]="news.hometype"
			(ngModelChange)="updateField(news,'hometype',news.hometype)">
		  <mat-radio-button value="withpic" color="warn">...avec une image &nbsp;&nbsp;&nbsp;</mat-radio-button>
		  <mat-radio-button value="nopic" color="warn">...sans image &nbsp;&nbsp;&nbsp;</mat-radio-button>
		  <!-- <mat-radio-button *ngIf="news.artistdefaultimg" value="default" color="warn">...image de l'artiste par défault</mat-radio-button> -->
		</mat-radio-group>		

		<h3 *ngIf="	!news.imgurl && news.hometype!='nopic'">Fournir une image :</h3>

		<div class="fx-row fx-gap-10 fx-col-xs">
			<div class="fx-50 fx-100-xs">
				<div class="dropzone" 
			 *ngIf="!news.imgurl&&news.hometype&&news.hometype!='nopic'&&showDropzone&&!errors"
			dropZone
			(hovered)="toggleHover($event)"
			(dropped)="selectFile($event,news)"
			[class.hovering]="isHovering">

				<h4>Drag and Drop the image desktop <strong>{{ pictitle }}</strong> file</h4>
				<h5>
					Format : <strong>.jpeg</strong><br>
					Dimensions : <strong>minimum 1500px/800px</strong><br>
					Size: < <strong>300 Ko</strong>
				</h5>
				<div class="file">
					<label class="file-label">
							<input class="file-input" type="file" (change)="selectFile($event,news)">
							<span class="file-cta">
							<span class="file-icon"><mat-icon matListIcon class="vac">file_upload</mat-icon></span>
							<span class="file-label">or choose a file…</span>
							</span>
					</label>
				</div>
				</div>

				<div *ngIf="downloadURL | async as url">
					{{ recordImgUrl(url,news,isUrlUpdated) }}
				  <img [src]="url" class="max-width">
					<mat-icon (click)="removePicture(news)" class="icon-remove">clear</mat-icon>
				</div>
		
				<div *ngIf="news.imgurl&&!downloadURL">
				  <img [src]="news.imgurl" class="max-width">
					<mat-icon (click)="removePicture(news)" class="icon-remove">clear</mat-icon>		  
				</div>
		
				<div *ngIf="errors" class="errors">
					<h4>{{ errorMessage }}<br><br></h4>
				</div>
			</div>

			<div class="fx-50 fx-100-xs">
				<div class="dropzone"
				*ngIf="!news.imgurlmobile&&news.hometype&&news.hometype!='nopic'&&showDropzoneMobile"
				dropZone
				(hovered)="toggleHoverMobile($event)"
				(dropped)="selectFileMobile($event,news)"
				[class.hovering]="isHoveringMobile">

				<h4>Drag and Drop the image mobile <strong>{{ pictitle }}</strong> file</h4>
				<h5>
					Format : <strong>.jpeg</strong><br>
					Dimensions : <strong>minimum 750px/1334px</strong><br>
					Size: < <strong>300 Ko</strong>
				</h5>
				<div class="file">
				<label class="file-label">
						<input class="file-input" type="file" (change)="selectFileMobile($event,news)">
						<span class="file-cta">
						<span class="file-icon"><mat-icon matListIcon class="vac">file_upload</mat-icon></span>
						<span class="file-label">or choose a file…</span>
						</span>
				</label>
			</div>
				</div>

				<div *ngIf="downloadURLMobile | async as url">
					{{ recordImgUrlMobile(url,news,isUrlUpdatedMobile) }}
				  <img [src]="url" class="max-width">
					<mat-icon (click)="removePictureForMobile(news)" class="icon-remove">clear</mat-icon>
				</div>
		
				<div *ngIf="news.imgurlmobile&&!downloadURLMobile">
				  <img [src]="news.imgurlmobile" class="max-width">
					<mat-icon (click)="removePictureForMobile(news)" class="icon-remove">clear</mat-icon>		  
				</div>
		
				<div *ngIf="errorsMobile" class="errors">
					<h4>{{ errorMessageMobile }}<br><br></h4>
				</div>
			</div>
		
		</div>

		

		<!-- ---------------------- END IMAGES ------------------------------ -->

		<br>

		<h3>Contenu de l'actualité :</h3>

		<textarea class="news-content" rows="10"
			[(ngModel)]="news.content"
			(ngModelChange)="updateField(news,'content',$event)">
			{{ news.content }}
		</textarea>
		 		
		<span class="car-count" *ngIf="news.content" [ngClass]="{'car-wrong':350<news.content.length}">{{ news.content.length }} < 350</span>

		<br><br>

		<button mat-fab 
			*ngIf="news.artistkey"
			[routerLink]="['./../../../member$/member', news.artistkey]" color="black"
			matTooltip="See artist informations" matTooltipPosition="after">
			<mat-icon>face</mat-icon>
		</button>	

		<button mat-fab 
			routerLink="./../../../new$/news" color="black"
			matTooltip="Liste des Actualités" matTooltipPosition="after">
			<mat-icon>view_list</mat-icon>
		</button>				

		<button mat-fab matTooltip="Delete News" matTooltipPosition="after"
			*ngIf="news.isonline==false"
			(click)="deleteNews(news)" color="warn">
			<mat-icon>delete_forever</mat-icon>
		</button>

<!-- 		<button mat-fab color="black" 
			matTooltip="Save News Pictures" matTooltipPosition="after"
			*ngIf="news.isonline==0||!news.isonline"
			[disabled]="(news.content==''||350<news.content.length)||
						!news.date"
			(click)="saveNews(news)"><mat-icon>save</mat-icon>
		</button> -->



		<!-- <button mat-fab color="black" 
			matTooltip="Publish News Online" matTooltipPosition="after"
			*ngIf="news.isonline==false"
			(click)="updateField(news,'isonline',true)"><mat-icon>check</mat-icon>
		</button>

		<button mat-fab color="warn" 
			matTooltip="Remove News From Website" matTooltipPosition="after"
			*ngIf="news.isonline==true"
			(click)="updateField(news,'isonline',false)"><mat-icon>clear</mat-icon>
		</button> -->

		<button mat-fab 
			[color]="news.isonline ? 'warn' : 'black'" 
			[matTooltip]="news.isonline ? 'Remove News From Website' : 'Publish News Online'" 
			matTooltipPosition="after"
			(click)="updateField(news, 'isonline', !news.isonline)">
  			<mat-icon>{{ news.isonline ? 'clear' : 'check' }}</mat-icon>
		</button>		


	</div>


</div>