<!-- <ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template> -->
<img 
	*ngIf="!showPrintDiv"
	class="img-style-xs logo-bo logo-bo-xs" 
	[ngStyle.xs]="{ 'margin':'0', 'padding':'40px 0 0 90px', 'width':'200px', 'height':'auto' }"
	src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e">

<h1 *ngIf="!showPrintDiv" class="h1-style-xs">Events of Kajimoto Paris</h1>

<!-- ok -->
<!-- <img *ngIf="profilepic&&!showPrintDiv&&!fullname&&!id&&!year&&!profil&&!showartist"
		[routerLink]="['./../artist', key]" 
		[ngStyle.xs]="{ 'top':'100px', 'right':'85px' }"
		src="{{profilepic}}" class="profile-pic"> -->

<!-- if it is filter order by url ok -->
<!-- <img *ngIf="profilepic&&!showPrintDiv&&!id&&key"
		[routerLink]="['./../../../../../artist', key]" 
		[ngStyle.xs]="{ 'top':'100px', 'right':'85px' }"
		src="{{profilepic}}" class="profile-pic"> -->

<!-- if it is filter order by url -->
<img *ngIf="fullname&&id&&year&&profil&&showartist&&!showPrintDiv"
		[routerLink]="['./../../../../../artist', id]" 
		src="{{profil}}" class="profile-pic profile-pic-xs">	

<ng-container [ngSwitch]="key"  *ngIf ="!showPrintDiv">
		<button disabled mat-button color="black" class="fl-r2 fl-r2-xs">
				{{ key }}
		</button>
		
		<img *ngSwitchCase="'Hiver'" class="profile-pic profile-pic-xs" src="{{ hiver }}">
		<img *ngSwitchCase="'Été'" class="profile-pic profile-pic-xs" src="{{ ete }}">
		<img *ngSwitchCase="'Automne'" class="profile-pic profile-pic-xs" src="{{  automne }}">
		<img *ngSwitchCase="'Printemps'" class="profile-pic profile-pic-xs" src="{{  printemps }}">
 </ng-container>

		<!-- if it is filter order by url -->
 <!-- <button *ngIf="artistFullName&&!showPrintDiv&&fullname&&id&&year&&profil" mat-button color="black" class="fl-r2 darkblue"
	[ngStyle.xs]="{ 'top':'110px', 'right':'160px' }"
  matTooltip="See Artist Page" matTooltipPosition="before"
	[routerLink]="['./../../../../../artist', key]">
  {{ artistFullName }}
 </button> -->

<!-- <button *ngIf="artistFullName&&!showPrintDiv&&!year&&!profil" mat-button color="black" class="fl-r2 darkblue"
	[ngStyle.xs]="{ 'top':'110px', 'right':'160px' }"
  matTooltip="See Artist Page" matTooltipPosition="before"
	[routerLink]="['./../artist', key]">
  {{ artistFullName }}
</button> -->
<!-- if it is filter order by url -->
<button *ngIf="fullname&&id&&year&&profil&&showartist&&!showPrintDiv" mat-button color="black" 
	class="fl-r2 fl-r2-xs darkblue"
  	matTooltip="See Artist Page" matTooltipPosition="before"
	[routerLink]="['./../../../../../artist', id]">
		{{ fullname }}
</button>

<!-- <button *ngIf="season&&!showPrintDiv" disabled mat-button color="black" class="fl-r2"
	[ngStyle.xs]="{ 'top':'110px', 'right':'160px' }">
  {{ season }}
</button> -->

<!-- <button *ngIf="artistFullName||season&&!showPrintDiv" mat-fab color="black" class="fl-r3 noshadow"
	[ngStyle.xs]="{ 'top':'100px', 'right':'20px' }"
  matTooltip="Clear Filter Event" matTooltipPosition="before"
  [routerLink]="['./events']">
  <mat-icon>clear</mat-icon>
</button> -->

<!-- filter order by artists in dialog -->
<button *ngIf="fullname&&id&&year&&profil&&showartist&& !showPrintDiv" mat-fab color="black" class="fl-r3 fl-r3-xs noshadow"
	matTooltip="Clear Filter Event" matTooltipPosition="before" 
	[routerLink]="['../../../../../events']">
  		<mat-icon>clear</mat-icon>
</button>

<button *ngIf="key&&!key2&&!showPrintDiv" mat-fab color="black" class="fl-r3 fl-r3-xs noshadow"
	matTooltip="Clear Filter Event" matTooltipPosition="before" 
  	[routerLink]="['../../events']">
  		<mat-icon>clear</mat-icon>
</button>

<button *ngIf="key2&&!showPrintDiv" mat-fab color="black" class="fl-r3 fl-r3-xs noshadow"
  	matTooltip="Clear Filter Event" matTooltipPosition="before"
  	[routerLink]="['../../../events']">
  		<mat-icon>clear</mat-icon>
</button>

<ng-container *ngIf="!showPrintDiv">
	<button mat-fab color="black" class="fl-r4 noshadow hide-mobile" 
		*ngIf="key || id || key2" 
		[ngStyle]="{ 'top':'100px', 'right':'30px','position':'absolute' }"
	  	matTooltip="Print Events" matTooltipPosition="before"	
	  	(click)="showPrintDiv=true">
	  		<mat-icon>print</mat-icon>
	</button>
</ng-container>


<button *ngIf="!showPrintDiv&&id" mat-fab color="black" class="fl-r fl-r-xs noshadow"
  matTooltip="Filter Event" matTooltipPosition="before"
  [routerLink]="['./../../events']"
  (click)="openDialog()">
  	<mat-icon>filter_list</mat-icon>
</button>

<button *ngIf="!showPrintDiv" mat-fab color="black" class="fl-r fl-r-xs noshadow"
  matTooltip="Filter Event" matTooltipPosition="before"
  [routerLink]="['/events']"
  (click)="openDialog()">
  	<mat-icon>filter_list</mat-icon>
</button>

<!-- after filter order by artists -->
<!-- <img *ngIf="profilepic&&key"
		[routerLink]="['./../artist', key]" 
		[ngStyle.xs]="{ 'top':'100px', 'right':'85px' }"
		src="{{profilepic}}" class="profile-pic"> -->

<div class="bounds" *ngIf="!showPrintDiv">

	<div class="content fx-row fx-wrap fx-col-xs fx-fill" [ngStyle]="{'padding-top':'30px'}">

			<div *ngIf="events$?.length==0&&dataIsEmpty" class="noevent">
				No <strong>event</strong> recorded.
			</div>

			<div class="event-list fx-row fx-wrap fx-fill"
				[@events]="events$?.length"> 
					<div class="fx-100 event-capsule fx-row fx-wrap fx-start-center fx-fill" *ngFor="let card of cards | async">
							<ng-container *ngIf="events$[card - 1]">
								
								<div class="fx-30 fx-col padding-top-xs fx-100-xs">
									<app-artist-event *ngIf="!artistFullName&&events$[card - 1]?.$key" [eventkey]="events$[card - 1]?.$key"></app-artist-event>
									<h3 class="h3-style-xs" *ngIf="events$[card - 1]?.date || events$[card - 1]?.time" [ngStyle]="{'font-size':'0.8em'}">
										<span class="event-date">{{ events$[card - 1]?.date | date }} </span>
										<span class="event-time">{{ events$[card - 1]?.time }}</span>
									</h3>
								</div>

								<div class="fx-50 fx-100-xs title-venue fx-row fx-wrap fx-start-start">
									<div *ngIf="events$[card - 1]?.title" class="fx-100" [ngStyle]="{'padding':'0','margin':'0'}">
										<span class="span-style-xs">
												{{  (events$[card - 1]?.title.length>40) ? 
													(events$[card - 1]?.title | slice:0:40)+'...':
													(events$[card - 1]?.title) 
												}}
										</span>
									</div> 
									<div *ngIf="events$[card - 1]?.venue" class="fx-100" [ngStyle]="{'padding':'0','margin':'0'}">
										<span *ngIf="events$[card - 1]?.venue.venuename" class="event-venuename event-venuename-xs">
												{{ 	
													(events$[card - 1]?.venue.venuename.length>40) ? 
													(events$[card - 1]?.venue.venuename | slice:0:40)+'...':
													(events$[card - 1]?.venue.venuename) 
												}}
										</span>	        
									</div>
									<app-program-event [eventkey]="events$[card - 1]?.$key" *ngIf="events$[card - 1]?.$key" ></app-program-event>
								</div>

								<div class="fx-center-stretch fx-20 fx-100-xs fx-col">
									<span class="event-link event-link-xs fx-fill" 
										*ngIf="events$[card - 1]?.link"
										(click)="gotoExternalHref(events$[card - 1]?.link)">
											Infos/Tickets
									</span>
									<div class="admin-but fx-fill" *ngIf="(auth.user | async)?.role == 'admin' || (auth.user | async)?.role == 'agent'">
										<mat-icon *ngIf="events$[card - 1]?.artistkey&&year&&!key" 
											[routerLink]="['./../../../../../member$/member', events$[card - 1]?.artistkey]"
											matTooltip="Edit Artist" matTooltipPosition="before">
												face
										</mat-icon>
									
										<!-- filter by no url -->
										<mat-icon *ngIf="events$[card - 1]?.artistkey&&!year&&!id&&!key" 
											[routerLink]="['./../member$/member', events$[card - 1]?.artistkey]"
											matTooltip="Edit Artist" matTooltipPosition="before">
												face
										</mat-icon>
										<!-- nn -->
										<mat-icon *ngIf="events$[card - 1]?.artistkey&&key&&!key2" 
											[routerLink]="['./../../member$/member', events$[card - 1]?.artistkey]"
											matTooltip="Edit Artist" matTooltipPosition="before">
												face
										</mat-icon>
										<mat-icon *ngIf="events$[card - 1]?.artistkey&&key2&&key" 
											[routerLink]="['./../../../member$/member', events$[card - 1]?.artistkey]"
											matTooltip="Edit Artist" matTooltipPosition="before">
												face
										</mat-icon>
									
										<!-- filter by no url -->
										<mat-icon *ngIf="!key&&!year&&!id" 	
											[routerLink]="['./../event$/event', events$[card - 1]?.$key]"
											matTooltip="Edit Event" matTooltipPosition="before">
												edit
										</mat-icon>

										<mat-icon *ngIf="year&&id&&!key"
											[routerLink]="['./../../../../../event$/event', events$[card - 1]?.$key]"
											matTooltip="Edit Event" matTooltipPosition="before">
												edit
										</mat-icon>
										<mat-icon *ngIf="key&&!year&&!id&&!key2"
											[routerLink]="['./../../event$/event', events$[card - 1]?.$key]"
											matTooltip="Edit Event" matTooltipPosition="before">
												edit
										</mat-icon>

										<mat-icon *ngIf="key2&&key"
											[routerLink]="['./../../../event$/event', events$[card - 1]?.$key]"
											matTooltip="Edit Event" matTooltipPosition="before">
											edit
										</mat-icon>

										<mat-icon *ngIf="!events$[card - 1]?.tourkey" 
											(click)="openTourDialog(events$[card - 1])"
											matTooltip="Send Event To Tour" matTooltipPosition="before">
												send
										</mat-icon>
										<!-- filter by no url -->
										<mat-icon *ngIf="events$[card - 1]?.tourkey&&!id&&!year&&!key"
											[routerLink]="['./../event$/tour', events$[card - 1]?.tourkey]"
											matTooltip="Edit tour" matTooltipPosition="before">
												cached
										</mat-icon>	

										<mat-icon *ngIf="events$[card - 1]?.tourkey&&id&&year&&!key"
											[routerLink]="['./../../../../../event$/tour', events$[card - 1]?.tourkey]"
											matTooltip="Edit tour" matTooltipPosition="before">
												cached
										</mat-icon>	

										<mat-icon *ngIf="events$[card - 1]?.tourkey&&key&&!key2"
											[routerLink]="['./../../event$/tour', events$[card - 1]?.tourkey]"
											matTooltip="Edit tour" matTooltipPosition="before">
												cached
										</mat-icon>	

										<mat-icon *ngIf="events$[card - 1]?.tourkey&&key2"
											[routerLink]="['./../../../event$/tour', events$[card - 1]?.tourkey]"
											matTooltip="Edit tour" matTooltipPosition="before">
												cached
										</mat-icon>	

										<mat-icon mat-mini-fab color="black"
											matTooltip="Duplicate Event" matTooltipPosition="after" 
											(click)="openDuplicateEventDialog( events$[card - 1])">
												content_copy
										</mat-icon>					
									</div>
								</div>
							</ng-container>
					</div>
			</div>
	</div>

	<div *ngIf="loading | async; else more">
		<mat-spinner diameter="40"></mat-spinner>
	</div>
	
	<ng-template #more>
		<ng-container *ngIf="page < pages">
			<button type="button" mat-raised-button color="primary" (click)="loadMore()">Load more</button>
			<div class="infinite-scroll-zone" inViewport [inViewportOptions]="{ threshold: 0 }" (inViewportAction)="loadMore($event)">&#160;</div>
		</ng-container>
	</ng-template>
</div>


<div *ngIf="showPrintDiv" class="bounds">

	<mat-toolbar class="doNotPrint">

		<span class="example-spacer"></span>
		
		<button (click)="print_page()" mat-raised-button type="button" color="black" >
			<mat-icon>print</mat-icon>
		</button>	
	
		<button  (click)="showPrintDiv=false" mat-raised-button type="button" color="black" >
			<mat-icon>close</mat-icon>
		</button>					
		
	</mat-toolbar>

	<div *ngIf="events$ ; let events" class="fx-row fx-wrap" >
		
		<div class="fx-100" class="doNotPrint">
			<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" alt="" class="logo-print">
			<h1 class="title-print">Event's list</h1>
		</div>
			
		<div class="fx-100 fx-center-start">

			<table width="1200px">
				<tr>
					<th><strong>Date</strong></th>
					<th><strong>Artist</strong></th>
					<th><strong>Event's name</strong></th>
					<th><strong>Venue Name</strong></th>
					<th><strong>Partners</strong></th>
					<th><strong>Composer</strong></th>
				</tr>

				<tr *ngFor="let event of events">
					<td width="80px" *ngIf="event.date" class="smaller-txt">{{ event.date | date }}</td>
					<td width="80px" class="smaller-txt">
						<events-artist-print [eventkey]="event.$key"></events-artist-print>
					</td>
					<td width="220px" class="smaller-txt">{{event.title}}</td>
					<td width="200px" class="smaller-txt">
						<span *ngIf="event.venue&&event.venue.venuename" class="width200px">
						{{ (event.venue.venuename.length>40) ? (event.venue.venuename | slice:0:40)+'...':(event.venue.venuename) }}</span>
					</td>
					<td width="150px" class="smaller-txt">
						<span *ngIf="event.artistsoliste" class="width150px">
							{{ (event.artistsoliste.length>20) ? (event.artistsoliste | slice:0:30)+'...':(event.artistsoliste) }}
						</span>
					</td>
					<td>
						<app-program-event class="width300px" [eventkey]="event.$key"></app-program-event>
					</td>					
				</tr>
			</table>
	
		</div>
		
	</div>

</div>




<br><br><br>

<footer-nav class="hide-mobile" *ngIf="!showPrintDiv"></footer-nav>	













<!-- <ng-container *ngFor="let card of cards | async">
	<mat-card class="card" [ngClass]="{ odd: this.isOdd(card), even: isEven(card) }">
		<mat-card-header>
			<div mat-card-avatar class="header-image"></div>
			<mat-card-title>{{ this.isOdd(card) ? 'Lego Builder' : 'Lego Doctor' }}</mat-card-title>
			<mat-card-subtitle>{{ this.isOdd(card) ? 'Engineer' : 'Surgeon' }}</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque porta lorem id nulla varius dictum. Suspendisse ut
				ante ac odio finibus fringilla id quis nulla. Etiam sapien elit, facilisis ut mattis non, euismod ac magna. Donec vehicula,
				mi ac bibendum finibus, mauris turpis malesuada tellus, accumsan feugiat felis mi in velit. Donec vitae sollicitudin
				eros. Vestibulum hendrerit magna urna, ut malesuada sem fringilla sit amet. Cras non tellus posuere nibh tincidunt pellentesque.
				Nunc mattis finibus accumsan. Aenean fringilla sapien ut quam posuere tempus. Class aptent taciti sociosqu ad litora
				torquent per conubia nostra, per inceptos himenaeos. Pellentesque interdum sit amet dui id auctor. Donec in gravida ex.
				Duis convallis tellus at lacus euismod suscipit sed quis risus. In varius urna ipsum, eget varius eros viverra in.
			</p>
		</mat-card-content>
	</mat-card>
</ng-container>

<ng-container *ngIf="loading | async; else more">
	<mat-spinner diameter="40"></mat-spinner>
</ng-container>

<ng-template #more>
	<ng-container *ngIf="page < pages">
		<button type="button" mat-raised-button color="primary" (click)="loadMore()">Load more</button>
    <div class="infinite-scroll-zone" inViewport [inViewportOptions]="{ threshold: 0 }" (inViewportAction)="loadMore($event)">&#160;</div>
  </ng-container>
</ng-template> -->

