<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">



<h1>Add New Venue</h1>


<div *ngIf="addVenue" [ngStyle]="{'padding':'10px'}">

		<form #newPlaceForm="ngForm" (ngSubmit)="onSubmit(newPlaceForm)">
			<mat-form-field class="full-width">
				<mat-label> Name of the Place </mat-label>
				<input matInput required
					ngModel #name="ngModel" name="name">
			</mat-form-field>
			<mat-form-field class="full-width">
				<mat-label> Adress </mat-label>
				<input matInput required					
					ngModel #address="ngModel" name="address">
			</mat-form-field>
			<mat-form-field class="full-width">
				<mat-label> Website </mat-label>
				<input matInput required					
					ngModel #url="ngModel" name="url">
			</mat-form-field>


<!-- 			<button mat-fab type="button" color="black"
				routerLink="./../venue">
				<mat-icon>list</mat-icon>
			</button>	 -->

			<!-- 			
			<button mat-fab (click)="addVenues=true;addVenue=false;" 
				type="button" color="black">
				<mat-icon>library_add</mat-icon>
			</button>	 
			-->

			<button mat-fab type="submit" color="warn"
				[disabled]="!newPlaceForm.form.valid">
				<mat-icon>save</mat-icon>
			</button>
		</form>

</div>






<!-- <mat-card *ngIf="addVenues">

	<mat-card-header>
		<mat-card-title>Nouvelles Salles de Concert</mat-card-title>
		<mat-card-subtitle>Copy/Paste les salles de concert à importer</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content>
		<mat-form-field class="full-width">
			<textarea matInput
								rows="10" 
								placeholder="Copy-paste your list of places : Name	Adress Url : One Places per line"
								name="newPlaces"
								ngModel #newPlaces="ngModel"></textarea>
		</mat-form-field>
		<br><br><br>
		<button mat-fab (click)="addVenue=true;addVenues=false" type="button" color="black">
			<mat-icon>add</mat-icon>
		</button>	
		<button mat-fab color="warn" 
			(click)="importPlaces(newPlaces)">
			<mat-icon>file_upload</mat-icon>
		</button>		
	</mat-card-content>

</mat-card> -->