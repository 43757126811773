<!-- <pre>{{ events | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<ng-template #loading>        
  <br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<!-- <mat-card  *ngIf="dev"> -->
<!-- <mat-card *ngIf="events | async; let events; else loading">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <mat-icon matListIcon>today</mat-icon>
    </div>      
    <mat-card-title>Events of Kajimotomusic Paris</mat-card-title>
    <mat-card-subtitle>Click an event to see further informations</mat-card-subtitle>
       
    <button mat-button color="black" class="fl-right"
      routerLink="./../eventform">
      <mat-icon matListIcon>add</mat-icon>
    </button>     
   
  </mat-card-header>
  <mat-card-content>
  
    <mat-grid-list cols="3" rowHeight="200px">
      <mat-grid-tile 
        *ngFor="let event of events"
        [routerLink]="['./../event', event.$key]">

        <h4 mat-line>
          <span *ngIf="event.status==1" class="event-status">
            <mat-icon matListIcon color="primary">lightbulb_outline</mat-icon> Idea
          </span>
          <span *ngIf="event.status==2" class="event-status">
            <mat-icon matListIcon color="accent">question_answer</mat-icon> Negotiating
          </span>
          <span *ngIf="event.status==3" class="event-status">
            <mat-icon matListIcon color="warn">check_circle</mat-icon> Online
          </span>
          <span class="event-title">{{ event.title }}</span>
          <span *ngIf="event.date" class="event-date">{{ event.date }}</span>
          <span *ngIf="event.genre" class="event-genre">{{ event.genre }}</span>
        </h4>
        <p class="info-event">
          <span *ngIf="event.musicians">
            <strong>Artists</strong><br> 
            <span class="musicians" *ngFor="let musician of event.musicians">
              {{ musician.fullname }}
            </span>
          </span>
          <span *ngIf="event.venue"><strong>Venue</strong><br>{{ event.venue }}</span>
          <span *ngIf="event.programs">
            <strong>Programme</strong><br>
            <span *ngFor="let program of event.programs">
              {{ program.composer }}
            </span>
          </span>
          <span *ngIf="event.producer"><strong>Producer</strong><br> {{ event.producer}}<br></span>
          <span *ngIf="event.producername"><strong>Producer</strong><br> {{ event.producername}}<br></span>          
        </p>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card> -->





<!-- <event-list></event-list> -->






<!-- <pre>{{ artists | async | json }}</pre> -->
<!-- *ngIf="dev" -->
<mat-card appearance="outlined" class="artists-list">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <mat-icon matListIcon>fingerprint</mat-icon>
    </div>      
    <mat-card-title>Artists at Kajimotomusic Paris</mat-card-title>
    <mat-card-subtitle>Click an artist to see further informations</mat-card-subtitle>
    <button mat-button color="black" class="fl-right"
      routerLink="./../member$/members">
      <mat-icon matListIcon>people</mat-icon>
    </button>      
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="5" rowHeight="170px">
      <mat-grid-tile 
        *ngFor="let artist of artists | async"
        [routerLink]="['./../member$/member', artist.$key]">
        <div class="text-inside-grid">
          <img *ngIf="artist.profilepic; else noProfilePicProvided" src="{{artist.profilepic}}" class="profile-pic" alt="{{ artist.lastname }} {{ artist.firstname}}">
          <ng-template #noProfilePicProvided>
            <img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fnoprofilepic.png?alt=media&token=c2f12f49-1942-4680-a637-b034a4902131" class="profile-pic" alt="No Profile Pic">
          </ng-template>
          <p mat-line class="info-artist">
            <span class="online-light"
              [ngClass]="
              {
                'bg-red':artist.isonline=='1'
              }"
            ></span>
            <span class="name-artist">{{ artist.lastname }} {{ artist.firstname}}</span>
            <span class="intru-list" 
              *ngFor="let agent of artist.isagents">
              {{ agent.fullname }}
            </span>
            <br>
            <span class="intru-list" 
              *ngFor="let instru of artist.isinstruments">
              {{ instru.title }}
            </span>
            <!-- {{ artist.$key }} -->
          </p>
        </div>  
      </mat-grid-tile>
      <mat-grid-tile class="bg-white">
        <button mat-icon-button routerLink="./../member$/memberform" color="black">
          <mat-icon class="mat-24">add</mat-icon>
        </button>          
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>





<!-- <mat-card>
  <mat-card-header>
    <div mat-card-avatar class="example-header-image">
      <mat-icon matListIcon>data_usage</mat-icon>
    </div>      
    <mat-card-title>Database in Progress</mat-card-title>
    <mat-card-subtitle>Start feeding it</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <button mat-menu-item routerLink="./../members" 
      [routerLinkActive]="['matl-button--colored']">
        <mat-icon>people</mat-icon>
        <span>See Members</span>
    </button>
    <button mat-menu-item routerLink="./../memberform"
      [routerLinkActive]="['matl-button--colored']">
        <mat-icon>person_add</mat-icon>
        <span>Add Member/Artist</span>
    </button>
    <button mat-menu-item routerLink="./../composers" 
      [routerLinkActive]="['matl-button--colored']">
        <mat-icon>library_music</mat-icon>
        <span>See Composers</span>
    </button>
        
    <button mat-menu-item disabled routerLink="./../composerform" 
      [routerLinkActive]="['matl-button--colored']">
        <mat-icon>person_add</mat-icon>
        <span>Composers</span>
    </button>
    <button mat-menu-item disabled routerLink="./lab">
        <mat-icon> build </mat-icon>
        <span>Lab</span>
    </button> 
   
  </mat-card-content>
</mat-card> -->



