<!-- <pre>{{ artist | async | json  }}</pre> -->


<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<h1>Add News</h1>

<mat-card  class="nocss">


	<form #newNewsForm="ngForm" (ngSubmit)="onSubmit(newNewsForm)">

	<mat-card-content>

				
		<mat-form-field>
			<mat-label> Date</mat-label>
		  <input matInput [matDatepicker]="picker" 
		  	required readonly
		  	ngModel #dateNews="ngModel" 
		  	name="dateNews">
		  <mat-datepicker-toggle matSuffix type="button" [for]="picker"></mat-datepicker-toggle>
		  <mat-datepicker #picker></mat-datepicker>
		</mat-form-field> 
		

		<br><br>

		<mat-form-field class="full-width">
			<mat-label> Title</mat-label>
			<input matInput required
				ngModel #titleNews="ngModel"
				name="titleNews">
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label>Subtitle </mat-label>
			<input matInput
				ngModel #subtitleNews="ngModel"
				name="subtitleNews">
		</mat-form-field>		

		<br>

    <mat-form-field class="full-width">
		<mat-label> Select Artist</mat-label>
      <mat-select required
        ngModel #selectedArtist="ngModel" name="selectedArtist">
        <mat-option [value]="0">-- No Artist = Global News --</mat-option>
        <mat-option *ngFor="let artist of artists | async" 
          [value]="artist">
          {{ artist.fullname }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br>

		<mat-form-field class="full-width">
			<mat-label>News Link </mat-label>
			<input matInput
				ngModel #linkNews="ngModel"
				name="linkNews">
			<mat-icon matSuffix>link</mat-icon>
		</mat-form-field>    


	</mat-card-content>


	<mat-card-actions>

		<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab type="submit" color="warn" class="ml-15"
			[disabled]="!newNewsForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>

	</mat-card-actions>	

	</form>

</mat-card>


<!-- <app-news-list></app-news-list> -->