

<mat-card appearance="outlined">
	<br>
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>person</mat-icon>
		</div>	
		<mat-card-title>New Member</mat-card-title>
		<mat-card-subtitle>Ajout d'un nouveau membre ou artiste</mat-card-subtitle>
	</mat-card-header>

	<form #newMemberForm="ngForm" (ngSubmit)="onSubmit(newMemberForm)">
	<mat-card-content>
		<table cellspacing="0" class="full-width max-width-table">
			<tr>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> First name </mat-label>
    				<input matInput required
    					ngModel #newMemberfirstname="ngModel"
    					name="newMemberfirstname">
  				</mat-form-field>
  			</td>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> Last name </mat-label>
    				<input matInput required
    				ngModel #newMemberlastname="ngModel"
    				name="newMemberlastname">
  				</mat-form-field>
  			</td>
  		</tr>
  		<tr>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> Email </mat-label>
    				<input matInput type="email"
    				ngModel #newMemberemail="ngModel" 
    				name="newMemberemail">
 	  				</mat-form-field>
  			</td>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> Phone </mat-label>
    				<input matInput mat-maxlength="3"
    				ngModel name="newMemberphone">
  				</mat-form-field>    				
  			</td>    			
  		</tr>		
  		<tr>
  			<td colspan="2">
					<mat-radio-group required
					ngModel name="pickedMemberRole">
					  <mat-radio-button class="radio-role" color="warn"
					  	*ngFor="let role of roles" 
					  	[value]="role">
					    {{role.title}}
					  </mat-radio-button>
					</mat-radio-group>
  			</td>	  				
  		</tr>
  	</table>
	</mat-card-content>

	<mat-card-actions>

		<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab type="submit" color="warn" class="ml-15"
			[disabled]="!newMemberForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>

	</mat-card-actions>
	</form>
	<br>

</mat-card>