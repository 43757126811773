import { AuthService } from './../../core/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogClass } from './../../shared/logclass';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, setDoc, updateDoc } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { switchMap, map } from 'rxjs/operators';
import { Venue } from './venue';


@Injectable()
export class VenueService {

  venue    	        : Observable<any>;
  venueCollection   : AngularFirestoreCollection<unknown>;
  logClass          : LogClass;

  private venuesSubject = new BehaviorSubject<any[]>([]);
  venues$ = this.venuesSubject.asObservable();  

  constructor(
    private router  : Router,
    private afs     : Firestore) {
      
      this.loadVenues(); // Charge les données au démarrage
      //  this.venueCollection = this.afs.collection('venues', ref => ref.orderBy('name', 'asc'));      
      //  this.logClass        = new LogClass(this.auth, this.afs);
  }


/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// G E T 
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


    /** Charge la liste des venues en temps réel */
    private loadVenues() {
      const venueCollection = collection(this.afs, 'venues'); // Référence à la collection

      collectionData(venueCollection, { idField: '$key' }) // Ajoute l'ID à chaque document
        .subscribe(venues => {
          console.log('📡 Mise à jour venues en temps réel:', venues);
          this.venuesSubject.next(venues); // Met à jour les données locales à chaque changement
        });
    }

    /** ✅ Retourne les données en cache (synchro automatique avec Firestore) */
    getVenuesList() {
      return this.venues$; 
    }



    /** Récupère un document Firestore avec son ID et reste synchronisé en temps réel */
    getVenueWithKey(key: string): Observable<any> {
      const venueDocRef = doc(this.afs, `venues/${key}`); // Référence au document

      return docData(venueDocRef).pipe(
        map(data => ({
          $key: key, // Ajoute l'ID du document
          ...data // Copie toutes les autres données
        }))
      );
    }

  // getVenuesList() {
  //   return this.venueCollection.get().pipe(
  //     map(snapshot => snapshot.docs.map(doc => ({
  //       ...(doc.data() as object), // Assure que data() est un objet
  //       $key: doc.id
  //     })))
  //   );
  // }

  // Return Member with Key
  // getVenueWithKey(key: string) {
  //   const venuePath = `venues/${key}`;
  //   this.venue = this.afs.doc<any>(venuePath)
  //     .get().pipe(map(action => {
  //       const $key = action.id;
  //       const data = { $key, ...action.data() };
  //       return data;
  //     }));
  //   return this.venue ;
  // }


/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// C R E A T E
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

  /** Crée une venue et met à jour automatiquement la liste */
  createVenueFromAddVenueToEventForm(newVenueForm: NgForm) {
    const venueCollectionRef = collection(this.afs, 'venues'); // Référence à la collection
    const venueref = doc(venueCollectionRef); // Crée un document avec un ID auto-généré
    const venuekey = venueref.id;

    const newVenueData = {
      timestamp: Date.now(),
      name: newVenueForm.value.name || null,
      address: newVenueForm.value.address || null,
      url: newVenueForm.value.url || null,
    };

    // 🔥 Ajoute le document à Firestore
    setDoc(venueref, newVenueData)
      .then(() => {
        console.log('Document ajouté avec succès :', venuekey);
        // Pas besoin d'appeler loadVenues(), Firestore mettra à jour automatiquement venues$
      })
      .catch(error => console.error('Erreur lors de l\'ajout du document:', error));
  }

  /** Crée une venue et met à jour automatiquement la liste */
  createVenue(newPlaceForm: NgForm): Promise<void> {
    const venueCollectionRef = collection(this.afs, 'venues'); // Référence à la collection

    const newPlaceData = {
      timestamp: Date.now(),
      name: newPlaceForm.value.name || null,
      address: newPlaceForm.value.address || null,
      url: newPlaceForm.value.url || null,
    };

    console.log('📡 Nouvelle venue créée:', newPlaceData);

    // Ajoute le document à Firestore avec `addDoc()`
    return addDoc(venueCollectionRef, newPlaceData).then(() => {
        console.log('Document ajouté avec succès !');
        // Redirection après création
        this.router.navigate(['./venue$/venues']);
      })
      .catch(error => console.error('Erreur lors de l\'ajout du document:', error));
  }



  // createVenueFromAddVenueToEventForm(newVenueForm: NgForm) {

  //   const venueref             = this.afs.firestore.collection('venues').doc();
  //   const venuekey             = venueref.id;

  //   var newVenueData = {}
  //   newVenueData['timestamp']  = Date.now();
  //   newVenueData['name']       = newVenueForm.value.name ? newVenueForm.value.name : null;
  //   newVenueData['address']    = newVenueForm.value.address ? newVenueForm.value.address : null;
  //   newVenueData['url']        = newVenueForm.value.url ? newVenueForm.value.url : null;

  //   this.venueCollection.doc(venuekey).set(newVenueData)
  //   .then((docRef)=>{ console.log(venuekey, docRef);})
  //   .catch((error)=>{ console.error("Error adding document: ", error);});

  //   window.location.reload();
  //   // this.logClass.createLog(this.afs, 'createVenueFromAddVenueToEventForm', venuekey);
  // }


  // createVenue(newPlaceForm: NgForm): void {
  //   var newPlaceData = {}
  //   newPlaceData['timestamp']  = Date.now();
  //   newPlaceData['name']       = newPlaceForm.value.name ? newPlaceForm.value.name : null;
  //   newPlaceData['address']    = newPlaceForm.value.address ? newPlaceForm.value.address : null;
  //   newPlaceData['url']        = newPlaceForm.value.url ? newPlaceForm.value.url : null;

  // 	console.log(newPlaceData);  
  //   this.venueCollection.add(newPlaceData);
  //   this.logClass.createLog(this.afs, 'createPlace', 'nokey' );
  //   this.router.navigate(['./venue$/venues']);  
  // }



  // importPlaces(places):void {
  // 	// console.log(places.value);

  // 	var places    = places.value;
	// 	var nbOfLine  = (places.match(/\n/g)||[]).length;

	// 	for(var i=0;i<=nbOfLine;i++)
	// 	{
	//   	var line    = places.split('\n')[i];
	//   	// console.log(i,line);
	//   	var tabs    = line.split('\t');	
	//   	var name    = tabs[0] ? tabs[0] : null;
	//   	var address = tabs[1] ? tabs[1] : null;
	//   	var url     = tabs[2] ? tabs[2] : null;
	//   	var timestp = Date.now();

	//   	var newPlaceData = {}
	//     newPlaceData['timestamp'] = timestp;
  // 	  newPlaceData['name'] = name;
  //   	newPlaceData['address'] = address;
  //   	newPlaceData['url'] = url;

	//   	console.log(newPlaceData);

	//   	if(name && timestp && address) {
	//   		// console.log("imported to DB!");	  		
  //     // 	this.placesRef.push(newPlaceData);
  //     this.venueCollection.add(newPlaceData);
	//   	}
	//   	else console.log("not imported to DB");
	// 	}
  //   this.router.navigate(['/venues']);    
  // }




/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// U P D A T E
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


  /** ✅ Met à jour un champ spécifique d'une venue */
  updateVenue(place: any, field: string, value: any): void {
    const placekey = place.$key;
    const placeRef = doc(this.afs, `venues/${placekey}`); // Référence au document

    const updateData = { [field]: value }; // Met à jour dynamiquement le champ

    updateDoc(placeRef, updateData)
      .then(() => {
        console.log(`✅ Venue mise à jour (${field} -> ${value}) :`, placekey);
        // 🔥 Enregistre l'action dans les logs
        // this.logClass.createLog(this.afs, 'updateVenue', placekey);
      })
      .catch(error => console.error('❌ Erreur lors de la mise à jour:', error));
  }


  // updateVenue(place, field, value) {      
  //   var placekey = place.$key;
  //   var updateData = {};
  //   updateData[`${field}`] = value;
  //   this.venueCollection.doc(placekey).update(updateData);
  //   this.logClass.createLog(this.afs, 'updateVenue', placekey );
  // }



/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
  //////// D E L E T E
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

  /** ✅ Supprime une venue */
  deleteVenue(place: any): void {
    const placekey = place.$key;
    const placeRef = doc(this.afs, `venues/${placekey}`); // Référence au document

    deleteDoc(placeRef)
      .then(() => {
        console.log('✅ Venue supprimée avec succès:', placekey);
        // 🔥 Enregistre l'action dans les logs
        // Si `logClass` est un service injectable, injecte-le dans le constructeur et utilise-le directement
        // this.logClass.createLog(this.afs, 'deleteVenue', placekey);

        // 🔄 Redirection après suppression
        this.router.navigate(['./venue$/venues']);
      })
      .catch(error => console.error('❌ Erreur lors de la suppression:', error));
  }


  // deleteVenue(place):void {
  //   var placekey         	= place.$key;
  //   const placePath      	= `venue/${placekey}`;
  //   this.venueCollection.doc(placekey).delete();
  //   console.log('deleteData');
  //   this.logClass.createLog(this.afs, 'deleteVenue', placekey);
  //   this.router.navigate(['./venue$/venues']);
  // }


}
