<!-- <pre>{{ artist | async | json  }}</pre> -->


<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<div *ngIf="artist | async as artist; else loading">

<h1>{{ artist.firstname }} {{ artist.lastname }}</h1>
<h2>Add press content to this artist</h2>


	<form #newPressForm="ngForm" (ngSubmit)="onSubmit(newPressForm)">

	<div [ngStyle]="{'padding':'50px 20px 20px'}">

		<mat-form-field>
			<mat-label>Title </mat-label>
			<input matInput required
				ngModel #titlePress="ngModel"
				name="titlePress">
		</mat-form-field>

		&nbsp;&nbsp;

		<mat-form-field>
			<mat-label> Date</mat-label>
		  <input matInput [matDatepicker]="picker" 
		    required readonly
		  	ngModel #datePress="ngModel" 
		  	name="datePress">
		  <mat-datepicker-toggle matSuffix type="button" [for]="picker"></mat-datepicker-toggle>
		  <mat-datepicker #picker></mat-datepicker>
		</mat-form-field>

		&nbsp;&nbsp;

		<mat-form-field>
			<mat-label>Info </mat-label>
			<input matInput
				ngModel #infoPress="ngModel" 
				name="infoPress">
		</mat-form-field>

<!-- 		<mat-form-field class="full-width">
	<mat-label>Press Content </mat-label>
			<textarea matInput rows="5" required
			ngModel #contentPress="ngModel"	
			name="contentPress"></textarea>
		</mat-form-field>
 -->

		<ngx-wig 
			placeholder="Press content"
			[buttons]="'bold, italic, link'"
			name="contentPress"
			ngModel #contentPress="ngModel">
		</ngx-wig>

	</div>


	<div [ngStyle]="{'padding':'0 20px'}">

		<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15"
		[ngStyle]="{'margin-right':'10px'}">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab type="submit" color="black" class="ml-15"
			[disabled]="!newPressForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>

	</div>	

	</form>

</div>