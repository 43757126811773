import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { switchMap, map } from 'rxjs/operators';


import { MemberService } from './../../members/shared/member.service';
import { UploadService } from './../shared/upload.service';

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss']
})
export class UploadMediaComponent implements OnInit {

	artistkey	       	:	any;
	artist   	       	: any;

  medialist        	: Observable<any>;
  showSpinner      	: boolean = true;

  constructor(
  	private uploadService: UploadService,
    private memberService: MemberService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private location: Location
  ) { }


  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
        this.artistkey = params['id'];
    });

		this.artist = this.route.paramMap
			.pipe(switchMap((params: ParamMap)=>
				this.memberService.getArtistWithKey(params.get('id'))));
  }

  goBack(): void {
    this.location.back();
  }


	validateYouTubeUrl(url) {    
    // var url = $('#youTubeUrl').val();
    if (url != undefined || url != '') {        
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        // Do anything for being valid
        // if need to change the url to embed url then use below line            
        // $('#videoObject').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1');
        return match[2];
      } else {
        alert('Link or Title not valid');
        return false;
      }
    }
	}


  onSubmit(newMediaForm: NgForm) {
  	console.log(newMediaForm.value);

  	const fullname				 = newMediaForm.value.lastname?`${newMediaForm.value.firstname} ${newMediaForm.value.lastname}`:0;
  	const title 			     = newMediaForm.value.titlemedia?newMediaForm.value.titlemedia:0;
  	const youtubelink      = newMediaForm.value.youtubelinkMedia?newMediaForm.value.youtubelinkMedia:0;

  	const checkyoutubelink = this.validateYouTubeUrl(youtubelink);

    if(checkyoutubelink!=false) {
      this.uploadService.addMediaArtist(this.artistkey, fullname, title, checkyoutubelink);      
    }
  }    

}
