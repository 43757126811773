<!-- <pre>{{ newslist$ | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<h1>News of Kajimotomusic Paris</h1>

<button mat-icon-button routerLink="./../../new$/newsform" type="button" color="black" class="button-right button-right-xs">
	<mat-icon>add</mat-icon>
</button>	

<div *ngIf="newslist$ | async; let newslist; else loading" 
	class="news-list-container"
	fxLayoutAlign="start stretch" 
	[ngStyle]="{'margin-top':'50px'}">

	<div *ngFor="let singleNews of newslist" class="news-list"
		[ngStyle]="{'margin-top':'5px','border-top':'5px solid #fff'}">

		<div class="news-header fx-20 fx-col"
			[ngStyle]="{'padding':'10px'}">
				<strong>{{ singleNews.title }}</strong>
				<i [ngStyle]="{'font-size':'0.8em','color':'#ccc'}">{{ 
							singleNews.homeposition=='hpup' ? 'Up News Homepage' : 
							singleNews.homeposition=='hpdown' ? 'Donw News Homepage' : 
							singleNews.homeposition=='hpdown'
						}}
				</i>
				<i [ngStyle]="{'font-size':'0.8em','color':'#ccc'}" *ngIf="singleNews.date">{{ singleNews.date | date }}</i>
				<div>				
					<button mat-icon-button color="black" 
						*ngIf="singleNews.urlthumb"
						matTooltip="News has a thumb image" matTooltipPosition="after">
							<mat-icon matListIcon>image</mat-icon>
					</button>			
					
					<button mat-icon-button color="black" *ngIf="singleNews.urlfsimg"
						matTooltip="News has a fullscreen image" matTooltipPosition="after">
							<mat-icon matListIcon>fullscreen</mat-icon>
					</button>

					<button mat-icon-button color="black" *ngIf="singleNews.link"
						matTooltip="News has a link" matTooltipPosition="after">
						<mat-icon matListIcon>link</mat-icon>
					</button>					

				</div>
		</div>

		<div class="news-content fx-60 fx-60-xs fx-start-center"
			[ngStyle]="{'padding':'20px', 'background' : 'url(' + singleNews.imgurl + ') no-repeat center center', 'background-color': '#101138'}"
			[routerLink]="['./../../new$/newsdetail', singleNews.$key]">
			<!-- [ngStyle]="{'height':'300px','padding':'20px', 'background' : 'url(' + singleNews.imgurl + ') no-repeat center center'}" -->

				<span style="word-break: break-word;" innerHTML="{{ singleNews.content }}"></span>
		</div>

		<div class="fx-row fx-20 news-footer fx-center-center">

			<button mat-icon-button color="black"
				*ngIf="singleNews.artistkey"
				matTooltip="See Artist Details" matTooltipPosition="after"
				[routerLink]="['./../../member$/member', singleNews.artistkey]">
					<mat-icon matListIcon>face</mat-icon>
			</button>	

			<button mat-icon-button color="black"
				matTooltip="See News Details" matTooltipPosition="after"
				[routerLink]="['./../../new$/newsdetail', singleNews.$key]">
					<mat-icon matListIcon>info</mat-icon>
			</button>				

			<button mat-icon-button color="black" 
				matTooltip="Publish News On Artist Page" matTooltipPosition="after"
				*ngIf="singleNews.isonline==false||!singleNews.isonline"
				(click)="updateField(singleNews,'isonline',true)">
					<mat-icon>visibility_off</mat-icon>
			</button>
			<button mat-icon-button color="warn" 
				matTooltip="Remove News From Artist Page" matTooltipPosition="after"
				*ngIf="singleNews.isonline==true"
				(click)="updateField(singleNews,'isonline',false)">
					<mat-icon>visibility</mat-icon>
			</button>

			<button mat-icon-button color="black" 
				matTooltip="Publish News On Homepage" matTooltipPosition="after"
				*ngIf="(singleNews.isonhp==false||!singleNews.isonhp)&&singleNews.homeposition"
				(click)="updateField(singleNews,'isonhp',true)">
					<mat-icon>home</mat-icon>
			</button>
			<button mat-icon-button color="warn" 
				matTooltip="Remove News From Homepage" matTooltipPosition="after"
				*ngIf="(singleNews.isonhp==true)&&singleNews.homeposition"
				(click)="updateField(singleNews,'isonhp',false)">
					<mat-icon>home</mat-icon>
			</button>					
		</div>
	</div>
</div>