<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<span id="Topnav" class="ancre-topnav">.</span>


<button id="mainmenu" mat-fab class="main-menu-trigger-button doNotPrint"
  [matMenuTriggerFor]="menu">
  <mat-icon>menu</mat-icon>
</button>

<button id="searchbut" mat-fab class="search-button doNotPrint"
  [routerLink]="['home']"
  [ngStyle]="{ 'color':'#2e3192' }"
  (click)="openDialog()">
  <mat-icon>search</mat-icon>
</button>

<!-- <button mat-mini-fab color="black"
matTooltip="Duplicate Event" matTooltipPosition="after" (click)="openDuplicateEventDialog(event)">
  <mat-icon>content_copy</mat-icon>
</button> -->

<!-- <mat-form-field class="field" *ngIf="isVisible&&!showFields">
  <mat-label>select a search option </mat-label>
  <mat-select ngModel #selectedChoice="ngModel"
   name="selectedChoice">
    <mat-option *ngFor="let choice of choices" [value]="choice" (click)="showField(selectedChoice)">
      {{choice}}
    </mat-option>
  </mat-select>
</mat-form-field> -->

<!-- <mat-card class="fields" *ngIf="showFields" >
<mat-form-field	class="full-width">
  <input class="full-width"
      matInput 
      placeholder="{{dataSelected}}" aria-label="Search" 
      name="newEventData"
      ngModel
      [matAutocomplete]="auto" 
      [formControl]="dataCtrl">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
    <mat-option *ngFor="let data of filteredData | async" (click) = "link(data)"
      [value]="data">
      <span *ngIf="isArtist" >{{ data.$key }} <strong>{{ data.firstname }}</strong> {{ data.lastname }}</span>
      <span *ngIf="isEvent&&data.venue" ><strong>{{ data.title }}</strong> {{ data.venue.venuename }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix class="icon-but">search</mat-icon>	  	
</mat-form-field>
    
</mat-card> -->

  <mat-menu #menu="matMenu" yPosition="below" xPosition="before">

    <img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_menu_png_1524061873770.jpg?alt=media&token=76343833-83a2-4e32-9f29-245a94217f1e" class="logo-menu" routerLink="./home">

    <button mat-menu-item [matMenuTriggerFor]="menuartists">
      <mat-icon>accessibility_new</mat-icon>
      <span>Artists </span>
    </button>

    <button mat-menu-item routerLink="./touring">
      <mat-icon>map</mat-icon>
      <span>Touring</span>
    </button>

    <button mat-menu-item routerLink="./events">
      <mat-icon>today</mat-icon>
      <span>Events</span>
    </button>
    
    <button mat-menu-item [matMenuTriggerFor]="menuaboutus">
      <mat-icon>pan_tool</mat-icon>
      <span>About Us </span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="menucontact">
      <mat-icon>chat</mat-icon>
      <span>Contact</span>
    </button>

    <mat-divider *ngIf="auth.user | async as user"></mat-divider>

<!--     <button *ngIf="auth.user | async as user" mat-menu-item routerLink="./dashboard">
      <mat-icon>apps</mat-icon>
      <span>Dashboard</span>
    </button> -->

    <button *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" mat-menu-item [matMenuTriggerFor]="menuevent">  
      <mat-icon>event</mat-icon>
      <span>Edit Events</span>
    </button>
    
    <button *ngIf="(auth.user | async)?.role=='admin'" mat-menu-item  routerLink="./profile">
      <mat-icon>person</mat-icon>
      <span *ngIf="auth.user | async as user">{{ user.displayName }}</span>
    </button>
    <button *ngIf="(auth.user | async)?.role=='agent'" mat-menu-item  routerLink="./profile">
      <mat-icon>person</mat-icon>
      <span *ngIf="auth.user | async as user">{{ user.displayName }}</span>
    </button>

    <button *ngIf="(auth.user | async)?.role=='admin'" mat-menu-item mat-menu-item [matMenuTriggerFor]="menuusers">
      <mat-icon>settings</mat-icon>
      <span>Admin</span>
    </button>

  

    <button *ngIf="auth.user | async as user; else login" mat-menu-item (click)="logout(user)">
      <mat-icon>power_settings_new</mat-icon>
      <span>Log out</span>
    </button>
    <ng-template #login>
      <button mat-menu-item  routerLink ='/login'>
        <mat-icon>lock</mat-icon>
        <span>login</span>
      </button>  
    </ng-template>


  </mat-menu>


  <!--------------------------------------------------------------- -->
  <!--------------------------------------------------------------- -->
  <!--------------------- P U B L I C   S U B M E N U ------------- -->
  <!--------------------------------------------------------------- -->
  <!--------------------------------------------------------------- -->
  <!--------------------------------------------------------------- -->

  <mat-menu #menuartists="matMenu">
    <button mat-menu-item routerLink="./artists">
      <mat-icon>language</mat-icon>
      <span>Paris</span>
    </button>
    <button mat-menu-item routerLink="./artistschina">
      <mat-icon>language</mat-icon>
      <span>China</span>
    </button>
    <a href="https://www.kajimotomusic.com/" target="_blank">
    <button mat-menu-item>
      <mat-icon>language</mat-icon>
      <span>Japan</span>
    </button>
    </a>
  </mat-menu>

  <mat-menu #menuaboutus="matMenu">
    <button mat-menu-item routerLink="./words">
      <mat-icon>portrait</mat-icon>
      <span>A few words</span>
    </button>
    <button mat-menu-item routerLink="./history">
      <mat-icon>blur_on</mat-icon>
      <span>History</span>
    </button>
  </mat-menu>

  <mat-menu #menucontact="matMenu">
    <button mat-menu-item routerLink="./contact">
      <mat-icon>people</mat-icon>
      <span>Team</span>
    </button>
    <button mat-menu-item routerLink="./offices">
      <mat-icon>business</mat-icon>
      <span>Offices</span>
    </button>    
  </mat-menu>


  <!--------------------------------------------------------------- -->
  <!--------------------------------------------------------------- -->
  <!--------------------- A D M I N  S U B M E N U ---------------- -->
  <!--------------------------------------------------------------- -->
  <!--------------------------------------------------------------- -->

  <mat-menu #menuevent="matMenu">
    <button mat-menu-item routerLink="./event$/eventform">
      <mat-icon>event</mat-icon>
      <span>New Event</span>
    </button>      
    <button mat-menu-item routerLink="./event$/tours">
      <mat-icon>360</mat-icon>
      <span>Tours & Routings</span>
    </button>
    <button mat-menu-item routerLink="./venue$/venues">
      <mat-icon>music_video</mat-icon>
      <span>Venues</span>
    </button>            
    <button *ngIf="((auth.user | async)?.role=='admin')" mat-menu-item routerLink="./event$/eventslist">
      <mat-icon>list</mat-icon>
      <span>Events List</span>
    </button>
  </mat-menu>


  <mat-menu #menuusers="matMenu">   
    <button *ngIf="(auth.user | async)?.role==('admin' || 'agent')" mat-menu-item [matMenuTriggerFor]="menucompo">  
      <mat-icon>music_note</mat-icon>
      <span>Programme</span>
    </button>
    <button *ngIf="(auth.user | async)?.role=='admin'" mat-menu-item routerLink="./new$/news" >  
      <mat-icon>description</mat-icon>
      <span>News</span>
    </button>    
    <button *ngIf="(auth.user | async)?.role=='admin'" mat-menu-item routerLink="./member$/members" >  
      <mat-icon>people</mat-icon>
      <span>Members</span>
    </button>  
    <button *ngIf="(auth.user | async)?.role=='admin'" mat-menu-item routerLink="./instrument$/list" >  
      <mat-icon>people</mat-icon>
      <span>Instruments</span>
    </button>        
    <button mat-menu-item routerLink="./management">
      <mat-icon>assignment_ind</mat-icon>
      <span>Agents Management</span>
    </button>
    <button *ngIf="(auth.user | async)?.displayName=='Corentin Banzet'" mat-menu-item routerLink="./users">
      <mat-icon>account_circle</mat-icon>
      <span>Users</span>
    </button>
    <button *ngIf="(auth.user | async)?.displayName=='Corentin Banzet'" mat-menu-item routerLink="./logslist">
      <mat-icon>settings_input_composite</mat-icon>
      <span>Logs</span>
    </button>    
  </mat-menu>


  <mat-menu #menucompo="matMenu">
    <button mat-menu-item routerLink="./composer$/composers">
      <mat-icon>music_note</mat-icon>
      <span>Composers</span>
    </button>
    <button mat-menu-item routerLink="./composer$/compositions">
      <mat-icon>library_music</mat-icon>
      <span>Compositions</span>
    </button>    
  </mat-menu>


