import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  emailFormControl = new UntypedFormControl('', [
    Validators.required, 
    Validators.pattern(EMAIL_REGEX)]);
  show = false;
  exeFunctionOnlyOnce= true;
 // visible: boolean = true;

  constructor(public auth: AuthService,
              private router: Router,
              public dialog: MatDialog) { }


  ngOnInit() {
  }

  /// Social Login

  signInWithGoogle() {
    // this.visible = false;
    this.auth.googleLogin()
      .then(() => this.afterSignIn());
  }

  mayILogin() {
    this.auth.googleLogin();
  }

  // checkIfUserWithRoleExist(user) {
  //   if(this.exeFunctionOnlyOnce) { 
  //     console.log(user.role); 
  //     if(user.role) {
  //       console.log('check role', user.role);
  //       this.signInWithGoogle();
  //     }
  //     else{ 
  //       console.log('delete');
  //       // this.auth.deleteUser(user)
  //     }
  //     this.exeFunctionOnlyOnce=false;
  //   }
  // }

  /// Anonymous Sign In

  signInAnonymously() {
    this.auth.anonymousLogin()
      .then(() => this.afterSignIn());
  }


  /// Shared

  private afterSignIn(): void {
    // Do after login stuff here, such router redirects, toast messages, etc.
    this.router.navigate(['/redirection']);
  }


  openDialog(): void {
      const dialogRef = this.dialog.open(DialogSignupComponent, {
        width: '400px',
        backdropClass: 'filterEventsDialog',
        panelClass: 'searchDialogPanel',          
        data: {
            key: 'key'
         }
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
      });
    }
  
    close(){
      this.show = false;
  }
}







@Component({
  selector: 'app-dialog-signup',
  templateUrl: 'dialog-signup.component.html',
  styleUrls: ['./dialog-signup.scss']  
})

export class DialogSignupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSignupComponent>,
    private router: Router,
    public auth : AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
 
  ngOnInit() {
  }

  signUpWithGoogle() {
    this.auth.googleRegister()
      .then(() => this.afterSignUp());
      this.onNoClick();
  }

  private afterSignUp(): void {
    // Do after login stuff here, such router redirects, toast messages, etc.
    // this.router.navigate(['/profile']);
  }
}


