import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from './ui/angularmaterial.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './ui/not-found.component';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';

// Core & Shared
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import 'hammerjs';

// Feature Modules
import { UiModule } from './ui/shared/ui.module';
import { UploadModule } from './upload/shared/upload.module';
import { MemberModule } from './members/shared/member.module';
import { PressModule } from './press/shared/press.module';
import { NewsModule } from './news/shared/news.module';
import { EventModule } from './events/shared/event.module';
import { VenueModule } from './venue/shared/venue.module';
import { PublicModule } from './public/shared/public.module';
import { AdminModule } from './admin/shared/admin.module';
import { ComposerModule } from './composers/shared/composer.module';

// Firebase
// ✅ Ancienne API (compat) pour éviter de casser l’existant
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

// ✅ Nouvelle API Firebase 9+ (pour une migration progressive)
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';

// Environment
// import { environment } from '../environments/environment';
import { environment } from '../environments/environment.prod';
// import { AppServerModule } from './app.server.module';


import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VersionDialogComponent } from './version-dialog/version-dialog.component';
// Other
// import { NgStickyDirective } from 'ng-sticky';


@NgModule({
  declarations: [
    AppComponent,
    // NgStickyDirective,
    PageNotFoundComponent,
    VersionDialogComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'kajimotomusic' }),
    CommonModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NewsModule,
    PressModule,
    UiModule,
    VenueModule,
    UploadModule,
    PublicModule,
    AdminModule,
    MemberModule,
    ComposerModule,
    EventModule,  

    // ✅ Initialise l’ancienne API (compat) pour ne rien casser
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,

    // ✅ Nouvelle API Firebase (permet de migrer progressivement)
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),

    BrowserAnimationsModule,
    MatIconModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatCardModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    NgxPageScrollModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // AppServerModule
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    // BackgroundImageResolver
    { provide:  SETTINGS, useValue: {}}
  ]
})
export class AppModule { }
