<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>								
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<mat-card *ngIf="searchVenueForm" class="noshadow">

	<mat-card-content *ngIf="eventview | async; let eventview; else loading" [ngStyle]="{'padding-top':'50px'}">

		<mat-form-field	class="full-width" *ngIf="showSearchVenuesField">
			<mat-label> Search Venue </mat-label>
		  <input class="full-width"
		  		matInput 
		  		aria-label="Venue" 
		  		name="newEventVenue"
		  		ngModel
		  		[matAutocomplete]="auto" 
		  		[formControl]="venueCtrl">
		  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
		    <mat-option *ngFor="let venue of filteredVenues | async" 
		    	[value]="venue">
		      <span><strong>{{ venue.name }}</strong> {{ venue.address }}</span>
		    </mat-option>
		  </mat-autocomplete>  
		<mat-spinner matSuffix *ngIf="showSearchLoader" class="loader fx-row" ></mat-spinner>
		  <mat-icon matSuffix *ngIf="!showSearchLoader" class="add-icon">search</mat-icon>
			<mat-icon matSuffix *ngIf="!showSearchLoader" (click)="addVenueForm=true;searchVenueForm=false" class="add-icon">add</mat-icon>
			<mat-icon matSuffix *ngIf="!showSearchLoader" [routerLink]="['./../../../../event$/event', eventkey]" class="add-icon">clear</mat-icon>
		</mat-form-field>
		
		<span *ngIf="isVenueSelected">
			{{ addVenueToEvent(eventview, selectedvenue) }}
		</span>

	</mat-card-content>

</mat-card>




<mat-card appearance="outlined" *ngIf="addVenueForm" class="noshadow">

	<mat-card-content [ngStyle]="{'padding-top':'50px'}">
		<form #newVenueForm="ngForm" (ngSubmit)="onSubmit(newVenueForm)">
			<mat-form-field class="full-width">
				<mat-label> Name of the Venue </mat-label>
				<input matInput required
					ngModel #name="ngModel" name="name">
			</mat-form-field>
			<mat-form-field class="full-width">
				<mat-label> Address </mat-label>
				<input matInput required					
					ngModel #address="ngModel" name="address">
			</mat-form-field>
			<mat-form-field class="full-width">
				<mat-label> Website </mat-label>
				<input matInput					
					ngModel #url="ngModel" name="url">
			</mat-form-field>

			<button mat-fab type="button" color="black" class="mr-10"
				[routerLink]="['./../../event$/event', eventkey]">
				<mat-icon>clear</mat-icon>
			</button>

			<button mat-fab type="submit" color="warn"
				[disabled]="!newVenueForm.form.valid">
				<mat-icon>save</mat-icon>
			</button>

		</form>
	</mat-card-content>
</mat-card>