<!-- <pre>{{ events | async | json }}</pre> -->

<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner> 
  <br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<h1 class="mobile-h1">Events of Kajimoto Paris</h1>


<button mat-fab color="black" class="fl-r fl-r-xs"
  matTooltip="Add Event" matTooltipPosition="before"
  [routerLink]="['./../../event$/eventform']">
  <mat-icon>add</mat-icon>
</button>

<div [ngStyle]="{'padding-top':'30px'}">

<!--     <div *ngIf="event$ | async; let events">
      <div *ngFor="let event of events">
        <event-repair [eventkey]="event.$key"></event-repair>{{ event.$key }} : {{ event.title }} / {{ event.artistkey }}
      </div>
    </div> -->



    <table mat-table [dataSource]="dataSource">
  
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> {{ (element.title.length>70) ? (element.title | slice:0:70)+'...':(element.title) }} </td>
      </ng-container>
  
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> {{ element.date | date  }} | {{ element.time }}</td>
      </ng-container>
  
      <ng-container matColumnDef="artist lastname">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
              <app-event-artist [eventkey]="element.$key"></app-event-artist> 
          </td>
      </ng-container>

      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
                <mat-icon 
                  [routerLink]="['./../../event$/event', element.$key]"
                  matTooltip="See & Edit Event Details" 
                  matTooltipPosition="after">info</mat-icon> 
                  </td>
      </ng-container>

      <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">    
                  <mat-icon 
                    *ngIf="!element.artistkey"
                    matTooltip="Delete Event" 
                    matTooltipPosition="after"
                    (click)="deleteEvent(element)">delete_forever</mat-icon>
             </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>

  </div>