import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-touring',
  templateUrl: './touring.component.html',
  styleUrls: ['./touring.component.scss'],
  animations: [
    trigger('show_2017', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('300ms', [
          animate('.6s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('300ms', [
          animate('.6s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])

  ]
})
export class TouringComponent implements OnInit {

	imglogokey:string; imglogoname:string; imglogourl:string;
	imglogobr:string;
  publicToursDetails: any;
	txtInEn:boolean = true;
	txtInFr:boolean = false;
	titleInFr:boolean =false;
	titleInEn:boolean =true;
	progyear:boolean =true;
	show_2019:boolean =true;
	show_2018:boolean =true;
	show_2017:boolean =false;
  addTouringForm: boolean = false;

  constructor(public  auth: AuthService, private ps: PublicService,
    private titleService: Title,
    private meta: Meta) {
    this.publicToursDetails = this.ps.getPublicToursDetails();
  }

  setTitle( newTitle: string) { this.titleService.setTitle( newTitle ); }

  ngOnInit() {

    this.setTitle('Kajimoto Music Paris | Touring');


    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/touring` },
      { name: 'og:title', content: 'touring Page'},
      { name: 'og:description', content: 'Kajimoto Music presents its touring activity'},
      { name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830`},

      { name: 'description', content: 'Kajimoto Music presents its touring activity' },
      { name: 'title',  content: 'touring Page' },
      { name: 'twitter:card',  content: 'summary' },
      { name: 'twitter:site',  content: 'Kajimoto Music' },
      { name: 'twitter:title', content: 'touring Page' },
      { name: 'twitter:description', content: 'Kajimoto Music presents its touring activity' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:image',  content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830` },
      { name: 'twitter:url', content: `https://kajimotomusicparis.com/touring` },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'msapplication-starturl', content: '/' },
      { name: 'theme-color', content: '#f48c5b' } ,
      { name : 'keywords',  content: `touring`},
      { name : 'url', content:   `https://kajimotomusicparis.com/touring` }
    ]);

		this.imglogobr = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2FKajimoto_logo_red-black.jpg?alt=media&token=909a189c-8eb7-463f-bf45-2c2068ecb4e6";
		this.imglogokey = "-L3-ARQZ07dzuZa2xImh";
		this.imglogoname = "logo-kajimoto-aboutus.png";
		this.imglogourl = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Flogo-kajimoto-touring.png?alt=media&token=b8d1ff95-6fc0-46fc-b56a-508fc8ee88a4";
  }

  lg(en, fr) {
  	if (this.txtInEn) return en;
  	else return fr;
  }

  onSubmit(publicTourForm: NgForm) {
    this.ps.createPublicTourDetail(publicTourForm);
    this.addTouringForm = !this.addTouringForm;
  }

  deletePublicTourDetail(publictourdetail) {
    this.ps.deletePublicTourDetail(publictourdetail);
  }


  isNewYear(x, y) {
  }

}