<!-- <pre>{{ artist | async | json }}</pre> -->
<title *ngIf="artist | async as artist">Kajimoto | {{ artist.lastname }} {{ artist.firstname }}</title> 

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>



<button mat-fab class="button-lang button-lang-xs" 
  [ngClass]="linkClass"
  *ngIf="txtInEn"
  (click)="
    txtInFr=true;txtInEn=false;
    titleInFr=true;titleInEn=false
    ">
  FR
</button>
<button mat-fab class="button-lang button-lang-xs"  
  [ngClass]="linkClass"
  *ngIf="txtInFr"
  (click)="
    txtInFr=false;txtInEn=true;
    titleInFr=false;titleInEn=true
    ">
  EN
</button>

<div *ngIf="artist | async as artist; else loading" class="bounds">

  <div *ngIf="auth.user | async as user">
    <div *ngIf="checkIfArtistManagedByagent(artist.agentkeys,user.memberkey)" class="admin-menu">
      <button mat-mini-fab type="button" color="black"      
        [routerLink]="['./../../member$/member', artist.$key]">
        <mat-icon>edit</mat-icon>
      </button>       
    </div>
  </div>

  <div class="content fx-row-wrap fx-col-xs fx-100" #scContent>
    <!-- {{ instruClass(artist.$key, artist.instrument) }} -->

     <div class="sec1 fx-10 fx-col fx-start-start hide-mobile fx-start-start">
      
        <h1 [ngClass]="darkClass" *ngIf="artist.lastname==='Zeliha'">
          <span [ngClass]="lightClass">T</span>r<span [ngClass]="lightClass">i</span>o
        </h1>
        <h1 [ngClass]="darkClass" *ngIf="artist.lastname==='Wilson'&&txtInEn">
          <span [ngClass]="lightClass">N</span>a<span [ngClass]="lightClass">r</span>r<span [ngClass]="lightClass">a</span>t<span [ngClass]="lightClass">o</span>r
        </h1>        
        <h1 [ngClass]="darkClass" *ngIf="artist.lastname==='Wilson'&&txtInFr">
          <span [ngClass]="lightClass">R</span>é<span [ngClass]="lightClass">c</span>i<span [ngClass]="lightClass">t</span>a<span [ngClass]="lightClass">n</span>t
        </h1>                
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='conductor'&&!txtInFr">
          <span [ngClass]="lightClass">C</span>o<span [ngClass]="lightClass">n</span>d<span [ngClass]="lightClass">u</span>c<span [ngClass]="lightClass">t</span>o<span [ngClass]="lightClass">r</span>
        </h1>
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='conductor'&&txtInFr">
          <span [ngClass]="lightClass">D</span>i<span [ngClass]="lightClass">r</span>e<span [ngClass]="lightClass">c</span>t<span [ngClass]="lightClass">i</span>o<span [ngClass]="lightClass">n</span>
        </h1>

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='harp'">
          <span [ngClass]="lightClass">H</span>a<span [ngClass]="lightClass">r</span>p
        </h1>

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='composer'&&txtInFr">
          <span [ngClass]="lightClass">C</span>o<span [ngClass]="lightClass">m</span>p<span [ngClass]="lightClass">o</span>s<span [ngClass]="lightClass">e</span>r
        </h1> 
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='composer'&&!txtInFr">
          <span [ngClass]="lightClass">C</span>o<span [ngClass]="lightClass">m</span>p<span [ngClass]="lightClass">o</span>s<span [ngClass]="lightClass">i</span>t<span [ngClass]="lightClass">e</span>u<span [ngClass]="lightClass">r</span>
        </h1>          
        
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='cello'&&txtInFr">
          <span [ngClass]="lightClass">V</span>i<span [ngClass]="lightClass">o</span>l<span [ngClass]="lightClass">o</span>n<span [ngClass]="lightClass">c</span>e<span [ngClass]="lightClass">l</span>l<span [ngClass]="lightClass">e</span>
        </h1> 
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='cello'&&!txtInFr && artist.lastname!='Zeliha'">
          <span [ngClass]="lightClass">C</span>e<span [ngClass]="lightClass">l</span>l<span [ngClass]="lightClass">o</span>
        </h1> 

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='piano'">
          <span [ngClass]="lightClass">P</span>i<span [ngClass]="lightClass">a</span>n<span [ngClass]="lightClass">o</span>
        </h1>    

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='viola'&&txtInFr">
          <span [ngClass]="lightClass">V</span>i<span [ngClass]="lightClass">o</span>l<span [ngClass]="lightClass">a</span>
        </h1>
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='viola'&&!txtInFr">
          <span [ngClass]="lightClass">A</span>l<span [ngClass]="lightClass">t</span>o
        </h1>      

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='violin'&&txtInFr">
          <span [ngClass]="lightClass">V</span>i<span [ngClass]="lightClass">o</span>l<span [ngClass]="lightClass">i</span>n
        </h1>
        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='violin'&&!txtInFr">
          <span [ngClass]="lightClass">V</span>i<span [ngClass]="lightClass">o</span>l<span [ngClass]="lightClass">o</span>n
        </h1>

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='sheng'">
            <span [ngClass]="lightClass">S</span>h<span [ngClass]="lightClass">e</span>n<span [ngClass]="lightClass">g</span>
        </h1>

        <h1 [ngClass]="darkClass" *ngIf="artist.instrument.name=='percussions'">
            <span [ngClass]="lightClass">P</span>e<span [ngClass]="lightClass">r</span>c<span [ngClass]="lightClass">u</span>s<span [ngClass]="lightClass">s</span>i<span [ngClass]="lightClass">o</span>n<span [ngClass]="lightClass">s</span>
        </h1>  
      
    </div> 
    <div class="fx-80 fx-row-wrap fx-100-xs">
    	<div class="fx-100 fx-col fx-wrap fx-start-start fx-100-xs">
    		<h2 class="artistNameForMobileVersion"
          [ngClass]="darkClass">
            {{ artist.firstname }} {{ artist.lastname }} 
        </h2>
        
        <ul [ngStyle.xs]="menuForMobileVersionUL" class="submenu-artist submenu-artist-xs menuForMobileVersionUL fx-wrap-xs" [ngClass]="lightClass" id="nav-bar">
          <li [ngClass]="hoverClass" (click)="scrollToTop()" class="sticky-name">
            <img src="{{ artist.profilepic }}" class="profile-pic">
            {{ artist.firstname }} {{ artist.lastname }} 
          </li>     
          <li [ngClass]="hoverClass" (click)="scrollToDiv(BiographieDiv)" *ngIf="artist.bioshorthtmlfr" class="pl-nosticky hide-mobile">{{ lg("Biography","Biographie") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(EventsDiv)" *ngIf="artist.isevent" class="hide-mobile">{{ lg("Events","Événements") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(PresseDiv)" *ngIf="artist.ispress" class="hide-mobile">{{ lg("Press","Presse") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(NewsDiv)" *ngIf="artist.isnews" class="hide-mobile">{{ lg("News","Actualité") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(MediaDiv)" *ngIf="artist.ismedia" class="hide-mobile">{{ lg("Videos","Vidéos") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(DiscographieDiv)" *ngIf="artist.isdisco" class="hide-mobile">{{ lg("Discography","Discographie") }}</li>
          <li [ngClass]="hoverClass" (click)="scrollToDiv(ContactDiv)" class="hide-mobile">Contact</li>
        </ul>   
    	</div>

      <!-- //////////// IMG /////////////////////////////////////////// -->  
      <div id="trigger-on" class="fx-100"
          *ngIf="artist.imgcarrousel1&&artist.imgcarrousel2" 
          (scroll)="onWindowScroll($event)">
          <ul class="command-carrousel">
            <li *ngFor="let img of imgs; let i = index" [ngClass]="linkClass"
            (click)="changeSlide(i + 1, nbslides)"></li>      
          </ul>
      </div>
      
      <div class="fx-100 carousell-img" *ngFor="let img of imgs; let i = index">
        <div  [hidden]="!(artist.imgcarrousel[i]&&showimg[i])">
          <img src="{{ imageToDisplay[i] }}" alt="{{ artist.firstname }} {{ artist.lastname }}">
          <span *ngIf="artist.imgcarrouselcredit[i]" class="credit-photo">
            © {{ artist.imgcarrouselcredit[i] }}
            <a *ngIf="artist.hireslink" href="{{ artist.hireslink }}" target="_blank" class="dl-hr">Download Hi-Res</a>
          </span>
        </div>
      </div>

      <!-- <div class="carousell-img fx-100" *ngIf="artist.imgcarrousel1&&showimg1">
        <img src="{{ img1 }}" alt="{{ artist.firstname }} {{ artist.lastname }}">
        <span *ngIf="artist.imgcarrousel1credit" class="credit-photo">
          © {{ artist.imgcarrousel1credit }}
          <a *ngIf="artist.hireslink" href="{{ artist.hireslink }}" target="_blank" class="dl-hr">Download Hi-Res</a>
        </span>
      </div>
      <div class="carousell-img fx-100" *ngIf="artist.imgcarrousel2&&showimg2">
        <img src="{{ img2 }}" alt="{{ artist.firstname }} {{ artist.lastname }}">
        <span *ngIf="artist.imgcarrousel2credit" class="credit-photo">
          © {{ artist.imgcarrousel2credit }}
          <a *ngIf="artist.hireslink" href="{{ artist.hireslink }}" target="_blank" class="dl-hr">Download Hi-Res</a>
        </span>
      </div>
      <div class="carousell-img fx-100" *ngIf="artist.imgcarrousel3&&showimg3">
        <img src="{{ img3 }}" alt="{{ artist.firstname }} {{ artist.lastname }}">
        <span *ngIf="artist.imgcarrousel3credit" class="credit-photo">
          © {{ artist.imgcarrousel3credit }}
          <a *ngIf="artist.hireslink" href="{{ artist.hireslink }}" target="_blank" class="dl-hr">Download Hi-Res</a>
        </span>
      </div>
      <div class="carousell-img fx-100" *ngIf="artist.imgcarrousel4&&showimg4">
        <img src="{{ img4 }}" alt="{{ artist.firstname }} {{ artist.lastname }}">
        <span *ngIf="artist.imgcarrousel4credit" class="credit-photo">
          © {{ artist.imgcarrousel4credit }}
          <a *ngIf="artist.hireslink" href="{{ artist.hireslink }}" target="_blank" class="dl-hr">Download Hi-Res</a>
        </span>
      </div> -->
      
      <div class="grid-block">

        <!-- //////////// BIO /////////////////////////////////////////// -->
        <div #BiographieDiv class="fx-wrap fx-33 bio-style bio-style-xs fx-100-xs" >
          <div class="fx-wrap bio-style-xs fx-100-xs" id="Biographie" *ngIf="artist.bioshorthtmlfr">
            <h4 class="margin-0" [ngClass]="darkClass" >{{ lg("Biography","Biographie") }}</h4>
            <!-- {{ sendBioAndCutIfTooLong(artist.bioshorthtmlfr,4000,'fr') }} -->
            <!-- {{ sendBioAndCutIfTooLong(artist.bioshorthtmlen,4000,'en') }}   -->
            <p *ngIf="txtInFr" class="bio padding-5-xs" innerHTML="{{ artist.bioshorthtmlfr }}"></p>        
            <p *ngIf="txtInEn" class="bio padding-5-xs" innerHTML="{{ artist.bioshorthtmlen }}"></p>
            <!-- <p *ngIf="txtInFr" [ngStyle.xs]="{ 'padding':'5px' }" class="bio" innerHTML="{{ biofr }}"></p> -->
            <!-- <p *ngIf="txtInEn" [ngStyle.xs]="{ 'padding':'5px' }" class="bio" innerHTML="{{ bioen }}"></p> -->

            <!-- <span innerHTML="{{ singleNews.content }}"></span> -->
            <a *ngIf="artist.website" href="http://www.{{ artist.website }}" target="_blank" class="height-20 pm-link" [ngClass]="linkClass">www.{{ artist.website }}</a>
            <a class="height-20 pm-link hide-mobile"
              [routerLink]="['./../../bio', artist.$key]"
              [queryParams]="{'lang': txtInEn ? 'en': 'fr'}"
              [ngClass]="linkClass">
              {{ lg("Download Biography","Télécharger la Biographie") }}
            </a>        
          </div>
        </div>

        <!-- //////////// EVENTS /////////////////////////////////////////// -->
        <!-- *ngIf="artist.arrevents" -->
        <div #EventsDiv class="fx-33 fx-col fx-100-xs" >
          <div class="fx-30 fx-col fx-100-xs" id="Events" *ngIf="artist.isevent">
            <h4 [ngClass]="darkClass" class="padding-5-xs" [ngStyle.xs]="{ 'padding':'5px' }">{{ lg("Events Coming","Events") }}</h4>
            <ul class="news-ul" *ngIf="events | async; let events">
              <li *ngFor="let event of events; let i = index ">
                <!-- {{ event.json }} -->
                <div *ngIf="i<nbevents">
                  <app-event [eventkey]="event.$key"></app-event>
                </div>
              </li>
            </ul>
            <hr class="breakli">
            <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(EventsDiv)"
              *ngIf="butmoreevents" (click)="nbevents=40;butmoreevents=false;butlessevents=true">
              add
            </mat-icon>
            <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(EventsDiv)"
              *ngIf="butlessevents" (click)="nbevents=3;butmoreevents=true;butlessevents=false">
              remove
            </mat-icon>           
          </div>
        </div>
   
        <!-- //////////// NEWS /////////////////////////////////////////// -->
        <div #NewsDiv class="fx-33 fx-100-xs news-style news-style-xs">
          <div class="fx-100 fx-100-xs" id="News" *ngIf="artist.isnews">
            <div class="fx-100 fx-col">
              <h4 [ngClass]="darkClass" class="padding-5-xs">{{ lg("News & Features","Actualités") }}</h4>

              <div *ngIf="news | async;let news" class="padding-5-xs" [ngStyle.xs]="{ 'padding':'5px' }">
                <div *ngFor="let news of news; let i = index">
                  <artist-news [newskey]="news.$key" [instrumentColor]="bgClass"></artist-news>
                </div>           
                <div *ngIf="news.length>3">
                  <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(NewsDiv)"
                    *ngIf="butmorenews" (click)="nbnews=30;butmorenews=false;butlessnews=true">
                    add
                  </mat-icon>
                  <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(NewsDiv)" 
                    *ngIf="butlessnews" (click)="nbnews=3;butmorenews=true;butlessnews=false">
                    remove
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- //////////// PRESS /////////////////////////////////////////// -->
        <div #PresseDiv class="fx-33 fx-col fx-100-xs press">
          <div class="fx-40 fx-col fx-100-xs press " id="Presse" *ngIf="artist.ispress">
            <div>
              <h4 [ngClass]="darkClass" class="padding-5-xs">{{ lg("Press","Presse") }}</h4>
              <div *ngIf="press | async; let press" class="padding-5-xs" [ngStyle.xs]="{ 'padding':'5px' }">
                <div *ngFor="let press of press; let i = index">
                  <div *ngIf="i<nbpress">
                    <artist-press [presskey]="press.$key"></artist-press>
                  </div>              
                </div>
              </div>
              <hr class="breakli">
            </div>
            <div>
              <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(PresseDiv)"
                *ngIf="butmorepress" (click)="nbpress=30;butmorepress=false;butlesspress=true">
                add
              </mat-icon>
              <mat-icon class="iconbut trans" [ngClass]="linkClass" (click)="scrollToDiv(PresseDiv)"
                *ngIf="butlesspress" (click)="nbpress=3;butmorepress=true;butlesspress=false">
                remove
              </mat-icon>          
            </div>
          </div>
        </div>

        <!-- //////////// DISCO /////////////////////////////////////////// -->
        <div #DiscographieDiv class="fx-33 fx-col fx-100-xs">
          <div class="fx-50 fx-col fx-100-xs" id="Discographie" *ngIf="artist.isdisco">
            <h4 [ngClass]="darkClass" class="padding-5-xs">{{ lg("Discography","Discographie") }}</h4>  
              <mat-grid-list cols="4" rowHeight="1:1">
                <mat-grid-tile class="arist-grid"
                  *ngFor="let disc of artist.arrdiscog">
                  <a href="{{ disc.link }}" target="_blank">
                    <img src="{{ disc.url }}" class="responsiveimg discog-img">
                  </a>
                </mat-grid-tile>
              </mat-grid-list>    
          </div>
        </div>

        <!-- //////////// CONTACT /////////////////////////////////////////// -->
        <div #ContactDiv class="fx-col fx-ai-start fx-33 fx-100-xs" id="Contact">
          <h4 [ngClass]="darkClass" class="padding-5-xs">Contacts KAJIMOTO</h4>

          <h5 *ngIf="artist.kajimotomanagementrepresentation" class="padding-5-xs">
            <span *ngIf="artist.kajimotomanagementrepresentation"><strong>REPRESENTATION </strong><br>
            {{ artist.kajimotomanagementrepresentation }}</span>
          </h5>

          <h5 *ngIf="artist.kajimotomanagementtokyoname" class="padding-5-xs">
            <strong>TOKYO</strong> <br>
            {{ artist.kajimotomanagementtokyoname }}
          </h5>
          <a *ngIf="artist.kajimotomanagementtokyoemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementtokyoemail}}">
          {{ 
            (artist.kajimotomanagementtokyoemail.indexOf('@')!=0) ? 
            (artist.kajimotomanagementtokyoemail.replace('@','[at]')) : artist.kajimotomanagementtokyoemail
          }}        
          </a>

          <h5 *ngIf="artist.kajimotomanagementparisname" class="padding-5-xs">
            <strong>PARIS</strong> <br>{{ artist.kajimotomanagementparisname }}</h5>
          <a *ngIf="artist.kajimotomanagementemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementemail}}">
          {{ 
            (artist.kajimotomanagementemail.indexOf('@')!=0) ? 
            (artist.kajimotomanagementemail.replace('@','[at]')) : artist.kajimotomanagementemail
          }}</a>
        
          <a *ngIf="artist.kajimotomanagementphoneoffice" class="pm-link" [ngClass]="linkClass" href="tel:{{ artist.kajimotomanagementphoneoffice }}">Office : {{ artist.kajimotomanagementphoneoffice }}</a>
          <br>      
          <a *ngIf="artist.kajimotomanagementphonemobile" class="pm-link" [ngClass]="linkClass" href="tel:{{ artist.kajimotomanagementphonemobile }}">Mobile : {{ artist.kajimotomanagementphonemobile }}</a>
        


          <h5 *ngIf="artist.kajimotomanagementbeijingname" class="padding-5-xs" >
            <strong>BEIJING</strong> <br> {{ artist.kajimotomanagementbeijingname }}</h5>
          <a *ngIf="artist.kajimotomanagementbeijingemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementbeijingemail}}">
            {{ 
                (artist.kajimotomanagementbeijingemail.indexOf('@')!=0) ? 
                (artist.kajimotomanagementbeijingemail.replace('@','[at]')) : artist.kajimotomanagementbeijingemail
            }}
          </a>

          <h5 class="padding-5-xs">
            <span *ngIf="artist.generalmanagementname"><strong>GENERAL MANAGEMENT</strong><br> 
            {{ artist.generalmanagementname }}
            </span>
          </h5>

        </div>

      </div>

      
      <div class="sec5 fx-10 hide-mobile">
       <img class="logo-artist" *ngIf="artist.instrument=='Conductor'"  src="{{ imglogourl }}">
       <img class="logo-artist" *ngIf="artist.instrument=='Piano'" src="{{ imglogopiano }}">
       <img class="logo-artist" *ngIf="artist.instrument=='Composer'" src="{{ imglogocomposer }}">
       <img class="logo-artist" src="{{ imglogostring }}"
            *ngIf="
                artist.instrument=='Cello'||
                artist.instrument=='Violin'||
                artist.instrument=='Viola'">
      </div>
    </div>


    <!-- //////////// MEDIA /////////////////////////////////////////// -->
    <div class="fx-100" #MediaDiv >
      <div  id="Media" *ngIf="artist.ismedia" class="fx-100">
        <div class="fx-100 fx-100-xs fx-row fx-wrap" *ngIf="videos | async as videos">        
          <h4 class="fx-100 padding-5-xs">{{ lg("Videos","Vidéos") }}</h4>        
          <div class="fx-33 fx-100-xs arist-media" *ngFor="let media of videos">
            <iframe [src]="('https://www.youtube.com/embed/' + media.link + '?rel=0&amp;controls=0') | safe"  allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>      
        </div>
      </div> 
    </div>

  </div>

</div>

<footer-nav></footer-nav> 
