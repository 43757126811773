<!-- <pre>{{ composer | async | json }}</pre> -->
<!-- <pre>{{ compositions | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>								
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<!-- <mat-card *ngIf="dev"> -->
<mat-card appearance="outlined" *ngIf="composer | async as composer" class="noshadow">
	<br>
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>fingerprint</mat-icon>
		</div>	
		<mat-card-title>Edit Composition of <strong>{{ composer.lastname }} {{ composer.firstname }}</strong></mat-card-title>
		<mat-card-subtitle>Add and remove the compositions of the artist<br>
			<!-- <span class="keystyle">{{ composer.$key }}</span> -->
		</mat-card-subtitle>

		<button mat-fab class="ml" type="button" color="black"
			*ngIf="showEditComposerButton"
			(click)="	showEditForm=true;
								addComposition=false;
								showCompositionList=false;
								showAddButtonAddCompositionsForm=true;
								showEditComposerButton=false;
								showListButton=true"
			matTooltip="Edit Firstname and Lastname" matTooltipPosition="after">
			<mat-icon>edit</mat-icon>
		</button>

		<button mat-fab class="ml" type="button" color="black"
			*ngIf="showAddButtonAddCompositionsForm"
			(click)="	addComposition=true;
								showAddButtonAddCompositionsForm=false;
								showCompositionList=false;
								showEditComposerButton=true;
								showEditForm=false;
								showListButton=true"
			matTooltip="Add composition(s)" matTooltipPosition="after">
			<mat-icon>playlist_add</mat-icon>
		</button>

		<button mat-fab type="button" color="black"
			*ngIf="showListButton"
			(click)="	showCompositionList=true;
								addComposition=false;
								showEditComposerButton=true;
								showEditForm=false;
								showListButton=false;
								showAddButtonAddCompositionsForm=true" 
			matTooltip="Remove the add composition form">
			<mat-icon>queue_music</mat-icon>
		</button> 
 
		
		
	</mat-card-header>

	<mat-card-content *ngIf="showCompositionList">
		<mat-list dense *ngIf="compositions | async; let compositions; else loading">
			<mat-list-item 
				*ngFor="let composition of compositions">
				<mat-icon matListIcon>music_note</mat-icon>
				{{ composition.title }}
				<button mat-icon-button color="warn"
						(click)="deleteCompositionFromComposer(composer.$key,composition.$key)">
		        <mat-icon>remove_circle</mat-icon>
		    </button>
			</mat-list-item>
		</mat-list>		
	</mat-card-content>	
</mat-card>



<mat-card  *ngIf="showEditForm">
	<mat-card-content *ngIf="composer | async as composer">
		<mat-form-field class="full-width">
			<mat-label>Firstname</mat-label>
			<input matInput 
				[(ngModel)]="composer.firstname"
				(ngModelChange)="updateComposerField(composer,'firstname',composer.firstname)">
		</mat-form-field>
		<mat-form-field class="full-width">
			<mat-label>Lastname</mat-label>
			<input matInput 
				[(ngModel)]="composer.lastname"
				(ngModelChange)="updateComposerField(composer,'lastname',composer.lastname)">
		</mat-form-field>		
	</mat-card-content>
</mat-card>




<mat-card *ngIf="addComposition">
	<mat-card-header *ngIf="composer | async as composer">
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>person_add</mat-icon>
		</div>			
		<mat-card-title>Add Compositions to {{ composer.firstname }} {{ composer.lastname }}</mat-card-title>
		<mat-card-subtitle>Copy/Paste Compositions to import them in Kajimotomusic Database</mat-card-subtitle>		
	</mat-card-header>

	<mat-card-content *ngIf="composer | async as composer">
		<mat-form-field class="example-full-width">
			<mat-label> Copy-paste your list of compositions : Full title : One Composition per line </mat-label>
			<textarea matInput
								rows="5" 
								placeholder="Copy-paste your list of compositions : Full title : One Composition per line"
								name="newComposers"
								ngModel #newCompositions="ngModel"></textarea>
		</mat-form-field>	
		<button mat-fab color="warn" 
			(click)="importCompositions(newCompositions,composer)">
			<mat-icon>save</mat-icon>
		</button>
		<!-- 		
		<button mat-fab color="black" (click)="addComposition=false">
        <mat-icon>close</mat-icon>
    </button>	 
  	-->	
	</mat-card-content>
</mat-card>








<mat-toolbar *ngIf="composer | async as composer">
	<button mat-mini-fab class="ml" (click)="goBack()" color="black">
		<mat-icon>keyboard_arrow_left</mat-icon>
	</button>

<!-- 	<button mat-mini-fab class="ml" (click)="addComposition=true" type="button" color="black">
		<mat-icon>add</mat-icon>
	</button>	 -->

	<button mat-mini-fab type="button" color="warn"
	  matTooltip="Delete Composer" matTooltipPosition="after"
		(click)="deleteComposer(composer)">
		<mat-icon>delete_forever</mat-icon>
	</button> 

</mat-toolbar>