<div class="fx-center-center fx-col"> 
    <h1 mat-dialog-title>{{title}}</h1>    
  
    <p mat-dialog-content>{{informations}}</p>
    
    <div mat-dialog-actions class="dialog-actions fx-fill fx-row fx-center-center">
      <div class="fx-center-center fx-50 dialog-but" mat-dialog-close (click)="closeDialog()">{{cancelButton}}</div>
      <div class="fx-center-center fx-50 dialog-but" mat-dialog-close (click)="deleteMember(member)">{{confirmButton}}</div>
    </div>
</div>

