import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { BehaviorSubject, of } from 'rxjs';
import { delay, tap, take, switchMap, map, startWith } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { EventService } from '../shared/event.service';
import { MemberService } from '../shared/member.service';

const ELEMENTS_PER_PAGE = 5 ;
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: [
    trigger('events', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('50ms', [
          animate('.1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateX(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateX(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('.1s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class EventsComponent implements OnInit {

  events: Observable<any[]>;
  artists: Observable<any[]>;
  artistFullName: any;
  testevents: any;
  key: string;
  season: string;
  seasonpic: string;
  profilepic: string;
  event$: Observable<any[]>;
  genres: any;
  d: any;
  dataIsEmpty = false;
  startDate: any;
  endDate: any;
  selectDate: any;
  showPrintDiv = false;
  showartist = false;
  id: any;
  year: string;
  profil: string;
  fullname: string;
  hiver = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-winter.jpg?alt=media&token=499b6686-109b-4862-8158-8c461ac3544f';
  ete = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-summer.jpg?alt=media&token=b9a629fa-1a01-40ef-96ed-f954bc3979bb';
  printemps = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-spring.jpg?alt=media&token=e0e9b7a4-ffb2-4dcd-a46d-75fb36034f10' ;
  automne = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-fall.jpg?alt=media&token=acd8c47f-43a8-4e07-bbe8-cf3e25080b27';

  public readonly loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly cards: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public  pages: number;
  public page = 1;
  public events$ = [] ;
  public dataForm: any;
  key2: string;

  idsOffline: string[];

  constructor(
              public auth: AuthService,
              private eventService: EventService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private titleService: Title,
              private meta: Meta) {

    this.id = this.route.snapshot.paramMap.get('id');
    this.key = this.route.snapshot.paramMap.get('key');
    this.key2 = this.route.snapshot.paramMap.get('key2');
    this.year = this.route.snapshot.paramMap.get('year');
    this.profil = this.route.snapshot.paramMap.get('profil');
    this.fullname = this.route.snapshot.paramMap.get('fullname');


    this.eventService.getIdArtistOffline();
    this.eventService.idsOfflineSubject.subscribe(data => {
      this.idsOffline = data;
      console.log('idsOffline', this.idsOffline);
      console.log('KEY : ', this.key);
      if (this.key) {
        if (this.key.length === 20) {
          console.log('IN SECTION 0');
          this.eventService.getOnlineEventsWithMemberKeyAfterToday(this.key).subscribe(
            (data) => {
              data.forEach((element) => {
                  if(!this.idsOffline.includes( element.artistkey) ) {
                    this.events$.push(element);
                  }
              });
              this.pages = Math.round(this.events$.length / ELEMENTS_PER_PAGE );
            }
          );
          this.dataIsEmpty = true;
        } else if (this.key && this.key2) {
          console.log('IN SECTION 1')
            this.eventService.getEventWithSeason(null, new Date(this.key) , new Date(this.key2)).subscribe(
              (data) => {
                data.forEach((element) => {
                  if(!this.idsOffline.includes( element.artistkey) && element.isonline) {
                    this.events$.push(element);
                  }
                });
                this.pages = Math.round(this.events$.length / ELEMENTS_PER_PAGE );
              }
            );
        } else {
          console.log('IN SECTION 2')
          this.eventService.getEventWithSeason(this.key).subscribe(
            (data) => {
              data.forEach((element) => {
                if(!this.idsOffline.includes( element.artistkey) && element.isonline) {
                  this.events$.push(element);  
                }
                  
              });
              this.pages = Math.round(this.events$.length / ELEMENTS_PER_PAGE );
            }
          );
          this.showartist = true;
        }
      } else if (this.id && this.year && this.profil ) {
        console.log('IN SECTION 3')
          this.eventService.getEventWithYearOrderByArtist(this.id, this.year).subscribe(
            (data) => {
              data.forEach((element) => {
                if(!this.idsOffline.includes( element.artistkey) && element.isonline) {
                  this.events$.push(element);
                }
              });
              this.pages = Math.round(this.events$.length / ELEMENTS_PER_PAGE );
            }
          );
          this.showartist = true;
  
      } else {
        console.log('IN SECTION 4')
        this.eventService.getEventsList().subscribe(
          (data) => {
            data.forEach((element) => {
              if(!this.idsOffline.includes( element.artistkey) && element.isonline) {
                this.events$.push(element);
              }
            });
            this.pages = Math.round(this.events$.length / ELEMENTS_PER_PAGE );
           // console.log(data);
          }
        );
      }
      
    });
  }

  // clearFilter() {
  // 	this.event$         = this.eventService.getPublicEventsList();
  //   this.showPrintDiv   = false;
  //   this.showartist = false;
  // }

  ngOnInit() {
    console.log(this.key);
    this.titleService.setTitle('Kajimoto Music Paris | Concerts');

    this.cards.next(this.mapCards([...this.generateCards()]));
    if (this.key) {
    }

    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/events` },
      { name: 'og:title', content: 'Kajimoto Music Paris | Concerts'},
      { name: 'og:description', content: 'Kajimoto Music Paris presents its coming events and concerts'},
      { name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830`},
      { name: 'description', content: 'Kajimoto Music Paris presents its coming events and concerts'},
      { name: 'title', content: 'Kajimoto Music Paris | Concerts' },
      { name: 'twitter:card',  content: 'summary' },
      { name: 'twitter:site',  content: 'Kajimoto Music' },
      { name: 'twitter:title', content: 'Kajimoto Music Paris | Concerts' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:description',   content: 'Kajimoto Music Paris presents its coming events and concerts' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830` },
      { name: 'twitter:url', content:  `https://kajimotomusicparis.com/events`},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'msapplication-starturl', content: '/' },
      { name: 'theme-color', content: '#f48c5b' },
      { name : 'keywords',  content: `events`},
      { name : 'url', content:  `https://kajimotomusicparis.com/events`}
    ]);
  }

  private mapCards(cards: number[]): number[] {
    return cards.fill(1).map((v, k) => v + k );
  }

  private generateCards(): number[] {
    return Array(ELEMENTS_PER_PAGE).fill(1);
  }


  public isEven(value: number): boolean {
    return !(value % 2);
  }

  public isOdd(value: number): boolean {
    return !this.isEven(value);
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }

    of([
      ...this.cards.getValue(),
      ...this.generateCards()
    ])
      .pipe(
        tap(() => this.loading.next(true)),
        delay(500),
        tap(() => this.loading.next(false)),
        take(1)
      )
      .subscribe((cards) => {
        this.page += 1;
        this.cards.next(this.mapCards(cards));
      });
  }
  //
  getArtistsEvent(eventkey: string) {
    this.artists = this.eventService.getArtistsEvent(eventkey);
    if (this.artists) { return true; }
  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogFilterEvents, {
      width: '650px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'filterEventsDialogPanel',
      data: {
          key: this.key
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.key) {
        // console.log(result.value);
        // console.log(this.key);
      } else { console.error('Impossible de Filtrer'); }
    });
  }



  openTourDialog(event): void {
    const dialogRef = this.dialog.open(DialogTours, {
      width: '550px',
      backdropClass: 'searchDialog',
      panelClass: 'searchDialogPanel',
      data: {
        key: event.$key,
        event: event
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDuplicateEventDialog(event): void {
    const dialogRef = this.dialog.open(DialogDuplicateEvents, {
      width: '400px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'searchDialogPanel',
      data: {
          key: event.$key
       }
    });

    dialogRef.afterClosed().subscribe(result => {

    // console.log('hello');
    });
  }

  print_page() {
     window.print();
  }

}



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-duplicate-events',
  templateUrl: 'dialog-duplicate-events.html',
  styleUrls: ['./dialog-duplicate-events.scss']
})
export class DialogDuplicateEvents implements OnInit {
  eventKey: any;
  showYesButton = true;
  event: Observable<any>;
  artists: Observable<({ [field: string]: any; } & { $key: string; })[]>;
  programs: Observable<({ [field: string]: any; } & { $key: string; })[]>;


  constructor(
    private ms: MemberService,
    private router: Router,
    private eventService: EventService,
    public dialogRef: MatDialogRef<DialogDuplicateEvents>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.eventKey = data.key;

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  duplicateEvent(event, artist, program) {
    this.onNoClick();
    this.eventService.createEvent(event, artist, program);
  }


  ngOnInit() {
     this.event = this.eventService.getEventWithKey(this.eventKey);
     this.artists =  this.eventService.getArtistsEvent(this.eventKey);
     this.programs = this.eventService.getProgramWithEventKey(this.eventKey);
  }
}









@Component({
  selector: 'dialog-filter-events',
  templateUrl: 'dialog-filter-events.html',
  styleUrls: ['./dialog-filter-events.scss']
})
export class DialogFilterEvents implements OnInit {

  allartists: Observable<any[]>;
  genres: any;
  isgenre = false;
  seasons: any;
  isseason = false;
  isartist = false;
  selectDay = false;

  constructor(
    private ms: MemberService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogFilterEvents>,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.allartists = this.ms.getArtistsList();
    this.genres = ['Ballet', 'Chambermusic', 'Concerto', 'Opera', 'Operaconcert',
                 'Operastaged', 'Recital', 'Symphonic', 'Vocal'];
    this.seasons = [
        {name: 'Automne', detail : 'from 9/23/2019 to 12/20/2019', url: 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-fall.jpg?alt=media&token=d671e4fd-d5ae-4696-9df6-c13a7b2fecfa'},
        {name: 'Hiver', detail : 'from 12/21/2019 to 03/19/2020', url: 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-winter.jpg?alt=media&token=c6002c1c-a7ff-48dd-8aa5-7cc36c8b0899'},
        {name: 'Printemps', detail : 'from 03/20/2020 to 06/20/2020', url: 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-spring.jpg?alt=media&token=026fdccd-f1c8-44b2-8991-c355a8cc32f8'},
        {name: 'Été', detail : 'from 06/21/2020 to 09/22/2020', url: 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fseason-summer.jpg?alt=media&token=affdec9e-ae21-4f5b-aaf5-fee19b1f90d2'}
      ];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getKey(artist) {
    // console.log(artist.$key);
  }

  getSeason() {
    this.isseason = true;
    this.isartist = false;
    this.isgenre = false;
    return this.seasons;
  }

  getArtist() {
    this.isseason = false;
    this.isartist = true;
    this.isgenre = false;
  }

  getGenre() {
    this.isgenre = true;
    return this.genres;
  }
  sendData(data) {
     const date1 = data.pick1.toLocaleDateString().toString().replace('/', '-').replace('/', '-').split('-');
     const date2 = data.pick2.toLocaleDateString().toString().replace('/', '-').replace('/', '-').split('-');
     const stardate =  date1[2] + '-' + date1[1] + '-' + date1[0];
     const enddate  =  date2[2] + '-' + date2[1] + '-' + date2[0];
    this.router.navigate(['events/' + stardate + '/' + enddate]);
  }


  filterEventsWithArtistAndCloseModal(artist) {
    this.router.navigate(['./../../../events', artist.$key,'undefined',artist.profilepic,artist.lastname+' '+artist.firstname])
    this.dialogRef.close();
  }


  ngOnInit() {
    // console.log(this.data);
  }
}
















@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-tours',
  templateUrl: 'dialog-tours.html',
  styleUrls: ['./dialog-tours.scss']
})
export class DialogTours implements OnInit {

  tours: Observable<any[]>;
  event: Observable<any>;
  eventkey: string;
  filteredData: Observable<any[]>;
  selectedData: any;
  dataCtrl: UntypedFormControl = new UntypedFormControl();
  dataSelected: string;

  constructor(
    private es: EventService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogTours>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.tours = this.es.getToursList();
        this.event = data.event;
        this.eventkey = data.key;
    }


  filterTours(val: string) {
    return this.tours
      .pipe(map(response => response.filter(tour => {
          const spacePosition = tour.title.toLowerCase().indexOf(' ') + 1 ;
          const tourNamePosition = tour.title.toLowerCase().indexOf(val.toLowerCase());
          const commaPosition = tour.title.toLowerCase().indexOf(val.toLowerCase()) + 1;
          const artistFirstname = tour.artistfirstname ? tour.artistfirstname.toLowerCase().indexOf(val.toLowerCase()) : tourNamePosition;
          const artistLastname =  tour.artistlastname ? tour.artistlastname.toLowerCase().indexOf(val.toLowerCase()) : tourNamePosition;
            // tslint:disable-next-line:max-line-length
            return tourNamePosition === 0 || tourNamePosition === spacePosition
                   || commaPosition ||  tourNamePosition === spacePosition ||
                   artistFirstname === 0 ||
                   artistLastname === 0;
      })));
  }







  displayFn(tour) {
    if (tour) { var title = `${tour.title} : from ${tour.startdate} to ${tour.enddate}`; }
    return title ? title : title;
  }

  ngOnInit() {

    this.filteredData = this.dataCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(data => {
         if (data) {
            if (data.timestamp) {
              this.linkEventToTour(data);
              return this.tours;
            } else {
              return this.filterTours(data);
            }
        } else {
           return this.filterTours('something');
        }
    }));

  }



  onNoClick(): void {
    this.dialogRef.close();
  }

  getKey(tour) {
  }

  linkEventToTour(tour) {
    this.es.addTourEvent(this.event, tour);
    this.onNoClick();
  }


}







