<!-- <ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template> -->


<div *ngIf="isartist" class="select-filter h50 fx-row fx-wrap fx-start-start">
	<div class="fx-25 width-25 width-33-xs fx-33-xs fx-start-center"	
	*ngFor="let artist of allartists | async"
	(click)="filterEventsWithArtistAndCloseModal(artist)">
		<img src="{{ artist.profilepic }}" class="hide-mobile profile-pic">		
		{{ (artist.lastname.length>10) ? (artist.lastname | slice:0:10)+'...':(artist.lastname) }}
	</div>
</div>

<div *ngIf="isseason" class="select-filter h100 fx-row fx-wrap fx-start-start fx-col-xs">
	<div class="fx-100" *ngFor="let season of seasons">
		<button mat-button [mat-dialog-close]='season' class="button-season" [routerLink]="['./../../events', season.name]">
			{{ season.name }} <br>
			<span class="detail-season">{{ season.detail }}</span>
		</button>
	</div>

	<div class="fx-100 fx-center-center-xs hide-mobile">
		<form #f="ngForm" class="fx-col-xs select-dates"  *ngIf="selectDay;else datePicker" >
			<mat-form-field color="accent">
				<mat-label>start date</mat-label>
				<input matInput [matDatepicker]="picker1" name='pick1'required
				ngModel #pick1="ngModel">
				<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
				<mat-datepicker #picker1></mat-datepicker>
			</mat-form-field>
				
			<mat-form-field color="accent">
				<mat-label>End date</mat-label>
				<input matInput [matDatepicker]="picker2" name='pick2' required
				ngModel #pick2="ngModel">
				<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
				<mat-datepicker #picker2 color="primary"></mat-datepicker>
			</mat-form-field>
				  
			<button mat-icon-button [mat-dialog-close]='f' (click)="sendData(f.value)">
				<span class="material-icons">check</span>
			</button>
		</form>
		<ng-template #datePicker>
			<div>
				<button mat-button class="button-season" (click)='selectDay=true'>Select Dates</button>
			</div>
		</ng-template>
	</div>
</div>



<div *ngIf="!isseason&&!isartist"
	class="select-filter h200 fx-center-center width-100 fx-col fx-fill">
	 <div class="fx-100 fx-fill fx-center-center bb1px" (click)="getArtist()" >Filter by Artist</div>
	 <div class="fx-100 fx-fill fx-center-center " (click)="getSeason()">Filter by Season</div>
</div>



