<!-- <pre>{{ tours | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<br><br>

<div class="fx-col">

	<h1 class="mobile-h1">Tours of Kajimotomusic Paris</h1>

	<div class="fx-col fx-100" *ngIf="searchTour">
		<form class="fx-row fx-wrap"  [ngStyle]="{'padding':'100px 50px'}">
			<mat-form-field	class="fx-100 example-form">
				<mat-label> Search Tours : use smart search for instance '2020' or '2020 FEB' or '2020 PASCAL' or '2020 FEB PASCAL' </mat-label>
				<input 
						matInput 
						aria-label="Tour" 
						[matAutocomplete]="auto" 
						[formControl]="tourCtrl">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
					<mat-option *ngFor="let tour of filteredTours | async" 
						[value]="tour">
						<span>
							<strong>{{ tour.title }}</strong> : {{ tour.artistfirstname }}  {{ tour.artistlastname }} 
							<i *ngIf="tour.startdate&&tour.enddate">from <strong>{{ tour.startdate | date }}</strong> to <strong>{{ tour.enddate | date}}</strong></i></span>
					</mat-option>
				</mat-autocomplete>
				<mat-icon matSuffix *ngIf="!showSearchLoader">search</mat-icon>
				<mat-icon matSuffix *ngIf="!showSearchLoader" (click)="searchTour=false;tourList=true;selectedTour=null">list</mat-icon>
				<mat-icon matSuffix *ngIf="!showSearchLoader" (click)="tourList=false;addTourForm=true;searchTour=false;selectedTour=null">add</mat-icon>
				<mat-spinner matSuffix *ngIf="showSearchLoader" class="loader fx-row" ></mat-spinner>  		  	
			</mat-form-field>		
		</form> 
	</div>

</div>


	<div *ngIf="!tourList" class="fx-row fx-wrap" >

		<div class="fx-row fx-wrap fx-100" *ngIf="selectedTour&&!searchTour">

			<div class="hover-grey fx-row fx-100"
				[ngStyle]="{'padding':'10px 0'}">
	
				<div class="fx-15 fx-end-center">
					<span class="but-artist" 
						*ngIf="selectedTour.artistkey"
						[routerLink]="['./../../member$/member', selectedTour.artistkey]">
						{{ selectedTour.artistlastname }} 
					</span>
				</div>
	
				<div class="fx-65 fx-col">
					<strong>{{ selectedTour.title }}</strong>
					<span class="tour-date" [ngStyle]="{'font-size':'0.8em'}">
						from <strong>{{ selectedTour.startdate | date }}</strong> to <strong>{{ selectedTour.enddate | date }}</strong>
					</span>
				</div>
					
				<div class="fx-20 fx-end-center">
					<button mat-icon-button color="black"
						*ngIf="selectedTour.isrouting==true"
						matTooltip="See Routing" matTooltipPosition="before"			
						[routerLink]="['./../../event$/routing', selectedTour.$key]">
							<mat-icon>flight_takeoff</mat-icon>
					</button>
					<button mat-icon-button color="black"
						matTooltip="Edit Tour" matTooltipPosition="before"			
						[routerLink]="['./../../event$/tour',selectedTour.$key]">
							<mat-icon [matBadge]="selectedTour.countevents" matBadgeColor="warn">info</mat-icon>
					</button>
					<button mat-icon-button color="black"
						matTooltip="Create event for this Tour" matTooltipPosition="before"			
						[routerLink]="['./../../event$/eventtourform', selectedTour.$key]">
							<mat-icon>add_circle</mat-icon>
					</button>
					<button mat-icon-button color="black"
						matTooltip="Search tours" matTooltipPosition="before"			
						(click) = "searchTour=true">
							<mat-icon>search</mat-icon>
				 </button>
				</div>         
			</div>
		</div>
	</div>

	<div *ngIf="tourList" class="fx-row fx-wrap" >

		<div class="fx-100 fx-end-center">
			<button mat-icon-button  type="button" color="black" class="pos-abso pos-abso-xs"
					matTooltip="Search tours" matTooltipPosition="before"			
					(click) = "searchTour=true;tourList=false">
					<mat-icon>search</mat-icon>
	  	</button>
	  	&nbsp;&nbsp;
			<button mat-icon-button type="button" color="black"
				class="pos-abso2 pos-abso2-xs"
				(click)="tourList=false;addTourForm=true;
				searchTour=false;
				selectedTour=null">
				<mat-icon>add</mat-icon>
			</button>   
	  </div>

		<div class="fx-row fx-wrap fx-100" *ngIf="tours | async; let tours; else loading">
 
			<div class="hover-grey fx-100 fx-row"
						[ngStyle]="{'padding':'10px 0'}"
						*ngFor="let tour of tours">
			
				<div class="fx-15 fx-end-center">
					<span class="but-artist" 
						*ngIf="tour.artistkey"
						[routerLink]="['./../../member$/member', tour.artistkey]">
						{{ tour.artistlastname }}
					</span>
				</div>
			
				<div class="fx-60 fx-col">
					<strong>{{ tour.title }}</strong>
					<span class="tour-date" [ngStyle]="{'font-size':'0.8em'}">
						from <strong>{{ tour.startdate | date }}</strong> to <strong>{{ tour.enddate | date }}</strong>
					</span>
				</div>
							
				<div class="fx-25 fx-end-center" [ngStyle]="{'padding-right':'10px'}">
					<button mat-icon-button color="black"
						*ngIf="tour.isrouting==true"
						matTooltip="See Routing" matTooltipPosition="before"			
						[routerLink]="['./../../event$/routing', tour.$key]">
							<mat-icon>flight_takeoff</mat-icon>
					</button>
					<button mat-icon-button color="black"
						matTooltip="Edit Tour" matTooltipPosition="before"			
						[routerLink]="['./../../event$/tour',tour.$key]">
							<mat-icon [matBadge]="tour.countevents" matBadgeColor="warn">info</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>

	










<div *ngIf="addTourForm" class="noshadow" [ngStyle]="{'padding':'20px'}">
	<button mat-icon-button class="ml" type="button" color="black" class="pos-abso3 pos-abso3-xs"
		(click)="addTourForm=false;tourList=true;tourDetails=false">
		<mat-icon>list</mat-icon>
	</button>
	<button mat-icon-button  type="button" color="black" class="pos-abso pos-abso-xs"
			matTooltip="Search tours" matTooltipPosition="before"			
			(click) = "searchTour=true;tourList=false;addTourForm=false">
			<mat-icon>search</mat-icon>
	</button>	 
  <div>
    <h2>New Tour</h2>
    <h3>Create new tour of events</h3>
  </div>
	<form #newTourForm="ngForm" (ngSubmit)="onSubmit(newTourForm)">
		<mat-form-field class="full-width">
			<mat-label> Tour title </mat-label>
			<input matInput required 
				ngModel #newTourTitle="ngModel"
				name="newTourTitle">
		</mat-form-field>
    <mat-form-field class="full-width">
		<mat-label> Select Artist </mat-label>
      <mat-select required
        ngModel #selectedArtist="ngModel" name="selectedArtist">
        <mat-option *ngFor="let artist of artists | async" 
          [value]="artist">
          {{ artist.fullname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
		<mat-form-field class="full-width">
			<mat-label> Choose a start date for the tour </mat-label>
		  <input matInput [matDatepicker]="datepicker1" required
		  	ngModel #newTourStarDate="ngModel"
				name="newTourStarDate">
		  <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
		  <mat-datepicker #datepicker1></mat-datepicker>
		</mat-form-field>

		<mat-form-field class="full-width">
			<mat-label> Choose an end date for the tour </mat-label>
		  <input matInput [matDatepicker]="datepicker2" required 
		  	ngModel #newTourEndDate="ngModel"
				name="newTourEndDate">
		  <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
		  <mat-datepicker #datepicker2></mat-datepicker>
		</mat-form-field>	

		<button mat-fab type="submit" color="warn"
			[disabled]="!newTourForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>
	</form>
</div>

