<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
</ng-template>


<div class="fx-100 fx-row h-400 fx-wrap" [ngStyle]="{'padding-left': '10px'}"  *ngIf="event | async; let event; else loading">
	<div *ngIf="event.venue" class="fx-start-start icon-event doNotPrint">
		<mat-icon 
			(click)="gotoGoogleMapAddress(event.venue.venueaddress)">map</mat-icon>
	</div>
	<div class="fx-90" *ngIf="event.date" 
		[ngStyle]="{'font-weight':'bold'}"
		[ngStyle.xs]="{'font-size':'1em'}">
			{{ event.date | date }} {{ event.time }}
	</div>
	<div class="fx-100" 
		[ngStyle]="{'text-transform':'uppercase', 'font-weight':'bold', 'font-size':'0.8em','padding-bottom':'5px','color':'#4C4C4C'}"
		[ngStyle.xs]="{'font-size':'0.9em'}">
			{{ event.title }}
	</div>
	<div class="fx-100 fx-col" *ngIf="event.venue"
		[ngStyle]="{'font-size':'0.8em','color':'#999999', 'padding-bottom':'5px'}"
		[ngStyle.xs]="{'font-size':'0.8em'}">
			<strong>{{ event.venue.venuename }}</strong>{{ event.venue.venueaddress }}<br>
	</div>
	<div class="fx-100 fx-col" *ngIf="event.artistsoliste" 
		[ngStyle]="{'font-size':'0.8em','color':'#999999', 'padding-bottom':'5px'}"
		[ngStyle.xs]="{'font-size':'0.8em'}">
			<strong>Partner/soloists</strong> {{ event.artistsoliste }} <br>
	</div>
	<div class="fx-100 fx-col" *ngIf="event.artistorchestre" 
		[ngStyle]="{'font-size':'0.8em','color':'#999999', 'padding-bottom':'5px'}"
		[ngStyle.xs]="{'font-size':'0.8em'}">
			<strong>Orchestra</strong>{{ event.artistorchestre }} <br>
	</div>	
	<div class="fx-100 fx-col" *ngIf="event.artistchef" 
		[ngStyle]="{'font-size':'0.8em','color':'#999999', 'padding-bottom':'5px'}"
		[ngStyle.xs]="{'font-size':'0.8em'}">
			<strong>Conductor</strong> {{ event.artistchef }} <br>
	</div>
	<div class="fx-100 fx-col" *ngIf="event.producer" 
		[ngStyle]="{'font-size':'0.8em','color':'#999999', 'padding-bottom':'5px'}"
		[ngStyle.xs]="{'font-size':'0.8em'}">
			<strong>Producer</strong>
			{{ event.producer }} <br>
			{{ event.producerphone }} <br>
			{{ event.produceremail }} 
	</div>

	<div class="fx-100 fx-gap-5" [ngStyle]="{'padding-top':'10px', 'align-items':'end'}">
		<div class="fx-30 grey-but doNotPrint" (click)="seeProgram=!seeProgram">
			<mat-icon *ngIf="!seeProgram" class="mat-icon-but">add</mat-icon> 
			<mat-icon *ngIf="seeProgram" class="mat-icon-but">remove</mat-icon>
			Program
		</div>
		<div *ngIf="event.venue" class="fx-30 grey-but doNotPrint" (click)="gotoGoogleMapAddress(event.venue.venueaddress)">
			<mat-icon class="mat-icon-but">map</mat-icon> Map
		</div>
	</div>

	<events-routing-program *ngIf="seeProgram" [eventkey]="event.$key"></events-routing-program>

</div>