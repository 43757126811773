<!-- <pre>
    {{ artists | async | json}}
</pre> -->

<div class="fx-row">

    <mat-icon style="max-width: 50px;" class="fx-50px fx-as artist"
        (click)="openDialog()" 
        matTooltip="Add Artist to Agent" matTooltipPosition="after">
            add_circle
    </mat-icon> 



    <mat-chip-listbox class="fx-1 fx-as">
        <mat-chip-option *ngFor="let artist of artists | async;let i = index">
            <strong>{{ artist.firstname }} {{ artist.lastname }}</strong>
            <mat-icon matChipRemove 
            (click)="removeArtist(artist)">cancel
            </mat-icon>
        </mat-chip-option>
    </mat-chip-listbox>

</div>