import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { switchMap,startWith, map } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';

import { EventService } from './../shared/event.service';
import { ComposerService } from './../../composers/shared/composer.service';

@Component({
  selector: 'app-event-program',
  templateUrl: './event-program.component.html',
  styleUrls: ['./event-program.component.scss']
})
export class EventProgramComponent implements OnInit {

	eventkey:any;

	showAddCompositionButton:boolean=false;  
	showSearchComposersField:boolean=true;
  showCompositions:boolean=true;
  addComposer: boolean= false;
  showSearchLoader : boolean= false;


	composers: Observable<any[]>;
  filteredComposers:Observable<any[]>;
  selectedComposer:Observable<any[]>;
	compositions:Observable<any[]>;
  eventView:Observable<any[]>;

	composerCtrl: UntypedFormControl = new UntypedFormControl();
  compositionCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(
    private composerService: ComposerService,  	
    private eventService: EventService,
  	private route: ActivatedRoute,) {
    	this.composers = this.composerService.getComposersList();
 	}


  filterComposers(val: string) {
    return this.composers
      .pipe(map(response => response.filter(composer => {
        this.showSearchLoader = false 
        return (composer.firstname.toLowerCase().indexOf(val.toLowerCase()) === 0) ||
               (composer.lastname.toLowerCase().indexOf(val.toLowerCase()) === 0)
      })));
  }

  displayFn(composer) {
    if(composer) { var fullname = `${composer.firstname} ${composer.lastname}`;}
    else var fullname = '';
    if(!composer) return null;
    else return composer ? fullname : composer;
  }   

	// updateEventComposition(eventkey,composition) {
	// 	var title = composition.viewModel.title;
 //    // this.eventService.updateEventField(eventkey,'composition',title);
 //    // this.router.navigate(['/event/'+eventkey]);
	// }

  addCompositionToComposer(event,composition,composer) { 
    if(composition && composer) {
      var compokey = this.composerService.addCompositionToComposerFromProgram(event,composition,composer);      
    }
    else  { console.log('Data missing'); }
  }

  addCompositionToProgram(event, composer, composition) {
    if(event && composer && composition) {
      this.eventService.addCompositionToProgram(event, composer, composition, null);
    }
    else  { console.log('Data missing'); }    
  }

  onSubmit(newComposerForm: NgForm) {
    this.selectedComposer = this.composerService.createCommposerFromProgram(newComposerForm);
    this.addComposer      = false;
    this.showAddCompositionButton = true;
  } 

  ngOnInit() {
    
    this.route.params.subscribe((params: Params) => {this.eventkey = params['id'];});

		this.eventView = this.route.paramMap
  		.pipe(switchMap((params: ParamMap)=>
  			this.eventService.getEventWithKey(params.get('id'))));

    this.filteredComposers = this.composerCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(compo => {
        if(compo) {
          this.showSearchLoader = true;
          if(compo.timestamp) { 
            var key = compo.$key;
            var compoFullname = `${compo.lastname} ${compo.firstname}`;

            // UPDATE COMPOSER FULLNAME IN PROGRAMME & EVENT
            // this.eventService.createComposerProgramme(this.eventkey,compo);

            this.selectedComposer = compo;
            this.showAddCompositionButton = true; 
            this.showCompositions = true; 
            this.compositions = this.composerService.getCompositionsWithComposerkey(key);

            return this.composers;
          }
          else return this.filterComposers(compo)
        } 
      	else 
      		{
	          // do something better here :P
            this.showSearchLoader = false
	          this.compositions = null;
	          this.showCompositions = false;
	          this.showAddCompositionButton = false;
	          return this.filterComposers('something');
        	}
      }))
  }
}