import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap,startWith, map } from 'rxjs/operators';


import { MemberService } from './../../members/shared/member.service';
import { EventService } from './../shared/event.service';

@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.scss']
})
export class TourListComponent implements OnInit {

  tourList      :boolean=false;	
  addTourForm   :boolean=false;
  tourDetails   :boolean=false;
  searchTour    : boolean=true ;

  tourkey       : string;
	tours         : Observable<any[]>;
  artists       : Observable<any[]>;
  tourSelected  : Observable<any>;
  tourCtrl      : UntypedFormControl = new UntypedFormControl();
  filteredTours : Observable<any[]>;
  selectedTour  : any;
  showSearchLoader = false

  constructor(private location      : Location,
  						private eventService  : EventService,
              private memberService : MemberService) 
  { 
    this.tours = this.eventService.getToursList();
    this.artists = this.memberService.getListOfArtisForTour();
  }


  // recherche sur la date: 'startdate' et l'artiste
  // format de recherche les 3 premieres lettres du mois en anglais suivi de l'annee
  // EX: jan 2019
  filterTours(val: string) {
      return this.tours
        .pipe(map(response => response.filter(tour => {
          this.showSearchLoader = false; 
          const spacePosition    = tour.title.toLowerCase().indexOf(' ') + 1 ;
          const tourNamePosition = tour.title.toLowerCase().indexOf(val.toLowerCase());
          const commaPosition    = tour.title.toLowerCase().indexOf(val.toLowerCase()) + 1;
          const artistFirstname  = tour.artistfirstname ? tour.artistfirstname.toLowerCase().indexOf(val.toLowerCase()) : tourNamePosition;
          const artistLastname   =  tour.artistlastname ? tour.artistlastname.toLowerCase().indexOf(val.toLowerCase()) : tourNamePosition;
          const startdate        =  tour.startdate.toString().toLowerCase();
          const tag              =  tour.tags.toLowerCase() ;
          const years            =  tag.split(' ')[0];
          const month            =  tag.split(' ')[1];
          const name             =  tag.split(' ')[2];
          const newFormatYearWithName = years + ' ' + name ;
          const newFormatStartDateWithName = years + ' ' + month + ' ' + name ;
          const newFormatStartDateWithNamePosition = newFormatStartDateWithName.indexOf(val.toLowerCase());
          const newFormatYearWithNamePosition = newFormatYearWithName.indexOf(val.toLowerCase());
          return tourNamePosition === 0 
                  || tourNamePosition === spacePosition
                  || commaPosition 
                  || tourNamePosition === spacePosition 
                  || artistFirstname === 0 
                  || artistLastname === 0 
                  || newFormatStartDateWithNamePosition === 0
                  || newFormatYearWithNamePosition === 0 ;
        })));
  }



  displayFn(tour) {
    let tourfullname: string;
    if (tour) { tourfullname = `${ tour.title }`; } else { tourfullname = ''; }
    return tour ? tourfullname : tour;
  }


  ngOnInit() {
    this.filteredTours = this.tourCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(tour => {
        if(tour) {
          this.showSearchLoader = true;
          if(tour.timestamp) 
          {
            var key = tour.$key;
            this.selectedTour = tour;
            this.searchTour = false;
            console.log( this.selectedTour);
            return this.tours;
          } else { return this.filterTours(tour); }
        }
        else 
        {
          this.showSearchLoader = false;
          // do something better here :P
          return this.filterTours('something');
        }
    }))    
  }

  onSubmit(newTourForm: NgForm) {
    this.eventService.createTour(newTourForm);
    this.addTourForm=false;
    this.tourList=true;
  } 

  deleteTour(tour) {
    this.eventService.deleteTour(tour);
  }

  goBack(): void {
    this.location.back();
  }


}
