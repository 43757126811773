import { Component, OnInit } from '@angular/core';

import { EventListComponent } from './../../events/event-list/event-list.component';
import { MemberService } from './../../members/shared/member.service';
import { EventService } from './../../events/shared/event.service';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  artists: any; events: any;
  artistsInEvent: any;

  constructor(
  		private eventService: EventService,
  		private memberService: MemberService) { }

  ngOnInit() {
  	this.artists = this.memberService.getAllArtistsList();
  	this.events = this.eventService.getEventsList();
  }

  getArtistsFromEvent(eventkey) {
    // this.artistsInEvent = this.eventService.getArtistsFromEventList(eventkey);
  }


}