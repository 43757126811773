<h3  *ngIf="user.artistkeys">remove all artists from the agent to delete the user</h3>
<div class="fx-row" *ngIf="user.artistkeys">
        <mat-chip-listbox class="fx-as" fxFlex *ngIf="member | async as member" >
                <mat-chip-option *ngFor="let artist of artists | async;let i = index">
                    <strong>{{ artist.firstname }} {{ artist.lastname }}</strong>
                    <mat-icon matChipRemove 
                        (click)=" removeUserToUserList(member,artist)">cancel
                    </mat-icon>
                </mat-chip-option>
        </mat-chip-listbox>
</div>

<div class="fx-row" *ngIf="!user.artistkeys">
        <h3 mat-dialog-title> Do you want to delete this user?</h3>
        <div mat-dialog-actions>
                <button mat-raised-button color="warn"   *ngIf="member | async as member" 
                     (click) = 'unbindUser(member,artist)'>Ok
                </button> 
        </div>
		  	
</div>
