import { Component, OnInit, Input } from '@angular/core';
import { trigger,style,transition,animate,keyframes,query,stagger } from '@angular/animations';
import { MemberService } from './../../members/shared/member.service';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
  animations: [
    trigger('artistList', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('300ms', [
          animate('.6s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('300ms', [
          animate('.6s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ]) 

  ]   
})

export class UserDashboardComponent implements OnInit {
  artistListOfAgent: any;
  artistList: any ;
  @Input() agentkey: string;
  @Input() userDisplayName: string;
  @Input() user: any;

  constructor(private ms: MemberService,
              private route: ActivatedRoute,
              private router: Router,
              private auth : AuthService) {   
  }

  ngOnInit() {
    this.artistList = this.ms.getAllArtistsList();
    // this.artistListOfAgent = this.ms.getArtistInAgentList(this.agentkey);

  }
  checkIfArtistManagedByagent(artistkeys:any):Boolean {
    artistkeys =  artistkeys?artistkeys:['no artist'];
     const compteur = artistkeys.indexOf(this.agentkey);

     if (compteur !== -1 || this.user.role === 'admin') {
       return true;
     }else{
       return false;
     }
  }

  redirection(key,artistkeys) {
    if(this.checkIfArtistManagedByagent(artistkeys)){
      this.router.navigate([`./../member$/member/${key}`]);
    }
  }
}