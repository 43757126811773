<br><br>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fkajimoto.gif?alt=media&token=cbf9e1b3-d788-49bb-9149-675bbc7cdc0e" class="logo-login">


<div *ngIf="auth.user | async as user; else subscription" class="but-signin">

    <div *ngIf="auth.user | async as user">        
        <br><br>
        <h5 *ngIf="user.role == 'pending'">Votre compte est en cours d'activation.</h5>
        <h6 *ngIf="user.role == 'pending'">Your account is pending.</h6>
        <br><br>
    </div>

</div>
<ng-template #subscription>
 
  <div *ngIf="!showSignUp" class="but-signin">
    <!-- <h5>Login to KAJIMOTO APP</h5> -->
    <button mat-button color="warn" (click)="signInWithGoogle()">
      <mat-icon>lock</mat-icon>
      <span> Sign in</span>
    </button>
    <button mat-button (click)="showSignUp=true">
      <mat-icon>error_outline</mat-icon>
      <span> Not registered yet ? Click here!</span>
    </button>
  </div>
   
  <div *ngIf="showSignUp" class="but-signin">
    <!-- <h5>Subscribe to KAJIMOTO APP</h5> -->
    <button mat-button (click)="showSignUp=false">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <span> Back to login</span>
    </button>
    <button mat-button color="warn" (click)="openDialog()">
       <mat-icon>lock</mat-icon>
       <span> Sign up</span>
    </button>
  </div>
   
</ng-template>

 



