import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';


import { UploadService } from './../shared/upload.service';
import { MemberService } from './../../members/shared/member.service';
import { PublicService } from './../../public/shared/public.service';

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class FileUploadComponent {

  key               : any;
  historykey        : any;
  disckey           : any;

	artistkey	       	:	any;
	artist   	       	: any;
  
  card              : any;	
  fileType          : string;
  uploadedFilename  : string;

  pictitle			    : string;
  pagesubtitle			: string;
  dimensioninfo			: string;
  maxsize						: number;
  errorMessage			: string;
  emptyCarouselPlace: number;

  showDropzone			: boolean = true;
  showProgressbar		: boolean = true;
  errors						: boolean = false;
  enableSaveButton	: boolean = false;
  showRefreshBut		: boolean = false;

  task              : AngularFireUploadTask;
  percentage        : Observable<number>;
  snapshot          : Observable<any>;

  downloadURL       : Observable<string>;
  uploadPercent     : Observable<number>;

  isHovering        : boolean;

  constructor(
  	private uploadService: UploadService,
  	private memberService: MemberService,
    private publicService: PublicService,
  	private route: ActivatedRoute,  
  	private router: Router,
  	private storage: AngularFireStorage) { }

  ngOnInit() { 

    this.route.params.subscribe((params: Params) => {
        this.fileType    = params['type'];
        this.key   = params['id'];
    });

    this.artist = (this.fileType=='carousel'||this.fileType=='profile'||this.fileType=='discogs') ? this.memberService.getArtistWithKey(this.key) : null;
    this.artistkey = (this.fileType=='carousel'||this.fileType=='profile'||this.fileType=='discogs') ?  this.key : null;
    this.historykey   = (this.fileType=='history') ? this.key : null;

    switch(this.fileType) 
    {
      case 'discogs' : 
      	this.pictitle			 = 'disc picture'
      	this.pagesubtitle  = 'Compléter la discographie de cet artiste';
      	this.dimensioninfo = '450px/450px';
      	this.maxsize			 = 80000;
      	break;        
      case 'carousel': 
      	this.pictitle			 = 'carousel picture'
      	this.pagesubtitle  = 'Add a picture to the artist carousel';
      	this.dimensioninfo = '1500px/1000px';
      	this.maxsize			 = 750000;
      	break;            
      case 'profile' : 
      	this.pictitle			 = 'profile picture'
      	this.pagesubtitle  = 'Add a profile picture to the artist';
      	this.dimensioninfo = '800px/800px';
      	this.maxsize			 = 500000;
      	break;        
      case 'history' : 
        this.pictitle       = 'history picture'
        this.pagesubtitle  = 'Add a picture to history';
        this.dimensioninfo = 'max600px/max600px';
        this.maxsize       = 200000;
        break;        
      default:       
        this.router.navigate(['/notfound']);    
    }
  }




  uploadFile(event) {

    console.log(event);
    // console.log(event[0]);
    // console.log(event[0].size);
    
    this.errors             = false;
    const file              = event[0];
    const fileSize          = event[0].size;
    const fileType          = event[0].type;


    // Client-side validation 
    if (file.type.split('/')[0] !== 'image') { 
      console.error('unsupported file type :( ')
      this.showRefreshBut   = true;
      this.errors           = true;
      this.enableSaveButton = false;
      this.errorMessage     = `Size type must be image/jpeg. The file you have uploaded is ${fileType}`;
    }    
    else if (fileSize > this.maxsize) { 
      this.showRefreshBut   = true;
      this.errors           = true; 
      var sizeInKo          = fileSize/1000;
      this.enableSaveButton = false;            
      this.errorMessage     = `Size file too large (${sizeInKo} Ko)`;      
    }
    // else if (!this.errors && (snap.bytesTransferred === snap.totalBytes)) {
    else if (!this.errors) {
      this.showDropzone     = false;
      this.errors           = false; 
      this.showProgressbar  = false;
      this.enableSaveButton = true;
      console.log("upload to console"); 

      this.uploadedFilename = `${new Date().getTime()}_${file.name}`;

      const filePath = `${this.fileType}/${new Date().getTime()}_${file.name}`;
      const fileRef = this.storage.ref(filePath);
      // console.log(fileRef);      
      const task = fileRef.put(file);
      // console.log(task);

      // observe percentage changes
      this.uploadPercent = task.percentageChanges();
      // get notified when the download URL is available    
      task.snapshotChanges().pipe(
        finalize(() => this.downloadURL = fileRef.getDownloadURL() )
      ).subscribe()
    }
  }




  
  toggleHover(event: boolean) {
    this.isHovering = event;
  }


  tryAgain() {
  	this.errors 			= false; 
  	this.showDropzone = true;
  	this.showRefreshBut = false;
	  this.enableSaveButton = false;  	
  }


  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


  savePictureFile(member,type,url,credit?,linkDisc?) {
    // console.log(member);
    // console.log(type);
    // console.log(credit);
    // console.log(url);
    const name = this.uploadedFilename;

    if(type=='carousel') {
      const emptycarous = member.imgcarouempty ? member.imgcarouempty : 1;
      this.memberService.addImageFileToMember(member,type,credit,url,emptycarous);
    }
    else if(type=='profile') {
      this.memberService.addImageFileToMember(member,type,credit,url,0);
    }
    else if(type=='discogs'&&linkDisc) {
      this.memberService.addDiscFileToMember(member, name, linkDisc, url);
    }    
    else console.error('Data Missing');
  }

  saveHistory(historykey,url) {
    this.publicService.addImageToHistory(historykey, url);
  }


}