import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../core/auth.service';

import { EventService } from './../shared/event.service';
import { ComposerService } from './../../composers/shared/composer.service';
import { MemberService } from './../../members/shared/member.service';

// import * as jspdf from 'jspdf';


@Component({
  selector: 'app-events-routing',
  templateUrl: './events-routing.component.html',
  styleUrls: ['./events-routing.component.scss']
})
export class EventsRoutingComponent implements OnInit {

tour: Observable<any>;
events: Observable<any>;
routingDetails: Observable<any>;
allartists: any;
tours: any;
tourkey: string;
isIt: boolean = true;
 // doc   = new jspdf();


  // doc.text('Hello world!', 10, 10)
  // doc.save('a4.pdf')


  constructor(
private composerService: ComposerService,
private memberService:   MemberService,
private eventService:    EventService,
private route:           ActivatedRoute,
private router:          Router,
private auth:            AuthService,
private location:        Location) {
      this.allartists   = this.memberService.getArtistsList();
      this.tours        = this.eventService.getToursList();
  }


  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
        this.tourkey    = params['id'];
    });
this.routingDetails = this.eventService.getRoutingDetailsWithTourKey(this.tourkey);
this.tour           = this.eventService.getTourWithKey(this.tourkey);
this.events         = this.eventService.getEventsTour(this.tourkey);
  }

  isNewDay(today, yst?) {
    if (yst && today) {
      const daytoday = today ? today.date.getDate() : null;
      const dayyst = yst ? yst.date.getDate() : null;
      if (daytoday != dayyst) { return this.isIt; }
    } else { return false; }
  }

  print_page() {
     window.print();
  }



}


