<div *ngIf="getevent | async; let event">
  <!-- {{ event | json }} -->
 	<strong *ngIf="event.date" class="date">{{ event.date | date }}<br></strong>
  <span *ngIf="event.title" class="uppercase">
  	<strong>{{ event.title }}</strong><br>
  </span>
  <span *ngIf="event.time" class="time">{{ event.time }}<br></span>
	<span *ngIf="event.genre">{{ event.genre }} <br></span>
  <span *ngIf="event.venue">{{ event.venue.venuename }}</span>  

  <!-- <a href="">Venue</a> -->
  <!-- <a href="">Buy Ticket</a> -->

  <div *ngIf="program | async; let program">
  	<!-- <strong (click)="seeProgramDetail = !seeProgramDetail">See Program</strong> -->

		<button mat-button class="nomarginbut upper"
						(click)="seeProgramDetail = !seeProgramDetail">
			Programme
		</button>

		<a *ngIf="event.link" href="{{ event.link }}" target="_blank">
		<button mat-button class="nomarginbut upper">
      Tickets
		</button>
		</a>

  	<ul *ngIf="seeProgramDetail" class="programul">
  		<li *ngFor="let composition of program">
  			<strong>{{ composition.composerlastname }}</strong>: {{ composition.compositiontitle }}
  		</li>
  	</ul>
  </div>



</div>
