import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { PressService } from './../shared/press.service';
import { MemberService } from './../../members/shared/member.service';


@Component({
  selector: 'app-press-form',
  templateUrl: './press-form.component.html',
  styleUrls: ['./press-form.component.scss']
})
export class PressFormComponent implements OnInit {

	artistkey:any;
	artist: any;

  constructor(
    private pressService: PressService,
    private memberService: MemberService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private location: Location,
  	) { }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
        this.artistkey = params['id'];
    });

		this.artist = this.route.paramMap
			.pipe(switchMap((params: ParamMap)=>
				this.memberService.getArtistWithKey(params.get('id'))));

  }


  onSubmit(newPressForm: NgForm) {
  	// console.log(newPressForm.value);
  	this.pressService.createPress(newPressForm, this.artistkey);
  }

  goBack(): void {
    this.location.back();
  }


}
