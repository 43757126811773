import { AuthService } from './../../core/auth.service';
import { LogClass } from './../../shared/logclass';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { NgForm } from '@angular/forms';

import { SingleNews } from './news';
import { Member } from './../../members/shared/member';
import { UploadService } from './../../upload/shared/upload.service';


import { map, tap } from 'rxjs/operators';

@Injectable()
export class NewsService {

  private cachedNewsList: SingleNews[] | null = null;
  private cachedAllHomepageNews: SingleNews[] | null = null;
  // private cachedDownHomeNews: SingleNews[] | null = null;
  private cachedNewsById: { [key: string]: SingleNews } = {};

  private cacheExpirationTime: number = 5 * 60 * 1000; // 5 minutes
  private cacheTimestamp: number | null = null;

  news: Observable<any>;
  newsCollection: AngularFirestoreCollection<unknown>;
  artistsCollection: AngularFirestoreCollection<unknown>;
  lookUpArtistNewsCollection: AngularFirestoreCollection<unknown>;
  topHomeNewsCollection: AngularFirestoreCollection<unknown>;
  downHomeNewsCollection: AngularFirestoreCollection<unknown>;
  logClass: LogClass;
  artist: Observable<any>;

  constructor(
    private upsrv: UploadService,
    private router: Router,
    private afs: AngularFirestore,
    private auth: AuthService) {
      this.newsCollection = this.afs.collection('news', ref => ref.orderBy('timestamp', 'desc'));
      this.topHomeNewsCollection = this.afs.collection('news', ref => ref
                                    .where('isonline', '==', true)
                                    .where('homeposition', '==', 'hpup')
                                    .where('isonhp', '==', true));
      this.downHomeNewsCollection = this.afs.collection('news', ref => ref
                                    .where('isonline', '==', true)
                                    .where('homeposition', '==', 'hpdown')
                                    .where('isonhp', '==', true));
      this.logClass = new LogClass(this.auth, this.afs);
  }

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////  G E T  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  // Récupération avec mise en cache
  getNewsList() {
    const now = Date.now();

    if (this.cachedNewsList && this.cacheTimestamp && now - this.cacheTimestamp < this.cacheExpirationTime) {
      return of(this.cachedNewsList); // Retourne le cache si disponible
    }
    return this.afs
      .collection('news', (ref) => ref.orderBy('timestamp', 'desc').limit(30)) // Limite à 20 éléments
      .get()
      .pipe(
        map((snapshot) => {
          this.cachedNewsList = snapshot.docs.map((doc) => ({
            ...(doc.data() as any),
            $key: doc.id,
            date: doc.data()['date']?.toDate(),
          }));
          this.cacheTimestamp = Date.now(); // Mettre à jour le timestamp
          return this.cachedNewsList;
        })
      );
  }

  getAllHomepageNews(): Observable<any> {
    if (this.cachedAllHomepageNews) {
      return of(this.cachedAllHomepageNews);
    }
    return this.afs.collection('news', (ref) =>
      ref.where('isonline', '==', true)
         .where('isonhp', '==', true)
         .limit(10)
    ).get().pipe(
      map((snapshot) => {
        this.cachedAllHomepageNews = snapshot.docs.map((doc) => ({
          ...(doc.data() as any),
          $key: doc.id
        }));
        return this.cachedAllHomepageNews;
      })
    );
  }  


  getNewsWithKey(key: string): Observable<SingleNews> {
    if (this.cachedNewsById[key]) {
      return of(this.cachedNewsById[key]); // Retourne directement depuis le cache
    }

    return this.afs.doc<SingleNews>(`news/${key}`).valueChanges().pipe(
      map((data) => {
        if (!data) {
          console.error(`No data found for key: ${key}`);
          return null;
        }

        const processedDate = (data.date as any)?.seconds
          ? new Date((data.date as any).seconds * 1000)
          : data.date;

        const singleNews: SingleNews = {
          ...data,
          $key: key,
          date: processedDate || null,
        };

        // Met à jour le cache
        this.cachedNewsById[key] = singleNews;
        return singleNews;
      })
    );
  }




////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// C R E A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  createNews(newNewsForm: NgForm): void {

    // console.log(newNewsForm.value;)

    const newsref              = this.afs.firestore.collection('news').doc();
    const newskey              = newsref.id;

    const artistkey 		       = newNewsForm.value.selectedArtist ? newNewsForm.value.selectedArtist.$key : null;
    const artistfullname       = newNewsForm.value.selectedArtist ? newNewsForm.value.selectedArtist.fullname : null;
    const artistdefaultimg     = newNewsForm.value.selectedArtist ?
                                 newNewsForm.value.selectedArtist.artistdefaultimg ?
                                 newNewsForm.value.selectedArtist.artistdefaultimg : null : null;

    const newsData               = {};
    newsData['timestamp']      = Date.now();
    newsData['title']          = newNewsForm.value.titleNews ? newNewsForm.value.titleNews : null;
    newsData['subtitle']       = newNewsForm.value.subtitleNews ? newNewsForm.value.subtitleNews : null;
    newsData['link']           = newNewsForm.value.linkNews ? newNewsForm.value.linkNews : null;
    newsData['artistkey']      = artistkey ? artistkey : null;
    newsData['artistfullname'] = artistfullname ? artistfullname : null;
    newsData['content']        = '';
    newsData['isonline']       = false;
    newsData['imgurl']         = artistdefaultimg;
    newsData['imgurlmobile']   = null;


    const batch                = this.afs.firestore.batch();

    const newsCollectionRef    = this.newsCollection.doc(newskey).ref;
    batch.set(newsCollectionRef, newsData);

    if (artistkey) {
      const memberCollectionRef = this.afs.collection('members').doc(artistkey).collection('news').doc(newskey).ref;
      batch.set(memberCollectionRef, newsData);
    }

    batch.commit()
    .then(() => { console.log('Batch Commited'); })
    .catch((error) => { console.error('Error adding document: ', error); });

    this.logClass.createLog(this.afs, 'createNews', newskey);

    this.router.navigate(['/new$/newsdetail/' + newskey]);
  }








////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// U P D A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////



updateNewsField(news: SingleNews, field: string, value: any): Promise<void> {

  return new Promise((resolve, reject) => {
    const newskey = news.$key;
    if (!newskey) {
      return Promise.reject('Invalid news key.');
    }

    const db = this.afs.firestore;
    const batch = db.batch();
  
    const newsRef = db.collection('news').doc(newskey);
    batch.update(newsRef, { [field]: value });

    batch
      .commit()
      .then(() => {
        console.log(`Field ${field} updated for news ${newskey}`);
        news[field] = value; // Mettez à jour immédiatement l'objet
        this.cachedNewsById[newskey] = { ...news }; // Synchronisez le cache
        resolve();
      })
      .catch((err) => {
        console.error(`Error updating field ${field} for news ${newskey}:`, err);
        reject(err);
      });
  })
}






////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// D E L E T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  deleteNews(news): Promise<void> {

    // console.log(news);

    const batch = this.afs.firestore.batch();

    const newskey     = news.$key;
    const artistkey   = news.artistkey ? news.artistkey : null;

    // Suppression dans Firestore
    const newsRef = this.newsCollection.doc(newskey).ref;
    batch.delete( newsRef);

    if (artistkey) {
      const memberRef = this.afs.firestore.collection('members').doc(artistkey).collection('news').doc(newskey);
      batch.delete( memberRef);
    }

    return batch.commit()
    .then(() => { 
      // Supprimer du cache local
      this.cachedNewsList = this.cachedNewsList?.filter((n) => n.$key !== newskey) || null;      
      delete this.cachedNewsById[newskey]; // Nettoyez le cache
      this.logClass.createLog(this.afs, 'deleteNews', newskey);
      this.router.navigate(['/new$/news']);      
      console.log('Batch Commited'); 
    })
    .catch((err) => {console.error(err); });

  }


  clearCacheForKey(key: string): void {
    if (this.cachedNewsById[key]) {
      delete this.cachedNewsById[key];
    }
  }


}
