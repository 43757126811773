<!-- <pre>{{ venueslist | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<h1>Venues List</h1>

<button mat-icon-button routerLink="./../venueform" type="button" color="black" class="button-right button-right-xs">
	<mat-icon>add</mat-icon>
</button>

<br>

<mat-card *ngIf="venueslist | async; let venueslist; else loading">

	<mat-card-content>
		<ul *ngFor="let venue of venueslist" class="places-list">
			<li>
				<button  mat-icon-button color="warn"
					matTooltip="Delete Venue" matTooltipPosition="after"
					(click)="deleteVenue(venue)">
		        <mat-icon>delete_forever</mat-icon>
		    </button>	
				<button mat-icon-button color="black" class="info-but"
					matTooltip="Edit Venue Details" matTooltipPosition="after"
					[routerLink]="['./../venue', venue.$key]">
		        <mat-icon>info_outline</mat-icon>
		    </button>			    			
				<span class="name"><strong>{{ venue.name }}</strong></span>
				<span class="address">{{ venue.address }}</span>
				<span class="url url-xs"><a href="{{ venue.url }}" target="_blank">{{ venue.url }}</a></span>
			</li>
		</ul>
	</mat-card-content>

</mat-card>