<!-- <pre>{{ tour | async | json }}</pre> -->
<!-- Tour Key : {{ tourkey }} -->

<div *ngIf="auth.user | async; let user">{{ sendUserToController(user) }}</div>


<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<br><br>

<h1>Tour</h1>

<div class="fx-row fx-wrap"  *ngIf="tour | async; let tour; else loading">
	<div class="fx-100 fx-row fx-wrap"  [ngStyle]="{'padding':'10px 20px'}">
    <div class="fx-25 fx-start-center">
    	<span *ngIf="tour.artistkey" [routerLink]="['./../../../member$/member', tour.artistkey]" class="artist-button">{{ tour.artistlastname }} {{ tour.artistfirstname }}</span>
    </div>
    <div class="fx-55 fx-start-center">
    	<p>
    		Details of <strong>{{ tour.title }}</strong><br>
    		<span class="grey small" *ngIf="tour.lastupdate">Last update : 
					{{ tour.lastupdate.date | date:'short' }}
					<strong *ngIf="tour.lastupdate.user">&nbsp;by {{ tour.lastupdate.user }}</strong>
				</span>
    	</p>
    </div>
    <div class="fx-20 fx-end-center">
			<button mat-icon-button class="ml" type="button" color="black"
				[routerLink]="['./../../tours']">
				<mat-icon>list</mat-icon>
			</button>
			&nbsp;&nbsp;
			<button *ngIf="false" mat-icon-button type="button" color="black" matTooltip="Create event for this Tour" matTooltipPosition="before"	[routerLink]="['./../../eventtourform', tour.$key]">
	      		<mat-icon>note_add</mat-icon>
	    	</button>
			&nbsp;&nbsp;    
			<button mat-icon-button type="button" color="black"
	      matTooltip="Add event" matTooltipPosition="before"			
	      [routerLink]="['./../../../events']">
	      <mat-icon>add</mat-icon>
	    </button>
		</div>  
	</div>

	<div class="fx-100 fx-row fx-wrap"  [ngStyle]="{'padding':'10px 20px'}">

		<mat-form-field class="fx-100">
			<mat-label> Tour Title </mat-label>
			<input matInput required
				[(ngModel)]="tour.title"
				(ngModelChange)="updateTourField(tour,'title',tour.title)">
		</mat-form-field>

		<mat-form-field class="fx-49">
			<mat-label> Choose start date for the tour </mat-label>
		  <input matInput readonly 
		  	[matDatepicker]="datepicker2"
		  	[(ngModel)]="tour.startdate"
		  	(ngModelChange)="updateTourField(tour,'startdate',tour.startdate)">
		  <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
		  <mat-datepicker #datepicker2></mat-datepicker>
		</mat-form-field>

		&nbsp;&nbsp;

		<mat-form-field class="fx-49">
			<mat-label> Choose end date for the tour </mat-label>
		  <input matInput readonly 
		  	[matDatepicker]="datepicker3" 
		  	[(ngModel)]="tour.enddate"
		  	(ngModelChange)="updateTourField(tour,'enddate',tour.enddate)">
		  <mat-datepicker-toggle matSuffix [for]="datepicker3"></mat-datepicker-toggle>
		  <mat-datepicker #datepicker3></mat-datepicker>
		</mat-form-field>

		<mat-form-field class="fx-100">
			<mat-label> Dress Code </mat-label>
			<input matInput 
				[(ngModel)]="tour.dresscode"
				(ngModelChange)="updateTourField(tour,'dresscode',tour.dresscode)">
		</mat-form-field>

		<mat-form-field class="fx-49">
			<mat-label>  Write Informations about the Hotels : Dates, Address, Contacts, ...</mat-label>
			<textarea matInput class="full-width"
								rows="10" 
								[(ngModel)]="tour.hotels"
								(ngModelChange)="updateTourField(tour,'hotels',tour.hotels)">
			</textarea>
		</mat-form-field>

		&nbsp;&nbsp;

		<mat-form-field class="fx-49">
			<mat-label> Comments ... </mat-label>
			<textarea matInput class="full-width"
								rows="10" 
								[(ngModel)]="tour.comments"
								(ngModelChange)="updateTourField(tour,'comments',tour.comments)">
			</textarea>
		</mat-form-field>

		<tour-events class="fx-100"
			[tourkey]="tour.$key" 
			[countevents]="tour.countevents">
		</tour-events>

		<routing-details class="fx-100"
			*ngIf         = "tour.startdate&&tour.enddate"
			[tourkey]     = "tour.$key"
			[artistkey]   = "tour.artistkey"
			[startdate]   = "tour.startdate"
			[artistemail] = "tour.artistemail"
			[artistphone] = "tour.artistphone"
			[enddate]     = "tour.enddate">
		</routing-details>



	</div>
	
	<div class="fx-100" [ngStyle]="{'padding':'10px 0','margin-top':'10px 0 50px'}">

<!--   	<button mat-fab type="button" 
  		[color]="checkIsRouting(tour.isrouting)"
  		(click)="generateRoutingDetails(tour)"
      matTooltip="Generate Routing Details" matTooltipPosition="before">
      <mat-icon>timeline</mat-icon>
    </button> -->
		&nbsp;&nbsp;    
  	<button mat-fab type="button" 
  		[color]="checkIsRouting(tour.isrouting)"
  		(click)="checkIsRoutingBeforeUpdate(tour)"
      matTooltip="Send tour to routing" matTooltipPosition="before">
      <mat-icon>flight_takeoff</mat-icon>
    </button>
		&nbsp;&nbsp;
  	<button mat-fab type="button" 
  		*ngIf="tour.isrouting==true"
  		[routerLink]="['./../../routing', tour.$key]"
  		color="black"
      matTooltip="See routing" matTooltipPosition="before">
      <mat-icon>remove_red_eye</mat-icon>
    </button> 
		&nbsp;&nbsp;    
 		<button mat-fab type="button" 
    	*ngIf="tour.artistemail&&showSendEmailButton"
    	(click)="confirmeSendingEmail()"
    	matTooltip="Send Routing to Artist" matTooltipPosition="before"
    	color="warn">
    	<mat-icon>email</mat-icon>
    </button>		
		&nbsp;&nbsp;    
		<button mat-fab type="button" 
    	(click)="confirmeDeleteTour()"
    	matTooltip="Delete Tour" matTooltipPosition="before"
    	color="warn">
    	<mat-icon>delete_forever</mat-icon>
    </button>  
		     
<!-- 		<button mat-fab type="button" 
    	(click)="deleteTour(tour)"
    	matTooltip="Delete Tour" matTooltipPosition="before"
    	color="warn">
    	<mat-icon>delete</mat-icon>
    </button>  
  	       -->

	</div>

</div>