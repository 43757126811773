import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-users-log',
  templateUrl: './users-log.component.html',
  styleUrls: ['./users-log.component.scss']
})
export class UsersLogComponent implements OnInit {
  //
  function: string;
  idcollection: string;
  timestamp: any;
  userdisplayname: string;
  userid: string;
  //
    displayedColumns = ['timestamp', 'userdisplayname', 'function', 'idcollection', 'url'];

    dataSource: MatTableDataSource<UsersLog>;
    usersLog: UsersLog[] ;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    logCollection: AngularFirestoreCollection<unknown>;

    constructor( private afs: AngularFirestore, ) {
    }

    ngOnInit() {

        this.logCollection  = this.afs.collection('logs', ref => ref.orderBy('timestamp', 'desc').limit(500) );
        this.logCollection.get().subscribe((item: any) => {
          this.usersLog = [];
          item.forEach(element => {
            const y = element.data();
            y['date'] = element.data().timestamp ? element.data().timestamp.toDate() : null;
            y['$key'] = element.id;
            this.usersLog.push( y as UsersLog);
          });
           // console.log(this.usersLog);
           this.dataSource = new MatTableDataSource(this.usersLog) ;
           this.dataSource.paginator = this.paginator;
           this.dataSource.sort = this.sort;
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}


export interface UsersLog {
  function: string;
  idcollection: string;
  timestamp: any;
  userdisplayname: string;
  userid: string;
}
