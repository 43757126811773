<!-- <pre>{{ eventView | async | json}}</pre> -->
<!-- <pre>{{ exprograms | async | json }}</pre> -->
<!-- <pre>{{ artists | async | json }}</pre> -->
<!-- <pre>{{ tours | async | json }}</pre> -->

<div *ngIf="auth.user | async; let user">{{ sendUserToController(user) }}</div>

<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<div *ngIf="eventView | async; let event; else loading" class="fx-row fx-wrap">

		<h1 class="fx-100">{{ event.title }}</h1>
		<h2 class="fx-col fx-wrap fx-100">
			<span *ngIf="event.date"><strong>{{ event.date | date }}</strong></span> &nbsp;
			<span *ngIf="event.genre">Genre : {{ event.genre }}<br></span>
			<span *ngIf="event.lastupdate" class="grey">Last update : 
				<strong>{{ event.lastupdate.date | date:'short' }} - {{ event.lastupdate.user }}</strong>
			</span>
			<!-- <span *ngIf="event.origine">Origine : {{ event.origine}}</span>  -->
			<!-- <span *ngIf="event.tourtitle">Tour : {{ event.tourtitle }}</span><br> -->
		</h2>

		<div class="fx-wrap fx-100">

			<div class="fx-100" [ngStyle]="{'padding-bottom':'20px'}">
				<mat-radio-group color="warn"
					[(ngModel)]="event.genre"
					(ngModelChange)="updateEventField(arrayArtists,event,'genre',event.genre)">
					  <mat-radio-button color="warn" value="Ballet">Ballet</mat-radio-button>
					  <mat-radio-button color="warn" value="Chamber Music">Chamber Music</mat-radio-button>
					  <mat-radio-button color="warn" value="Concerto">Concerto</mat-radio-button>
					  <mat-radio-button color="warn" value="Opera Staged">Opera Staged</mat-radio-button>
					  <mat-radio-button color="warn" value="Opera Concert">Opera Concert</mat-radio-button>
					  <mat-radio-button color="warn" value="Recital">Recital</mat-radio-button>
					  <mat-radio-button color="warn" value="Symphonic">Symphonic</mat-radio-button>
					  <mat-radio-button color="warn" value="Vocals">Vocals</mat-radio-button>
				</mat-radio-group>
			</div>
				
			<!-- ------------------------------------------------------------------- -->	
			<!-- B A S I C  I N F O -->
			<!-- ------------------------------------------------------------------- -->
			<div class="fx-col fw-wrap fx-100">
				<div class="fx-col fx-100">
					<hr><h3 (click)="isInfoOpen = !isInfoOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Basic Info</h3>
				</div>
				<div *ngIf="isInfoOpen" class="fx-100 fw-row fx-wrap div-pad">
					<div class="fx-100" [ngStyle]="{'padding-bottom':'20px'}">
						<br><strong>Origine: </strong>
						<mat-radio-group color="warn"
							[(ngModel)]="event.origine"
							(ngModelChange)="updateEventField(arrayArtists,event,'origine',event.origine)">
							  <mat-radio-button color="warn" value="agent">Agent</mat-radio-button>
							  <mat-radio-button color="warn" value="producer">Producer</mat-radio-button>
						</mat-radio-group>						
					</div>
					<div class="fx-col fx-100">
						<mat-form-field class="full-width">
							<mat-label> Title </mat-label>
		  				<input matInput required
		  					[(ngModel)]="event.title"
		  					(ngModelChange)="updateEventField(arrayArtists,event,titleField,event.title)">
						</mat-form-field>					
						<mat-form-field class="full-width">
							<mat-label> Link</mat-label>
		  				<input matInput 
		  					[(ngModel)]="event.link"
		  					(ngModelChange)="updateEventField(arrayArtists,event,'link',event.link)">
						</mat-form-field>
						<mat-form-field class="full-width">
							<mat-label> Choose a date for the event </mat-label>
						  <input matInput readonly 
						  	[matDatepicker]="datepicker" 
						  	[(ngModel)]="event.date"
						  	(ngModelChange)="updateEventField(arrayArtists,event,'date',event.date)">
						  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
						  <mat-datepicker #datepicker></mat-datepicker>
						</mat-form-field>
						<mat-form-field class="full-width">
							<mat-label> Select Event Time </mat-label>
						  <mat-select 
						  	[(ngModel)]="event.time" 
						  	(ngModelChange)="updateEventField(arrayArtists,event,'time',event.time)">
						    <mat-option *ngFor="let time of times" 
						    	[value]="time">
						      {{ time }}
						    </mat-option>
						  </mat-select>
						</mat-form-field>						
					</div>				
				</div>
			</div>

			<!-- ------------------------------------------------------------------ -->	
			<!-- T O U R  -->
			<!-- ------------------------------------------------------------------ -->
			<div class="fx-100 fx-row fx-wrap">
				<div class="fx-col fx-100">
					<hr><h3 (click)="isTourOpen = !isTourOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Tour</h3>
				</div>
				<div *ngIf="isTourOpen" class="div-pad fx-100 fx-col fx-start-start">
						<div *ngIf="event.tourkey" class="mb-20">
							<strong class="but-artist" [routerLink]="['./../../../event$/tour', event.tourkey]">{{ event.tourtitle }}</strong>
						</div>
						<button mat-button class="nomarginbut"
							*ngIf="!event.tourkey"
							(click)="showTourList=true">
							<mat-icon>replay</mat-icon>
							Send Event To Tour
						</button>
						<mat-form-field *ngIf="showTourList&&!event.tourkey" class="full-width">
							<mat-label> Select Tour </mat-label>
						  <mat-select 
						  	ngModel #selectedTours="ngModel" name="selectedTours"
						  	(ngModelChange)="addTourEvent(event,selectedTours)">
						    <mat-option 
						    	*ngFor="let tour of tours | async" 
						    	[value]="tour">
						    	<strong>From {{ tour.startdate | date }} to {{ tour.enddate | date }} </strong>: {{ tour.title }}
						    </mat-option>
						  </mat-select>
						</mat-form-field>						
				</div>
			</div>


			<!-- ------------------------------------------------------------------- -->	
			<!-- P R O D U C T I O N -->
			<!-- ------------------------------------------------------------------- -->	
			<div class="fx-100 fx-row fx-wrap">		
				<div class="fx-col fx-100">
					<hr>
					<h3 (click)="isProdOpen = !isProdOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Producer</h3>
				</div>					
				<div *ngIf="isProdOpen" id="custom-but" class="fx-100 fx-gap-5 fx-row fx-wrap div-pad">
						<mat-form-field class="full-width fx-100">
							<mat-label> Producer Full Name </mat-label>
		  				<input matInput
		  					[(ngModel)]="event.producer"
		  					(ngModelChange)="updateEventField(arrayArtists,event,'producer',event.producer)">
						</mat-form-field>
						<mat-form-field class="full-width fx-33">
							<mat-label> Producer Email </mat-label>
		  				<input matInput
		  					[(ngModel)]="event.produceremail"
		  					(ngModelChange)="updateEventField(arrayArtists,event,producerEmailField,event.produceremail)">
		  				<mat-icon matSuffix>mail</mat-icon>
						</mat-form-field>
						<mat-form-field class="full-width fx-33">
							<mat-label> Producer Phone </mat-label>
		  				<input matInput 
		  					[(ngModel)]="event.producerphone"
		  					(ngModelChange)="updateEventField(arrayArtists,event,producerPhoneField,event.producerphone)">
		  				<mat-icon matSuffix>phone</mat-icon>
						</mat-form-field>
						<mat-form-field class="full-width fx-33">
							<mat-label> Producer Postmail Address </mat-label>
		  				<input matInput 
		  					[(ngModel)]="event.produceraddress"
		  					(ngModelChange)="updateEventField(arrayArtists,event,producerAddressField,event.produceraddress)">
		  				<mat-icon matSuffix>home</mat-icon>
						</mat-form-field>
				</div>
			</div>

			<!-- ------------------------------------------------------------------- -->	
			<!-- A R T I S T S -->
			<!-- ------------------------------------------------------------------- -->	
			<div class="fx-100 fx-row fx-wrap">		
				<div class="fx-col fx-100">
					<hr>
					<h3 (click)="isArtistOpen = !isArtistOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Artists</h3>
				</div>			
				<div *ngIf="isArtistOpen" class="div-pad fx-100 fx-col fx-start-start">
						<!-- <button mat-button class="nomarginbut"
							*ngIf="ShowAddArtistBut"
							(click)="showAddArtistSelect=true;ShowAddArtistBut=false">
							<mat-icon>add</mat-icon>
							Add Kajimoto Artist
						</button> 										 -->
						<div class="first-cell" *ngIf="artists | async; let artist">
								<ul class="artists-list">
									<li>								
										<span class="but-artist"
										[routerLink]="['./../../../member$/member', artist.$key]">
												{{ artist.firstname }} {{ artist.lastname }}</span> <i>{{ artist.instrument.name | titlecase }} </i>
									</li>
								</ul>
							</div>

							<div *ngIf="showAddArtistSelect">
									<mat-form-field class="full-width">
										<mat-label> Select Artist </mat-label>
										<mat-select class="full-width"
											[(ngModel)]="newArtist"
											(ngModelChange)="addArtistToEvent(event,newArtist);showAddArtistSelect=false;ShowAddArtistBut=true">
											<mat-option *ngFor="let newartist of allartists | async" 
												[value]="newartist">
												{{ newartist.firstname }} {{ newartist.lastname }}
											</mat-option>
										</mat-select>						  
									</mat-form-field>								
							</div>
						<div class="fx-row fx-wrap fx-gap-5">
								<mat-form-field  class="fx-calc-25-5px">
									<mat-label> Conductor </mat-label>
				  				<input matInput 
				  					[(ngModel)]="event.artistchef"
				  					(ngModelChange)="updateEventField(arrayArtists,event,chefField,event.artistchef)">
								</mat-form-field>			
								<mat-form-field class="fx-calc-25-5px">
									<mat-label> Orchestra </mat-label>
				  				<input matInput 
				  					[(ngModel)]="event.artistorchestre"
				  					(ngModelChange)="updateEventField(arrayArtists,event,orchestreField,event.artistorchestre)">
								</mat-form-field>
								<mat-form-field class="fx-calc-25-5px">
									<mat-label> Partner(s) </mat-label>
				  				<input matInput
				  					[(ngModel)]="event.artistsoliste"
				  					(ngModelChange)="updateEventField(arrayArtists,event,solisteField,event.artistsoliste)">
								</mat-form-field>
								<mat-form-field class="fx-calc-25-5px">
									<mat-label> Others </mat-label>
				  				<input matInput
				  					[(ngModel)]="event.artistothers"
				  					(ngModelChange)="updateEventField(arrayArtists,event,othersField,event.artistothers)">
								</mat-form-field>
						</div>	
				</div>
			</div>
				
			<!-- ------------------------------------------------------------------- -->	
			<!-- V E N U E -->
			<!-- ------------------------------------------------------------------- -->	
			<div class="fx-100 fx-row fx-wrap" *ngIf="artists | async; let artist">		
				<div class="fx-col  fx-100">
					<hr>
					<h3 (click)="isVenueOpen = !isVenueOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Venue</h3>
				</div>
				<div *ngIf="isVenueOpen" class="fx-col fx-100 div-pad">
					<div>
						<button *ngIf="!event.venue" mat-button class="nomarginbut"
							[routerLink]="['./../../../event$/eventvenue', event.$key, artist.key]">
								<mat-icon>add</mat-icon>							
								Add Venue to Event
						</button>	
						<button *ngIf="event.venue" mat-button class="nomarginbut"
							[routerLink]="['./../../../event$/eventvenue', event.$key, artist.key]">
								<mat-icon>autorenew</mat-icon>							
								Change Event Venue
						</button>							
						<button *ngIf="event.venue" mat-button class="nomarginbut"
							(click)="deleteEventVenue(event)">
								<mat-icon>clear</mat-icon>							
								Delete Event Venue
						</button>
						<button *ngIf="event.venue" mat-button class="nomarginbut" (click)="deleteEventVenue(event)"
							[routerLink]="['./../../../venue$/venue', event.venue.venuekey,event.$key]">
								<mat-icon>edit</mat-icon>				
								Edit Venue
						</button>
					</div>
					<div>
						<address class="venueaddress" *ngIf="event.venue">
							<strong>{{ event.venue.venuename }}</strong><br>
							{{ event.venue.venueaddress }} <br>
							<a href="{{ event.venue.venueurl }}" target="_blank">{{ event.venue.venueurl }}</a>
						</address>
					</div>					
				</div>
			</div>

			<!-- ------------------------------------------------------------------- -->
			<!-- P R O G R A M M E -->
			<!-- ------------------------------------------------------------------- -->
			<div class="fx-100 fx-row fx-wrap">		
				<div class="fx-col fx-100">
					<hr>
					<h3 (click)="isProgOpen = !isProgOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Programme</h3>
				</div>				
				<div *ngIf="isProgOpen" class="fx-100 fx-col div-pad">
					<div>
						<button mat-button class="nomarginbut"
							[routerLink]="['./../../../event$/program', event.$key]">
								<mat-icon>add</mat-icon>							
								Add composition to programme
						</button>	
						<button mat-button class="nomarginbut" disabled *ngIf="showExistingProgramListBut"
							(click)="showExistingProgramList=true">
								<mat-icon>file_copy</mat-icon>							
								Duplicate program from another event
						</button>	
						<button mat-button class="nomarginbut" disabled>
								<mat-icon>file_copy</mat-icon>							
								Import from external source
						</button>													
					</div>
					<div>
						<mat-form-field	class="full-width" 
							*ngIf="showExistingProgramList">
							<mat-label> Search Existing Program Events </mat-label>
						  <input class="full-width" matInput 
						  	aria-label="Programs Event" 
						  	ngModel
						  	[matAutocomplete]="auto" 
						  	[formControl]="existingProgramCtrl">
								  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
								    <mat-option *ngFor="let exprogram of filteredPrograms | async" 
								    	[value]="exprogram">
								      <span>{{ exprogram.eventtitle }}  
								      	<!-- <strong *ngIf="exprogram.eventdate.toDate()">- {{ exprogram.eventdate.toDate() | date }}</strong> -->
								      </span>
								    </mat-option>
								  </mat-autocomplete>
						  	<mat-icon matSuffix class="icon-but">search</mat-icon>
						  	<mat-icon matSuffix class="icon-but" (click)="showExistingProgramList=false">clear</mat-icon>
						</mat-form-field>
					</div>
					<div *ngIf="programs | async; let programs">					
						<div *ngIf="oldProgramCompositionsCollection | async; let oldProgramCompositionsCollection">
							<!-- <pre>{{ oldProgramCompositionsCollection | json }}</pre> -->
							{{ SendOldProgramCompositionCollectionToCtrl(oldProgramCompositionsCollection,event) }}
						</div>
						<div *ngFor="let program of programs; let i = index" class="br-dotted pad-s">
							<span>
								<strong>
									<!-- {{ (i+1) }}&nbsp;-&nbsp; -->
								{{ program.composerlastname }}: </strong>
								{{ program.compositiontitle }}
							</span>
							<button mat-icon-button color="black"
								(click)="deleteCompositionInProgram(event,program)">
								<mat-icon>clear</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>

			<!-- ------------------------------------------------------------------- -->
			<!-- C A C H E T -->
			<!-- ------------------------------------------------------------------- -->
			<div class="fx-100 fx-row fx-wrap">		
				<div class="fx-col fx-100">
					<hr>
					<h3 (click)="isFeeOpen = !isFeeOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Fee</h3>
				</div>
				<div *ngIf="isFeeOpen" class="fx-col fx-100 div-pad">			
					<div id="custom-but2" class="fx-100 fx-row fx-wrap">
							
							<mat-form-field>
								<mat-label> Netto fee </mat-label>
			  				<input matInput 
			  					[(ngModel)]="event.cachetnet"
			  					(ngModelChange)="updateEventField(arrayArtists,event,'cachetnet',event.cachetnet)">
			  				<mat-icon matSuffix>euro_symbol</mat-icon>		  					
							</mat-form-field>
							<mat-form-field>
								<mat-label> Select Currency </mat-label>
							  <mat-select
							  	[(ngModel)]="event.cachetnetcurrency" 
							  	(ngModelChange)="updateEventField(arrayArtists,event,'cachetnetcurrency',event.cachetnetcurrency)">
							    <mat-option *ngFor="let cur of currencies" [value]="cur">
							      {{ cur }}
							    </mat-option>
							  </mat-select>
							</mat-form-field>	
							&nbsp;&nbsp;&nbsp;
							<mat-form-field>
								<mat-label> Brutto fee </mat-label>
			  				<input matInput
			  					[(ngModel)]="event.cachetbrut"
			  					(ngModelChange)="updateEventField(arrayArtists,event,'cachetbrut',event.cachetbrut)">
			  				<mat-icon matSuffix>euro_symbol</mat-icon>		  					
							</mat-form-field>
							<mat-form-field>
								<mat-label> Select Currency </mat-label>
							  <mat-select
							  	[(ngModel)]="event.cachetbrutcurrency" 
							  	(ngModelChange)="updateEventField(arrayArtists,event,'cachetbrutcurrency',event.cachetbrutcurrency)">
							    <mat-option *ngFor="let cur of currencies" [value]="cur">
							      {{ cur }}
							    </mat-option>
							  </mat-select>
							</mat-form-field>
							&nbsp;&nbsp;&nbsp;
							<mat-form-field>
								<mat-label> Commission </mat-label>
			  				<input matInput
			  					[(ngModel)]="event.cachetcommission"
			  					(ngModelChange)="updateEventField(arrayArtists,event,'cachetcommission',event.cachetcommission)">
			  				<span matSuffix>%</span>		  					
							</mat-form-field>						
					</div>
					<div class="fx-100 fx-row fx-wrap fx-gap-5">
						<mat-form-field class="fx-calc-50-3px full-width">
							<mat-label> Travel paid by... </mat-label>
		  				<input matInput
		  					[(ngModel)]="event.paidbytravel"
		  					(ngModelChange)="updateEventField(arrayArtists,event,paidbytravelField,event.paidbytravel)">
						</mat-form-field>
						<mat-form-field class="fx-calc-50-3px full-width">
							<mat-label> Hotel paid by... </mat-label>
		  				<input matInput 
		  					[(ngModel)]="event.paidbyhotel"
		  					(ngModelChange)="updateEventField(arrayArtists,event,paidbyhotelField,event.paidbyhotel)">
						</mat-form-field>
					</div>
				</div>

				<!-- ----------------------------------------------------------------- -->
				<!-- C O M M E N T -->				
				<!-- ----------------------------------------------------------------- -->
			<div class="fx-100 fx-row fx-wrap" [ngStyle]="{'padding-bottom':'20px'}">		
				<div class="fx-col fx-100">
					<hr>
					<h3 (click)="isCommOpen = !isCommOpen"><mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon> Comment</h3>
				</div>
				<div *ngIf="isCommOpen" class="fx-col fx-100 div-pad">
					<mat-form-field class="full-width">
						<mat-label> Write comment or notes about this event : Dress Code, Date Options, Venue, ... </mat-label>
						<textarea matInput class="full-width"
											rows="7" 
											[(ngModel)]="event.comment"
											(ngModelChange)="updateEventField(arrayArtists,event,commentField,event.comment)">
											</textarea>
					</mat-form-field>							
				</div>
			</div>
		</div>


		<div *ngIf="artists | async; let artists">

			<button mat-fab 
				*ngIf="artists?.length!=0&&artists ; let artists"	
				matTooltip="Delete Event With Artists" matTooltipPosition="after"
				(click)="confirmeDeleteEvent()" color="warn">
					<mat-icon>delete_forever</mat-icon>
			</button>

			<button mat-fab 
				routerLink="./../../../dashboard" color="black"
				matTooltip="Go back to Dashboard" matTooltipPosition="after">
				<mat-icon>home</mat-icon>
			</button>

			<button mat-fab 
				routerLink="./../../../events" color="black"
				matTooltip="Events List" matTooltipPosition="after">
				<mat-icon>list</mat-icon>
			</button>

		  <button mat-fab *ngIf="programs | async ;let programs"
		    matTooltip="Duplicate Event" matTooltipPosition="after" color="black"
		    (click)="duplicateEvent(event,artists,programs)">
		      <mat-icon>content_copy</mat-icon>
		  </button>		

		
			
			<button mat-fab 
				*ngIf="artists?.length==0"	
				matTooltip="Delete Event!" matTooltipPosition="after"
				(click)="confirmeDeleteEvent()" color="warn">
				<mat-icon>delete_forever</mat-icon>
			</button>

			<!-- Cancel Event -->
			<button mat-fab color="warn"
				*ngIf="event.isonline"	
				(click)="updateEventField(arrayArtists,event,'isonline',false)"
				matTooltip="Offline event" matTooltipPosition="after">
				<mat-icon>clear</mat-icon>
			</button>

			<!-- Deal Negociation -->
			<button mat-fab color="black" 
				(click)="updateEventField(arrayArtists,event,'isonline',true)"
				*ngIf="!event.isonline"
				matTooltip="Publish Event Online" matTooltipPosition="after">
				<mat-icon>check</mat-icon>
			</button>
			
			<!-- Repair event-->
			<button mat-fab color="black" 
				[disabled]="!repairButton"
				(click)="repairEvent(event)"
				matTooltip="Repair event" matTooltipPosition="after">
				<mat-icon>build</mat-icon>
			</button>

		</div>

</div>