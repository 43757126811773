import { Component, OnInit, Input, Inject } from '@angular/core';
import { NewsService } from './../../news/shared/news.service';
import { PublicService } from '../shared/public.service';

@Component({
  selector: 'artist-news',
  templateUrl: './artist-news.component.html',
  styleUrls: ['./artist-news.component.scss']
})
export class ArtistNewsComponent implements OnInit {

  @Input() newskey: string;
  @Input() instrumentColor: string;

  news: any;
  nbCar: any;
  nbCarShown: any;
  getOnce = true;

  constructor(private ns: NewsService,
              private ps: PublicService) { }

  ngOnInit() {
    this.news = this.ns.getNewsWithKey(this.newskey);
    this.nbCarShown = 300;
  }

  getNumberOfCaracterInArticleContent(n) {
    if (this.getOnce) { this.nbCar    = n; }
    this.getOnce    = false;
  }

  changeNbCarShown(nbCarShown,nbCarTotal) {
    this.nbCarShown = (nbCarShown === nbCarTotal) ? 300 : nbCarTotal;
  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

}
