import { AuthService } from './../../core/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/compat/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
// import { Event } from './event';
import * as moment from 'moment'; 
import {LogClass} from '../../../app/shared/logclass';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { deleteField } from 'firebase/firestore';

export interface Artist {
  $key: string;
}

@Injectable()
export class EventService {

  private memberPath     = '/member';
  private rolePath       = '/role';
  private instrumentPath = '/instrument';
  private eventPath      = '/events';

	// artistsRef             : AngularFireList<any>;
	artists                : any;
  artist                 : Observable<any>;
	// eventsRef              : AngularFireList<any>;
  // artistsEventRef        : AngularFireList<any>;
  tour                   : Observable<any>; 
  artistsEvent           : Observable<any>;
  // toursRef               : AngularFireList<any>;
  // programsRef            : AngularFireList<any>;
  programkey             : string;
  memberEmail            : Observable<any>;

  importEventlastnamefromCSV: string;
  artistFound:any;
  artistFoundKey:any;

  venueNameFromCSVLine: any;
  venueFound: any;

  counteventsimported: number = 0;
  countvenuesimported: number = 0;
  countartistsimported: number = 0;

  //now = new Date();
  //dateForFilters = new Date( + this.now + this.now.getTimezoneOffset() * 60000);
  yesterday = new Date( new Date().setDate( new Date().getDate() - 1 ) );
  dateForFilters = new Date( + this.yesterday + this.yesterday.getTimezoneOffset() * 60000);


  ////// FIRESTORE

  event              : Observable<Event>;
  eventCollection    : AngularFirestoreCollection<unknown>;
  publicEventCollection : AngularFirestoreCollection<unknown>;
  venueCollection    : AngularFirestoreCollection<unknown>;
  tourCollection     : AngularFirestoreCollection<unknown>;
  tourCollectionAfterToday : any;
  memberCollection   : AngularFirestoreCollection<unknown>;
  programCollection  : AngularFirestoreCollection<unknown>;
  artistCollection   : AngularFirestoreCollection<unknown>;
  genreCollection    : AngularFirestoreCollection<unknown>;
  EventSeasonCollection : AngularFirestoreCollection<unknown>;
  allEventCollection : AngularFirestoreCollection<unknown>;
  routingCollection  : AngularFirestoreCollection<unknown>;
  logClass           : LogClass;
  EventArtistCollection: AngularFirestoreCollection<unknown>; 
  eventWithNoArtistkeyCollection: AngularFirestoreCollection<unknown>;
  eventCollection2: any;

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; 

  idsOffline: string[];
  idsOfflineSubject = new Subject<string[]>();

  emitIdsOffline() {
    this.idsOfflineSubject.next( this.idsOffline )
  }

  constructor(
    private afs      : AngularFirestore,
    private router   : Router,
    private auth     : AuthService) {
    this.programCollection     = this.afs.collection('programs');
    this.allEventCollection    = this.afs.collection('events');
    this.eventCollection       = this.afs.collection('events', ref => ref.where('date','>',this.dateForFilters).orderBy('date'));
    this.eventCollection2       = this.afs.collection('events', ref => ref.where('date','>',this.dateForFilters).where('isonline', '==', true).orderBy('date'));

    this.eventWithNoArtistkeyCollection = this.afs.collection('events', ref => ref.where('artistkey','==',null));

    this.publicEventCollection = this.afs.collection('events', ref => ref.where('date','>',this.dateForFilters).where('isonline','==',true));
    this.memberCollection      = this.afs.collection('members');
    this.venueCollection       = this.afs.collection('venues');
    this.tourCollection        = this.afs.collection('tours', ref => ref.orderBy('enddate'));
    this.tourCollectionAfterToday = this.afs.collection('tours', ref => ref.where('startdate','>',this.dateForFilters).orderBy('startdate'));    
    this.routingCollection     = this.afs.collection('routings', ref => ref.orderBy('date'));
    this.logClass              = new LogClass(this.auth, this.afs);
  }


/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// G E T
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////


  getToursListAfterToday() {
    return this.tourCollectionAfterToday.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { startdate  : snap.payload.doc.data().startdate ? snap.payload.doc.data().startdate.toDate() : null},           
          { enddate  : snap.payload.doc.data().enddate ? snap.payload.doc.data().enddate.toDate() : null}, 
          { $key: snap.payload.doc.id }) )
      })
    );
  }

  getToursList() {
    return this.tourCollection.get().pipe(
      map((arr: firebase.default.firestore.QuerySnapshot<any>) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          { startdate  : snap.data().startdate ? snap.data().startdate.toDate() : null},           
          { enddate    : snap.data().enddate ? snap.data().enddate.toDate() : null},
          // { tags       : snap.payload.doc.data().startdate ? snap.payload.doc.data().startdate.toDate() + ' ' +snap.payload.doc.data().artistlastname : null}, 
          { tags       : snap.data().startdate ? snap.data().startdate.toDate().getFullYear() + ' ' + this.monthNames[snap.data().startdate.toDate().getMonth()] + ' ' +snap.data().artistlastname : null}, 
          { $key: snap.id }) )
      })
    );
  }


  getEventsList() {
    return this.eventCollection.get().pipe(
      map((arr: firebase.default.firestore.QuerySnapshot<any>) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          { programs: snap.data().programs?Object.values(snap.data().programs):0},
          { musicians: snap.data().artists?Object.values(snap.data().artists):0},        
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { genre: snap.data().genre ? 
                   snap.data().genre=='Operaconcert' ? 'Opera Concert' : 
                   snap.data().genre=='Operastaged' ? 'Opera Staged' :
                   snap.data().genre : null},        
          { $key: snap.id }) );
      })
    );
  }


  getEventsListWithNoArtistkey() {
    return this.eventWithNoArtistkeyCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},     
          { $key: snap.payload.doc.id }) );
      })
    );
  }


  getPublicEventsList() {
    return this.publicEventCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { programs: snap.payload.doc.data().programs?Object.values(snap.payload.doc.data().programs):0},
          { musicians: snap.payload.doc.data().artists?Object.values(snap.payload.doc.data().artists):0},        
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { genre: snap.payload.doc.data().genre ? 
                   snap.payload.doc.data().genre=='Operaconcert' ? 'Opera Concert' : 
                   snap.payload.doc.data().genre=='Operastaged' ? 'Opera Staged' :
                   snap.payload.doc.data().genre : null},        
          { $key: snap.payload.doc.id }) );
      })
    );
  }


  // getEventsListWithDateMissing() {
  //   return this.afs.collection('events', ref => ref.where('date','==',false)).snapshotChanges().map(arr => {
  //     return arr.map(snap => Object.assign(
  //       snap.payload.doc.data(), 
  //       { $key: snap.payload.doc.id }) );
  //   });
  // }


  getGenre(genre){
    this.genreCollection   = this.afs.collection('events',ref => ref.where('genre','==', genre) )
    return this.genreCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { $key: snap.payload.doc.id }) )
      })
    );
  }

  getRoutingDetailsWithTourKey(key) {
    return this.afs.collection('tours').doc(key).collection('routings', ref => ref.orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );    
  }

  getEventsWithMemberKey(key) {
    return this.afs.collection('members').doc(key).collection('events', ref => ref.orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          // { programs: snap.payload.doc.data().programs?Object.values(snap.payload.doc.data().programs):0},
          // { musicians: snap.payload.doc.data().artists?Object.values(snap.payload.doc.data().artists):0},        
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      }) 
    );   
  }


  getOnlineEventsWithMemberKeyAfterToday(key) {
    return this.afs.collection('members').doc(key).collection('events', ref => ref.where('date','>',this.dateForFilters).where('isonline','==',true))
    .get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          // { programs: snap.payload.doc.data().programs?Object.values(snap.payload.doc.data().programs):0},
          // { musicians: snap.payload.doc.data().artists?Object.values(snap.payload.doc.data().artists):0},
          { genre: snap.data().genre ? 
                   snap.data().genre=='Operaconcert' ? 'Opera Concert' : 
                   snap.data().genre=='Operastaged' ? 'Opera Staged' :
                   snap.data().genre : null},          
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { $key: snap.id }) );
      })
    ); 
  }

  getIdArtistOffline() {
    this.afs.collection('members', ref => ref.where('isonline', '==', false))
    .get().subscribe(array => {
      this.idsOffline = array.docs.map(e => {
        return e.id;
      });
      this.emitIdsOffline();
    });
  }

  getOnlineEventsWithMemberKey(key) {
    return this.afs.collection('members').doc(key).collection('events', ref => ref.where('isonline','==',true).orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          // { programs: snap.payload.doc.data().programs?Object.values(snap.payload.doc.data().programs):0},
          // { musicians: snap.payload.doc.data().artists?Object.values(snap.payload.doc.data().artists):0},        
          { genre: snap.payload.doc.data().genre ? 
                   snap.payload.doc.data().genre=='Operaconcert' ? 'Opera Concert' : 
                   snap.payload.doc.data().genre=='Operastaged' ? 'Opera Staged' :
                   snap.payload.doc.data().genre : null},
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );    
  }

  getProgramWithEventKey(key) {
    return this.afs.collection('events').doc(key).collection('program').get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          { $key: snap.id }) );
      })
    );
  }

  getExistingProgramsList() {
    return this.programCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(), 
          { $key: snap.payload.doc.id }) );
      })
    );
  }


  getEventWithKey(key: string): Observable<any> {
    const eventPath = `events/${key}`;
    this.event = this.afs.doc<any>(eventPath)
      .get().pipe(
        map((action: firebase.default.firestore.DocumentSnapshot<any>) => {
          const data = action.data() ? Object.assign(action.data(), 
          { 
            genre: action.data().genre ? 
                   action.data().genre=='Chamber' ? 'Chamber Music' : 
                   action.data().genre=='Operaconcert' ? 'Opera Concert' : 
                   action.data().genre=='Operastaged' ? 'Opera Staged' :
                   action.data().genre : null,
            date: action.data().date ? action.data().date.toDate() : null,           
            $key: action.id 
          }): null;
          return data;
        })
      );
    return this.event
  }




  getTourWithKey(key: string): Observable<Event> {
    const tourPath = `tours/${key}`;
    this.tour = this.afs.doc<any>(tourPath)
      .snapshotChanges().pipe(
        map(action => {
          const data = action.payload.data()? Object.assign(action.payload.data(),
          {
            startdate  : action.payload.data() ? action.payload.data().startdate.toDate() : {},
            enddate    : action.payload.data() ? action.payload.data().enddate.toDate() : {},
            $key       : action.payload.id
          }): null;
          return data;
        })
      );
    return this.tour ;
  }




  getCompositionsProgramWithEventKey(key: string) {
    // return this.afs.collection('events').doc(key).collection('program', ref => ref.orderBy('timestamp')).snapshotChanges().map(arr => {
    return this.afs.collection('events').doc(key).collection('program',ref => ref.orderBy('timestamp')).get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );   
  }  

  getCompositionsProgramWithProgramKey(key: string) {
    return this.afs.collection('programs').doc(key).collection('compositions').get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );    
  }  


  getArtistsEvent(key: string) {
    return this.afs.collection('events').doc(key).collection('artists').get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );   
  }

  getEventsTour(key: string) {
    return this.afs.collection('tours').doc(key).collection('events', ref => ref.orderBy('date')).get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { $key: snap.id }
        ));
      })
    );   
  } 
  
  getIdArtistByEvent(idEvent: string) {
    this.afs.collection('events').doc(idEvent).collection('artist').get();
  }



  getEventWithSeason(season?, early?,end?) {

    if(season == 'Automne'){
      var starTime =  new Date('2019-09-23');
      var endTime  =  new Date('2019-12-20');
    }else if(season == 'Hiver'){
      var starTime = new Date('2019-12-21');
      var endTime  = new Date('2020-03-19');
    }else if(season == 'Printemps'){
      var starTime = new Date('2020-03-20');
      var endTime  = new Date('2020-06-20');        
    }else if(season == 'Été'){
      var starTime = new Date('2020-06-21');
      var endTime  = new Date('2020-09-22');        
    }
   // console.log(starTime,endTime);

   this.EventSeasonCollection = this.afs.collection('events', ref => ref.where('date','>',starTime?starTime:early)
                                                                       .where('date','<',endTime?endTime:end)
                                                                       .where('isonline', '==', true));
    return this.EventSeasonCollection.get().pipe(
      map((arr) => {
        return arr.docs.map((snap: any) => Object.assign(
          snap.data(), 
          // console.log(snap.payload.doc.data().date.toDate())
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { genre: snap.data().genre ? 
                    snap.data().genre=='Operaconcert' ? 'Opera Concert' : 
                    snap.data().genre=='Operastaged' ? 'Opera Staged' :
                    snap.data().genre : null},        
          { $key: snap.id }) );
      })
    );
  }



  getEventWithYearOrderByArtist(key,year) {
    const starTime =  new Date( `${year}`+'-01-01');
    const endTime  =  new Date(`${year}`+'-12-31');
    if(year === 'undefined'){
        this.EventArtistCollection = this.afs.collection('members').doc(key)
        .collection('events', ref =>    ref.where('date','>',this.dateForFilters)
                                        .where('isonline','==',true))

    } else {
      this.EventArtistCollection = this.afs.collection('members').doc(key)
      .collection('events', ref => ref.where('date','>',starTime)
                                      .where('date','<',endTime)
                                      .where('isonline','==',true));
    }
    return this.EventArtistCollection.get().pipe(
      map((arr: firebase.default.firestore.QuerySnapshot<any>) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(), 
          // console.log(snap.payload.doc.data().date.toDate())
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { dates: snap.data().date ? snap.data().date.toDate(): null},
          { genre: snap.data().genre ? 
                    snap.data().genre=='Operaconcert' ? 'Opera Concert' : 
                    snap.data().genre=='Operastaged' ? 'Opera Staged' :
                    snap.data().genre : null},
          { $key: snap.id }) );
      })
    );
  }




/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// C R E A T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////



  createTour(newTourForm: NgForm): void {

    const tourref               = this.afs.firestore.collection('tours').doc();
    const tourkey               = tourref.id;

    var newTourData             = {}
    newTourData['artistemail']  = newTourForm.value.selectedArtist.email ? newTourForm.value.selectedArtist.email :null;
    newTourData['artistlastname']  = newTourForm.value.selectedArtist.lastname ? newTourForm.value.selectedArtist.lastname :null;
    newTourData['artistfirstname'] = newTourForm.value.selectedArtist.firstname ? newTourForm.value.selectedArtist.firstname :null;
    newTourData['artistfullname']  = newTourForm.value.selectedArtist.fullname ? newTourForm.value.selectedArtist.fullname :null;
    newTourData['artistkey']    = newTourForm.value.selectedArtist.$key ? newTourForm.value.selectedArtist.$key :null;
    newTourData['phone']        = newTourForm.value.selectedArtist.phone ? newTourForm.value.selectedArtist.phone :null;
    newTourData['isrouting']    = false;
    newTourData['enddate']      = newTourForm.value.newTourEndDate?newTourForm.value.newTourEndDate:null;
    newTourData['startdate']    = newTourForm.value.newTourStarDate?newTourForm.value.newTourStarDate:null;
    newTourData['timestamp']    = Date.now();    
    newTourData['title']        = newTourForm.value.newTourTitle?newTourForm.value.newTourTitle:null;

    // console.log(newTourForm.value);
    // console.log(newTourData);

    this.tourCollection.doc(tourkey).set(newTourData)
    .then(function(docRef){ console.log(newTourData);})
    .catch(function(error){ console.error("Error adding document: ", error);});
    this.logClass.createLog(this.afs,'createEvent',tourkey);

    this.router.navigate(['/event$/tour/'+ tourkey]);
  }


  addTourEvent(event,tour) {

    const eventkey               = event.$key;
    const artistkey              = event.artistkey?event.artistkey:null;
    const tourkey                = tour.viewModel? tour.viewModel.$key:tour.$key;
    const tourtitle              = tour.viewModel?tour.viewModel.title:tour.title;
    const countevents            = tour.countevents ? (tour.countevents+1) : 1;
    const date                   = event.date?event.date:null;
    const artistlastname         = event.artistlastname ? event.artistlastname : null;
    const artistfirstname        = event.artistfirstname ? event.artistfirstname : null;

    var eventData                = {}
    eventData['artistchef']      = event.artistchef?event.artistchef:null;
    eventData['artistorchestre'] = event.artistorchestre?event.artistorchestre:null;
    eventData['artistsoliste']   = event.artistsoliste?event.artistsoliste:null;
    eventData['artistothers']    = event.artistothers?event.artistothers:null;
    eventData['artistkey']       = artistkey;
    eventData['date']            = date;
    eventData['genre']           = event.genre?event.genre:null;
    eventData['origine']         = event.origine?event.origine:null;
    eventData['programkey']      = event.programkey?event.programkey:null;
    eventData['producername']    = event.producer?event.producer:null;
    eventData['produceremail']   = event.produceremail?event.produceremail:null;
    eventData['producerphone']   = event.producerphone?event.producerphone:null;
    eventData['produceraddress'] = event.produceraddress?event.produceraddress:null;
    eventData['title']           = event.title?event.title:null;    
    eventData['time']            = event.time?event.time:null;
    eventData['tourkey']         = tourkey;
    eventData['tourtitle']       = tourtitle;    
    eventData['venuekey']        = event.venuekey?event.venuekey:null;
    // eventData['venueaddress']    = event.venue? event.venue.venueaddress:null;
    // eventData['venuename']       = event.venue? event.venue.venuename:null;
    // eventData['venueurl']        = event.venue? event.venue.venueurl:null;

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).ref; 
    batch.update(eventRef, { ['tourkey'] : tourkey }); 
    batch.update(eventRef, { ['tourtitle'] : tourtitle }); 

    if(tour.artistkey) {
      var artistEventRef = this.memberCollection.doc(tour.artistkey).collection('events').doc(eventkey).ref; 
      batch.update(artistEventRef, { ['tourkey'] : tourkey });       
      batch.update(artistEventRef, { ['tourtitle'] : tourtitle });       
    }

    var tourRef = this.tourCollection.doc(tourkey).ref; 
    batch.update(tourRef, { ['countevents'] : countevents });     
    if(!tour.artistlastname&&artistlastname) { batch.update(tourRef, { ['artistlastname'] : event.artistlastname }) }     
    if(!tour.artistfirstname&&artistfirstname) { batch.update(tourRef, { ['artistfirstname'] : event.artistfirstname }) }     
    if(!tour.artistkey) { 
      batch.update(tourRef, { ['artistkey'] : artistkey })

      var artistEventRef = this.memberCollection.doc(event.artistkey).collection('events').doc(eventkey).ref; 
      batch.update(artistEventRef, { ['tourkey'] : tourkey })
      batch.update(artistEventRef, { ['tourtitle'] : tourtitle })
    }

    var eventTourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref; 
    batch.set(eventTourRef, eventData);    

    batch.commit().then(function() {console.log(eventData)}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'addTourEvent',eventkey,'tourdata');


  }



  // formatTourEventForRouting(date,time,details,tourkey,artistkey?) {
    
  //   // var newTourDetailForm = {};
  //   // var routingData = {
  //     // 'date': date, 'time':time,'details':details
  //   // }
  //   // newTourDetailForm['value'] = routingData; 

  //   // console.log(newTourDetailForm);
  //   // this.addDetailsRouting(tourkey,artistkey,newTourDetailForm);
  // }



  createEventFromTour(newEventForm: NgForm): void {

    const eventref            = this.afs.firestore.collection('events').doc();
    const eventkey            = eventref.id;
    const tourkey             = newEventForm.value.tourkey?newEventForm.value.tourkey:null;
    const tourtitle           = newEventForm.value.tourtitle?newEventForm.value.tourtitle:null;
    const countevents         = newEventForm.value.countevents?newEventForm.value.countevents++:1;
    const eventInTourRef      = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;

    const date                = newEventForm.value.newEventDate?newEventForm.value.newEventDate:null;

    var newEventData          = {}
    newEventData['timestamp'] = Date.now();
    newEventData['title']     = newEventForm.value.newEventTitle?newEventForm.value.newEventTitle:null;
    newEventData['comment']   = newEventForm.value.newEventComment?newEventForm.value.newEventComment:null;
    newEventData['statut']    = "0";
    newEventData['tourkey']   = tourkey;
    newEventData['tourtitle'] = tourtitle;
    newEventData['date']      = date;
    newEventData['countevents'] = countevents;

    var batch = this.afs.firestore.batch();
    batch.set(eventref, newEventData); 
    batch.set(eventInTourRef, newEventData); 

    batch.commit().then(function() {console.log('Event Created on Tour')}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'createEventFromTour',eventkey,'tourdata');
    this.router.navigate(['/event$/event/'+ eventkey]);
  }






  createEvent(newEventForm,artist?,programs?) {
    const eventref                      = this.afs.firestore.collection('events').doc();
    const eventkey                      = eventref.id;
    var batch                           = this.afs.firestore.batch();
    if(newEventForm.value) {
        this.insertInEventCollectionFromEventForm(newEventForm, eventkey, batch);
    } else {
      this.insertInEventCollectionFromEventDetailsOrPublicEvents(newEventForm, eventkey, batch, artist, programs);
      this.logClass.createLog(this.afs,'createEvent',eventkey);
      return this.router.navigate(['/event$/event/'+eventkey]);
    }
  }

   // remplacer ceci: 
   insertInEventCollectionFromEventForm(dataForm, eventkey, batch) {
      const newEventData                 = {};
      const artistData                   = {};
      newEventData['timestamp']          = Date.now();
      newEventData['title']              = dataForm.value.newEventTitle?dataForm.value.newEventTitle:null;
      newEventData['comment']            = dataForm.value.newEventComment?dataForm.value.newEventComment:null;
      newEventData['statut']             = "0";
      newEventData['date']               = dataForm.value.newEventDate?dataForm.value.newEventDate:null;
      // add artist
      if(dataForm.value.artist) {
          newEventData['genre']             = dataForm.value.gender?dataForm.value.gender:null;
          newEventData['artistkey']           = dataForm.value.artist.$key;
          newEventData['artistfirstname']     = dataForm.value.artist.firstname?dataForm.value.artist.firstname:null;
          newEventData['artistlastname']      = dataForm.value.artist.lastname?dataForm.value.artist.lastname:null;
          if(dataForm.value.tourkey) {
            const eventInTourRef        = this.tourCollection.doc(dataForm.value.tourkey).collection('events').doc(eventkey).ref;
            newEventData['tourkey']     = dataForm.value.tourkey?dataForm.value.tourkey:null;
            newEventData['tourtitle']   = dataForm.value.tourtitle?dataForm.value.tourtitle:null;
            newEventData['countevents'] = dataForm.value.countevents?dataForm.value.countevents++:1;
            batch.set(eventInTourRef, newEventData);
          }
          this.affiliateArtistToEvent(dataForm.value.artist,eventkey, batch, dataForm);
      }
      this.logClass.createLog(this.afs,'createEvent',eventkey);
      this.eventCollection.doc(eventkey).set(newEventData)
      .then(function(docRef){ console.log(eventkey);})
      .catch(function(error){ console.error("Error adding document: ", error);});
      return this.router.navigate(['/event$/event/'+ eventkey]);
   }

   insertInEventCollectionFromEventDetailsOrPublicEvents(newEventForm, eventkey, batch, artist, programs) {
      const newEventData               = {};
      let artistData  : any ;
      let programData : any ;
      newEventData['timestamp']        = newEventForm.timestamp?newEventForm.timestamp:null;
      newEventData['title']            = newEventForm.title?newEventForm.title + ' copie':null;
      newEventData['comment']          = newEventForm.comment?newEventForm.comment:null;
      newEventData['statut']           = newEventForm.statut?newEventForm.statut:null;
      newEventData['date']             = newEventForm.date?newEventForm.date:null;
      newEventData['artistkey']        = newEventForm.artistkey?newEventForm.artistkey:null;
      newEventData['artistfirstname']  = newEventForm.artistfirstname?newEventForm.artistfirstname:null;
      newEventData['artistlastname']   = newEventForm.artistlastname?newEventForm.artistlastname:null;
      newEventData['artistorchestre']  = newEventForm.artistorchestre?newEventForm.artistorchestre:null;
      newEventData['artistsoliste']    = newEventForm.artistsoliste?newEventForm.artistsoliste:null;
      newEventData['eventkey']         = eventkey;
      newEventData['genre']            = newEventForm.genre?newEventForm.genre:null;
      newEventData['isonline']         = newEventForm.isonline?newEventForm.isonline:null;
      newEventData['link']             = newEventForm.link?newEventForm.link:null;
      newEventData['producer']         = newEventForm.producer?newEventForm.producer:null;
      newEventData['programkey']       = newEventForm.programkey?newEventForm.programkey:null;
      newEventData['time']             = newEventForm.time?newEventForm.time:null;
      if(newEventForm.venue) {
        // var batch                      = this.afs.firestore.batch();
        var venuekey                   = newEventForm.venuekey?newEventForm.venuekey:null;
        var venueData                  = {};
        venueData['venueaddress']      = newEventForm.venue.venueaddress?newEventForm.venue.venueaddress:null;
        venueData['venuekey']          = newEventForm.venue.venuekey?newEventForm.venue.venuekey:null;
        venueData['venuename']         = newEventForm.venue.venuename?newEventForm.venue.venuename:null;
        venueData['venueurl']          = newEventForm.venue.venueurl?newEventForm.venue.venueurl:null;
        newEventData['venuekey']       = venuekey;
        newEventData['venue']          = venueData;
        const venueRootRef             =  this.afs.collection('venues').doc(venuekey)
                                                  .collection('events').doc(eventkey).ref;
        batch.set(venueRootRef,newEventData); 
      }
      if(artist){
        artistData = this.denormalizeEveventInArtistCollection(artist, newEventData, eventkey, batch) ;
      }
      if(programs){
        programData = this.denormalizeEventInProgramCollection(programs, eventkey, batch)
      }
      var eventRootRef = this.eventCollection.doc(eventkey).ref;
      batch.set(eventRootRef, newEventData);
      batch.commit().then(function() {console.log(newEventData,artistData,programData)})
       .catch(function(err){ console.log(err)});
  }

  denormalizeEveventInArtistCollection(artist, newEventData, eventkey, batch){
    const artistData = {};
    if(artist.length) {
      for(var i=0; i< artist.length; i++) {
          var artistKey = artist[i].$key;
          artistData['firstname'] = artist[i].firstname?artist[i].firstname:null;
          artistData['instrument'] = artist[i].instrument?artist[i].instrument:null;
          artistData['key'] = artist[i].$key;
          artistData['lastname'] = artist[i].lastname?artist[i].lastname:null;
          const artistRootRef =  this.afs.collection('events').doc(eventkey)
                                         .collection('artists').doc(artistKey).ref;
          batch.set(artistRootRef,artistData);
          const eventInArtistRootRef =  this.afs.collection('members').doc(artistKey)
                                                .collection('events').doc(eventkey).ref;
          batch.set(eventInArtistRootRef,newEventData);
          return artistData ;
      }
    }
  }

  denormalizeEventInProgramCollection(programs, eventkey, batch){
    const programData = {} ;
    if(programs.length) {
      for(let i=0; i < programs.length; i++) {
          var programkey = programs[i].$key;
          programData['composerfirstname'] = programs[i].composerfirstname?programs[i].composerfirstname:null;
          programData['composerkey'] = programs[i].composerkey? programs[i].composerkey:null;
          programData['composerlastname'] = programs[i].composerlastname?programs[i].composerlastname:null;
          programData['compositionkey'] = programs[i].compositionkey;
          programData['compositiontitle'] = programs[i].compositiontitle?programs[i].compositiontitle:null;
          programData['eventkey'] = eventkey;
          programData['eventtitle'] = programs[i].eventtitle?programs[i].eventtitle:null;
          programData['programkey'] = programkey;
          programData['timestamp'] = programs[i].timestamp?programs[i].timestamp:null;
          const artistRootRef =  this.afs.collection('events').doc(eventkey)
                                        .collection('program').doc(programkey).ref;
          batch.set(artistRootRef, programData);
      }
      console.log(programData);
      return programData ;      
   }
  }

  affiliateArtistToEvent(data, eventkey, batch, dataForm) {
    // console.log('data : '+data);
    if(data){
    const newEventData                  = {}
    const artistData                    = {};
    const arti$t                        = data ;
    var artistkey                       = arti$t.$key?arti$t.$key:null;
    
    newEventData['artistkey']           = artistkey;
    newEventData['artistfirstname']     = arti$t.firstname? arti$t.firstname:null;
    newEventData['artistlastname']      = arti$t.lastname?arti$t.lastname:null; 
    newEventData['timestamp']          = Date.now();
    newEventData['title']              = dataForm.value.newEventTitle?dataForm.value.newEventTitle:null;
    newEventData['comment']            = dataForm.value.newEventComment?dataForm.value.newEventComment:null;
    newEventData['statut']             = "0";
    newEventData['date']               = dataForm.value.newEventDate?dataForm.value.newEventDate:null;
    newEventData['tourkey']            = dataForm.value.tourkey?dataForm.value.tourkey:null;
    newEventData['tourtitle']          = dataForm.value.tourtitle?dataForm.value.tourtitle:null;
    newEventData['countevents']        = dataForm.value.countevents?dataForm.value.countevents++:1;
    
    artistData['firstname']             = arti$t.firstname? arti$t.firstname:null;
    artistData['instrument']            = arti$t.instrument?arti$t.instrument:null;
    artistData['key']                   = artistkey;
    artistData['lastname']              = arti$t.lastname?arti$t.lastname:null;
    
    const eventRootRef                  = this.afs.collection('events').doc(eventkey).ref;
    batch.set(eventRootRef,newEventData);

    const artistRootRef                 = this.afs.collection('events').doc(eventkey)
                                                   .collection('artists').doc(artistkey).ref;
    batch.set(artistRootRef,artistData);
    
    
    const eventInArtistRootRef     =  this.afs.collection('members').doc(artistkey)
                                              .collection('events').doc(eventkey).ref;
    batch.set(eventInArtistRootRef,newEventData);
    
    batch.commit().then(function() {console.log(newEventData,artistData)}).catch(function(err){ console.log(err)});
      console.log(data);
    }
  }












  addCompositionToProgram(event, composer, composition, progrkeyfromcopy) {

    // console.log(event);
    // console.log(composer);
    console.log('composition', composition);
    console.log( progrkeyfromcopy);

    const eventkey                   = event.$key;
    const eventtitle                 = event.title ? event.title : null;
    // const eventdate               = event.date ? event.date : null; 

    // If coming from new program or copy
    const compositionkey             = composition.viewModel?composition.viewModel.$key:composition.$key;
    const compositiontitle           = composition.viewModel?composition.viewModel.title:composition.title;
    const composerkey                = composer.$key?composer.$key:composer.key;

    if(progrkeyfromcopy) {
      this.programkey = progrkeyfromcopy ? progrkeyfromcopy: null;      
    }
    else {
      const programkeyfromevent       = event.programkey?event.programkey:null;
      const programref                = this.afs.firestore.collection('programs').doc();
      this.programkey                 = programkeyfromevent?programkeyfromevent:programref.id;          
    }

    var programData                  = {}
    programData['composerlastname']  = `${composer.lastname}`;
    programData['composerfirstname'] = `${composer.firstname}`;    
    programData['composerkey']       = composerkey;
    programData['timestamp']         = Date.now();
    programData['compositiontitle']  = compositiontitle;
    programData['compositionkey']    = compositionkey;
    programData['eventkey']          = eventkey;
    programData['eventtitle']        = eventtitle;
    programData['programkey']        = this.programkey?this.programkey:null;

    var eventData                    = {}
    eventData['eventtitle']          = eventtitle;
    eventData['eventkey']            = eventkey;


    var batch = this.afs.firestore.batch();

    var programRootRef = this.programCollection.doc(this.programkey).ref;    
    batch.set(programRootRef, eventData);

    const eventRef = this.eventCollection.doc(eventkey).ref; 
    batch.update(eventRef, { ['programkey'] : this.programkey }); 

    var programRef = this.programCollection.doc(this.programkey).collection('compositions').doc(compositionkey).ref;    
    batch.set(programRef, programData);

    const eventRef2 = this.eventCollection.doc(eventkey).collection('program').doc(compositionkey).ref; 
    batch.set(eventRef2, programData);    

    batch.commit().then(function() {console.log(programData)}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'addCompositionToProgram',compositionkey,'programdata');
    this.router.navigate(['/event$/event/'+eventkey]);    
  }

  duplicateProgramEvent(oldprogram,event) {

    const programref                 = this.afs.firestore.collection('programs').doc();
    const programkey                 = programref.id;          

    if (oldprogram.length) { 
      for (var i=0;i<oldprogram.length;i++) {

        var composerData          = {};
        composerData['lastname']  = oldprogram[i].composerlastname ?oldprogram[i].composerlastname:null; 
        composerData['firstname'] = oldprogram[i].composerfirstname?oldprogram[i].composerfirstname:null; 
        composerData['key']       = oldprogram[i].composerkey?oldprogram[i].composerkey:null;

        var compositionData       = {};
        compositionData['title']  = oldprogram[i].compositiontitle?oldprogram[i].compositiontitle:null; 
        compositionData['key']    = oldprogram[i].compositionkey?oldprogram[i].compositionkey:null; 

        this.addCompositionToProgram(event,composerData,compositionData,programkey);
      }
    }
  }

  addArtistToEvent(event,artist) {

    var eventkey                 = event.$key;
    var venuekey                 = event.venuekey?event.venuekey:null;
    var programkey               = event.programkey?event.programkey:null;
    var tourkey                  = event.tourkey?event.tourkey:null;
    var artistkey                = artist.$key ? artist.$key : null;
    var otherartists             = event.artists?event.artists:0;
    var artistlastname           = artist.lastname ? artist.lastname : null;
    var artistfirstname          = artist.firstname ? artist.firstname : null;

    var eventData                = {}
    eventData['timestamp']       = Date.now();
    eventData['title']           = event.title?event.title:null;
    eventData['genre']           = event.genre?event.genre:null;

    eventData['artistchef']      = event.artistchef?event.artistchef:null;
    eventData['artistorchestre'] = event.artistorchestre?event.artistorchestre:null;
    eventData['artistsoliste']   = event.artistsoliste?event.artistsoliste:null;
    eventData['artistothers']    = event.artistothers?event.artistothers:null;    
    eventData['date']            = event.date?event.date:null;    
    eventData['eventkey']        = eventkey;  
    eventData['isonline']        = event.isonline?event.isonline:null;    
    eventData['origine']         = event.origine?event.origine:null;    
    eventData['producer']        = event.producer?event.producer:null;
    eventData['producername']    = event.producername?event.producername:null;
    eventData['produceremail']   = event.produceremail?event.produceremail:null;
    eventData['producerphone']   = event.producerphone?event.producerphone:null;
    eventData['produceraddress'] = event.produceraddress?event.produceraddress:null;
    eventData['programkey']      = programkey;    
    eventData['status']          = "1";
    eventData['time']            = event.time?event.time:null;
    eventData['tourkey']         = tourkey;
    eventData['tourtitle']       = event.tourtitle?event.tourtitle:null;
    eventData['venuekey']        = venuekey;

    var artistData               = {};
    artistData['firstname']      = artist.firstname ? artist.firstname : null;
    artistData['lastname']       = artist.lastname ? artist.lastname : null; 
    artistData['key']            = artistkey;
    artistData['instrument']     = artist.instrument ? artist.instrument : null; 

    var venueData                = {};
    venueData['address']         = event.venue ? event.venue.venueaddress ? event.venue.venueaddress : null : null;
    venueData['name']            = event.venue ? event.venue.venuename ? event.venue.venuename : null : null;
    venueData['url']             = event.venue ? event.venue.venueurl ? event.venue.venueurl : null : null;;
    venueData['venuekey']        = venuekey;

    eventData['venue']           = venueData;

    var batch        = this.afs.firestore.batch();

    var eventRef = this.allEventCollection.doc(eventkey).ref;    
    batch.update(eventRef, { artistlastname: artistlastname });  
    batch.update(eventRef, { artistfirstname: artistfirstname });  

    if(artistkey) {
      batch.update(eventRef, { artistkey: artistkey });  

      var artistRef    = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;    
      batch.set(artistRef, eventData);

      var eventArtistRef     = this.allEventCollection.doc(eventkey).collection('artists').doc(artistkey).ref; 
      batch.set(eventArtistRef, artistData ); 
    }
    else console.error('No artistkey');

    if(programkey) { 
      var programRef = this.programCollection.doc(programkey).collection('artists').doc(artistkey).ref;    
      batch.set(programRef, artistData); 
    }

    if(venuekey) { 
      var venueRef   = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref; 
      batch.set(venueRef, artistData);  
    }

    if(tourkey) { 
      var tourRef    = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref; 
      batch.set(tourRef, artistData); 
    }

    batch.commit().then(function() {console.log(eventData,artistData)}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'addArtistToEvent',eventkey,'artistdata');
    this.router.navigate(['/event$/event/'+eventkey]); 
  }


  addDetailsRouting(tourkey,artistkey,newTourDetailForm) {

    const newRoutingRef       = this.afs.firestore.collection('routings').doc();
    const newRoutingkey       = newRoutingRef.id;    
    const date                = newTourDetailForm.value.date ? newTourDetailForm.value.date : null;
   // const time                = newTourDetailForm.value.time ? newTourDetailForm.value.time : null;
    const hour                = newTourDetailForm.value.hour =! 0 ? newTourDetailForm.value.hour : '00'; 
    const minute              = newTourDetailForm.value.minute =! 0 ? newTourDetailForm.value.minute : '00';
    var newdate               = date.setHours(hour,minute);
    newdate                   = new Date(newdate);
    const hourformat          = hour.toString().length == 1? '0'+hour.toString():hour.toString();
    const minuteformat        = minute.toString().length == 1? '0'+minute.toString():minute.toString();
    console.log(  hourformat    );
    console.log( minute  );
    const detailsData         = {};
    detailsData['timestamp']  = Date.now();
    detailsData['date']       = newdate;
    detailsData['details']    = newTourDetailForm.value.details ? newTourDetailForm.value.details : null;
    detailsData['time']       = hourformat+':'+minuteformat; 
    detailsData['artistkey']  = artistkey?artistkey:null;
    detailsData['tourkey']    = tourkey;
    detailsData['routingkey'] = newRoutingkey;

    if(newRoutingkey && tourkey) {
      var batch = this.afs.firestore.batch();
      
      var routingRef = this.routingCollection.doc(newRoutingkey).ref;    
      batch.set(routingRef, detailsData);

      var tourDetailsref = this.tourCollection.doc(tourkey).collection('routings').doc(newRoutingkey).ref;    
      batch.set(tourDetailsref, detailsData);

      batch.commit().then(function() {console.log('New Routing Details Recorded')}).catch(function(err){ console.log(err)});
      this.logClass.createLog(this.afs,'addDetailsRouting',newRoutingkey);
    }
    else { console.error('Key not provided.') }

  }


  importEvents(events, artists,venues) {

    var eventsValue = events.value;
    var nbOfLine = (eventsValue.match(/\n/g)||[]).length;

    // 1a- Création d'un tableau avec pour chaque nom d'artiste sa clé correspondante    
    var lastnameArtistsWithKey = [];
    if(artists) {
      var i = 0;
      Object.values(artists).forEach((artist:any) => {
        i++;
        var searchArtist = { 
          key            : artist.$key, 
          lastname       : artist.lastname,
          firstname      : artist.firstname,
          instrument     : artist.instrument
        }
        lastnameArtistsWithKey[i] = searchArtist;
      });
    }

    // 1b- Création d'un tableau avec pour chaque nom de scène sa clé et ses info
    var getVenueInfoWithName = [];
    if(venues) {
      var i = 0;
      Object.values(venues).forEach((venue:any) => {
        i++;
        var foundVenue = { 
          key            : venue.$key, 
          venuename      : venue.name,
          venueaddress   : venue.address,
          venueurl       : venue.url
        }
        getVenueInfoWithName[i] = foundVenue;
      });
    }


    for(var i=0;i<=nbOfLine;i++)
    // for(var i=0;i<=44;i++)
    {

      console.log('NEW LINE: ' + i + ' / ' + nbOfLine);
      var line = eventsValue.split('\n')[i];
      var tabs             = line.split('\t');  

      var artist           = tabs[0] ? tabs[0] : null;
      var artistlastname   = artist.substr(0, artist.indexOf(','));
      
      if(artistlastname=='Pascal') {
        // console.log(i,line);

        // ----------------------------------------------------------------------
        // GET FROM CSV
        var artist           = tabs[0] ? tabs[0] : null;
        var date             = tabs[1] ? tabs[1] : null;
        var time             = tabs[2] ? tabs[2] : null;
        var genre            = tabs[3] ? this.capitalizeFirstLetter(tabs[3]).replace(/\s+/g, '') : null;
        var producer         = tabs[4] ? tabs[4] : null;
        var artistorchestre  = tabs[5] ? tabs[5] : null;
        var artistsoliste    = tabs[6] ? tabs[6] : null;
        var origine          = tabs[7] ? tabs[7] : null;
        var venue            = tabs[8] ? tabs[8] : null;
        var link             = tabs[9] ? tabs[9] : null;
        var managerkajimoto  = tabs[10]?tabs[10] : null;


        // ----------------------------------------------------------------------
        // TRANSFORM
        
        // 1- Get artist info with its key
        if(artist) {
          this.importEventlastnamefromCSV = artist.substr(0, artist.indexOf(','));
          Object.values(lastnameArtistsWithKey).forEach((artist:any) => {
            if (artist.lastname === this.importEventlastnamefromCSV) {
              this.artistFound = artist;
            }
          });
        }
        else { console.error("Artist has no name"); }

        // 2- Get Venue Info
        if(venue) {        
          this.venueNameFromCSVLine = venue;
          Object.values(getVenueInfoWithName).forEach((venue:any) => {
            if (venue.venuename === this.venueNameFromCSVLine) {
              this.venueFound = venue;
            }
          });
        }
        else { console.error("Venue not found"); }

        var newEventData = {}
        newEventData['timestamp']       = Date.now();
        newEventData['title']           = `Event ${date} - ${i}`;        
        newEventData['date']            = null;             
        newEventData['time']            = time;             
        newEventData['genre']           = genre;            
        newEventData['producer']        = producer;         
        newEventData['artistlastname']  = this.importEventlastnamefromCSV;  
        newEventData['artistorchestre'] = artistorchestre;  
        newEventData['artistsoliste']   = artistsoliste;    
        newEventData['comment']         = `${this.importEventlastnamefromCSV} / Organisé par ${origine} / Manager Kajimoto : ${managerkajimoto}`;          
        newEventData['link']            = link;  
        newEventData['statut']          = "0";                 
        newEventData['isonline']        = false;                 


        // ----------------------------------------------------------------------
        // INSERT

        // Insert In Event Node
        const eventref            = this.afs.firestore.collection('events').doc();
        const eventkey            = eventref.id;

        var batch = this.afs.firestore.batch();

        var eventRef = this.eventCollection.doc(eventkey).ref;    
        batch.set(eventRef, newEventData);
        this.counteventsimported++;

        if(this.artistFound) {

          this.countartistsimported++;

          console.log('PREPARE ARTIST');
          console.log(this.artistFound);

          var artistData               = {};
          artistData['firstname']      = this.artistFound.firstname ? this.artistFound.firstname : null;
          artistData['lastname']       = this.artistFound.lastname ? this.artistFound.lastname : null; 
          artistData['key']            = this.artistFound.key;
          artistData['instrument']     = this.artistFound.instrument ? this.artistFound.instrument : null; 

          console.log(artistData);

          var artistInEventRef = this.eventCollection.doc(eventkey).collection('artists').doc(this.artistFound.key).ref;
          batch.set(artistInEventRef, artistData);

          var artistRef = this.memberCollection.doc(this.artistFound.key).collection('events').doc(eventkey).ref;
          batch.set(artistRef, newEventData);
        }
        if(this.venueFound) {

          this.countvenuesimported++;

          console.log('PREPARE VENUE');
          console.log(this.venueFound);

          var venueData                = {};
          venueData['venuename']       = this.venueFound.venuename ? this.venueFound.venuename : null;
          venueData['venueaddress']    = this.venueFound.venueaddress ? this.venueFound.venueaddress : null;
          venueData['venueurl']        = this.venueFound.url ? this.venueFound.url : null;
          venueData['venuekey']        = this.venueFound.key;

          console.log(venueData);

          var eventRef = this.eventCollection.doc(eventkey).ref;    
          batch.update(eventRef, { venue: venueData });
          batch.update(eventRef, { venuekey: this.venueFound.key });

          var venueRef = this.venueCollection.doc(this.venueFound.key).collection('events').doc(eventkey).ref; 
          batch.set(venueRef, venueData); 

        }

        console.log('Artists Found : ' + this.countartistsimported);
        console.log('Venue Found : ' + this.countvenuesimported);
        console.log('Event Ready: ' + this.counteventsimported);

        // batch.commit()
        // .then(function() {console.log('Batching')})
        // .catch(function(error){ console.error("Error adding document: ", error);});


        console.log('-------E N D    O F   L I N E---------------------------');

        // One Event per line : // Artist // Date // Heure // genre // producer // artistorchestre  
        // partenaire-soloist // origine // Lieu // Lien // Manager Kajimoto

      }

      else { console.log('not Pascal'); }

    }

  }






/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// U P D A T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////



  updateEventFieldWithBatch(artists,event,field,value,user?): void {

    var eventkey          = event.$key?event.$key:null;
    var artistkey         = event.artistkey ? event.artistkey : null;
    var artistlastname    = event.artistlastname ? event.artistlastname : null;
    var tourkey           = event.tourkey?event.tourkey:null;
    var venuekey          = event.venuekey?event.venuekey:null;

    var updateField       = {};
    updateField[`${field}`]= value;
    updateField['lastupdate']= {
      date: Date.now(),
      user: user?user.displayName:'',
      uid: user?user.uid:''
    }

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).ref; 
    batch.update(eventRef, updateField );

    if(venuekey) { 
      var venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref; 
      batch.update(venueRef, updateField); 
    } 

    if(tourkey) { 
      var tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref; 
      batch.update(tourRef, updateField);
    }

    // if(artists) {
    //   Object.values(artists).forEach((artist:any) => {
    //     var newartistkey = artist.$key;
    //     var artistRef = this.memberCollection.doc(newartistkey).collection('events').doc(eventkey).ref ? this.memberCollection.doc(newartistkey).collection('events').doc(eventkey).ref:null; 
    //     if(artistRef) {
    //       batch.update(artistRef, updateField);    
    //       batch.update(eventRef, {artistkey: newartistkey} );
    //       console.log('Event Updated in Artist Sub Collection');
    //     }
    //   });
    // }

    if(artistkey) {
      var artistRef = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref ? this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref:null; 
      batch.update(artistRef, updateField);    
    }    

    batch.commit()
        .then(function() {console.log(updateField)})
        .catch(function(err){ console.log(err)});
        this.logClass.createLog(this.afs,'updateEventFieldWithBatch',eventkey,field);
    this.router.navigate(['/event$/event/'+eventkey]); 
  }




  addArtistkeyToEvent(event,field,value): void {

    var eventkey          = event.$key?event.$key:null;
    var artistkey         = value ? value : null;
    var artistlastname    = event.artistlastname ? event.artistlastname : null;
    var tourkey           = event.tourkey?event.tourkey:null;
    var venuekey          = event.venuekey?event.venuekey:null;

    var updateField       = {};
    updateField[`${field}`]= value;

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).ref; 
    batch.update(eventRef, updateField );

    // if(venuekey) { 
    //   var venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref; 
    //   batch.update(venueRef, updateField); 
    // } 

    if(tourkey) { 
      var tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref; 
      batch.update(tourRef, updateField);
    }

    if(artistkey) {
      var artistRef = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref ? this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref:null; 
      batch.update(artistRef, updateField);    
    }    

    batch.commit()
        .then(function() {console.log(updateField)})
        .catch(function(err){ console.log(err)});

        this.logClass.createLog(this.afs,'addArtistkeyToEvent',artistkey);
  }





  updateTourField(tour,field,value,user?): void {
    var tourkey              = tour.$key?tour.$key:null;
    var updateField          = {};
    updateField[`${field}`]  = value;
    updateField['lastupdate']= {
      date: Date.now(),
      user: user?user.displayName:'',
      uid: user?user.uid:''
    }

    console.log(user);

    var tourRef              = this.tourCollection.doc(tourkey).ref; 
    if(field&&value) {
      var batch              = this.afs.firestore.batch();
      batch.update(tourRef, updateField );
      batch.commit()
          .then(function() {console.log(updateField)})
          .catch(function(err){ console.log(err)});

          this.logClass.createLog(this.afs,'updateTourField',tourkey);
    }
  }

  dropEmailInTour(tourkey,email) {
    var updateField             = {};
    updateField[`artistemail`]  = email; 
    var tourRef                 = this.tourCollection.doc(tourkey).ref; 
    var batch                   = this.afs.firestore.batch();
    batch.update(tourRef, updateField );
    batch.commit().then(function() {console.log(updateField)}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'dropEmailInTour',tourkey);
  }





  // checkIfEntryExist(pathRef) {
  //   pathRef.get().then((docSnapshot) => {
  //     if(docSnapshot.exists) {
  //       return true;
  //       // console.log("Checked : It Exist");
  //     }
  //     else { 
  //       return false;
  //       // console.log("Checked : It does NOT Exist");
  //     }
  //   })
  // }


  // updateEventFieldWithTransaction(artists,event,field,value) {

  //   var eventkey          = event.$key?event.$key:null;
  //   var venuekey          = event.venuekey?event.venuekey:null;

  //   var updateField       = {};
  //   updateField[`${field}`]= value;

  //   var eventRef          = this.afs.collection("events").doc(eventkey).ref;
  //   if (venuekey) {
  //     var eventInVenueRef = this.afs.collection("venues").doc(venuekey).collection("events").doc(eventkey).ref;
  //     var arrayRef        = [eventRef,eventInVenueRef]
  //   }
  //   else { 
  //     var arrayRef        = [eventRef] 
  //   }
    
  //   // Add Artists Ref To Reference Array
  //   if(artists) {
  //     Object.values(artists).forEach((artist:any) => {
  //       var artistRef = this.memberCollection.doc(artist.$key).collection('events').doc(eventkey).ref?this.memberCollection.doc(artist.$key).collection('events').doc(eventkey).ref:null; 
  //       if(artistRef) { arrayRef.push(artistRef)}
  //     });
  //   }

  //   // Build Transaction to All Reference
  //   return this.afs.firestore.runTransaction(t => {
  //     return Promise.all(arrayRef.map(async (element) => {
  //       const doc = await t.get(element);
  //       if(doc.exists) {
  //         await t.update(element, updateField);          
  //       }
  //       else {
  //         await t.set(element, updateField);
  //       }
  //     }))
  //   }) 
  // }



  addVenueToEvent(event,venue) {

    var eventkey              = event.$key;
    var eventtitle            = event.title;
    var oldvenuekey           = event.venue?event.venue.venuekey:null;

    var venuekey              = venue.$key;

    var eventData             = {};
    eventData['venuename']    = venue.name;
    eventData['venueaddress'] = venue.address;
    eventData['venueurl']     = venue.url;
    eventData['venuekey']     = venuekey;

    var updateVenueEvents = {};
    updateVenueEvents[`${eventkey}`] = eventtitle;

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).ref;    
    batch.update(eventRef, { venue: eventData });
    batch.update(eventRef, { venuekey: venuekey });    

    var venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref; 
    batch.set(venueRef, event);    

    // Suppression de l'ancienne Venue
    if(oldvenuekey) { 
      var deleteVenueRef = this.venueCollection.doc(oldvenuekey).collection('events').doc(eventkey).ref;
      batch.delete(deleteVenueRef);   
    }

    batch.commit().then(function() {console.log('Batching')});

    this.router.navigate(['/event$/event/' + eventkey]);
    this.logClass.createLog(this.afs,'addVenueToEvent',venuekey); 
  }








/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// D E L E T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////


  removeRoutingDetails(routing) {
    var routingkey = routing.$key ? routing.$key : null;
    var tourkey    = routing.tourkey ? routing.tourkey : null;

    if (routingkey && tourkey) {
      var batch = this.afs.firestore.batch();

      var deleteRoutingTourRef = this.tourCollection.doc(tourkey).collection('routings').doc(routingkey).ref;
      batch.delete(deleteRoutingTourRef);   

      var deleteRoutingRef = this.routingCollection.doc(routingkey).ref;
      batch.delete(deleteRoutingRef);       

      batch.commit().then(function() {console.log('Routing Delete')});  
      this.logClass.createLog(this.afs,'removeRoutingDetails',routingkey);
    }
    else { console.error('Keys not provided.')}


  }

  deleteVenueFromEvent(event) {

    var eventkey              = event.$key;
    var venuekey              = event.venue.venuekey;

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).ref;    
    batch.update(eventRef, { venue: null });   
    batch.update(eventRef, { venuekey: null });   

    var deleteVenueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref;
    batch.delete(deleteVenueRef);   

    batch.commit().then(function() {console.log('Batch Delete')});
    this.logClass.createLog(this.afs,'deleteVenueFromEvent',venuekey);

  }


  deleteEventWithNoArtist(event): void {

    var eventkey     = event.$key;
    var tourkey      = event.tourkey?event.tourkey:null;
    var venuekey     = event.venuekey?event.venuekey:null;
    var programkey   = event.programkey?event.programkey:null;

    var batch = this.afs.firestore.batch();

    console.log('Event Key',eventkey);

    var eventRef = this.eventCollection.doc(eventkey).ref;    
    batch.delete(eventRef);   

    if(venuekey)  { var deleteVenueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref;
    batch.delete(deleteVenueRef);   }

    if(programkey) { var programRef = this.programCollection.doc(programkey).ref;
      batch.delete(programRef);
    }

    if(tourkey) {
      var tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;
      batch.delete(tourRef);
    }

    batch.commit().then(function() {console.log('Batch Delete')});
    this.logClass.createLog(this.afs,'deleteEventWithNoArtist',venuekey);
    this.router.navigate(['/events']);  
  }


  deleteCompositionInProgram(event,composition) {

    var eventkey       = event.$key;
    var compositionkey = composition.$key;    
    var tourkey      = event.tourkey?event.tourkey:null;
    var venuekey     = event.venuekey?event.venuekey:null;
    var programkey   = event.programkey?event.programkey:null;

    var batch = this.afs.firestore.batch();

    var eventRef = this.eventCollection.doc(eventkey).collection('program').doc(compositionkey).ref;    
    batch.delete(eventRef);

    if(venuekey)  { var deleteVenueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).collection('program').doc(compositionkey).ref;
                    batch.delete(deleteVenueRef);   }

    if(programkey){ var programRef = this.programCollection.doc(programkey).collection('compositions').doc(compositionkey).ref;
                    batch.delete(programRef);}

    if(tourkey) {   var tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('program').doc(compositionkey).ref;
                    batch.delete(tourRef);}  

    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'deleteCompositionInProgram',compositionkey);
  }


  removeArtistFromEvent(event,artist) {

    var eventkey                 = event.$key;
    var venuekey                 = event.venuekey?event.venuekey:null;
    var programkey               = event.programkey?event.programkey:null;
    var tourkey                  = event.tourkey?event.tourkey:null;
    var artistkey                = artist.$key ? artist.$key : artist;

    var keyData                  = {};
    keyData['eventkey']          = eventkey;
    keyData['venuekey']          = venuekey;
    keyData['programkey']        = programkey;
    keyData['tourkey']           = tourkey;
    keyData['artistkey']         = artistkey;

    var batch = this.afs.firestore.batch();

    var eventRef = this.allEventCollection.doc(eventkey).ref;    
    batch.update(eventRef, {artistlastname: firebase.default.firestore.FieldValue.delete()});   
    batch.update(eventRef, {artistkey: firebase.default.firestore.FieldValue.delete()});   

    var eventArtistRef = this.allEventCollection.doc(eventkey).collection('artists').doc(artistkey).ref;    
    batch.delete(eventArtistRef);   

    var artistRef = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;    
    batch.delete(artistRef); 

    if(programkey) {
      var programRef = this.programCollection.doc(programkey).collection('artists').doc(artistkey).ref;
      batch.delete(programRef);
    }

    if(venuekey) {
      var venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
      batch.delete(venueRef);
    }
    
    if(tourkey) {
      var tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
      batch.delete(tourRef);
    }

    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'removeArtistFromEvent',eventkey);
  }

  removeAllArtistsAndDeleteEvent(event,artists) {
    if (artists.length) { 
      for (var i=0;i<artists.length;i++) {
        var artistkey = artists[i].$key?artists[i].$key:null;
        this.removeArtistFromEvent(event,artistkey);
      }
    this.deleteEventWithNoArtist(event);    
    }  
  }

  deleteTour(tour): void {
    var tourkey = tour.$key;
    const tourPath = `tours/${tourkey}`;

    var batch = this.afs.firestore.batch();
    var tourRef = this.tourCollection.doc(tourkey).ref;    
    batch.delete(tourRef); 
    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)});
    this.router.navigate(['/event$/tours/']);    
    this.logClass.createLog(this.afs,'deleteTour',tourkey); 
  }

  removeTourFromEvent(event, eventsnb) {
    var tourkey    = event.tourkey ? event.tourkey : null;
    var eventkey   = event.$key ? event.$key : null;
    var artistkey  = event.artistkey ? event.artistkey : null;
    const countevents= eventsnb ? (eventsnb-1) : 0;

    var batch = this.afs.firestore.batch();

    var tourRef         = this.tourCollection.doc(tourkey).ref;    
    var eventRef        = this.eventCollection.doc(eventkey).ref;
    var tourEventRef    = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;    

    batch.delete(tourEventRef); 
    batch.update(tourRef, {['countevents'] : countevents});
    batch.update(eventRef, {['tourkey'] :firebase.default.firestore.FieldValue.delete()});
    batch.update(eventRef, {['tourtitle'] :firebase.default.firestore.FieldValue.delete()});    

    if(artistkey) {
      var eventArtistRef  = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;
      batch.update(eventArtistRef, {['tourtitle'] :firebase.default.firestore.FieldValue.delete()});    
      batch.update(eventArtistRef, {['tourkey'] :firebase.default.firestore.FieldValue.delete()});
    }

    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'removeTourFromEvent',tourkey); 
  }







  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }






}
