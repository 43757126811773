import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../../core/admin.guards';
import { ComposerDetailComponent } from '../composer-detail/composer-detail.component';
import { ComposerListComponent } from '../composer-list/composer-list.component';
import { ComposerFormComponent } from '../composer-form/composer-form.component';
import { CompositionListComponent } from '../composition-list/composition-list.component';


const routes: Routes = [
    { path: 'composer/:id', component: ComposerDetailComponent, canActivate: [AdminGuard] },  
    { path: 'composers', component: ComposerListComponent, canActivate: [AdminGuard] },
    { path: 'composerform', component: ComposerFormComponent, canActivate: [AdminGuard] },
    { path: 'compositions', component: CompositionListComponent, canActivate: [AdminGuard] },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompositionRoutingModule { }


