import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from  '@angular/material/select';
import { MatPaginatorModule } from  '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';

import {MatTabsModule} from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  imports: [
  	MatAutocompleteModule,
  	MatBadgeModule,
		MatButtonModule, 
		MatCardModule, 
		MatCheckboxModule, 
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatTableModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTableModule,
		MatToolbarModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatBottomSheetModule,

		MatButtonToggleModule,

		MatDividerModule,
		MatProgressBarModule,
		MatRippleModule,
		MatSidenavModule,
		MatSliderModule,

		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTabsModule,
		MatTreeModule,
  ],
  exports: [
  	MatAutocompleteModule,
  	MatBadgeModule,
  	MatButtonModule, 
		MatCardModule, 
		MatCheckboxModule, 
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatTableModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSelectModule,
		MatSlideToggleModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatToolbarModule,
	MatTooltipModule,
	MatButtonToggleModule,

	MatDividerModule,
	MatProgressBarModule,
	MatRippleModule,
	MatSidenavModule,
	MatSliderModule,

	MatSnackBarModule,
	MatSortModule,
	MatStepperModule,
	MatTabsModule,
	MatTreeModule,
	MatPaginatorModule
	],
  declarations: [],
})
export class AngularMaterialModule { }