<!-- <pre>{{ venues | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<div *ngIf="addEvent" class="noshadow">

	<h1>Create New Event</h1>

	<div [ngStyle]="{'padding':'50px 10px'}">
		<form #newPrestaForm="ngForm" (ngSubmit)="onSubmit(newPrestaForm)">

		   <mat-radio-group color="warn" required name="gender" ngModel required #gender="ngModel" >
				<mat-radio-button color="warn" value="Ballet">Ballet</mat-radio-button>
				<mat-radio-button color="warn" value="Chamber">Chamber Music</mat-radio-button>
				<mat-radio-button color="warn" value="Concerto">Concerto</mat-radio-button>
				<mat-radio-button color="warn" value="Opera Staged">Opera Staged</mat-radio-button>
				<mat-radio-button color="warn" value="Opera Concert">Opera Concert</mat-radio-button>
				<mat-radio-button color="warn" value="Recital">Recital</mat-radio-button>
				<mat-radio-button color="warn" value="Symphonic">Symphonic</mat-radio-button>
				<mat-radio-button color="warn" value="Vocals">Vocals</mat-radio-button>
			</mat-radio-group>

			<mat-form-field class="full-width">
				<mat-label>Title</mat-label>
				<input matInput required
					ngModel #newEventTitle="ngModel"
					name="newEventTitle">
			</mat-form-field>									

			<mat-form-field class="full-width">
				<mat-label>Choose a date for the event</mat-label>
			  <input matInput readonly placeholder="Possible Date" required
			  	[matDatepicker]="datepicker"
			  	ngModel #newEventDate="ngModel"
			  	name="newEventDate">
			  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
			  <mat-datepicker #datepicker></mat-datepicker>
			</mat-form-field>

			<h3>Comments</h3>
			<mat-form-field class="full-width">
				<mat-label>Write comment or notes about this event : Date Options, Venue, ...</mat-label>
				<textarea matInput class="full-width"
									rows="10" 
									name="newEventComment"
									ngModel #newEventComment="ngModel"></textarea>
			</mat-form-field>
			<!-- deb -->
			<h3>Add Kajimoto Artist</h3>
						<div>
							<mat-form-field class="full-width">
								<mat-label>Select Artist</mat-label>
							  <mat-select class="full-width"  name="artist" ngModel required #artist="ngModel">
								<mat-option *ngFor="let newartist of artists | async" 
									[value]="newartist">
									{{ newartist.firstname }} {{ newartist.lastname }}
								</mat-option>
							  </mat-select>						  
							</mat-form-field>								
						</div>
			<!-- fin -->

			<br>		
			<button mat-fab (click)="goBack()" type="button" color="black">
				<mat-icon>keyboard_arrow_left</mat-icon>
			</button>
			<button mat-fab type="submit" color="warn"
				*ngIf="saveEventButton"
				[disabled]="!newPrestaForm.form.valid">
				<mat-icon>save</mat-icon>
			</button>			
		</form>		
	</div>
</div>






<mat-card appearance="outlined" *ngIf="addEvents" class="noshadow">
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>event_note</mat-icon>
		</div>			
		<mat-card-title>New Events</mat-card-title>
		<mat-card-subtitle>Import Events in CSV</mat-card-subtitle>
		<button mat-fab (click)="addEvent=true;addEvents=false" type="button" color="black">
			<mat-icon>add</mat-icon>
		</button>			
	</mat-card-header>

	<mat-card-content *ngIf="artists | async; let artists">
		<mat-form-field class="full-width">
			<textarea matInput
								rows="10" 
								placeholder="Copy-paste your list of events : One Event per line : 
											      // Artist
											      // Date  
											      // Heure  
											      // genre
											      // producer  
											      // artistorchestre  
											      // partenaire-soloist 
											      // origine   
											      // Lieu
											      // Lien  
											      // Manager Kajimoto"
								name="newEvents"
								ngModel #newEvents="ngModel"></textarea>
		</mat-form-field>

		<button *ngIf="venues | async; let venues" mat-fab color="warn" 
						(click)="importEvents(newEvents,artists,venues)">
			<mat-icon>file_upload</mat-icon>
		</button>		
		<button mat-fab routerLink="./../events" type="button" color="black">
			<mat-icon>list</mat-icon>
		</button>				
	</mat-card-content>

</mat-card>





<!-- <event-list></event-list> -->













