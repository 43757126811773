import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Query } from '@firebase/firestore-types'


import { NewsService } from './../shared/news.service';



export interface News {
  text:           string;
  artistkey:      string;
  artistfullname: string;
  content:        string;
  homeposition:   string;
  hometype:       string;
  link:           string;
  title:          string;
}


@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  // newsList : AngularFirestoreCollection<any>;

  newslist$            : Observable<any[]>;
  hpFilter$            : BehaviorSubject<string|null>;
  artistFilter$        : BehaviorSubject<string|null>;

  constructor(
    private afs: AngularFirestore,
  	private newsService: NewsService) 
  {

    this.hpFilter$     = new BehaviorSubject(null);
    this.artistFilter$ = new BehaviorSubject(null);

    this.newslist$ = this.newsService.getNewsList();

    // this.newslist$ = combineLatest(
    //     this.hpFilter$,
    //     this.artistFilter$
    // ).pipe(switchMap(([artist,hppos]) => this.afs.collection('news', ref => 
    //     { 
    //       let query: Query = ref;
    //       if(hppos) { console.log(hppos);query = query.where('homeposition', '==', hppos )};
    //       if(artist){ query = query.where('artistkey', '==', artist)};
    //       return query;
    //     }).snapshotChanges().map(arr => 
    //         { return arr.map(snap => Object.assign(snap.payload.doc.data(), 
    //           { 
    //               $key        : snap.payload.doc.id,
    //               // ishomenews  : snap.payload.doc.data().hometype ? 1 : 0,
    //               // date        : snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null 
    //           }))
    //         }
    //  )))

  }

  ngOnInit() {

    // this.newslist$ = this.hpFilter$
    // .pipe(
    //   switchMap(type => 
    //     this.afs.collection('news', ref => 
    //     ref.where('homeposition', '==', type ))
    //     .snapshotChanges().map(arr => 
    //       { return arr.map(snap => Object.assign(snap.payload.doc.data(), { 
    //             $key        : snap.payload.doc.id,
    //             ishomenews  : snap.payload.doc.data().hometype ? 1 : 0,
    //             date        : snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null 
    //       }
    // ))})))

  }



  deleteNews(news) {
  	this.newsService.deleteNews(news);
  }

  updateField(news,field,value) {
    this.newsService.updateNewsField(news,field,value);
  }

  filterNews(type: string|null) {
    this.hpFilter$.next(type);
  }

  filterByArtist(artist: string|null) {
    console.log(artist);
    this.artistFilter$.next(artist); 
  }
  filterByHp(hppos: string|null) {
    // console.log(hppos);    
    this.hpFilter$.next(hppos); 
  }


}
