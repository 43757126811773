import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemberService } from '../../members/shared/member.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-user-all',
  templateUrl: './user-all.component.html',
  styleUrls: ['./user-all.component.scss']
})
export class UserAllComponent implements OnInit {

  users: Observable<any[]>;
  pending: Observable<any[]>;
  showUsers = true;
  showPending = false;

  constructor(public auth: AuthService,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.users = this.auth.getUsersList();
    this.pending = this.auth.getUsersPendingList();
  }

  updateField(user, value) {
    this.auth.changeRole(user, value);
  }

  showAllPending() {
      this.showUsers = false;
      this.showPending = true;
  }

  openDialog(user: any): void {
    const dialogRef = this.dialog.open( BindUserMemberComponent, {
      width: '400px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'filterEventsDialogPanel',
      data: {
          user: user
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showUsers = true;
      this.showPending = false;
    });
  }

  openUserDialog(user: any): void {
    const dialogRef = this.dialog.open( UserDialogComponent, {
      width: '400px',

     // backdropClass: 'filterEventsDialog',
     // panelClass: 'filterEventsDialogPanel',
      data: {
          user: user
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.showUsers = true;
      // this.showPending = false;
    });
  }

  openDialogWithBindUserMember(user: any): void {
    const dialogRef = this.dialog.open( CreateAndBindUserMemberComponent, {
      width: '600px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'filterEventsDialogPanel',
      data: {
          user: user
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showUsers = true;
      this.showPending = false;
    });
  }

}




@Component({
  selector: 'app-bind-user-member',
  templateUrl: 'bind-user-member.html',
  styleUrls: ['./bind-user-member.scss']
})
export class BindUserMemberComponent implements OnInit {

  artistsList: any;
  agentsList: any;
  agentData: any;
  user: any;

  constructor(
    private ms: MemberService,
    private router: Router,
    private auth: AuthService,
    public dialogRef: MatDialogRef<BindUserMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // this.artistsList = this.ms.getMembersIsNotUserList();
      this.agentsList = this.ms.getAgentsIsNotUserList();
      this.user = this.data.user ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  bindUserAndMember(member) {
     this.ms.bindUserMember(member, this.user);
      this.onNoClick();
  }

}


@Component({
  selector: 'app-user-dialog',
  templateUrl: 'user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  artistsList: any;
  agentsList: any;
  agentData: any;
  user: any;
  memberkey: any;
  artists: Observable<{ $key: string; }[]>;
  member: Observable<any>;

  constructor(
    private ms: MemberService,
    private router: Router,
    private auth: AuthService,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // this.artistsList = this.ms.getMembersIsNotUserList();
      this.agentsList = this.ms.getAgentsIsNotUserList();
      this.memberkey = this.data.user.memberkey ;
      this.user = this.data.user ;
      this.artists = this.ms.getArtistInAgentList(this.memberkey);
      this.member = this.ms.getMemberWithKey(this.memberkey);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  removeUserToUserList(member, artist) {
    this.ms.removeUser(member, artist);
     if (member.artistkeys.length === 0) {
        this.onNoClick();
    }
  }

  unbindUser(member, artist) {
      this.ms.unbindUser(member, artist);
      this.onNoClick();
  }
}


@Component({
  selector: 'app-create-bind-user-member',
  templateUrl: 'create-bind-member.html',
  styleUrls: ['./create-bind-member.scss']
})
export class CreateAndBindUserMemberComponent implements OnInit {

  artistsList: any;
  agentsList: any;
  agentData: any;
  user: any;

  roles = [
    {$key: 1, key: 1, title: 'Artist'},
    {$key: 3, key: 3, title: 'Agent'}
  ];

  constructor(
    private ms: MemberService,
    private router: Router,
    private auth: AuthService,
    public dialogRef: MatDialogRef<BindUserMemberComponent>,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // this.artistsList = this.ms.getMembersIsNotUserList();
      this.agentsList = this.ms.getAgentsIsNotUserList();
      this.user = this.data.user ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  bindUserAndMember(member) {
     this.ms.bindUserMember(member, this.user);
      this.onNoClick();
  }

  onSubmit(newMemberForm: NgForm) {
      const memberkey = this.ms.createMember(newMemberForm, true);
      const firstname = newMemberForm.value.newMemberfirstname ;
      const lastname  = newMemberForm.value.newMemberlastname ;
      const role      = newMemberForm.value.pickedMemberRole.title ;
      const member = {
        $key      : memberkey,
        firstname : firstname,
        lastname  : lastname,
        role      : role
      };
       this.bindUserAndMember(member);
      console.log(member);
  }

  goBack(): void {
    this.location.back();
  }

}
