<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<mat-card appearance="outlined" *ngIf="addComposers">
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>person_add</mat-icon>
		</div>			
		<mat-card-title>Add Composers</mat-card-title>
		<mat-card-subtitle>Copy/Paste Composers to import them in Kajimotomusic Database</mat-card-subtitle>		
	</mat-card-header>

	<mat-card-content>
		<mat-form-field class="example-full-width">
			<textarea matInput
								rows="10" 
								placeholder="Copy-paste your list of composers : firstname	lastname : One Composer per line"
								name="newComposers"
								ngModel #newComposers="ngModel"></textarea>
		</mat-form-field>
		<!-- <button mat-fab color="warn" (click)="importComposers(newComposers)">
			<mat-icon>file_upload</mat-icon>
		</button>		 -->
		<button mat-fab routerLink="./../../composer$/composers" type="button" color="black">
			<mat-icon>search</mat-icon>
		</button>		
		<button mat-fab (click)="addComposer=true;addComposers=false" type="button" color="black">
			<mat-icon>person_add</mat-icon>
		</button>			
	</mat-card-content>

</mat-card>


<mat-card appearance="outlined noshadow" *ngIf="addComposer">
	<mat-card-header style="padding-top: 35px">
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>person_add</mat-icon>
		</div>			
		<mat-card-title>Add Composer</mat-card-title>
		<mat-card-subtitle>Add Composer in Kajimotomusic Database</mat-card-subtitle>		
	</mat-card-header>

	<mat-card-content>
	<form #newComposerForm="ngForm" (ngSubmit)="onSubmit(newComposerForm)">
		<mat-form-field>
			<mat-label>First name</mat-label>
			<input matInput
				required
				ngModel #newComposerfirstname="ngModel"
				name="newComposerfirstname">
		</mat-form-field>
		<mat-form-field>
			<mat-label> Last name </mat-label>
			<input matInput
				required
				ngModel #newComposerlastname="ngModel"
				name="newComposerlastname">
		</mat-form-field>
		<button mat-fab type="submit" color="warn"
			[disabled]="!newComposerForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>
		<button mat-fab (click)="addComposers=true;addComposer=false;" 
											type="button" color="black">
			<mat-icon>group_add</mat-icon>
		</button>		
		<button mat-fab routerLink="./../../composer$/composers" type="button" color="black">
			<mat-icon>search</mat-icon>
		</button>				
	</form>
	</mat-card-content>
</mat-card>
