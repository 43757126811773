
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventService } from './../shared/event.service';
import { MemberService } from './../../members/shared/member.service';

import { ComposerService } from './../../composers/shared/composer.service';
import { BehaviorSubject } from 'rxjs';
import { combineLatest } from 'rxjs';
// import {ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

// import { InfiniteScrollModule } from 'ngx-infinite-scroll';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  events      : Observable<any[]>;
  artists     : Observable<any[]>;
  artistFullName:any;
  testevents  : any;
  key         : string;
  event$      : Observable<any[]>;
  genres      : any;
  d           : any;
  eventlist: Event[];
  pageSizeOptions: number[] = [5, 10, 25, 100];

  displayedColumns: string[] = ['title', 'date', 'artist lastname', 'Edit', 'Delete'];
  dataSource = new MatTableDataSource<Event>();
  // dataSource: MatTableDataSource<Employee> ;


  constructor(
              private location: Location,
              private eventService: EventService,
              public dialog: MatDialog) { 
  
    // this.page.init('events', 'date', { reverse: false, prepend: false }); 
    // if (this.key) {
    //   if(this.key.length === 20){
    //     this.event$ = this.eventService.getEventsWithMemberKey(this.key);
    //   }else{
    //     this.event$ = this.eventService.getGenre(this.key);
    //   }
    // }else{
      // this.event$ = this.eventService.getEventsList();
      this.event$ = this.eventService.getEventsListWithNoArtistkey();
      // this.event$ = this.page.data; 
   // }

  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  ngOnInit() {
    this.eventService.eventCollection.get().subscribe(item => {
     this.eventlist = [];
     console.log(item)
     item.forEach((element: any) => {
       const y = element.data();
       y['date'] = element.data().date ? element.data().date.toDate() : null;
       y['$key'] = element.id;
       this.eventlist.push( y as Event);
     });
      // console.log(this.eventlist);
      this.dataSource = new MatTableDataSource(this.eventlist) ;
      this.dataSource.paginator = this.paginator;
   });
 }

  // scrollHandler(e) {
  //   if (e === 'bottom') {
  //     this.page.more();
  //   }
  // }

  getArtistsEvent(eventkey: string) {
    this.artists = this.eventService.getArtistsEvent(eventkey);
    if(this.artists) return true;
  }

  // onScroll() {
  //   console.log('scrolled!!');
  // }
  
  deleteEvent(event) {
    this.eventService.deleteEventWithNoArtist(event);
  }

  goBack(): void {
    this.location.back();
  }

  clearFilter() {
    this.event$ = this.eventService.getPublicEventsList();
    this.key = null;
    this.artistFullName = null;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogFilterEvents, {
      width: '550px',
      data: {
          key: 'key'
       }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.key = result.$key; 
      this.artistFullName = `${result.firstname} ${result.lastname}`;

      console.log(this.key,this.artistFullName);

      if (this.key.length === 20) {
        this.event$ = this.eventService.getOnlineEventsWithMemberKeyAfterToday(this.key);
      }
      else{
        this.event$ = this.eventService.getGenre(this.key);
      }
     console.log(result.length);
    });
  }

  openDuplicateEventDialog(event): void {
    const dialogRef = this.dialog.open(DialogDuplicateEvents, {
      width: '400px',
      data: {
          key: event.$key
       }
    });

    dialogRef.afterClosed().subscribe(result => {

    console.log('hello');
    });
  }



}











@Component({
  selector: 'dialog-filter-events',
  templateUrl: 'dialog-filter-events.html',
  styleUrls: ['./dialog-filter-events.scss']  
})
export class DialogFilterEvents implements OnInit {

  allartists  : Observable<any[]>;
  genres : any;
  isgenre:boolean = false;

  constructor(
    private ms: MemberService,
    private router: Router,
    public dialogRef: MatDialogRef<DialogFilterEvents>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.allartists = this.ms.getArtistsList();
    this.genres = ['Ballet','Chambermusic','Concerto','Opera','Operaconcert',
                 'Operastaged','Recital','Symphonic','Vocal'];
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  getKey(artist) {
    console.log(artist.$key);
  }

  getGenre() {
    // console.log(this.genres);
    this.isgenre = true;
    return this.genres;
  }
 
  ngOnInit() {
  }
}




@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-duplicate-events',
  templateUrl: 'dialog-duplicate-events.html',
  styleUrls: ['./dialog-duplicate-events.scss']  
})
export class DialogDuplicateEvents implements OnInit {
  eventKey: any;
  showYesButton: boolean=true;
  event: Observable<any>;
  artists: Observable<({ [field: string]: any; } & { $key: string; })[]>;
  programs: Observable<({ [field: string]: any; } & { $key: string; })[]>;


  constructor(
    private ms: MemberService,
    private router: Router,
    private eventService: EventService,
    public dialogRef: MatDialogRef<DialogDuplicateEvents>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.eventKey = data.key;

  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  duplicateEvent(event,artist,program){
    this.onNoClick();
    this.eventService.createEvent(event,artist,program);
  }

 
  ngOnInit() {
     this.event = this.eventService.getEventWithKey(this.eventKey);
     this.artists =  this.eventService.getArtistsEvent(this.eventKey);
     this.programs = this.eventService.getProgramWithEventKey(this.eventKey);
  }
}