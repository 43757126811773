<div *ngIf="artists | async; let artists" class="artist-event">
 	<span *ngFor="let artist of artists" class="span-list">
		<span [routerLink]="['./../artist', artist.$key]" class="artist-name" *ngIf="!key && !profil" >
			{{ artist.lastname }} {{ artist.firstname }}
		</span>
		<span [routerLink]="['./../../../../../artist', artist.$key]" class="artist-name" *ngIf="profil && !key">
			{{ artist.lastname }} {{ artist.firstname }}
		</span>
		<span [routerLink]="['./../../artist', artist.$key]" class="artist-name" *ngIf="key && !key2">
			{{ artist.lastname }} {{ artist.firstname }}
		</span>
		<span [routerLink]="['./../../../artist', artist.$key]" class="artist-name" *ngIf="key2">
			{{ artist.lastname }} {{ artist.firstname }}
		</span>
	</span>
</div>