import { Router } from '@angular/router';
import { PublicService } from './../../public/shared/public.service';
import { Component, OnInit } from '@angular/core';

// import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';

import { Observable, of } from 'rxjs';
import { switchMap, map, startWith } from 'rxjs/operators';

import { NgForm } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { ComposerService } from './../shared/composer.service';



@Component({
  selector: 'composer-list',
  templateUrl: './composer-list.component.html',
  styleUrls: ['./composer-list.component.scss']
})

export class ComposerListComponent implements OnInit {

addComposer = false;
addComposers = false;
loadingData = false;
searchComposer = true;
composerList = false;
  dev: boolean = false;
  showSearchLoader : boolean= false;

  composerslist: Observable<any[]>;
composers: Observable<any[]>;
composerCtrl: UntypedFormControl = new UntypedFormControl();
filteredComposers: Observable<any[]>;

  constructor(
  // private db: AngularFireDatabase,
    private router: Router,
    private composerService: ComposerService,
    private ps: PublicService
    ) {
  }


  filterMembers(val: string) {
    return this.composers
      .pipe(map(response => response.filter(composer => {
        this.showSearchLoader = false
        return (composer.lastname.toLowerCase().indexOf(val.toLowerCase()) === 0) ||
               (composer.firstname.toLowerCase().indexOf(val.toLowerCase()) === 0);
      })));
  }


  displayFn(composer) {
    if (composer) {
      var fullname = `${composer.firstname} ${composer.lastname}`;
    } else { var fullname = ''; }
    return composer ? fullname : composer;
  }


  ngOnInit() {


    this.composers = this.composerService.getComposersList();


    this.filteredComposers = this.composerCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(compo => {
        if (compo) {
          this.showSearchLoader = true;
          if (compo.timestamp) {
            console.log('Goto Composer Page');
            var key = compo.$key;
            this.router.navigate(['/composer$/composer/' + key]);
            return this.composers;
          } else { return this.filterMembers(compo); }
        } else {
          // do something better here :P
          this.showSearchLoader = false;
          console.log('Do not get this case');
          return this.filterMembers('something');
        }
      }));
  }




  onSubmit(newComposerForm: NgForm) {
  this.composerService.createComposer(newComposerForm);
  this.addComposer = false;
  }

  deleteComposer(composer) {
  this.composerService.deleteComposer(composer);
  }




}
