import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PressFormComponent } from '../press-form/press-form.component';
import { AdminGuard } from '../../core/admin.guards';
import { PressDetailComponent } from '../press-detail/press-detail.component';
import { PressListComponent } from '../press-list/press-list.component';


const routes: Routes = [
    { path: 'press/:id', component: PressFormComponent, canActivate: [AdminGuard] },  
    { path: 'pressdetail/:id', component: PressDetailComponent, canActivate: [AdminGuard] },  
    { path: 'presslist', component: PressListComponent, canActivate: [AdminGuard] },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PressRoutingModule { }


