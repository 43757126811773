
<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<mat-card appearance="outlined" class="noshadow" *ngIf="(auth.user | async)?.role == 'admin'; else prohibit" >
	<mat-card-header>
    <mat-card-title><strong>Kajimotomusic</strong> Users</mat-card-title>
		
		<button mat-fab matBadgePosition="before" *ngIf="pending | async;let pending " [matBadge]=pending.length (click)="showAllPending()" color="warn">
				<mat-icon>notifications_active</mat-icon>
		</button>
	</mat-card-header>

	<mat-card-content *ngIf="users | async; let users">
		<mat-list dense *ngIf="showUsers">
				<span>Users : {{ users.length }}</span><br><br>
		  <mat-list-item *ngFor="let user of users; let i = index">
          <p>
            <img class="card-img-top" [src]="user.photoURL || 'https://api.adorable.io/avatars/109/fire.png'" width="50px">
          </p>
					<p matLine> 
						<span><strong>{{user.displayName}}</strong></span><br>
            <span>{{user.email}}</span><br>
						<span><strong>{{user.role}}</strong></span><br>
						<span><strong>{{user.memberfullname}}</strong></span>
						<!-- <app-users-log [userkey]="user.uid"></app-users-log> -->
					</p>
				

					<span [ngSwitch]="user.online">
					 <p matLine> 
							<span *ngSwitchCase="true" [ngClass]="{'online':user.online}">
							</span>
							<span *ngSwitchDefault [ngClass]="{'offline':!user.online}"> 
							</span>
						</p>
					</span>

					<mat-radio-group class="office-radio" color="warn"  matLine
						[(ngModel)]="user.role"
						(ngModelChange)="updateField(user,user.role)">
					  <mat-radio-button value="admin" color="warn">&nbsp;admin&nbsp;&nbsp;</mat-radio-button>
					  <mat-radio-button value="artist" color="warn">&nbsp;artist&nbsp;&nbsp;</mat-radio-button>
						<mat-radio-button value="public" color="warn">&nbsp;public&nbsp;&nbsp;</mat-radio-button>
						<mat-radio-button value="agent" color="warn">&nbsp;agent&nbsp;&nbsp;</mat-radio-button>
						<!-- <mat-radio-button value="prohibit" color="warn">prohibit</mat-radio-button> -->
					</mat-radio-group>

					<button mat-icon-button color="warn" *ngIf="user.role !== 'admin'" 
						(click) = 'openUserDialog(user)'>
		        <mat-icon>remove_circle</mat-icon>
					</button> 
		  	
		  </mat-list-item>
		</mat-list>
	</mat-card-content>

	<!-- for pending's users -->
	<mat-card-content *ngIf="pending | async; let users">
			<mat-list dense *ngIf="showPending"  >
				<mat-list-item *ngFor="let user of users; let i = index">
				 <!-- [routerLink]="['./../member', member.$key]">  -->
					<p>
							<img 	class="card-img-top" [src]="user.photoURL || 'https://api.adorable.io/avatars/109/fire.png'" width=50px>
						</p>
						<p matLine> 
							<span><strong>{{user.displayName}}</strong></span><br>
							<span>{{user.email}}</span><br>
							<span><strong>{{user.role}}</strong></span>
							<mat-chip-listbox >
									<mat-chip-option color="primary">
											<strong>Bind</strong>
											<mat-icon matChipRemove
											(click)="openDialog(user)">add_circle</mat-icon>
									</mat-chip-option>

									<mat-chip-option color="primary">
										<strong>Create Member And Bind</strong>
										<mat-icon matChipRemove
										(click)=" openDialogWithBindUserMember(user)">add_circle</mat-icon>
								</mat-chip-option>
							</mat-chip-listbox>
						</p>

						<button mat-icon-button color="warn" 
						(click) = 'removeUserToDataBase(user)'>
						<mat-icon>remove_circle</mat-icon>
					</button> 

				</mat-list-item>
			</mat-list>
	</mat-card-content>
</mat-card> 

<ng-template #prohibit>
		<h2> Acces denied</h2>
</ng-template>