import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../angularmaterial.module';

import { NavService } from './nav.service';

import { EventListComponent } from './../../events/event-list/event-list.component';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { UserFormComponent } from '../user-form/user-form.component';
import { TopNavComponent, MoteurRechercheComponent } from '../top-nav/top-nav.component';
import { ReadmePageComponent } from '../readme-page/readme-page.component';
import { UserAllComponent, BindUserMemberComponent, UserDialogComponent, CreateAndBindUserMemberComponent } from './../user-all/user-all.component';
import { SendEmailComponent } from './../send-email/send-email.component';
import { UserDashboardComponent } from '../user-dashboard/user-dashboard.component';
import { PageManagementComponent } from './../page-management/page-management.component';
import { UserLoginComponent, DialogSignupComponent } from '../user-login/user-login.component';
import { UsersLogComponent } from '../users-log/users-log.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
    ],
    declarations: [
        DashboardComponent,
        UsersLogComponent,
        UserLoginComponent,
        UserProfileComponent,
        CreateAndBindUserMemberComponent,
        TopNavComponent,
        UserFormComponent,
        ReadmePageComponent,
        MoteurRechercheComponent,
        UserAllComponent,
        DialogSignupComponent,
        BindUserMemberComponent,
        UserDialogComponent,
        UserDashboardComponent,
        SendEmailComponent,
        PageManagementComponent
    ],
    exports: [
        TopNavComponent,
        UserProfileComponent,
    ]
})
export class UiModule { }
