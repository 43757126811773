import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {

endpoint = 'https://us-central1-kajimotomusic-prod.cloudfunctions.net/httpEmail';

  constructor(private http:  HttpClient) { }

  ngOnInit() {
  }

  sendEmail() {

    console.log('Sending Mail ...');

    const data = {
      toEmail: 'cbanzet@gmail.com',
      toName: 'Jeff Delaney'
    };
    this.http.post(this.endpoint, data).subscribe();
  }

}
