import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { switchMap, startWith, map } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';

import { EventService } from './../shared/event.service';
import { VenueService } from './../../venue/shared/venue.service';
import { MemberService } from './../../members/shared/member.service';


@Component({
  selector: 'app-event-venue',
  templateUrl: './event-venue.component.html',
  styleUrls: ['./event-venue.component.scss']
})
export class EventVenueComponent implements OnInit {

	eventkey:any;
  eventview: any;
  artists:any;
	venues:Observable<any[]>;
  filteredVenues:Observable<any[]>;
  selectedVenue:Observable<any[]>;
	venueCtrl: UntypedFormControl = new UntypedFormControl();
	
	searchVenueForm        :boolean=true;
	addVenueForm           :boolean=false;
	showSearchVenuesField  :boolean=true;
  showSearchLoader       :boolean=false

  isVenueSelected : boolean=false;
  selectedvenue: any;
  artistKey: any ;
  isArtistId : boolean = true;

  constructor(
    private venueService: VenueService,  
    private memberService:MemberService,	
    private eventService: EventService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private location: Location) { 
    	this.venues   = this.venueService.getVenuesList();
  }


  filterVenues(val: string) {

    console.log('filterVenues val',val);

    // return this.venues
      // .map(response => response.filter(venue => { 
        // return venue.name.toLowerCase().indexOf(val.toLowerCase()) === 0
      // }));
    return this.venues
      .pipe(map(response => response.filter(venue => { 
        const spacePosition = venue.name.toLowerCase().indexOf(' ') + 1 ;
        const venueNamePosition = venue.name.toLowerCase().indexOf(val.toLowerCase());
        const commaPosition = venue.name.toLowerCase().indexOf(val.toLowerCase()) + 1;
        this.showSearchLoader=false;

          // tslint:disable-next-line:max-line-length
          return venueNamePosition === 0 || venueNamePosition === spacePosition || commaPosition ||  venueNamePosition === spacePosition;
      })));
  }

  displayFn(venue) {
    if(venue) { var venuefullname = `${venue.name} ${venue.address}`;}
    else var venuefullname = '';
    return venue ? venuefullname : venue;
  } 

  ngOnInit() {

    this.route.params.subscribe((params: Params) => { this.eventkey = params['id'];} ); 

    this.route.params.subscribe((params: Params) => {
      this.artistKey = params['key'];
     });

		this.eventview = this.route.paramMap.pipe(switchMap((params: ParamMap)=>
  			this.eventService.getEventWithKey(params.get('id'))));

    // this.artists  = this.memberService.getArtistsWithEventKey(this.eventkey);


    this.filteredVenues = this.venueCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(venue => {
        console.log('venue',venue);
        if(venue) {
          this.showSearchLoader=true;
          if(venue.timestamp) 
          {
            var key = venue.$key;
            var venuename = venue.name;
            // UPDATE PLACE IN EVENT
            this.selectedvenue = venue;
            this.isVenueSelected = true;
            return this.venues
          }
          else { 
            return this.filterVenues(venue);
          } 
        }
    		else 
    		{
          this.showSearchLoader=false;
          // do something better here :P
          return this.filterVenues('something');
      	}
      }))
  }

  addVenueToEvent(event,venue) {
    // this.isVenueSelected = false;
    if (this.isArtistId) {
      this.isArtistId = false;
      this.eventService.addVenueToEvent(event,venue, this.artistKey);
    }
     
  }

  onSubmit(newVenueForm: NgForm) {
    this.venueService.createVenueFromAddVenueToEventForm(newVenueForm);
    this.addVenueForm = false;
    this.searchVenueForm = true;
  }  


}
