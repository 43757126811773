import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ComposerService } from './../shared/composer.service';
import { PublicService } from '../../public/shared/public.service';


@Component({
  selector: 'composer-form',
  templateUrl: './composer-form.component.html',
  styleUrls: ['./composer-form.component.scss']
})
export class ComposerFormComponent implements OnInit {

	addComposer = true;
	addComposers = false;

  constructor(
    private router: Router,
    private composerService: ComposerService,
    private ps: PublicService) { }

  ngOnInit() {
  }


  onSubmit(newComposerForm: NgForm) {
  	const composerkey = this.composerService.createComposer(newComposerForm);
    if(composerkey) {     
      this.router.navigate(['/composer$/composers']);  
    }
  	// this.addComposer = false;
  }  

  // importComposers(composers) {
  	// this.composerService.importComposers(composers);  	
  // }

}
