import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-artists-china',
  templateUrl: './artists-china.component.html',
  styleUrls: ['./artists-china.component.scss']
})
export class ArtistsChinaComponent implements OnInit {

	imglogokey:string; imglogoname:string; imglogourl:string;
	imglogobr:string;

	txtInCh:boolean= false; titleInCh:boolean=false;
	txtInFr:boolean= true; titleInFr:boolean=true;

  page: Observable<any>; 

  constructor(public  auth: AuthService, private ps: PublicService,
              private titleService: Title,
              private meta: Meta) { }

  setTitle( newTitle: string) { this.titleService.setTitle( newTitle ); }

  ngOnInit() {
		this.imglogobr = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2FKajimoto_logo_red-black.jpg?alt=media&token=909a189c-8eb7-463f-bf45-2c2068ecb4e6";
		this.imglogokey = "-L3-ARQZ07dzuZa2xImh";
		this.imglogoname = "logo-kajimoto-aboutus.png";
		this.imglogourl = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Purple-Blue.png?alt=media&token=9435118c-5854-4e0b-88b8-c75cd5df46f4";		  	  	

    this.page = this.ps.getPageVariables('artistschina');    

    this.titleService.setTitle('Kajimoto Music Paris | Artists from China');

    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/artistschina` },
      { name: 'og:title', content: 'Kajimoto Music | China'},
      { name: 'og:description', content: 'Kajimoto Music China presents all its artits.'}, 
      // tslint:disable-next-line:max-line-length
      { name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Purple-Blue.png?alt=media&token=9435118c-5854-4e0b-88b8-c75cd5df46f4`},

      { name: 'description', content: 'Kajimoto Music China presents all its artits.'},
      { name: 'title', content: 'Kajimoto Music | China'},
      { name: 'twitter:card',  content: 'summary' },
      { name: 'twitter:site',  content: 'Kajimoto Music' },
      { name: 'twitter:title', content: 'Kajimoto Music | China' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:description',   content: 'Kajimoto Music China presents all its artits.' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Purple-Blue.png?alt=media&token=9435118c-5854-4e0b-88b8-c75cd5df46f4` },
      { name: 'twitter:url', content: `https://kajimotomusicparis.com/artistschina`},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'msapplication-starturl', content: '/' },
      { name: 'theme-color', content: '#f48c5b' },
      { name : 'keywords',  content: `artists china`},
      { name : 'url', content: `https://kajimotomusicparis.com/artistschina`}
    ]);
  }

  lg(ch,fr) {
  	if(this.txtInCh) return ch;
  	else return fr;
  }

  updatePageField(page,field,value) {
    this.ps.updatePage(page,field,value);      
  }
}
