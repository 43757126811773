import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../../core/admin.guards';
import { VenueListComponent } from '../venue-list/venue-list.component';
import { VenueFormComponent } from '../venue-form/venue-form.component';
import { VenueDetailComponent } from '../venue-detail/venue-detail.component';

const routes: Routes = [
    { path: 'venues', component: VenueListComponent, canActivate: [AdminGuard] },
    { path: 'venueform', component: VenueFormComponent, canActivate: [AdminGuard] },  
    { path: 'venue/:id', component: VenueDetailComponent, canActivate: [AdminGuard] },  
    { path: 'venue/:id/:event', component: VenueDetailComponent, canActivate: [AdminGuard] },  
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VenueRoutingModule { }


