
<div class="fx-col">
	<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">
	<h1>New Member</h1>
	<p class="info-h1">Ajout d'un nouveau membre ou artiste</p>
</div>

<mat-card  [ngStyle]="{'box-shadow': 'none !important'}">
	<br>

	<form #newMemberForm="ngForm" (ngSubmit)="onSubmit(newMemberForm)">
	<mat-card-content>
		<table cellspacing="0" class="full-width max-width-table">
			<tr>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> First name</mat-label>
    				<input matInput required
    					ngModel #newMemberfirstname="ngModel"
    					name="newMemberfirstname">
  				</mat-form-field>
  			</td>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label>Last name </mat-label>
    				<input matInput required
    				ngModel #newMemberlastname="ngModel"
    				name="newMemberlastname">
  				</mat-form-field>
  			</td>
  		</tr>
  		<tr>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label> Email</mat-label>
    				<input matInput type="email"
    				ngModel #newMemberemail="ngModel" 
    				name="newMemberemail">
 	  				</mat-form-field>
  			</td>
  			<td>
  				<mat-form-field class="full-width">
					<mat-label>Phone </mat-label>
    				<input matInput mat-maxlength="3"
    				ngModel name="newMemberphone">
  				</mat-form-field>    				
  			</td>    			
  		</tr>		
  		<tr>
  			<td colspan="2">
					<mat-radio-group required
					ngModel name="pickedMemberRole">
					  <mat-radio-button class="radio-role" color="warn"
					  	*ngFor="let role of memberRole" 
					  	(change)=activateInstrument(role.$key)
					  	[disabled]="role.$key==4"
					  	[value]="role">
					    {{role.title}}
					  </mat-radio-button>
					</mat-radio-group>
  			</td>	  				
  		</tr>
  		<tr *ngIf="showInstrumentList">
        <td colspan="2" class="va-top">
          <br>
          <mat-form-field class="width-100">
			<mat-label> Select Instruments</mat-label>
            <mat-select required
              ngModel #selectedInstrus="ngModel" name="selectedInstrus">
              <!-- [formControl]="instrumentsCtrl" -->
				  <mat-option *ngFor="let instrument of instruments | async" 
				  	[value]="instrument">
						<strong>{{ instrument.name | titlecase }}</strong> 
						| {{ instrument.category | titlecase}}
				</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="width-100">
			<mat-label>Select Agents </mat-label>
            <mat-select multiple required
              ngModel #selectedAgents="ngModel" name="selectedAgents">
              <mat-option *ngFor="let agent of agents | async" 
                [value]="agent">
                {{ agent.firstname }} {{ agent.lastname }}
              </mat-option>
            </mat-select>
          </mat-form-field>
  			</td> 			
  		</tr>
  	</table>
	</mat-card-content>

	<mat-card-actions>

		<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab type="submit" color="warn" class="ml-15"
			[disabled]="!newMemberForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>

	</mat-card-actions>
	</form>
	<br>

</mat-card>