import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { AuthService } from './../../core/auth.service';
import { UploadService } from './../../upload/shared/upload.service';
import { MemberService } from './../shared/member.service';
import { PressService } from './../../press/shared/press.service';
import { NewsService } from './../../news/shared/news.service';
import { EventService } from './../../events/shared/event.service';
import { InstrumentService } from './../../instruments/shared/instrument.service';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent implements OnInit {

  member: Observable<any>;
  role: Observable<any>;
  roles: any;
  instruments: any;
  agents: any;
  events: any;
  artistsAgent: any;
  agentsArtist: any;

  roleMember: Observable<any>;
  instrumentsMember: Observable<any>;
  firstnameField = 'firstname';
  lastnameField = 'lastname';
  emailField = 'email';
  phoneField = 'phone';
  streetField = 'street';
  zipField = 'zip';
  cityField = 'city';
  birthdateField = 'birthdate';
  gmField = 'generalmanagement';
  kmField = 'kajimotomanagement';
  websiteField = 'website';
  biofrfield = 'biofr';
  bioenfield = 'bioen';
  profilepicField = 'profilepic';
  bioFormInput = false;
  noinstrument = false;
  toggleInstrument = false;
  usedToBeAnArtist = true;
  butVisOn = true;
  butVisOff = false;
  butVisPicOn = true;
  butLongVisOn = true;
  butLongVisPicOn = true;
  isBioOpen = false;
  isInfoOpen = false;
  isInstruOpen = false;
  isManagementOpen = false;
  ShowAddInstruBut = true;
  ShowAddAgentBut = true;
  press: any; event: any; news: any;
  media: Observable<({ [field: string]: any; } & { $key: string; })[]>;
  timestamp: any; id: any;
  add = false;
  minus = true;
  seeInstrumentMenuSelect = false;
  seeSecondInstrumentMenuSelect = false;

  constructor(
    public auth: AuthService,
    private uploadService: UploadService,
    private pressService: PressService,
    private newsService: NewsService,
    private instrumentService: InstrumentService,
    private memberService: MemberService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) { }

  ngOnInit() {

    this.instruments = this.instrumentService.getInstruments();

    // GET MEMBER INFO FROM URL PARAM
    this.member    = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.memberService.getArtistWithKey(params.get('id'))));

    this.press     = this.route.paramMap
    .pipe(switchMap((params: ParamMap) =>
      this.memberService.getPressInMember(params.get('id'))));

    this.news      = this.route.paramMap
    .pipe(switchMap((params: ParamMap) =>
      this.memberService.getNewsInMember(params.get('id'))));

    this.events    = this.route.paramMap
    .pipe(switchMap((params: ParamMap) =>
      this.eventService.getEventsWithMemberKey(params.get('id'))));

    this.media     = this.route.paramMap
    .pipe(switchMap((params: ParamMap) =>
      this.memberService.getMediaInMember(params.get('id'))));

    this.id        = this.route.snapshot.paramMap.get('id');
    this.timestamp =  this.memberService.getYearFromMembersEvent(this.id);
  }

  goBack(): void {
    this.location.back();
  }

  updateField(member, field, value, type?) {
    this.memberService.updateMember(member, field, value, type);
    if (type === 'default') { this.seeInstrumentMenuSelect = false; }
    if (type === 'other') { this.seeSecondInstrumentMenuSelect = false; }
  }

  updateNewsField(news, field, value) {
    this.newsService.updateNewsField(news, field, value);
  }



  deleteDisk(disc, memberkey) {
    this.uploadService.deleteDisk(disc, memberkey);
  }

  removeCarouselPic(member, nb, path) {
    this.memberService.removeCarouselPictureWithLegend(member, nb, path);
  }

  changeInstrument(member, value) {
    console.log(member);
    console.log(value);
  }

  removeMedia(media, memberkey) {
    console.log(media, memberkey);
    this.memberService.removeMediaFromArtist(media, memberkey);
  }


  onSubmit(newMediaForm: NgForm) {
    this.memberService.createMember(newMediaForm);
  }

  deleteMember(member) {
    this.memberService.deleteMember(member);
  }

  deletePress(press) {
    this.pressService.deletePress(press);
  }

  deleteEvent(event, artist) {
    this.eventService.removeArtistFromEvent(event, artist);
  }

  deleteInstruInMember(member) {
    this.memberService.removeInstruInMember(member);
    this.minus  = true ;
  }

  cutBioAndKeepOpenAndCloseTags(s, l) {
    let openTag = 0, closeTag = 0, i = 0;
    for (i; i < l; i++) {
      if (s[i] === '<') { openTag++; }
      if (s[i] === '>') { closeTag++; }
    }
    if (openTag > closeTag) {
      while (s[i] !== '>') { i++; }
    }
    return s.substring(0, (i + 1));
  }

  isShortBioEnough(s) {
    if (this.cutBioAndKeepOpenAndCloseTags(s, 4000).length < 4001) { return false; } else { return true; }
  }

  saveBiography(member, biofr?, biolongfr?, bioen?, biolongen?) {
    if (biofr) {
      if (this.cutBioAndKeepOpenAndCloseTags(member.bioshorthtmlfr, 4000).length < 4001) {
        this.memberService.updateMember(member, 'bioshorthtmlfr', member.bioshorthtmlfr);
        console.log('Short Bio Fr Saved');
      } else { console.error('Short Bio Fr Too Long !'); }
    }
    if (bioen) {
      if (this.cutBioAndKeepOpenAndCloseTags(member.bioshorthtmlen, 4000).length < 4001) {
        this.memberService.updateMember(member, 'bioshorthtmlen', member.bioshorthtmlen); }
        console.log('Short Bio En Saved');
      } else { console.error('Short Bio En Too Long !'); }
    if (biolongfr) { this.memberService.updateMember(member, 'biolonghtmlfr', member.biolonghtmlfr); }
    if (biolongen) { this.memberService.updateMember(member, 'biolonghtmlen', member.biolonghtmlen); }
  }

  checkIfArtistManagedByagent(artistkeys: any, agentkey: any): Boolean {
    artistkeys =  artistkeys ? artistkeys : ['no artist'];
    const compteur = artistkeys.indexOf(agentkey);
    if (compteur !== -1) { return true; } else { return false; }
  }

  addInstru() {
    this.add = true;
    this.minus = false;
  }


}
