import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormsModule } from "@angular/forms";

import { ComposerService } from './../shared/composer.service';
import { PublicService } from '../../public/shared/public.service';

@Component({
  selector: 'composer-detail',
  templateUrl: './composer-detail.component.html',
  styleUrls: ['./composer-detail.component.scss']
})
export class ComposerDetailComponent implements OnInit {

	composer: Observable<any>;
	addComposition=false;
  compositions: Observable<any[]>;
  titleField = "title";
  composerkey: string;
  showCompositionList              : boolean= true;
  showClearButton                  : boolean= false;
  showAddButtonAddCompositionsForm : boolean= true;
  showEditComposerButton           : boolean=true;
  showEditForm: boolean= false;
  showListButton: boolean=false;
  id: string;

  constructor(  	
  	private composerService: ComposerService,
  	private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private ps: PublicService) 
  { 
  	// this.compositions = this.composerService.getCompositionsList();
 	}

  ngOnInit() {

    // this.route.params.subscribe((params: Params) => {this.composerkey = params['id'];});
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);

    this.composer = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.composerService.getComposerWithKey(params.get('id'))));

    this.compositions = this.composerService.getCompositionsWithComposerkey(this.id); 
  
  }

  deleteCompositionFromComposer(composerkey,compositionkey) {
    this.composerService.deleteCompositionFromComposer(composerkey,compositionkey);
  }

  deleteComposer(composer) {
    this.composerService.deleteComposer(composer);
    this.router.navigate(['/composer$/composers']);        
  }

  importCompositions(compositions,composer) {
  	this.composerService.importCompositions(compositions,composer);
    this.addComposition      = false;  
    this.showCompositionList = true;	
  }

  updateComposerField(composer,field,value) {
    this.composerService.updateComposerField(composer,field,value);
  }

  // updateComposition(composition,field,value) {
    // this.composerService.updateComposition(composition,field,value);
  // }  

  // updateCompositionFromComposer(composerkey,compositionkey,compositiontitle) {
    // this.composerService.updateCompositionFromComposer(composerkey,compositionkey,compositiontitle);
  // }

  // rebuildComposition(composerkey,compositionkey,compositiontitle) {
    // this.composerService.rebuildCompositionInComposer(composerkey,compositionkey,compositiontitle);
  // }


  goBack(): void {
  	this.location.back();
	}


}
