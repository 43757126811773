import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

// FIREBASE

import { UploadFormComponent } from './../upload-form/upload-form.component';
import { UploadMediaComponent } from './../upload-media/upload-media.component';
import { UploadCarouselComponent } from './../upload-carousel/upload-carousel.component';
import { FileUploadComponent } from './../upload-file/upload-file.component';

import { DropZoneDirective } from './drop-zone.directive';
import { FileSizePipe } from './file-size.pipe';

import { UploadService } from './upload.service';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    AngularMaterialModule
  ],
  declarations: [
	  UploadFormComponent,
    UploadMediaComponent,
    UploadCarouselComponent,
    FileUploadComponent,
    DropZoneDirective,
    FileSizePipe
  ],
  providers: [
  	UploadService,
    AuthService
  ]
})
export class UploadModule { }