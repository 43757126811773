import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../../core/admin.guards';
import { NewsDetailComponent } from '../news-detail/news-detail.component';
import { NewsFormComponent } from '../news-form/news-form.component';
import { NewsListComponent } from '../news-list/news-list.component';


const routes: Routes = [
    { path: 'newsdetail/:id', component: NewsDetailComponent, canActivate: [AdminGuard] },  
    { path: 'newsform', component: NewsFormComponent, canActivate: [AdminGuard] },  
    { path: 'news', component: NewsListComponent, canActivate: [AdminGuard] },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }


