import { Component, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {

yearInput = [ '1950', '1951', '1952', '1953', '1954', '1955', '1956', '1957', '1958', '1959', '1960',
'1961', '1962', '1963', '1964', '1965', '1966', '1967', '1968', '1969', '1970',
'1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980',
'1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990',
'1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000',
'2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010',
'2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020',
'2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030',
'2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040',
'2041', '2042', '2043', '2044', '2045', '2046', '2047', '2048', '2049', '2050',
'2051', '2052', '2053', '2054', '2055', '2056', '2057', '2058', '2059', '2060',
'2061', '2062', '2063', '2064', '2065', '2066', '2067', '2068', '2069', '2070' 
];
addHistoryForm: boolean;
onlyOnce  = true;
txtInEn = true;
imglogourl: string;
dateseeing = '1951';
datenext = '1952'; dateprev: string;
datenextnext = '1955'; dateprevprev: string;
histories: any;
page: any;

  public readonly items: number[] = Array(1000).fill(1).map((item, index) => item + index);


  public ngxScrollToDestination: string;
  lastScrollTop: number = 0;

  constructor(
public  auth: AuthService, private ps: PublicService,
private renderer: Renderer2
  ) { }

  ngOnInit() {
this.imglogourl = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830';
this.histories = this.ps.getHistories();
  }



  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');
  }



  onSubmit(historyForm: NgForm) {
this.ps.createHistory(historyForm);
this.addHistoryForm = !this.addHistoryForm;
  }

  deleteHistory(history) {
this.ps.deleteHistory(history);
  }



public readNext(prevdate, history, nexthistory, nextnexthistory, prevprevhistory) {

const date = history.year;
this.dateseeing = date;

this.dateprev = prevdate;
this.datenext = nexthistory ? nexthistory.year : null;
this.dateprevprev = prevprevhistory ? prevprevhistory.year : null;
this.datenextnext = nextnexthistory ? nextnexthistory.year : null;

// !------ scrolling ---------!
  this.scroll(this.dateprev);
  console.log("scroll :",this.scroll);


// const config: ScrollToConfigOptions = {
// target: this.dateprevprev,
// duration: 300,
// easing: 'easeOutElastic',
// };
// console.log("target:",config.target);
// this._scrollToService.scrollTo(config);

}

public readDate(prevprevhistory, prevhistory, history, nexthistory, nextnexthistory) {

const date = history.year;
this.dateseeing = date;

this.dateprev = prevhistory ? prevhistory.year : null;
this.dateprevprev = prevprevhistory ? prevprevhistory.year : null;
this.datenext = nexthistory ? nexthistory.year : null;
this.datenextnext = nextnexthistory ? nextnexthistory.year : null;

// !------ scrolling ---------!
  this.scroll(this.dateprev);
  console.log("scroll :",this.scroll);

// const config: ScrollToConfigOptions = {
// target: this.dateprevprev,
// duration: 300,
// easing: 'easeOutElastic'
// };
// this._scrollToService.scrollTo(config);

}

insertHistoryInFS(histories) {

if (this.onlyOnce && histories) {
var i = 0;
Object.values(histories).forEach((history: any) => {
i++;
this.ps.insertAllHistory(history, i, histories.length);
});
this.onlyOnce = false;
}

}
 scroll(id){
  const element = document.getElementById(id);

  element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
 }


}
