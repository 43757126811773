import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'events-routing-events-event',
  templateUrl: './events-routing-events-event.component.html',
  styleUrls: ['./events-routing-events-event.component.scss']
})
export class EventsRoutingEventsEventComponent implements OnInit {

	@Input() eventkey: string;

	event:any;

  constructor(private eventService: EventService) { }

  ngOnInit() {
		this.event = this.eventService.getEventWithKey(this.eventkey);  	
  }

  gotoGoogleMapAddress(address) {
    window.open('https://maps.google.com/?q='+address, '_blank');
  }

}
