<button mat-fab color="warn" class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInCh" 
	(click)="
		txtInFr=true;txtInCh=false;
		titleInFr=true;titleInCh=false
		">
  FR
</button>

<button mat-fab color="warn" class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInFr" 
	(click)="
		txtInFr=false;txtInCh=true;
		titleInFr=false;titleInCh=true
		">
  CH
</button>

<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="editpage = !editpage"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!editpage">create</mat-icon>
  <mat-icon *ngIf="editpage">close</mat-icon>
</button>


<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>


<div class="bounds" *ngIf="page | async; let page; else loading">

  <div class="content fx-row fx-end-start fx-col-xs fx-fill">
    <div class="sec1 fx-10 hide-mobile">
    		<h1 *ngIf="txtInFr" class="bluegreen">
    			<span class="purple">A</span>r<span class="purple">t</span>i<span class="purple">s</span>t<span class="purple">e</span>s
    		</h1>        
    </div>
    <div *ngIf="editpage" class="fx-80 fx-row fx-wrap"  [ngStyle]="{'padding':'10px'}">
      <div class="fx-100 fx-row fx-wrap"  [ngStyle]="{'padding':'40px 0 10px'}">
        <mat-form-field class="fx-50">
          <mat-label>Page Title </mat-label>
          <input matInput required
            [(ngModel)]="page.title"
            (ngModelChange)="updatePageField(page,'title',page.title)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Page Title In Chinese </mat-label>
          <input matInput required
            [(ngModel)]="page.titlech"
            (ngModelChange)="updatePageField(page,'titlech',page.titlech)">
        </mat-form-field>        
      </div>

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 1 </mat-label>
          <input matInput required
            [(ngModel)]="page.subtitle1"
            (ngModelChange)="updatePageField(page,'subtitle1',page.subtitle1)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 1 In Chinese </mat-label>
          <input matInput required
            [(ngModel)]="page.subtitlech1"
            (ngModelChange)="updatePageField(page,'subtitlech1',page.subtitlech1)">
        </mat-form-field>        
        <mat-form-field class="fx-50">
          <mat-label>Text 1 Left Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt1a"
            (ngModelChange)="updatePageField(page,'txt1a',page.txt1a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Text 1 Right Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt1b"
            (ngModelChange)="updatePageField(page,'txt1b',page.txt1b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 1 Left Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt1ach"
            (ngModelChange)="updatePageField(page,'txt1ach',page.txt1ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 1 Right Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt1bch"
            (ngModelChange)="updatePageField(page,'txt1bch',page.txt1bch)"></textarea>
        </mat-form-field> 
      </div>  

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 2 </mat-label>
          <input matInput required
            [(ngModel)]="page.subtitle2"
            (ngModelChange)="updatePageField(page,'subtitle2',page.subtitle2)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 2 In Chinese </mat-label>
          <input matInput required
            [(ngModel)]="page.subtitlech2"
            (ngModelChange)="updatePageField(page,'subtitlech2',page.subtitlech2)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Text 2 Left Column</mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt2a"
            (ngModelChange)="updatePageField(page,'txt2a',page.txt2a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Text 2 Right Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt2b"
            (ngModelChange)="updatePageField(page,'txt2b',page.txt2b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 2 Left Column</mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt2ach"
            (ngModelChange)="updatePageField(page,'txt2ach',page.txt2ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 2 Right Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt2bch"
            (ngModelChange)="updatePageField(page,'txt2bch',page.txt2bch)"></textarea>
        </mat-form-field>        
      </div> 

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 3 </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle3"
            (ngModelChange)="updatePageField(page,'subtitle3',page.subtitle3)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Subtitle 3 In Chinese</mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech3"
            (ngModelChange)="updatePageField(page,'subtitlech3',page.subtitlech3)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Text 3 Left Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt3a"
            (ngModelChange)="updatePageField(page,'txt3a',page.txt3a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Text 3 Right Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt3b"
            (ngModelChange)="updatePageField(page,'txt3b',page.txt3b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 3 Left Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt3ach"
            (ngModelChange)="updatePageField(page,'txt3ach',page.txt3ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 3 Right Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt3bch"
            (ngModelChange)="updatePageField(page,'txt3bch',page.txt3bch)"></textarea>
        </mat-form-field>               
      </div> 

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label> Subtitle 4</mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle4"
            (ngModelChange)="updatePageField(page,'subtitle4',page.subtitle4)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 4 In Chinese </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech4"
            (ngModelChange)="updatePageField(page,'subtitlech4',page.subtitlech4)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Text 4 Left Column </mat-label>
          <textarea matInput required rows="3"
            [(ngModel)]="page.txt4a"
            (ngModelChange)="updatePageField(page,'txt4a',page.txt4a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Text 4 Right Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt4b"
            (ngModelChange)="updatePageField(page,'txt4b',page.txt4b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 4 Left Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt4ach"
            (ngModelChange)="updatePageField(page,'txt4ach',page.txt4ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 4 Right Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt4bch"
            (ngModelChange)="updatePageField(page,'txt4bch',page.txt4bch)"></textarea>
        </mat-form-field>               
      </div> 

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 5 </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle5"
            (ngModelChange)="updatePageField(page,'subtitle5',page.subtitle5)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Subtitle 5 In Chinese</mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech5"
            (ngModelChange)="updatePageField(page,'subtitlech5',page.subtitlech5)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Text 5 Left Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt5a"
            (ngModelChange)="updatePageField(page,'txt5a',page.txt5a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Text 5 Right Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt5b"
            (ngModelChange)="updatePageField(page,'txt5b',page.txt5b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 5 Left Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt5ach"
            (ngModelChange)="updatePageField(page,'txt5ach',page.txt5ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 5 Right Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt5bch"
            (ngModelChange)="updatePageField(page,'txt5bch',page.txt5bch)"></textarea>
        </mat-form-field>            
      </div> 

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 6 </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle6"
            (ngModelChange)="updatePageField(page,'subtitle6',page.subtitle6)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Subtitle 6 In Chinese</mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech6"
            (ngModelChange)="updatePageField(page,'subtitlech6',page.subtitlech6)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Text 6 Left Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt6a"
            (ngModelChange)="updatePageField(page,'txt6a',page.txt6a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Text 6 Right Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt6b"
            (ngModelChange)="updatePageField(page,'txt6b',page.txt6b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 6 Left Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt6ach"
            (ngModelChange)="updatePageField(page,'txt6ach',page.txt6ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 6 Right Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt6bch"
            (ngModelChange)="updatePageField(page,'txt6bch',page.txt6bch)"></textarea>
        </mat-form-field>          
      </div>                         

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 7 </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle7"
            (ngModelChange)="updatePageField(page,'subtitle7',page.subtitle7)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Subtitle 7 In Chinese</mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech7"
            (ngModelChange)="updatePageField(page,'subtitlech7',page.subtitlech7)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label> Text 7 Left Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt7a"
            (ngModelChange)="updatePageField(page,'txt7a',page.txt7a)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Text 7 Right Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt7b"
            (ngModelChange)="updatePageField(page,'txt7b',page.txt7b)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label>Chinese Text 7 Left Column </mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt7ach"
            (ngModelChange)="updatePageField(page,'txt7ach',page.txt7ach)"></textarea>
        </mat-form-field> 
        <mat-form-field class="fx-50">
          <mat-label> Chinese Text 7 Right Column</mat-label>
          <textarea matInput   required rows="3"
            [(ngModel)]="page.txt7bch"
            (ngModelChange)="updatePageField(page,'txt7bch',page.txt7bch)"></textarea>
        </mat-form-field>            
      </div> 

      <hr class="fx-100">

      <div class="fx-100 fx-row fx-wrap" >
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 8 </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitle8"
            (ngModelChange)="updatePageField(page,'subtitle8',page.subtitle8)">
        </mat-form-field>
        <mat-form-field class="fx-50">
          <mat-label>Subtitle 8 In Chinese </mat-label>
          <input matInput   required
            [(ngModel)]="page.subtitlech8"
            (ngModelChange)="updatePageField(page,'subtitlech8',page.subtitlech8)">
        </mat-form-field> 

        <mat-form-field class="fx-33">
          <mat-label>Contact n°1 : Full Name </mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8fn"
            (ngModelChange)="updatePageField(page,'txt8fn',page.txt8fn)">
        </mat-form-field>
        <mat-form-field class="fx-33">
          <mat-label>Contact n°1 : Job Position </mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8pos"
            (ngModelChange)="updatePageField(page,'txt8pos',page.txt8pos)">
        </mat-form-field>
        <mat-form-field class="fx-33">
          <mat-label>Contact n°1 : Email </mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8email"
            (ngModelChange)="updatePageField(page,'txt8email',page.txt8email)">
        </mat-form-field>

        <mat-form-field class="fx-33">
          <mat-label>Contact n°2 : Full Name </mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8fn2"
            (ngModelChange)="updatePageField(page,'txt8fn2',page.txt8fn2)">
        </mat-form-field>
        <mat-form-field class="fx-33">
          <mat-label> Contact n°2 : Job Position</mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8pos2"
            (ngModelChange)="updatePageField(page,'txt8pos2',page.txt8pos2)">
        </mat-form-field>
        <mat-form-field class="fx-33">
          <mat-label> Contact n°2 : Email</mat-label>
          <input matInput   required
            [(ngModel)]="page.txt8email2"
            (ngModelChange)="updatePageField(page,'txt8email2',page.txt8email2)">
        </mat-form-field>

      </div>

    </div>    
    <div *ngIf="!editpage" class="sec2 contacts fx-row fx-wrap fx-80 fx-col-xs fx-col-xs fx-start-stretch" [ngStyle]="{'padding':'0 0 50px 0'}"
    	fxLayoutAlign="start stretch"
    	>
    	<div class="fx-100">
    		<h2 [ngStyle.xs]="{ 'font-size':'1.1em', 'padding':'50px 0 50px 100px','font-weight':'bold' }" class="purple">{{ lg(page.titlech,page.title) }}</h2>
    	</div>

    	<div class="fx-100" ngClass="mh3">
    		<h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle1ch,page.subtitle1) }}</h3>
  		</div>  	
    	<div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt1ach,page.txt1a) }}</span>
    	</div>
    	<div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt1bch,page.txt1b) }}</span>
    	</div>

      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle2ch,page.subtitle2) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt2ach,page.txt2a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt2bch,page.txt2b) }}</span>
      </div>      

      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle3ch,page.subtitle3) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt3ach,page.txt3a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt3bch,page.txt3b) }}</span>
      </div>      

      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle4ch,page.subtitle4) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt4ach,page.txt4a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt4bch,page.txt4b) }}</span>
      </div>      
      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle5ch,page.subtitle5) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt5ach,page.txt5a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt5bch,page.txt5b) }}</span>
      </div>      

      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle6ch,page.subtitle6) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt6ach,page.txt6a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt6bch,page.txt6b) }}</span>
      </div>      

      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle7ch,page.subtitle7) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt7ach,page.txt7a) }}</span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">{{ lg(page.txt7bch,page.txt7b) }}</span>
      </div> 


      <div class="fx-100" ngClass="mh3">
        <h3 class="bluegreen" [ngStyle.xs]="{ 'padding-left':'10px'}">{{ lg(page.subtitle8ch,page.subtitle8) }}</h3>
      </div>    
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">
          <strong>{{ page.txt8fn }}</strong><br>{{ page.txt8pos }} <br>{{ 
              (page.txt8email.indexOf('@')!=0) ? 
              (page.txt8email.replace('@','[at]')) : page.txt8email
          }}
        </span>
      </div>
      <div class="fx-50" ngClass="mtxt">
        <span class="angular-with-newlines">
          <strong>{{ page.txt8fn2 }}</strong><br>{{ page.txt8pos2 }} <br>{{ 
              (page.txt8email2.indexOf('@')!=0) ? 
              (page.txt8email2.replace('@','[at]')) : page.txt8email2 }}
        </span>
      </div>
 	 	 	  	 	  	
    </div>
    <div class="sec5 fx-10 hide-mobile">
       <img src="{{ imglogourl }}" alt="{{ imglogoname.substring(0, imglogoname.indexOf('.')) }}">
    </div>
  </div>  
</div>

<footer-nav></footer-nav>   
  