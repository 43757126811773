import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'events-routing-events',
  templateUrl: './events-routing-events.component.html',
  styleUrls: ['./events-routing-events.component.scss']
})
export class EventsRoutingEventsComponent implements OnInit {

	@Input() tourkey: string;
  @Input() countevents: any;

	events:any;

  constructor(private eventService: EventService) { }

  ngOnInit() {
  	this.events 	= this.eventService.getEventsTour(this.tourkey);
  }

  gotoGoogleMapAddress(address) {
    window.open('https://maps.google.com/?q='+address, '_blank');
  }


}
