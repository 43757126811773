import { NgModule } from '@angular/core';
import { AdminGuard } from './admin.guards';
import { AuthService } from './auth.service';
import { AgentGuard } from './agent.guard';

@NgModule({
  providers: 	[AuthService,AgentGuard,AdminGuard],
  imports: 		[]
})
export class CoreModule { }
