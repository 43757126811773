
<mat-form-field	class="full-width">
	<mat-label> Search Composition </mat-label>
  <input class="full-width"
  		matInput 
  		aria-label="Composition" 
  		name="newEventComposition"
  		ngModel
  		[matAutocomplete]="auto" 
  		[formControl]="compositionCtrl">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let composition of filteredCompositions | async" 
    	[value]="composition">
      <span>{{ composition.title }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix class="icon-but">search</mat-icon>	  	
</mat-form-field>

<div *ngIf="isCompositionSelected">
  {{ addCompositionToProgram(eventView, selectedComposer, selectedComposition) }}
</div>
      

<!-- <div *ngIf="selectedComposer&&showCompositions">
		<mat-form-field *ngIf="compositions" class="full-width">
			<mat-label> Select Composition(s) </mat-label>
		  <mat-select required class="full-width"
		  	ngModel #selectedCompositions="ngModel" name="selectedCompositions"
		  	(ngModelChange)="addCompositionToProgram(event,selectedComposer,selectedCompositions)">
		    <mat-option 
		    	*ngFor="let composition of compositions | async" 
		    	[value]="composition">
		    	{{ composition.title }}
		    </mat-option>
		  </mat-select>
		</mat-form-field>
	</div> -->