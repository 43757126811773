import { NgModule } from '@angular/core';
import { Routes, RouterModule, Event } from '@angular/router';

import { UserLoginComponent } from './ui/user-login/user-login.component';
import { UserProfileComponent } from './ui/user-profile/user-profile.component';
import { DashboardComponent } from './ui/dashboard/dashboard.component';
import { ReadmePageComponent } from './ui/readme-page/readme-page.component';
import { PageNotFoundComponent } from './ui/not-found.component';
import { UsersLogComponent } from './ui/users-log/users-log.component';

import { FileUploadComponent } from './upload/upload-file/upload-file.component';
import { UploadFormComponent } from './upload/upload-form/upload-form.component';
import { UploadMediaComponent } from './upload/upload-media/upload-media.component';
import { UploadCarouselComponent } from './upload/upload-carousel/upload-carousel.component';

import { RepairComponent } from './admin/repair/repair.component';
import { ManagementComponent } from './admin/management/management.component';
import { UserAllComponent } from './ui/user-all/user-all.component';
import { PageManagementComponent } from './ui/page-management/page-management.component';

import { AdminGuard } from './core/admin.guards';
import { AuthGuard } from './core/auth.guard';
import { SendEmailComponent } from './ui/send-email/send-email.component';

const routes: Routes = [
  { path: 'sendmail', component: SendEmailComponent, canActivate: [AdminGuard] },
  ///////////////////////////////////////////////////////////////////////////////
  { path: 'repair', component: RepairComponent, canActivate: [AdminGuard] },
  { path: 'management', component: ManagementComponent, canActivate: [AdminGuard] },
  { path: 'redirection', component: PageManagementComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserAllComponent, canActivate: [AdminGuard] },
  { path: 'logslist', component: UsersLogComponent, canActivate: [AdminGuard] },
  ///////////////////////////////////////////////////////////////////////////////
  {
    path: 'instrument$',
    loadChildren: () => import('./instruments/shared/instrument.module').then(mod => mod.InstrumentModule)
  },
  {
    path: 'event$',
    loadChildren: () => import('./events/shared/event.module').then(mod => mod.EventModule)
  },
  {
    path: 'new$',
    loadChildren: () => import('./news/shared/news.module').then(mod => mod.NewsModule)
  },
  {
    path: 'press$',
    loadChildren: () => import('./press/shared/press.module').then(mod => mod.PressModule)
  },
  {
    path: 'composer$',
    loadChildren: () => import('./composers/shared/composer.module').then(mod => mod.ComposerModule)
  },
  {
    path: 'venue$',
    loadChildren: () => import('./venue/shared/venue.module').then(mod => mod.VenueModule)
  },
  {
    path: 'member$',
    loadChildren: () => import('./members/shared/member.module').then(mod => mod.MemberModule)
  },
  {
    path: '',
    loadChildren: () => import('./public/shared/public.module').then(mod => mod.PublicModule)
  },
  ///////////////////////////////////////////////////////////////////////////////
  { path: 'upload', component: UploadFormComponent, canActivate: [AdminGuard] },
  { path: 'uploadfile/:type/:id', component: FileUploadComponent, canActivate: [AdminGuard] },
  { path: 'uploadmedia/:id', component: UploadMediaComponent, canActivate: [AdminGuard] },
  { path: 'uploadcarousel/:id', component: UploadCarouselComponent, canActivate: [AdminGuard] },
  ///////////////////////////////////////////////////////////////////////////////
  { path: 'login', component: UserLoginComponent, },
  { path: 'profile', component: UserProfileComponent, },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AdminGuard]},
  { path: 'readme', component: ReadmePageComponent, canActivate: [AdminGuard]},
  { path: '**', component: PageNotFoundComponent },
  { path: 'notfound', component: PageNotFoundComponent }
  ///////////////////////////////////////////////////////////////////////////////
];

@NgModule({
  imports:   [RouterModule.forRoot(routes)],
  exports:   [RouterModule],
  providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule { }
