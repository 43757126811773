import { MemberService } from './../../members/shared/member.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { EventService } from '../../events/shared/event.service';
import { switchMap, startWith, map } from 'rxjs/operators';
import { UpdateService } from '../../update.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  isCookies = true;

  constructor(public auth: AuthService,
              private router: Router,
              private memberService: MemberService,
              public dialog: MatDialog,
              private eventService: EventService,
              ) {
                // this.artists = this.memberService.getArtistsList();
                // this.events = this.eventService.getPublicEventsList();
              }

  logout(user) {
    this.auth.signOut(user);
  }


  // filterArtists(val: string) {
  //   return this.artists
  //     .map(response => response.filter(artist => {
  //       return (artist.firstname.toLowerCase().indexOf(val.toLowerCase()) === 0)
  //       ||  (artist.lastname.toLowerCase().indexOf(val.toLowerCase()) === 0);
  //     }));
  // }

  // filterEvents(val: string) {
  //   return this.events
  //     .map(response => response.filter(event => {
  //       const spacePosition = event.title.toLowerCase().indexOf(' ') + 1 ;
  //       const eventTitlePosition = event.title.toLowerCase().indexOf(val.toLowerCase());
  //       const commaPosition = event.title.toLowerCase().indexOf(val.toLowerCase()) + 1;
  //       return event.title.toLowerCase().indexOf(val.toLowerCase()) === 0 ||
  //              eventTitlePosition === spacePosition || commaPosition ||
  //              eventTitlePosition === spacePosition;
  //     }));
  // }

  // displayFn(composer) {
  //   if(composer) { var fullname = `${composer.firstname} ${composer.lastname}`;}
  //   else var fullname = '';
  //   if(!composer) return null;
  //   else return composer ? fullname : composer;
  // }

  ngOnInit() {
  }

  gotoUserProfile() {}

  openDialog(): void {
    const dialogRef = this.dialog.open( MoteurRechercheComponent, {
      width: '550px',
      backdropClass: 'searchDialog',
      panelClass: 'searchDialogPanel',
      data: {
          key: 'key'
       }
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('Exit');
    });
  }


}



@Component({
  selector: 'app-moteur-recherche',
  templateUrl: 'moteur-recherche.component.html',
  styleUrls: ['./moteur-recherche.component.scss']
})
export class MoteurRechercheComponent implements OnInit {
  // show = false;
  // isVisible = false;
  // showFields: boolean;
  isArtist: boolean;
  isEvent: boolean;
  events: any;
  filteredData: Observable<any[]>;
  selectedData: any;
  dataCtrl: UntypedFormControl = new UntypedFormControl();
  // choices = ['Search Events','Search Artists'];
  essai: string;
  dataSelected: string;
  artists: Observable <any[]>;
  showSearchLoader : boolean= false;


  constructor( private router: Router,
               private memberService: MemberService,
               private eventService: EventService,
               public dialogRef: MatDialogRef<MoteurRechercheComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) {
                this.artists = this.memberService.getArtistsList();
                this.events = this.eventService.getPublicEventsList();
              }


  filterArtists(val: string) {
    return this.artists
      .pipe(map(response => response.filter(artist => {
        this.showSearchLoader = false;
        return (artist.firstname.toLowerCase().indexOf(val.toLowerCase()) === 0) ||
               (artist.lastname.toLowerCase().indexOf(val.toLowerCase()) === 0);
      })));
  }


  displayFn(artist) {
    if(artist) { var artistfullname = `${artist.firstname} ${artist.lastname}`; }
    else var artistfullname = '';
    return artist ? artistfullname : artist;
  }



  ngOnInit() {

    this.filteredData = this.dataCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(data => {
         if (data) {
            this.showSearchLoader = true;
            if (data.timestamp) {
              this.link(data);
              return this.artists;
            } else {
              return this.filterArtists(data);
            }
        } else {
          this.showSearchLoader = false;
           return this.filterArtists('something');
        }
    }));

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  link(data) {
    this.router.navigate(['./../artist', data.$key]);
    this.onNoClick();
  }

}
