<!-- <ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>
 -->

<mat-form-field	class="full-width">
  <mat-label> Search tour and add event</mat-label>
  <input matInput 
      aria-label="Search" 
      name="newEventData"
      ngModel
      [matAutocomplete]="auto" 
      [formControl]="dataCtrl">
  <mat-autocomplete 
  	#auto="matAutocomplete"
    [displayWith]="displayFn">
	    <mat-option *ngFor="let tour of filteredData | async" [value]="tour">
	      <span>
	      	<strong>{{ tour.title }} </strong>
	      	<i *ngIf="tour.startdate">from {{ tour.startdate | date }} 
	      		<span *ngIf="tour.enddate">to {{ tour.enddate | date }}</span></i>
	      </span>
	    </mat-option>
  </mat-autocomplete>
	<mat-icon matSuffix class="icon-but">search</mat-icon>	  	
</mat-form-field> 

	