<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<ng-template #loading>								
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<mat-card appearance="outlined" *ngIf="searchComposer" class="noshadow mt-20">
	<mat-card-content>
		<form>
			<mat-form-field	class="example-form">
				<mat-label>Search Composers</mat-label>
			  <input 
			  		matInput 
			  		aria-label="Composer" 
			  		[matAutocomplete]="auto" 
			  		[formControl]="composerCtrl">
			  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			    <mat-option *ngFor="let composer of filteredComposers | async" 
			    	[value]="composer">
			      <span>{{ composer.firstname }} {{ composer.lastname }}</span>
			    </mat-option>
			  </mat-autocomplete>
			  <mat-spinner matSuffix *ngIf="showSearchLoader" class="loader fx-row" ></mat-spinner>
			  <mat-icon matSuffix *ngIf="!showSearchLoader">search</mat-icon>
			</mat-form-field>

			<button mat-fab (click)="
												searchComposer=false;
												composerList=true;
												addComposer=false;
												addComposers=false" 
												type="button" color="black">
				<mat-icon>list</mat-icon>
			</button>

			<button mat-fab routerLink="./../../composer$/composerform"
												type="button" color="black">
				<mat-icon>person_add</mat-icon>
			</button>

			<button mat-fab routerLink="./../../composer$/compositions"
												type="button" color="black">
				<mat-icon>queue_music</mat-icon>
			</button>			

		</form> 
	</mat-card-content>
	<br>
</mat-card>



<mat-card appearance="outlined" *ngIf="composerList" class="noshadow">
	<mat-card-header class="example-full-width">
		<mat-card-title>Composers</mat-card-title>
		<mat-card-subtitle>List of the composers saved in Kajimotomusic Database</mat-card-subtitle>
	  <span class="example-fill-remaining-space"></span>
		<button mat-fab style="right: 50px;"
								(click)="
											searchComposer=true;
											composerList=false;
											addComposer=false;
											addComposers=false" 
											type="button" color="black">
			<mat-icon>search</mat-icon>
		</button>
		<button style="right: 50px;" mat-fab routerLink="./../../composer$/composerform"
											type="button" color="black">
			<mat-icon>person_add</mat-icon>
		</button>					
	</mat-card-header>

	<mat-list dense *ngIf="composers | async; let composers; else loading">
		<mat-list-item *ngFor="let compo of composers; let i=index" [routerLink]="['./../../composer$/composer', compo.$key]">			
			<span class="fx-100">
				<p class="fx-100">
				<span class="bubble-composer fl">{{ (i+1) }}</span>
				<span class="fl">{{ compo.firstname }} {{ compo.lastname }}</span>
			</p>
		
			<span  class="nb-compo">
				<app-composition-length [composerkey]='compo.$key'></app-composition-length>
			</span>			
			<!-- <span *ngIf="compo.compositions" class="nb-compo">
				{{ compo.compositions.length }}
			</span> -->
			
			<button mat-icon-button
				[routerLink]="['./../../composer$/composer', compo.$key]">
		     <mat-icon>info</mat-icon>
		  </button>	
		</span>
		  <!-- 			
		  <button mat-icon-button
					(click)="deleteComposer(compo)">
	        <mat-icon>remove_circle</mat-icon>
	    </button> 
	  	-->
	</mat-list-item>
	</mat-list>
</mat-card>