import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';
import { MemberService } from './../../members/shared/member.service';

@Component({
  selector: 'events-routing-artist',
  templateUrl: './events-routing-artist.component.html',
  styleUrls: ['./events-routing-artist.component.scss']
})
export class EventsRoutingArtistComponent implements OnInit {

	@Input() artistkey: string;

	artist: any;

  constructor(private ms: MemberService) { }

  ngOnInit() {
  	this.artist = this.ms.getArtistWithKey(this.artistkey)
  }

  gotoMailto(mail) {
    window.open('mailto:'+mail);
  }

  gotoCall(phone) {
    var res = phone.replace(/ /g,'').replace("(0)", "");
    window.open('tel:'+res);
  }

}