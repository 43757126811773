 <!-- <pre>{{ artist$ | async | json }}</pre>  -->

<ng-template #loading>				
	<br><br><br><br><br>
	<mat-spinner color="black"></mat-spinner>
	<br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" alt="" class="logo-artist-page logo-artist-page-xs">

<div class="nocss content fx-row fx-wrap" 
	*ngIf="artist$ | async; let artists; else loading"
	[@artists]="artists.length">

	<div class="grid-instrument fx-gap-1 fx-100">
			<div class="fx-fill fx-jc-center" (click)="gotoExternalHref('https://www.kajimotomusic.com/')">TOKYO</div>
			<div class="fx-fill fx-jc-center instru-selected">PARIS</div>
			<div class="fx-fill fx-jc-center" routerLink="./../artistschina">BEIJING</div>
	</div>

	<div class="fx-100 grid-instrument grid-instrument-xs smaller-txt fx-row fx-wrap fx-gap-1">
		  <div 
		  class="hide-mobile fx-jc-center fx-calc-12-1px fx-100-xs"
		  	(click)=" filterByCategory('')" 
		  	*ngIf="this.instruFilter$.getValue()" >All
		  </div>
		  <div 
			class="hide-mobile fx-jc-center instru-selected fx-calc-12-1px fx-100-xs"
		  	*ngIf="!this.instruFilter$.getValue()" >
		  		Select Category:
		  </div>
		  <div 
		  	class="fx-calc-12-1px fx-jc-center fx-calc-25-1px-xs padding-10-xs"
		  	*ngFor="let category of CATEGORIES "
		  	[ngClass]="{'instru-selected':category.title==categorySelected}"
		  	(click)=" filterByCategory(category)">
		  	{{ category.title=='instrumentalist' ? 'others' : category.title=='instrumentiste' ? 'autres' : category.title }}
		  </div>
	</div>

	<div class="fx-100 fx-row fx-wrap">
	  <div class="arist-grid artist-grid-xs fx-col fx-20 fx-33-xs fx-end-stretch"
	  	*ngFor="let artist of artists"
		[routerLink]="['./../artist', artist.firstname+'_'+artist.lastname]"
		[style.background-image]="getBackground(artist.profilepic)"
		[ngStyle]="{ 'height':'300px', 'background' : 'no-repeat center center' }">
			<span><strong>{{ artist.firstname }} {{ artist.lastname }}</strong></span>
			<span *ngIf="artist.lastname==='Zeliha'" class="printinstru">
				Chamber Music
			</span>
			<span *ngIf="artist.lastname!='Zeliha'" class="printinstru">
				{{ artist.instrument.name | titlecase }}
			</span>			
	  </div>
	</div>
</div>
