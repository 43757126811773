import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, ParamMap } from '@angular/router';
import { MemberService } from './../../members/shared/member.service';
import { Title, Meta } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-artist-biography',
  templateUrl: './artist-biography.component.html',
  styleUrls: ['./artist-biography.component.scss']
})
export class ArtistBiographyComponent implements OnInit {

	artist:any;artistkey:string;

  txtInEn:boolean= true;
  txtInFr:boolean= false;

  bioVersion : string = 'long';

  constructor( 
    private titleService: Title,
  	private memberService: MemberService,
    private route: ActivatedRoute,
    private meta: Meta,) { }

  ngOnInit() {
    const lang = this.route.snapshot.queryParamMap.get('lang');
    if(lang === 'fr') {
      this.txtInEn = false;
      this.txtInFr = true;
    }
    this.artist = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.memberService.getArtistWithKey(params.get('id'))));
    
    this.route.params.subscribe((params: Params) => {
        this.artistkey = params['id'];
    });   
    
    this.memberService.getArtistWithKey(this.artistkey).subscribe((data) => {
      this.titleService.setTitle(`Kajimoto Music Paris | ${data.lastname} ${data.firstname} Biography`);     

      this.meta.addTags([
        { name: 'twitter:card', content: 'summary' },
        { name: 'og:url', content: `https://kajimotomusicparis.com/bio/${data.$key}` },
        { name: 'og:title', content: `Kajimoto Music | ${data.lastname} ${data.firstname} Biography | ${data.instrument}`},
        { name: 'og:description', content: `Biography of Kajimoto Music artist ${data.lastname} ${data.firstname}.`}, 
        { name: 'og:image', content: `${data.profilepic}`},
        { name: 'description', content: `Biography of Kajimoto Music artist ${data.lastname} ${data.firstname}.`},
        { name: 'title', content: `Kajimoto Music | ${data.lastname} ${data.firstname} Biography | ${data.instrument}` },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: 'Kajimoto Music' },
        { name: 'twitter:title', content: `Kajimoto Music | ${data.lastname} ${data.firstname}` },
        // tslint:disable-next-line:max-line-length
        { name: 'twitter:description',  content: `Biography of Kajimoto Music artist ${data.lastname} ${data.firstname}.` },
        // tslint:disable-next-line:max-line-length
        { name: 'twitter:image', content:  `${data.profilepic}` },
        { name: 'twitter:url', content: `https://kajimotomusicparis.com/artist/${data.$key}`},
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'msapplication-starturl', content: '/' },
        { name: 'theme-color', content: '#f48c5b' },
        { name : 'keywords',  content: `${data.lastname} ${data.firstname}`},
        { name : 'url',  content: `https://kajimotomusicparis.com/artist/${data.$key}`}
      ]);
    });
  }

  print_page() {
     window.print();
  }

}
