import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

import { NgxWigModule } from 'ngx-wig';

// FIREBASE

import { PressFormComponent } from './../press-form/press-form.component';
import { PressListComponent } from './../press-list/press-list.component';
import { PressDetailComponent } from './../press-detail/press-detail.component';

import { PressService } from './press.service';
import { PressRoutingModule } from './press-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,ReactiveFormsModule,
    AngularMaterialModule,
    AngularMaterialModule,
    NgxWigModule,
    PressRoutingModule
  ],
  declarations: [
  	PressFormComponent,
  	PressListComponent,
    PressDetailComponent
  ],
  providers: [
    PressService
  ]    
})
export class PressModule { }
