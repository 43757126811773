import { Component, OnInit, Inject, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventService } from './../shared/event.service';
import { ComposerService } from './../../composers/shared/composer.service';
import { MemberService } from './../../members/shared/member.service';
import { AuthService } from './../../core/auth.service';
import { switchMap, map, startWith } from 'rxjs/operators';
import { cp } from 'fs';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  eventView:  Observable<any>;
  userOnline: any;
  eventKey: string;
  arrayArtists: any;

  artists: Observable<any>;
  allartists: Observable<any>;

  titleField = 'title';
  timeField = 'time';
  venueField = 'venue';
  producerField = 'producer';
  websiteField = 'website';
  commentField = 'comment';
  venuenameField = 'venuename';
  venueaddressField = 'venueaddress';
  venuezipField = 'venuezip';
  venuecityField = 'venuecity';
  venuecountryField = 'venuecountry';
  venuewebsiteField = 'venuewebsite';

  isInfoOpen = false; isProdOpen = false;
  isTourOpen = false;

  orchestreField = 'artistorchestre';
  chefField = 'artistchef';
  solisteField = 'artistsoliste';
  othersField = 'artistothers';

  producerNameField = 'producername';
  producerEmailField = 'produceremail';
  producerPhoneField = 'producerphone';
  producerAddressField = 'produceraddress';

  origineField = 'origine';

  paidbytravelField = 'paidbytravel';
  paidbyhotelField = 'paidbyhotel';

  composers: Observable<any[]>;
  showCompositions = false;
  showAddArtistSelect = false;
  ShowAddArtistBut = true;
  sendtoCtrlOneTime = true;
  showExistingProgramListBut = true;
  showTourList = false;

  filteredTours: Observable<any[]>;
  tourEventsCtrl: UntypedFormControl = new UntypedFormControl();
  tours: Observable<any[]>;

  filteredPrograms: Observable<any[]>;
  existingProgramCtrl: UntypedFormControl = new UntypedFormControl();
  programs: Observable<any[]>;
  exprograms: Observable<any[]>;
  oldProgramCompositionsCollection: Observable<any[]>;

  selectedComposer: Observable<any[]>;
  showAddCompositionButton = false;
  showExistingProgramList = false;
  isSendArtistsToStaticArray = true;
  newdate;

  repairButton: boolean = true;

  currencies = [ 'EUR', 'GBP', 'CHF', 'USD', 'US', 'CAD', 'AUD', 'JPY', 'CNY', 'HKD', 'SGD' ];
  times = [
    '8:00',
    '8:05',
    '8:10',
    '8:15',
    '8:20',
    '8:25',
    '8:30',
    '8:45',
    '9:00',
    '9:05',
    '9:10',
    '9:15',
    '9:20',
    '9:25',
    '9:30',
    '9:45',
    '10:00',
    '10:05',
    '10:10',
    '10:15',
    '10:20',
    '10:25',
    '10:30',
    '10:45',
    '11:00',
    '11:05',
    '11:10',
    '11:15',
    '11:20',
    '11:25',
    '11:30',
    '11:45',
    '12:00',
    '12:05',
    '12:10',
    '12:15',
    '12:20',
    '12:25',
    '12:30',
    '12:45',
    '13:00',
    '13:05',
    '13:10',
    '13:15',
    '13:20',
    '13:25',
    '13:30',
    '13:45',
    '14:00',
    '14:05',
    '14:10',
    '14:15',
    '14:20',
    '14:25',
    '14:30',
    '14:45',
    '15:00',
    '15:05',
    '15:10',
    '15:15',
    '15:20',
    '15:25',
    '15:30',
    '15:45',
    '16:00',
    '16:05',
    '16:10',
    '16:15',
    '16:20',
    '16:25',
    '16:30',
    '16:45',
    '17:00',
    '17:05',
    '17:10',
    '17:15',
    '17:20',
    '17:25',
    '17:30',
    '17:45',
    '18:00',
    '18:05',
    '18:10',
    '18:15',
    '18:20',
    '18:25',
    '18:30',
    '18:35',
    '18:40',
    '18:45',
    '19:00',
    '19:05',
    '19:10',
    '19:15',
    '19:20',
    '19:25',
    '19:30',
    '19:45',
    '19:50',
    '19:55',
    '20:00',
    '20:05',
    '20:10',
    '20:15',
    '20:20',
    '20:25',
    '20:30',
    '20:35',
    '20:40',
    '20:45',
    '20:50',
    '20:55',
    '21:00',
    '22:00'
  ];


  // comment variable
  comment: string;
  eventData: any;

  constructor(
    private composerService: ComposerService,
    private memberService: MemberService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    public  auth: AuthService ) {
      this.composers    = this.composerService.getComposersList();
      this.allartists   = this.memberService.getArtistsList();
      this.exprograms   = this.eventService.getExistingProgramsList();
      this.tours        = this.eventService.getToursList();
  }

  ngOnInit() {


    // GET KEY FROM URL AND SAVE IT TO EVENTKEY
    this.route.params.subscribe((params: Params) => {
        this.eventKey = params['id'];
    });

    this.newdate = new Date();

    this.eventView = this.route.paramMap.pipe(switchMap((params: ParamMap) => this.eventService.getEventWithKey(params.get('id'))));

    this.artists = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.eventService.getArtistsEvent(params.get('id')))).pipe(
          map((data) => {
            this.arrayArtists = data[0];
            return data[0];
          })
        );

    this.programs = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.eventService.getCompositionsProgramWithEventKey(params.get('id'))));


    this.filteredPrograms = this.existingProgramCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(program => {
        if (program) {
          if (program.eventtitle) {
            this.oldProgramCompositionsCollection = this.eventService.getCompositionsProgramWithProgramKey(program.$key);
            return this.programs;
          } else { return this.filterPrograms(program); }
        } else {
          console.log('Do not get this case');
          return this.filterPrograms('something');
        }
      }));

     
    this.filteredTours = this.tourEventsCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(tour => {
        if (tour) {
          if (tour.startdate) {
            // this.eventService.addEventToTour(tour.$key);
            console.log(tour);
            return this.tours;
          } else { return this.filterTours(tour); }
        } else {
          console.log('Do not get this case');
          return this.filterTours('something');
        }
      }));
   }


  filterTours(val: string) {
    return this.tours
      .pipe(map(response => response.filter(tour => {
        return tour.title.toLowerCase().indexOf(val.toLowerCase()) === 0;
      })));
  }

  displayFn2(tour) {
    let tourbis;
    if (tour) { tourbis = `${tour.title}`; } else { tourbis = ''; }
    return tour ? tour : tourbis;
  }

  clear() {
    this.eventView = null;
    this.router.navigate(['/event$/eventslist']);
  }

  filterPrograms(val: string) {
    return this.exprograms
      .pipe(map(response => response.filter(program => {
        return program.eventtitle.toLowerCase().indexOf(val.toLowerCase()) === 0;
      })));
  }

  displayFn(program) {
    let exprogram;
    if (program) { exprogram = `${program.eventtitle}`; } else { exprogram = ''; }
    return program ? exprogram : program;
  }

  SendOldProgramCompositionCollectionToCtrl(oldprogram, event) {
    if (this.sendtoCtrlOneTime) {
      this.sendtoCtrlOneTime = false;
      this.eventService.duplicateProgramEvent(oldprogram, event);
      this.showExistingProgramList = false;
      this.showExistingProgramListBut = false;
    }
  }


  addArtistToEvent(event, artist) {
    this.eventService.addArtistToEvent(event, artist);
  }

  sendUserToController(user) {
    this.userOnline = user;
  }

  goBack(): void { this.location.back(); }


  updateEventField(artists, eventkey, field, value) {
    this.comment = value;
    this.eventData = eventkey;
    this.eventService.updateEventFieldWithBatch(artists, eventkey, field, value, this.userOnline);
  }

  deleteEventVenue(event, artists) {
    this.eventService.deleteVenueFromEvent(event);
  }

  deleteCompositionInProgram(event, composition) {
    this.eventService.deleteCompositionInProgram(event, composition);
  }

  removeArtistFromEvent(event, artist) {
    this.eventService.removeArtistFromEvent(event, artist);
  }

  removeAllArtistsAndDeleteEvent(event, artists) {
    this.eventService.removeAllArtistsAndDeleteEvent(event, artists);
    this.clear();
  }

  deleteEventWithNoArtist(event) {
    this.eventService.deleteEventWithNoArtist(event);
  }

  addTourEvent(event, tour) {
    this.eventService.addTourEvent(event, tour);
  }


  duplicateEvent(event, artist, program) {
    this.eventService.createEvent(event, artist, program);
  }



  confirmeDeleteEvent(): void {
    const dialogRef = this.dialog.open(ConfirmeDeleteEventComponent, {
      width: '400px',
      backdropClass: 'filterEventsDialog',
      panelClass: 'searchDialogPanel',
       data : {
          event : this.eventView,
          artist: this.artists
       },
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  checkEventConstruction(tourKey:string = null, venueKey: string = null, eventKey: string = null) {
    this.eventService.checkDenormalizeVenueAndArtist(tourKey, venueKey, eventKey).then((result: boolean) => {
      this.repairButton = result;
    });
  }

  repairEvent(event: any) {
    //this.checkEventConstruction(event.tourkey, event.venuekey, event.$key);
    this.eventService.repairEvent(event).then(() => {
      console.log('Repair success');
      this.repairButton = false
    }).catch((error) => console.log(error));
  }

  ngAfterViewInit() {
    this.eventView.subscribe((data) => {
      if (!data) return;
      this.checkEventConstruction(data.tourkey, data.venuekey, data.$key);
    });
  }

  

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    // if(this.eventData){ 
    //   this.eventService.updateEventFieldWithBatch(this.arrayArtists, this.eventData, 'comment', this.comment, this.userOnline, false).then(() => {
    //     console.log('comment updated')
    //   })
    // };
    // Do more processing...
  }

  ngOnDestroy() {
    this.eventService.updateEventFieldWithBatch(this.arrayArtists, this.eventData, 'comment', this.comment, this.userOnline, false);
  }

}







@Component({
  selector: 'app-confirme-delete-event',
  templateUrl: './confirme-delete-event.component.html',
  styleUrls: ['./confirme-delete-event.component.scss']
})

export class ConfirmeDeleteEventComponent implements OnInit {
  event: any;
  artists: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmeDeleteEventComponent>,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.event = this.data.event;
      this.artists = this.data.artist;

  }

  removeAllArtistsAndDeleteEvent(event, artists) {
     this.eventService.removeAllArtistsAndDeleteEvent(event, artists);
     this.onNoClick() ;
  }

   deleteEventWithNoArtist(event) {
    this.eventService.deleteEventWithNoArtist(event);
    this.onNoClick() ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }
}










