<!-- <pre>{{ artist | async | json }}</pre> -->

<mat-toolbar class="doNotPrint">

	<span class="example-spacer"></span>

	<button mat-raised-button type="button" color="black" 
		*ngIf="bioVersion=='long'" (click)="bioVersion='short'">Long Version</button>	

	<button mat-raised-button type="button" color="black" 
		*ngIf="bioVersion=='short'" (click)="bioVersion='both'">Short Version</button>			

	<button mat-raised-button type="button" color="black" 
		*ngIf="bioVersion=='both'" (click)="bioVersion='long'">Long And Short Versions</button>					
	
	<button (click)="print_page()"  mat-raised-button type="button" color="black" ><mat-icon>print</mat-icon></button>	

	<button [routerLink]="['./../../artist/', artistkey]"  mat-raised-button type="button" color="black" ><mat-icon>close</mat-icon></button>					

</mat-toolbar>


<button mat-fab class="button-lang button-lang-xs doNotPrint" color="warn"
  [ngClass]="linkClass"
  *ngIf="txtInEn"
  (click)="
    txtInFr=true;txtInEn=false;
    titleInFr=true;titleInEn=false
    ">
  FR
</button>

<button mat-fab class="button-lang button-lang-xs doNotPrint" color="warn"
  [ngClass]="linkClass"
  *ngIf="txtInFr"
  (click)="
    txtInFr=false;txtInEn=true;
    titleInFr=false;titleInEn=true
    ">
  EN
</button>



<ng-template #loading>				
	<br><br><br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>


<mat-card appearance="outlined" *ngIf="artist | async; let artist;" class="mode-A4 noshadow">

	<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" alt="" class="logo-artist-page">

	<mat-card-title>{{ artist.firstname }} {{ artist.lastname }}</mat-card-title>
	<mat-card-subtitle>
		<span *ngIf="artist.instrument=='Conductor'&&txtInFr">Direction
		</span><span *ngIf="artist.instrument=='Conductor'&&!txtInFr">Conductor
		</span>

		<span *ngIf="artist.instrument=='Composer'&&txtInFr">Compositeur
		</span> <span *ngIf="artist.instrument=='Composer'&&!txtInFr">Composer
		</span>          

		<span *ngIf="artist.instrument=='Cello'&&txtInFr">Violoncelle
		</span> <span *ngIf="artist.instrument=='Cello'&&!txtInFr">Cello
		</span> 

		<span *ngIf="artist.instrument=='Piano'">Piano
		</span>    

		<span *ngIf="artist.instrument=='Viola'&&txtInFr">Alto
		</span><span *ngIf="artist.instrument=='Viola'&&!txtInFr">Viola
		</span>      

		<span *ngIf="artist.instrument=='Violin'&&txtInFr">Violon
		</span><span *ngIf="artist.instrument=='Violin'&&!txtInFr">Violin
		</span>
	</mat-card-subtitle>

	<mat-card-content>

		<h2 *ngIf="txtInFr&&(bioVersion=='short'||bioVersion=='both')">Biographie : Version Courte</h2>
		<h2 *ngIf="txtInEn&&(bioVersion=='short'||bioVersion=='both')">Biography : Short Version</h2>
		<p *ngIf="txtInFr&&(bioVersion=='short'||bioVersion=='both')" class="bio" innerHTML="{{ artist.bioshorthtmlfr }}"></p>
		<p *ngIf="txtInEn&&(bioVersion=='short'||bioVersion=='both')" class="bio" innerHTML="{{ artist.bioshorthtmlen }}"></p>


		<h2 *ngIf="txtInFr&&(bioVersion=='long'||bioVersion=='both')">Biographie : Version Longue</h2>
		<h2 *ngIf="txtInEn&&(bioVersion=='long'||bioVersion=='both')">Biography : Long Version</h2>		
		<p *ngIf="txtInFr&&(bioVersion=='long'||bioVersion=='both')" class="bio" innerHTML="{{ artist.biolonghtmlfr }}"></p>
		<p *ngIf="txtInEn&&(bioVersion=='long'||bioVersion=='both')" class="bio" innerHTML="{{ artist.biolonghtmlen }}"></p>


		<div class="profilepic">
			<img src="{{ artist.profilepic }}" class="profile-pic">
		</div>

		<div class="info">
			<span *ngIf="artist.website"><strong>Website: </strong>{{ artist.website }}</span>
			<span><strong>CONTACT KAJIMOTO PARIS</strong></span>
<!-- 			<span *ngIf="artist.kajimotomanagementtokyoname">
			   <strong>TOKYO MAIN OFFFICE</strong> <br>
			   {{ artist.kajimotomanagementtokyoname }}
			</span>
			<a *ngIf="artist.kajimotomanagementtokyoemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementtokyoemail}}">{{ artist.kajimotomanagementtokyoemail }}</a> -->
			<span *ngIf="artist.kajimotomanagementparisname">
			   <strong>PARIS LIAISON OFFFICE</strong> <br>{{ artist.kajimotomanagementparisname }}</span>
			<a *ngIf="artist.kajimotomanagementemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementemail}}">{{ artist.kajimotomanagementemail }}</a>
			<br>
			<a *ngIf="artist.kajimotomanagementphoneoffice" class="pm-link" [ngClass]="linkClass" href="tel:{{ artist.kajimotomanagementphoneoffice }}">Office : {{ artist.kajimotomanagementphoneoffice }}</a><br>      
			<a *ngIf="artist.kajimotomanagementphone" class="pm-link" [ngClass]="linkClass" href="tel:{{ artist.kajimotomanagementphone }}">Mobile : {{ artist.kajimotomanagementphone }}</a>
			<br>
			<!-- 			
			<span *ngIf="artist.kajimotomanagementbeijingname">
			   <strong>BEIJING LIAISON OFFFICE</strong> <br> {{ artist.kajimotomanagementbeijingname }}</span>
			<a *ngIf="artist.kajimotomanagementbeijingemail" class="pm-link" [ngClass]="linkClass" href="mailto:{{artist.kajimotomanagementbeijingemail}}">{{ artist.kajimotomanagementbeijingemail }}</a> -->
			<span *ngIf="artist.kajimotomanagementrepresentation">
			  <strong>GENERAL MANAGEMENT</strong><br>{{ artist.generalmanagementname }}<br> 
			  <strong *ngIf="artist.kajimotomanagementrepresentation">REPRESENTATION </strong><br>{{ artist.kajimotomanagementrepresentation }}
			</span>
		</div>

	</mat-card-content>

</mat-card>


