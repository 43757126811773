import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, state, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { PublicService } from './../shared/public.service';
import { NewsService } from './../../news/shared/news.service';
import { map, Observable, shareReplay } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { timer, Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { UpdateService } from '../../update.service';
import { SingleNews } from '../../news/shared/news';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  animations: [
    trigger('kajimotoIntroAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('100ms', [
          animate('.1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateX(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateX(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('.1s ease-out', keyframes([
            style({opacity: 1, transform: 'translateX(0)', offset: 0}),
            style({opacity: .5, transform: 'translateX(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateX(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class HomepageComponent implements OnInit {

  showmenuartist = false;
  showmenucontact = false;
  showmenuaboutus = false;

  urllogostart: string;
  urllogoend: string;

  news1txt: string; news1pic: string;
  news2pic1: string; news2title: string; news2txt: string;
  news3pic: string; news3title: string; news3txt: string;

  allnews$:  Observable<SingleNews[]>;
  topnews$: Observable<SingleNews[]>;
  downnews$: Observable<SingleNews[]>;

  homeIntro = true;
  homeHeader = false;
  homeFooter = false;

  newsUp = false;
  newsDown = false;
  coverMenuButton = true;

  nbslides: number;
  slideShown = 1;
  items: any;
  bg: string;
  slideshowtimer = 200000;
  kab: string;
  kay: string;
  jib: string;
  jiy: string;
  mob: string;
  moy: string;
  tob: string;
  toy: string;
  startinglogoimgurl = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o';

  kabimg = new Image();
  kayimg = new Image();
  jibimg = new Image();
  jiyimg = new Image();
  mobimg = new Image();
  moyimg = new Image();
  tobimg = new Image();
  toyimg = new Image();
  private subscription: Subscription;


  slidesImg: string[] = [];
  slidesImgMobile: string[] = [];
  slideSubscription: Subscription;

  // width of screen
  innerWidth: number;


  constructor(
    private titleService: Title,
    private meta: Meta,
    private _sanitizer: DomSanitizer,
    private newsService: NewsService) {


    this.kabimg.src = `${this.startinglogoimgurl}/theme%2FLogo_KA_blue.png?alt=media&token=d8980906-df49-4477-ad80-2730becdbc98`;
    this.kayimg.src = `${this.startinglogoimgurl}/theme%2FLogo_KA_yellow.png?alt=media&token=4a1389fe-5dfd-4abb-ac49-9e7aa87076bd`;
    this.jibimg.src = `${this.startinglogoimgurl}/theme%2FLogo_JI_blue.png?alt=media&token=50244913-4723-44f4-b193-db24fd0c070c`;
    this.jiyimg.src = `${this.startinglogoimgurl}/theme%2FLogo_JI_yellow.png?alt=media&token=c8a5a33f-8caf-4f15-bd80-2b14b61e04e3`;
    this.mobimg.src = `${this.startinglogoimgurl}/theme%2FLogo_MO_blue.png?alt=media&token=668bceb7-555a-457f-92e9-0ca170f642d0`;
    this.moyimg.src = `${this.startinglogoimgurl}/theme%2FLogo_MO_yellow.png?alt=media&token=9c4c58c0-d376-457f-9942-3aa684778ad7`;
    this.tobimg.src = `${this.startinglogoimgurl}/theme%2FLogo_TO_blue.png?alt=media&token=07302d4c-deea-4724-9b81-7ad84bc54753`;
    this.toyimg.src = `${this.startinglogoimgurl}/theme%2FLogo_TO_yellow.png?alt=media&token=3cca9306-bdad-40bc-ad36-3278c492e7db`;

    this.kab = this.kabimg.src;
    this.kay = this.kayimg.src;
    this.jib = this.jibimg.src;
    this.jiy = this.jiyimg.src;
    this.mob = this.mobimg.src;
    this.moy = this.moyimg.src;
    this.tob = this.tobimg.src;
    this.toy = this.toyimg.src;

    this.items  = [
      {img: null    , bg: 'bg-darkblue', layout: 'start center', flex: '40', flexxs: '10' },
      {img: this.kay, bg: 'bg-darkblue', layout: 'start center', flex: '10', flexxs: '20' },
      {img: this.jiy, bg: 'bg-darkblue', layout: 'start center', flex: '10', flexxs: '20' },
      {img: this.mob, bg: 'bg-yellow',   layout: 'start center', flex: '10', flexxs: '20' },
      {img: this.tob, bg: 'bg-yellow',   layout: 'start center', flex: '10', flexxs: '20' },
      {img: null,     bg: 'bg-yellow',   layout: 'start center', flex: '20', flexxs: '10' },
    ];

  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;
    console.log('Screen width : ', innerWidth);

    // this.updateService.checkForUpdate();

    this.titleService.setTitle('Kajimoto Music Paris | Management, Concerts & Touring');
    this.meta.addTags([
      { name: 'title', content: 'Kajimoto Music Paris | Home'},
      { name: 'description', content: 'Find all the last news of KAJIMOTO MUSIC PARIS OFFICE.'},
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com` },
    ]);

    let urllogoendImg = new Image();
    let urllogostartImg = new Image();

    urllogostartImg.src = `${this.startinglogoimgurl}/uploads%2Flogo-kajim.png?alt=media&token=e98294a3-25b9-4baa-8857-7f5426211107`; 
    urllogoendImg.src = `${this.startinglogoimgurl}/uploads%2Flogo-oto.png?alt=media&token=d201bf8c-990f-41c9-8532-365029fd48af`;

    this.urllogostart = urllogostartImg.src;
    this.urllogoend   = urllogoendImg.src;

    // Classement des news
    const allNews$ = this.newsService.getAllHomepageNews().pipe(shareReplay(1)); // Fetch once and share

    // Split the data into `topnews` and `downnews`
    this.topnews$ = allNews$.pipe(
      map((news) => news.filter((item) => item.homeposition === 'hpup'))
    );

    this.downnews$ = allNews$.pipe(
      map((news) => news.filter((item) => item.homeposition === 'hpdown'))
    );

    // Préparation des images pour les slides
    this.subscription = this.topnews$.subscribe((topnews: SingleNews[]) => {
      this.nbslides = topnews.length;
      topnews.forEach((singleNews, index) => {
        const img = new Image();
        img.src = singleNews.imgurl;
        img.onload = () => {
          console.log(`Image ${index} loaded successfully:`, singleNews.imgurl);
        };
        img.onerror = () => {
          console.error(`Error loading image ${index}:`, singleNews.imgurl);
        };
      });      
    }); 
    
    // Lancer l'affichage après chargement
    setTimeout(() => this.launchHome(), 2500);

  }


  launchHome() {
    this.coverMenuButton = false;
    this.homeHeader  = true;
    this.homeIntro   = false;
    this.newsUp      = true;
    this.newsDown    = true;
    this.homeFooter  = true;

    const numbers = timer(this.slideshowtimer, this.slideshowtimer);
    this.slideSubscription = numbers.subscribe(x => {
      this.slideShown = (this.slideShown + 1 < this.nbslides) ? (this.slideShown + 1) : 0;
    });
  }

  changeSlide(s, t) {
    this.slideShown = (s < t) ? s : 0;
    this.slideSubscription.unsubscribe();
    const numbers = timer(this.slideshowtimer, this.slideshowtimer);
    this.slideSubscription = numbers.subscribe(x => {
      this.slideShown = (this.slideShown + 1 < this.nbslides) ? (this.slideShown + 1) : 0;
    });
  }


  ngOnDestroy() {
    if (this.slideSubscription) {
      this.slideSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }    
  }

  getBackground(image) {
    let img = new Image();
    img.src = image ? image : 'assets/bg-phone.jpg';
    return this._sanitizer.bypassSecurityTrustStyle(`url('${img.src}')`);
  }


  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

}







