import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

import { EventService } from './../../events/shared/event.service'
import { VenueService } from './../shared/venue.service';
import { Venue } from './../shared/venue';

@Component({
  selector: 'app-venue-detail',
  templateUrl: './venue-detail.component.html',
  styleUrls: ['./venue-detail.component.scss']
})
export class VenueDetailComponent implements OnInit {

	venue: Observable<any>;
  event: Observable<any>;
  eventkey: string;

  constructor(
  	private venueService: VenueService,
    private eventService: EventService,
  	private route: ActivatedRoute,
    private router: Router,
  	private location: Location) { }

  ngOnInit() {
    this.venue = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.venueService.getVenueWithKey(params.get('id'))));

    this.route.params.subscribe((params: Params) => { this.eventkey = params['event']; });

    if (this.eventkey === undefined) {
      this.event = null ;
    } 
    else {
      this.event = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.eventService.getEventWithKey( this.eventkey)));
    }
  }

  deleteVenue(venue) {
    this.venueService.deleteVenue(venue);
  }

  updateField(venue,field,value) {
    this.venueService.updateVenue(venue,field,value);
  }

  goBack(event?, venue?): void {
    if(event && venue){
      this.eventService.addVenueToEvent(event, venue);
      this.location.back();
      console.log(event,venue);
    } else{
      this.location.back();
    }
  }

}
