import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventDetailComponent } from '../event-detail/event-detail.component';
import { AdminGuard } from '../../core/admin.guards';
import { EventListComponent } from '../event-list/event-list.component';
import { EventFormComponent } from '../event-form/event-form.component';
import { TourListComponent } from '../tour-list/tour-list.component';
import { TourDetailComponent } from '../tour-detail/tour-detail.component';
import { EventtourFormComponent } from '../eventtour-form/eventtour-form.component';
import { EventProgramComponent } from '../event-program/event-program.component';
import { EventVenueComponent } from '../event-venue/event-venue.component';
import { EventsRoutingComponent } from '../events-routing/events-routing.component';

const routes: Routes = [  
    { path: 'event/:id', component: EventDetailComponent, canActivate: [AdminGuard] },  
    { path: 'eventslist', component: EventListComponent, canActivate: [AdminGuard] },
    { path: 'eventform', component: EventFormComponent, canActivate: [AdminGuard] },
    { path: 'tours', component: TourListComponent, canActivate: [AdminGuard] },
    { path: 'tour/:id', component: TourDetailComponent, canActivate: [AdminGuard] },
    { path: 'eventtourform/:id', component: EventtourFormComponent, canActivate: [AdminGuard] },
    { path: 'program/:id', component: EventProgramComponent, canActivate: [AdminGuard] },
    { path: 'eventvenue/:id/:key', component: EventVenueComponent, canActivate: [AdminGuard] },
    { path: 'routing/:id', component: EventsRoutingComponent }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }


