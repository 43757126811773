import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import packageInfo from './../../../../package.json';

@Component({
  selector: 'footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  isCookies = sessionStorage.getItem( '1');
  isLocalCookies = true;
  cookies: any;
  version: string = (packageInfo as any).version;

  constructor(public dialog: MatDialog,
              private ps: PublicService) { }

  ngOnInit() {
    this.ps.getfooterModal('footermodal').subscribe((val) => {
      this.cookies = val.cookies;
    });
    this.cookiesOff();
  }

 updateCookies() {
    sessionStorage.setItem( '1' , 'false');
    localStorage.setItem('1','false')
    this.isLocalCookies = false ;
  }

  cookiesOff(){
    if(localStorage.getItem('1') == 'false'){
      this.isLocalCookies = false;
    }
  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

  openDialog(part): void {
    const dialogRef = this.dialog.open(DialogFooter, {
      width: '650px',
      backdropClass: 'footerDialog',
      data: {
          part: part
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  
  // Click event handler function for Scrolling
  scrollToTop( ){
    scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

}



@Component({
  selector: 'dialog-footer',
  templateUrl: 'dialog-footer.html',
  styleUrls: ['./dialog-footer.scss']
})
export class DialogFooter {

  allartists: Observable<any[]>;
  genres: any;
  isgenre: boolean = false;
  seasons: any;
  isseason: boolean = false;
  isartist: boolean = false;
  part: string;
  dialogTitle: string;
  page: any;
  editCredit: boolean;


  constructor(
    private router: Router,
    public  auth: AuthService,
    private ps: PublicService,
    public dialogRef: MatDialogRef<DialogFooter>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.part = data.part;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {

    this.page = this.ps.getPageVariables('footermodal');


    if(this.part == 'terms') {
      this.dialogTitle = 'Privacy policy KAJIMOTO web site';
    }
    else if (this.part === 'cookies') {
      this.dialogTitle = 'Cookies';
    }
    else if (this.part == 'credits') {
      this.dialogTitle = 'Credits Photos';
    }    
    else if (this.part == 'jobs') {
      this.dialogTitle = 'Jobs Application';
    }   
    else if (this.part == 'legal') {
      this.dialogTitle = 'Legal Notice';
    }
    else if(this.part == 'newsletter') {
      this.dialogTitle = 'Subscribe to our Newsletter';
    }
  }






  updateCredit(page, field, value) {
    this.ps.updatePage(page, field, value);
  }


}


