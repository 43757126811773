 <img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" alt="" class="logo-artist-page" [ngStyle]="{'width':'250px','padding-left':'100px'}">


<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="addHistoryForm = !addHistoryForm"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!addHistoryForm">edit</mat-icon>
  <mat-icon *ngIf="addHistoryForm">close</mat-icon>
</button>


<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>


<div class="bounds">
	<div class="content fx-row fx-wrap fx-col-xs fx-fill"

	*ngIf="histories | async; let histories; else loading">

		<div class="fx-start-start fx-100">
			<h1 class="darkblue h1-style-xs" [ngStyle.xs]="{'font-size':'1.3em', 'padding':'0 0 0 90px'}">History</h1>
		</div>

		<div *ngIf="addHistoryForm" class="fx-100 fx-col contacform">
		<form class="fx-col" #historyForm="ngForm" (ngSubmit)="onSubmit(historyForm)">
			<h4>New history</h4>
			<div class="fx-row fx-wrap fx-gap-5">          
					<mat-form-field class="fx-15">
						<mat-label> Select Year </mat-label>
					<mat-select required
						ngModel #year="ngModel"
							name="year">
						<mat-option *ngFor="let year of yearInput" 
							[value]="year">
						{{ year }}
						</mat-option>
					</mat-select>
					</mat-form-field>	          
			<mat-form-field class="fx-30">
				<mat-label> Details </mat-label>
				<input matInput required
				ngModel #details="ngModel"
				name="details">
			</mat-form-field> 
			<mat-form-field class="fx-30">
				<mat-label> Image Url </mat-label>
				<input matInput 
				ngModel #img="ngModel"
				name="img">
			</mat-form-field>           
			<button mat-fab type="submit" color="warn"
				[disabled]="!historyForm.form.valid">
				<mat-icon>save</mat-icon>
			</button>           
			</div>        
		</form>                             
		</div>
		


		<div style="padding-left: 10px" class="history fx-100 "
		*ngFor="let history of histories; let i=index; "
		[id]=history.year
		[ngClass]="{'seeing':history.year==dateseeing}"
		(ngx-scroll-tracker)="handler(histories[i-2],histories[i-1],$event,histories[i+1],histories[i+2])">
			<div class="fx-15 fx-ai-start-xs max-width-15 fx-center-center">
				<span class="history-date" 
					(click)="readDate(histories[i-2],histories[i-1],history,histories[i+1],histories[i+2])"
					[ngClass]="{
											'history-date-seen history-date-seen-xs':history.year==dateseeing,
											'hasseendate hasseendate-xs':history.year==dateprev,
											'willseedate willseedate-xs':history.year==datenext
										}"
					[ngStyle.xs]="{'width':'50px','height':'50px','font-size':'1em','line-height':'50px' }">
					{{ history.year }}
				</span>
			</div>
			<div class="fx-55 width-55 width-60-xs fx-col-xs fx-60-xs">		
				<span class="history-txt"
					[ngStyle.xs]="{'font-size':'0.8em' }"
					[ngClass]="{
											'history-txt-seen history-txt-seen-xs':history.year==dateseeing,
											'hasseentxt':history.year==dateprev,
											'willseetxt':history.year==datenext,
											'willwillseetxt':history.year==datenextnext,
											'hashasseentxt':history.year==dateprevprev
										}"
					innerHTML="{{ history.details }}">
				</span>
				<span class="history-img" *ngIf="history.img&&history.year==dateseeing">
					<img [ngStyle.xs]="{'max-width':'237px'}" src="{{ history.img }}">
				</span>					
			</div>
			<div class="fx-30 max-width-30 fx-start-center-xs fx-15-xs">		

				<button mat-icon-button
					*ngIf="history.year==dateseeing"
					(click)="readNext(history.year,histories[i+1],histories[i+2],histories[i+3],histories[i-1])">
						<mat-icon class="but-next-date">keyboard_arrow_down</mat-icon>
				</button>
				&nbsp;&nbsp;
				<button mat-icon-button color="warn"
					*ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addHistoryForm" 
					[routerLink]="['./../uploadfile', 'history',history.$key]">
						<mat-icon class="but-next-date">add_photo_alternate</mat-icon>
				</button>
				&nbsp;&nbsp;
				<button mat-icon-button color="warn"
					*ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addHistoryForm" 
					(click)="deleteHistory(history)">
						<mat-icon class="but-next-date">delete</mat-icon>
				</button>

			</div>
		</div>
	</div>
</div>

<br><br>

<footer-nav></footer-nav> 