import { Meta } from '@angular/platform-browser';
import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';
import { PublicService } from '../shared/public.service';

@Component({
  selector: 'events-artist-print',
  templateUrl: './events-artist-print.component.html',
  styleUrls: ['./events-artist-print.component.scss']
})
export class EventsArtistPrintComponent implements OnInit {

	@Input() eventkey: string;

	getevent:any;artists:any;

  constructor(
    private eventService: EventService,
    private ps: PublicService,
    private meta: Meta
		) { }

  ngOnInit() {
    this.artists 	= this.eventService.getArtistsEvent(this.eventkey);

    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/history` },
      { name: 'og:title', content: 'Contact Page'},
      { name: 'og:description', content: 'Contact me through this awesome search engine optimized Angular component'}, 
      { name: 'og:image', content: `https://instafire-app.firebaseapp.com/assets/meerkat.jpeg`},
    ]);
  }


}
