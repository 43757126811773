<ng-template #loading>        
	<br><br><br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
  </ng-template>
  
<div *ngIf="coverMenuButton" class="cover-menu-button"></div>
  
<div *ngIf="homeIntro" [@kajimotoIntroAnimation]="6"
	(click)="launchHome()"
	class="fx-row fx-wrap fx-end-start fx-fullscreen fx-row-xs fx-wrap-xs fx-fullscreen-xs"  >
		<div *ngFor="let item of items" 
			class="{{item.bg}} fx-start-center fx-fullscreen fx-{{item.flex}}
			 fx-start-center-xs fx-fullscreen-xs fx-{{item.flexxs}}-xs">
				<img src="{{ item.img }}" class="img-respons logo-intro-img">
		</div>
</div>

  
<div *ngIf="homeHeader" class="content logo-grid fx-row fx-wrap">
  
	<!-- ONLY MOBILE -->
	<div [ngStyle.xs]="{ 'height':'15px' }" class="hide-desktop fx-20-xs bg-darkblue"></div>
	<div [ngStyle.xs]="{ 'height':'15px' }" class="hide-desktop fx-20-xs bg-yellow"></div>
	<div [ngStyle.xs]="{ 'height':'15px' }" class="hide-desktop fx-20-xs bg-darkblue"></div>
	<div [ngStyle.xs]="{ 'height':'15px' }" class="hide-desktop fx-20-xs bg-yellow"></div>
	<div [ngStyle.xs]="{ 'height':'15px' }" class="hide-desktop fx-20-xs bg-darkblue"></div>	

	<div class="hide-mobile fx-40 fx-end-end bg-darkblue height-firstrow">
		<div class="logo-accroche">
		<h1>MANAGEMENT, CONCERTS & TOURING</h1>
		<span routerLink="/artists" class="but-office">Paris</span>
		<span (click)="gotoExternalHref('https://www.kajimotomusic.com/')" class="but-office">Tokyo</span>
		<span routerLink="/artistschina" class="but-office">Beijing</span>

		<!-- OLD TOKYO KAJIMOTOMUSIC LINK-->
		<!-- <span (click)="gotoExternalHref('http://www.kajimotomusic.com/en/artists/')" class="but-office">Tokyo</span> -->
		</div>
	</div>
	<div class="fx-20-xs fx-0 bg-darkblue">
	</div>
	<div   class="fx-10 fx-20-xs fx-start-end bg-yellow logo-letters"><img src="{{ kab }}"></div>
	<div   class="fx-10 fx-20-xs fx-start-end bg-darkblue logo-letters"><img src="{{ jiy }}"></div>
	<div   class="fx-10 fx-20-xs fx-start-end bg-yellow logo-letters"><img src="{{ mob }}"></div>  
	<div   class="fx-10 fx-20-xs fx-start-end bg-darkblue logo-letters"><img src="{{ toy }}"></div>  
	<div   class="fx-20 fx-0-xs fx-start-end bg-yellow"></div>    
    
	<!-- MENU MOBILE -->
	<div class="hide-desktop fx-20-xs bg-darkblue"></div>
	<div routerLink="/artists" class="hide-desktop fx-20-xs bg-yellow darkblue hp-mobile-menu">ARTIST</div>
	<div routerLink="/touring" class="hide-desktop fx-20-xs bg-darkblue yellow hp-mobile-menu">TOURING</div>
	<div routerLink="/events" class="hide-desktop fx-20-xs bg-yellow darkblue hp-mobile-menu">EVENT</div>
	<div routerLink="/contact" class="hide-desktop fx-20-xs bg-darkblue yellow hp-mobile-menu">CONTACT</div>

	<!-- MENU DESKTOP -->
	<div class="hide-mobile fx-20 bg-darkblue fx-center-center"></div>
	<div routerLink="/artists" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu">ARTISTS</div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div routerLink="/touring" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu">TOURING</div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div routerLink="/events" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu">EVENTS</div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div class="hide-mobile fx-20 bg-yellow fx-center-center"></div>
  
  
	  
	<div class="hide-mobile fx-20 bg-yellow fx-center-center"></div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div 
		(click)="
				showmenuaboutus=true;		
				showmenuartist=false;
				showmenucontact=false
				" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu">ABOUT US</div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div routerLink="/offices" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu" >OFFICES</div>
	<div class="hide-mobile fx-10 fx-center-center bg-darkblue"></div>
	<div routerLink="/contact" class="fx-10 fx-center-center hide-mobile bg-yellow hp-menu">CONTACT</div>
	<div class="hide-mobile fx-20 bg-darkblue fx-center-center"></div>
  
  
	<div  
		*ngIf="showmenuaboutus" (click)="showmenuaboutus=false" class="fx-20 bg-darkblue fx-center-center"></div>
	<div   
		routerLink="/words"
		*ngIf="showmenuaboutus" class="fx-10 fx-center-center bg-white">Words</div>
	<div  
		*ngIf="showmenuaboutus" (click)="showmenuaboutus=false" class="fx-10 fx-center-center bg-darkblue"></div>
	<div   routerLink="/history"	
		*ngIf="showmenuaboutus" class="fx-10 fx-center-center bg-white">History</div>
	<div  
		  *ngIf="showmenuaboutus" (click)="showmenuaboutus=false" class="fx-50 bg-darkblue fx-center-center"></div>
  
</div>
	
<div *ngIf="newsUp" class="fx-col grad-home">
	<div *ngIf="topnews$ | async; let topnews; else loading" class="fx-col xs-fullscreen-slideshow">
		<div *ngFor="let topnew of topnews; let i=index" class="fx-col xs-fullscreen-slideshow">
			<div id="carousel-container" *ngIf="i==slideShown"
			(click)="changeSlide(i+1,topnews.length)"
			[style.background-image]="innerWidth > 500 ? getBackground(topnew.imgurl) : getBackground(topnew.imgurlmobile)"
			[ngStyle]="{'background' : 'no-repeat center url(' + imgurl + ')center'}"
			[ngStyle.xs]="{ 'background' : 'no-repeat url(' + imgurlmobile + ') center center'}"
			class="xs-fullscreen-slideshow">

				<div class="dark-layer" [ngClass]="{ 'dark-layer-soft' : i === slideShown }">
					<div class="cartouche-news-uplayer cartouche-news-uplayer-xs">
						<div class="cartouche-news-content">
							<h3 class="fx-ai-center-xs"
								[ngStyle]="{ 'margin':'0' }"
								[ngStyle.xs]="{ 'padding':'0', 'margin':'0','font-size':'1.5em' }">
								{{ topnew.title }}
							</h3>
							<p class="fx-ai-center-xs"
								[ngStyle.xs]="{ 'font-size':'0.8em', 'max-width':'300px' }" innerHTML="{{ topnew.content }}"></p>
							<span class="fx-ai-center-xs"
								*ngIf="!topnew.link"
								[ngStyle.xs]="{ 'padding':'7px 100px', 'margin':'20px 0 0'}" 
								[routerLink]="['./artist', topnew.artistkey]">
									Read More
							</span>
							<span class="fx-ai-center-xs"
								(click)="gotoExternalHref(topnew.link)"
								*ngIf="topnew.link"
								[ngStyle.xs]="{ 'padding':'7px 100px', 'margin':'20px 0 0'}">
								<a [href]="topnew.link" style="text-decoration: none;" target="_blank" rel="noopener noreferrer">Read More</a>	
							</span>
						</div>							 
						<div class="pellet-container">
								<div *ngFor="let pastille of topnews; let j=index" class="pellet"
									(click)="changeSlide(j,topnews.length)"
									[ngClass]="{'pellet-selected':j==i}">&nbsp;</div>
						</div>							  
					</div>
				</div>
			</div>						
	</div>
</div>



  
<div *ngIf="newsDown" [ngStyle]="{'background-color':'#FFF'}">
	<div *ngIf="downnews$ | async; let downnews" class="content fx-row fx-wrap">
			<div class="fx-100 mob-style fx-start-start">	
			<h3 
				[ngStyle]="{ 'padding':'100px 0 30px 50px','font-size':'1.7em', 'font-weight':'900' }">Kajimoto featured news</h3>
			</div>
			<div class="fx-100 fx-row fx-wrap fx-gap-1 bg-black">
				<div class="fx-start-end bg-black downnews fx-fill-xs fx-calc-33-1px"
					*ngFor="let downnew of downnews"
					[style.background-image]="getBackground(downnew.imgurl)"
					[ngStyle]="{ 'height':'300px', 'background' : 'no-repeat center center' }"				
					(click)="gotoExternalHref(downnew.link)">
						<span class="fx-col fx-100" *ngIf="downnew.title&&downnew.content">
							<strong>{{ downnew.title}}</strong>
							<!-- KEY : {{ downnew.$key }} <br> -->
							{{ (downnew.content.length>55) ? (downnew.content | slice:0:55)+'...':(downnew.content) }} 
							<mat-icon>link</mat-icon>
						</span>
				</div>
			</div>	
	</div>
</div>
  
  
<footer-nav *ngIf="homeFooter"></footer-nav>	
  