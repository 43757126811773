import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';
import { ManagementChildComponent , DialogManagementComponent } from './../management-child/management-child.component';
import { ManagementComponent } from './../management/management.component';
import { RepairComponent } from './../repair/repair.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        BrowserAnimationsModule,
        AngularMaterialModule
    ],
    declarations: [
        ManagementComponent,
        ManagementChildComponent,
        DialogManagementComponent,
        RepairComponent
    ]
})
export class AdminModule { }
