import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'app-event-artist',
  templateUrl: './event-artist.component.html',
  styleUrls: ['./event-artist.component.scss']
})
export class EventArtistComponent implements OnInit {

	@Input() eventkey: string;

	getevent:any;artists:any;

  constructor(
  	private eventService: EventService
		) { }

  ngOnInit() {
  	// this.getevent = this.eventService.getEventWithKey(this.eventkey);
  	this.artists 	= this.eventService.getArtistsEvent(this.eventkey);
  }

}