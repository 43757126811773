// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    firebaseConfig: {
		apiKey: 'AIzaSyAr0NhBIK2vf-ELk7OgE9TK51cgHwkfh-E',
	    authDomain: 'kajimotomusic-prod.firebaseapp.com',
	    databaseURL: 'https://kajimotomusic-prod.firebaseio.com',
	    projectId: 'kajimotomusic-prod',
	    storageBucket: 'kajimotomusic-prod.appspot.com',
	    messagingSenderId: '931699323418'
    }
};


// export const environment = {
//     production: true,
//     firebaseConfig: {
// 	    apiKey: "AIzaSyBsJ0aSJMw8m84-qDOpF2xJDLwaXlxff7k",
// 	    authDomain: "kajimoto-fabrice.firebaseapp.com",
// 	    databaseURL: "https://kajimoto-fabrice.firebaseio.com",
// 	    projectId: "kajimoto-fabrice",
// 	    storageBucket: "kajimoto-fabrice.appspot.com",
// 	    messagingSenderId: "965182434374"
//     }
// };



// export const environment = {
//     production: true,
//     firebaseConfig: {
// 	    apiKey: "AIzaSyDLfO-5KNhshKFBU6JRTD9qyJ8YMfy4oJA",
// 	    authDomain: "kajimotomusic-dev.firebaseapp.com",
// 	    databaseURL: "https://kajimotomusic-dev.firebaseio.com",
// 	    projectId: "kajimotomusic-dev",
// 	    storageBucket: "kajimotomusic-dev.appspot.com",
// 	    messagingSenderId: "794513205325"
// 	}
// };
