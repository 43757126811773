import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { NewsService } from './../shared/news.service';
import { SingleNews } from '../shared/news';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default, // Assurez-vous que cette valeur est correcte
})
export class NewsDetailComponent implements OnInit {

	newskey:any;
	news: Observable<any>;
  errors           : boolean = false;
  errorMessage: any;
  dropzone          : boolean = false;
  maxsize: number;
  
  // private updateFieldSubject = new Subject<{ news: any; field: string; value: any }>();
  // private updateFieldSubject = new BehaviorSubject<{ news: any; field: string; value: any } | null>(null);
  private updateFieldSubject = new Subject<{ news: SingleNews; field: string; value: any }>();

  //Mobile
  errorsMobile           : boolean = false;
  errorMessageMobile: any;
  dropzoneMobile          : boolean = false;
  maxsizeMobile: number;

  task              : AngularFireUploadTask;
  percentage        : Observable<number>;
  snapshot          : Observable<any>;

  downloadURL       : Observable<string>;
  uploadPercent     : Observable<number>;

  // Mobile
  downloadURLMobile       : Observable<string>;
  uploadPercentMobile    : Observable<number>;

  showDropzone      : boolean = true;
  showProgressbar    : boolean = true;
  enableSaveButton  : boolean = false;
  showRefreshBut    : boolean = false;
  display: boolean = true;

  // Mobile
  showRefreshButMobile    : boolean = false;
  showProgressbarMobile    : boolean = true;
  showDropzoneMobile      : boolean = true;

  isHovering        : boolean;
  isHoveringMobile        : boolean;
  isUrlUpdated      : boolean = true;
  isUrlUpdatedMobile      : boolean = true;
  isDeleting = false;

  constructor(
    private newsService  : NewsService,    
  	private route        : ActivatedRoute,
    private storage: AngularFireStorage,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const newskey = this.route.snapshot.paramMap.get('id'); // Récupération directe du paramètre
    if (newskey) {
      this.news = this.newsService.getNewsWithKey(newskey);
    } else {
      console.error('No news key provided in route.');
      this.news = of(null); // Fournit une valeur par défaut pour éviter les erreurs
    }

    // Gestion des mises à jour avec debounce
    this.updateFieldSubject
      .pipe(debounceTime(1000))
      .subscribe(({ news, field, value }) => {
        // Appeler le service pour effectuer la mise à jour
        this.newsService.updateNewsField(news, field, value);
    });
  }

  updateField(news,field,value) {
    if (!news || !field) {
      console.error('Invalid parameters for updateField:', { news, field, value });
      return;
    }
    this.updateFieldSubject.next({ news, field, value }); 
  }

  deleteNews(news) {
    if (this.isDeleting) {
      return; // Évite les appels répétés
    }
    this.isDeleting = true;
    this.newsService.deleteNews(news).finally(() => {
      this.newsService.clearCacheForKey(news.$key); // Supprime du cache
      this.isDeleting = false; // Réinitialise l'état
      this.news = null;
    });
  }



  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }


  selectFile(event,news) {

    console.log(event,news);
    // console.log(event[0]);
    // console.log(event[0].size);

    this.maxsize            = 500000;
    this.errors             = false;
    const file              = event[0];
    const fileSize          = event[0].size;
    const fileType          = event[0].type;
    // const fileName          = event[0].name;
    // const fileExtension     = fileName

    // Client-side validation example
    if (file.type.split('/')[0] !== 'image' || file.type.split('/')[1] !== 'jpeg') { 
    //   console.error('unsupported file type :( ')
      this.showRefreshBut   = true;
      this.errors           = true;
      // this.enableSaveButton = false;
      this.errorMessage     = `Size type must be image/jpeg. The file you have uploaded is ${fileType}`;
    }    
    else if (fileSize > this.maxsize) { 
      this.showRefreshBut   = true;
      this.errors           = true; 
      var sizeInKo          = fileSize/1000;
      // this.enableSaveButton = false;            
      this.errorMessage     = `Size file too large (${sizeInKo} Ko)`;      
    }
    else if (!this.errors) {
      this.showDropzone     = false;
      this.errors           = false; 
      this.showProgressbar  = false;
      // this.enableSaveButton = true;
      console.log("upload to console");      

      const filePath        = `news/${news.$key}.jpg`;
      const fileRef         = this.storage.ref(filePath);
      const task            = this.storage.upload(filePath, file);

      // observe percentage changes
      this.uploadPercent    = task.percentageChanges();
      // get notified when the download URL is available    
      task.snapshotChanges().pipe(
        finalize(() => 
          this.downloadURL = fileRef.getDownloadURL() )
      ).subscribe()
    }
  }

  async selectFileMobile(event,news) {

    console.log(event,news);
    // console.log(event[0]);
    // console.log(event[0].size);

    this.maxsizeMobile            = 500000;
    this.errorsMobile             = false;
    const file              = event[0];
    const fileSize          = event[0].size;
    const fileType          = event[0].type;
    // const fileName          = event[0].name;
    // const fileExtension     = fileName

    // Client-side validation example
    let  dimension = await this.getImageDimension(window.URL.createObjectURL(file));
    
    if (file.type.split('/')[0] !== 'image' || file.type.split('/')[1] !== 'jpeg') { 
    //   console.error('unsupported file type :( ')
      this.showRefreshButMobile   = true;
      this.errorsMobile           = true;
      // this.enableSaveButton = false;
      this.errorMessageMobile     = `Size type must be image/jpeg. The file you have uploaded is ${fileType}`;
    } else if (fileSize > this.maxsizeMobile) 
    { 
      this.showRefreshButMobile   = true;
      this.errorsMobile           = true; 
      var sizeInKo          = fileSize/1000;
      // this.enableSaveButton = false;            
      this.errorMessageMobile     = `Size file too large (${sizeInKo} Ko)`;      
    } else if(dimension.largeur == 750 && dimension.hauteur >= 1334) 
    {
      console.log('Largeur : ', dimension.largeur, ' - Hauteur : ', dimension.hauteur)
      alert('Entrez une image entre 750 et 1334px');
    } else if (!this.errorsMobile) 
    {
      this.showDropzoneMobile     = false;
      this.errorsMobile           = false; 
      this.showProgressbarMobile  = false;
      // this.enableSaveButton = true;
      console.log("upload to console");      

      const filePath        = `news/${news.$key}_mobile.jpg`;
      const fileRef         = this.storage.ref(filePath);
      const task            = this.storage.upload(filePath, file);

      // observe percentage changes
      this.uploadPercentMobile    = task.percentageChanges();
      // get notified when the download URL is available    
      task.snapshotChanges().pipe(
        finalize(() => 
          this.downloadURLMobile = fileRef.getDownloadURL() )
      ).subscribe()
    }
  }


  removePicture(news) {
    this.updateField(news,'imgurl',null);
    this.isUrlUpdated = true;
  }

  removePictureForMobile(news) {
    this.updateField(news,'imgurlmobile',null);
    this.isUrlUpdatedMobile = true;
    this.storage.ref(`news/${news.$key}_mobile.jpg`).delete();
    this.downloadURLMobile = null;
    this.showDropzoneMobile = true;
    this.news.subscribe(data => console.log(data.imgurlmobile));
  }


  recordImgUrl(url,news,isUpdatedUrl) {
    // console.log('Quand on supprime');
    if(this.isUrlUpdated) {
      console.log(url);
      console.log(news);
      console.log(isUpdatedUrl);      
      this.updateField(news,'imgurl',url); 
    }
    this.isUrlUpdated = false;
  }

  recordImgUrlMobile(url,news,isUpdatedUrl) {
    
    if(this.isUrlUpdatedMobile) {
      console.log(url);
      console.log(news);
      console.log(isUpdatedUrl);
      this.updateField(news,'imgurlmobile',url);
    }
    this.isUrlUpdatedMobile = false;
  }

  getImageDimension(adresse: string) {
    const img = new Image();
    img.src = adresse;
    return new Promise<any>((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const heigth = img.naturalHeight;
        resolve(
          {largeur: width,
          hauteur: heigth}
          );
      };
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  toggleHoverMobile(event: boolean) {
    this.isHoveringMobile = event;
  }




}
