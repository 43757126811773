<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs logofortouringpage" [ngStyle]="{'width':'250px'}">

<button mat-icon-button type="button" color="black" class="doNotPrint"
	(click)="print_page()" 
	[ngStyle]="{ 'top':'50px', 'right':'50px','position':'absolute' }">
	<mat-icon>print</mat-icon>
</button>

<h1 class="doNotPrint">Routing</h1>

<div class="content" *ngIf="tour | async; let tour; else loading">

	<div class="fx-row fx-wrap fx-center-start fx-col-xs fx-fill">

		<div class="fx-100 fx-col">

			<h2 [ngStyle.xs]="{'font-size':'0.9em'}">{{ tour.title }}</h2>
			<p class="tour-date" [ngStyle]="{'font-size':'0.8em'}">from <strong>{{ tour.startdate | date }}</strong> to <strong>{{ tour.enddate | date }}</strong></p>

		</div>

		<div class="fx-100 fx-start-start">
			<events-routing-artist [artistkey]="tour.artistkey"></events-routing-artist>
		</div>

		<div class="fx-100" [ngStyle]="{'padding':'20px 0'}">
			<events-routing-events [tourkey]="tour.$key" [countevents]="tour.countevents"></events-routing-events>
		</div>

		<div class="fx-100 fx-row fx-wrap fx-col fx-start-start" >		

			<div *ngIf="tour.dresscode" class="fx-30 fx-col" [ngStyle]="{'padding-bottom':'20px'}">
				<h4>Dress Code</h4>
				<pre class="txt-pre">{{ tour.dresscode }}</pre>
			</div>			

			<div *ngIf="tour.hotels" class="fx-30 fx-col" [ngStyle]="{'padding-bottom':'20px'}">
				<h4>Hotels</h4>
				<p class="txt-pre" innerHTML="{{ tour.hotels }}"></p>
			</div>

			<div *ngIf="tour.comments" class="fx-30 fx-col" [ngStyle]="{'padding-bottom':'20px'}">
				<h4>Comments</h4>
				<p class="txt-pre" innerHTML="{{ tour.comments }}"></p>
			</div>		

		</div>

		<div *ngIf="routingDetails | async; let routingDetails; else loading" class="fx-100 fx-row fx-wrap" >
	
			<div class="fx-100" [ngStyle]="{'padding':'20px 0'}">
				<h4>Routing Details</h4>
			</div>

			<div class="fx-100 fx-row fx-wrap hoverline"
				*ngFor="let details of routingDetails; let i=index"
				[ngClass]="{ 'line-break':isNewDay(routingDetails[i-1],routingDetails[i]) }"
				>
				<div class="fx-10 fx-100-xs">
					<strong *ngIf="i>0; else firstdate">
						<span *ngIf="routingDetails[i-1].date.getDate()!=routingDetails[i].date.getDate()">
							<span class="date-span">{{ details.date | date }}</span>
						</span>
					</strong>
					<ng-template #firstdate>        
						<strong>
							<span class="date-span">{{ details.date | date }}</span>
						</strong>
					</ng-template>
				</div>
				<div class="fx-10 fx-jc-start fx-15-xs fx-start-start-xs" fxLayoutAlign.xs="start start" [ngStyle]="{'color':'#333','font-size':'0.8em'}">
					<strong>{{details.time}}</strong>
				</div>
				<div class="fx-start-start details-class fx-80 fx-85-xs" [ngStyle]="{'color':'#666','font-size':'0.8em'}">{{ details.details }} </div>
			</div>
<!-- 			<div class="fx-100" [ngStyle]="{'padding-top':'5px'}">			
				<strong>{{ tour.enddate | date }}</strong>
			</div> -->
		</div>

	</div>

	<br><br>

	<button mat-fab type="button" class="doNotPrint"
		*ngIf="(auth.user | async)?.role == 'admin'"
		color="black"
		[routerLink]="['./../../tour', tour.$key]"
	  	matTooltip="Edit Tour" matTooltipPosition="before">
	  		<mat-icon>edit</mat-icon>
	</button>

</div>
  	
