import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';


import { EventService } from './../shared/event.service';

@Component({
  selector: 'app-eventtour-form',
  templateUrl: './eventtour-form.component.html',
  styleUrls: ['./eventtour-form.component.scss']
})
export class EventtourFormComponent implements OnInit {

	tour: any; tourkey: string;

  constructor(
    private eventService: EventService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private location: Location
  	) { }

  ngOnInit() {
		this.tour = this.route.paramMap
  		.pipe(switchMap((params: ParamMap)=>
  			this.eventService.getTourWithKey(params.get('id'))));

    this.route.params.subscribe((params: Params) => {
        this.tourkey = params['id'];
    });  		
  }

  onSubmit(eventTourForm: NgForm) {
    this.eventService.createEvent(eventTourForm);
  }  

  goBack(): void {
    this.location.back();
  }

}