<ng-template #loading>        
  <br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>


<h1>{{userDisplayName}}</h1>


<div class="artists-list fx-row fx-wrap"
  *ngIf="artistList | async as artists; else loading"
  [ngStyle]="{'margin-top':'50px'}">
    <div 
      *ngFor="let artist of artists"
      class="fx-col artist artist-xs fx-20">
        <div class="text-inside-grid fx-enter-end"  
        [ngClass]="{'artistManaged':checkIfArtistManagedByagent(artist.agentkeys) }" 
        class="artistNoManaged" 
        (click)="redirection(artist.$key,artist.agentkeys)">
          <img *ngIf="artist.profilepic; else noProfilePicProvided" src="{{artist.profilepic}}" class="profile-pic" alt="{{ artist.lastname }} {{ artist.firstname}}">
          <ng-template #noProfilePicProvided>
            <img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fnoprofilepic.png?alt=media&token=c2f12f49-1942-4680-a637-b034a4902131" class="profile-pic" alt="No Profile Pic">
          </ng-template>
        </div>
        <div class="info-artist fx-start-start" 
          [ngClass]="{'artistManagedTitle':checkIfArtistManagedByagent(artist.agentkeys) }"
          [ngStyle]="{'padding':'10px 0','margin':'0'}">
            <span class="online-light" [ngClass]="{'bg-red':artist.isonline=='1'}"></span>
            <span class="name-artist">{{ artist.lastname }} {{ artist.firstname}}</span>
        </div>  
    </div>
</div>













<!-- 

<mat-card class="artists-list" *ngIf="artistList | async as artists; else loading"  >
  <mat-card-header>    
    <mat-card-title>Artists at Kajimotomusic Paris  managed by <strong>{{userDisplayName}}</strong> </mat-card-title>
    <mat-card-subtitle>Click an artist to see further informations</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="5" rowHeight="170px">
      <mat-grid-tile 
        *ngFor="let artist of artists"> 
        <div class="text-inside-grid"  
        [ngClass]="{'artistManaged':checkIfArtistManagedByagent(artist.agentkeys) }" class="artistNoManaged" (click) = "redirection(artist.$key,artist.agentkeys)">
          <img *ngIf="artist.profilepic; else noProfilePicProvided" src="{{artist.profilepic}}" class="profile-pic" alt="{{ artist.lastname }} {{ artist.firstname}}">
          <ng-template #noProfilePicProvided>
            <img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fnoprofilepic.png?alt=media&token=c2f12f49-1942-4680-a637-b034a4902131" class="profile-pic" alt="No Profile Pic">
          </ng-template>
          <p mat-line class="info-artist">
            <span class="online-light"
              [ngClass]="
              {
                'bg-red':artist.isonline=='1'
              }"
            ></span>
            <span class="name-artist">{{ artist.lastname }} {{ artist.firstname}}</span>
            <span class="intru-list" 
              *ngFor="let agent of artist.isagents">
              {{ agent.fullname }}
            </span>
            <br>
            <span class="intru-list" 
              *ngFor="let instru of artist.isinstruments"> 
              {{ instru.title }}
            </span>
          </p>
        </div>  
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card> -->