import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import { EventService } from './../shared/event.service';
import { MemberService } from './../../members/shared/member.service';
import { VenueService } from './../../venue/shared/venue.service';
import { ComposerService } from './../../composers/shared/composer.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit {

  events: Observable<any[]>;
  artists: Observable<any[]>;
  tours: Observable<any[]>;
  venues: Observable<any[]>;
  tourkey: string;
  pagetitle = 'New Event';
  pagesubtitle = 'Create new event';
  saveEventButton = true;

  addEvents = false;
  addEvent = true;

  constructor(
              private eventService: EventService,
              private memberService: MemberService,
              private venueService: VenueService,
              private location: Location) {
                this.artists = this.memberService.getArtistsList();
                this.venues = this.venueService.getVenuesList();
  }

  ngOnInit() {
  }

  onSubmit(newEventForm: NgForm) {
    // console.log(newEventForm);
    this.saveEventButton = false;
    this.eventService.createEvent(newEventForm);
  }

  goBack(): void {
    this.location.back();
  }

  importEvents(events, artists, venues) {
    console.log(events, artists, venues);
  }


}
