import { Component, OnInit, Input } from '@angular/core';
import { ComposerService } from '../../composers/shared/composer.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, startWith,map } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { EventService } from '../shared/event.service';

@Component({
  selector: 'app-event-program-child',
  templateUrl: './event-program-child.component.html',
  styleUrls: ['./event-program-child.component.scss']
})
export class EventProgramChildComponent implements OnInit {
  @Input() composerkey: string;
  @Input() eventView: any;
  @Input() selectedComposer: any;
  getComposition: Observable<any[]>;
  filteredCompositions:Observable<any[]>;
  compositionCtrl: UntypedFormControl = new UntypedFormControl();
  selectedComposition: any;
  isCompositionSelected: boolean;

  constructor(
    private composerService: ComposerService,
    private eventService: EventService,
    private router   : Router
  ) {}

  filterCompositions(val: string) {
    return this.getComposition
      .pipe(map(response => response.filter(composition => { 
        const spacePosition = composition.title.toLowerCase().indexOf(' ') + 1 ;
        const compoNamePosition = composition.title.toLowerCase().indexOf(val.toLowerCase());
        const commaPosition = composition.title.toLowerCase().indexOf(val.toLowerCase()) + 1;
        return composition.title.toLowerCase().indexOf(val.toLowerCase()) === 0 ||
               compoNamePosition === spacePosition || commaPosition || 
               compoNamePosition === spacePosition;
      })));
  }

  addCompositionToProgram(event, composer, composition) {
    
    if (event && composer && composition &&  this.selectedComposition.title) {
      this.isCompositionSelected = false;
      this.eventService.addCompositionToProgram(event, composer, composition, null);
    }
    else  { console.log('Data missing'); }
  }

  displayFn(composition) {
    if(composition) { var compotitle = `${composition.title}`; }
    else var compotitle = '';
    return composition ? compotitle : composition;
  } 

  ngOnInit() {
    this.getComposition = this.composerService.getCompositionsWithComposerkey(this.composerkey);
    // console.log(this.selectedComposer);
    this.filteredCompositions = this.compositionCtrl.valueChanges
      .pipe(startWith(null))
      .pipe(switchMap(composition => {
        if(composition) {
            //  if(composition.timestamp){
            this.selectedComposition = composition;
            this.isCompositionSelected = true;
            // this.router.navigate(['/event/'+eventkey]);                
            return this.filterCompositions(composition);
            // } else{this.filterCompositions(composition); }
        } 
        else {
            return this.getComposition;
        }
      }));
  }

}