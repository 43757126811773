import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';


import { VenueDetailComponent } from './../venue-detail/venue-detail.component';
import { VenueFormComponent } from './../venue-form/venue-form.component';
import { VenueListComponent } from './../venue-list/venue-list.component';

import { VenueService } from './venue.service';
import { VenueRoutingModule } from './venue-routing.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    AngularMaterialModule,
    AngularMaterialModule,
    VenueRoutingModule
  ],
  declarations: [
VenueListComponent,
VenueFormComponent,
VenueDetailComponent
  ],
  providers: [
VenueService
  ]
})
export class VenueModule { }
