import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { UploadService } from './../shared/upload.service';
import { Upload } from '../shared/upload';
import { FileUpload } from './../shared/file-upload';

@Component({
  selector: 'app-upload-carousel',
  templateUrl: './upload-carousel.component.html',
  styleUrls: ['./upload-carousel.component.scss']
})
export class UploadCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
