<!-- <pre>{{ compositions | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<ng-template #loading>				
	<br><br>
	<mat-spinner color="warn"></mat-spinner>
	<br><br>
</ng-template>

<!-- <mat-card *ngIf="dev"> -->
<mat-card appearance="outlined" class="noshadow">	
	<mat-card-header style="padding-top: 40px;">
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>queue_music</mat-icon>
		</div>	
		<mat-card-title>Compositions</mat-card-title>
		<mat-card-subtitle>List of the all compositions in Kajimotomusic</mat-card-subtitle>

		<button mat-fab (click)="goBack()" color="black">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab routerLink="./../../composer$/composerform" type="button" color="black">
			<mat-icon>person_add</mat-icon>
		</button>

		<button mat-fab routerLink="./../../composer$/composers" type="button" color="black">
			<mat-icon>search</mat-icon>
		</button>

	</mat-card-header>
	<mat-card-content>
		
		<mat-list dense *ngIf="compositions | async; let compositions; else loading">
			<mat-list-item *ngFor="let composition of compositions">
				<!-- <mat-icon matListIcon>music_note</mat-icon> -->
				<button mat-icon-button color="warn" (click)="deleteComposition(composition)">
		        <mat-icon>remove_circle</mat-icon>
		    </button>				
				<button mat-button [routerLink]="['./../../composer$/composer', composition.composerkey]" class="composer-button">
					{{ composition.composerlastname}} {{ composition.composerfirstname}} 
				</button>								
				<span>
					<strong>{{ composition.title }} </strong>
					<!--  - {{ composition.composerkey}}  -->
				</span>
			</mat-list-item>
		</mat-list>

		<br><br>

		<button mat-fab (click)="goBack()" color="black">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		
	</mat-card-content>
</mat-card>