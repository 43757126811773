export class FileUpload {
 
  $key: string;
  name: string;
  url: string;
  width: string;
  height: string;
  type: string;
  file: File;
  progress:number;
  timestamp: Date = new Date();

  constructor(file: File) {
    this.file = file;
  }
}