import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss']
})
export class OfficesComponent implements OnInit {

	imglogokey:string; imglogoname:string; imglogourl:string;
	imglogobr:string;

	imgmapchina:string;
	imgmapfrance:string;
	imgmapjapan:string;

  page: Observable<any>; 

  onlyOnce : boolean = true;

  offices = [];

  constructor(public  auth: AuthService, private ps: PublicService, private titleService: Title,
    private meta: Meta) { }

  setTitle( newTitle: string) { this.titleService.setTitle( newTitle ); }

  ngOnInit() {

    this.setTitle('Kajimoto Music Paris | Offices');

    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/offices` },
      { name: 'og:title', content: 'Offices Page'},
      { name: 'og:description', content: 'Kajimoto Music presents its offices.'}, 
      { name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fkajimoto-office-france.png?alt=media&token=9cd747c5-e4bf-4d51-ae9e-3cab3f22d692`},

      { name: 'description', content: 'Kajimoto Music presents its offices.' },
      { name: 'title',  content: 'Offices Page' },
      { name: 'twitter:card',  content: 'summary' },
      { name: 'twitter:site',  content: 'Kajimoto Music' },
      { name: 'twitter:title', content: 'Offices Page' },
      { name: 'twitter:description',  content: 'Kajimoto Music presents its offices.' },
      // tslint:disable-next-line:max-line-length
      { name: 'twitter:image',  content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/theme%2Fkajimoto-office-france.png?alt=media&token=9cd747c5-e4bf-4d51-ae9e-3cab3f22d692` },
      { name: 'twitter:url', content: `https://kajimotomusicparis.com/offices` },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'msapplication-starturl', content: '/' },
      { name: 'theme-color', content: '#f48c5b' } ,
      { name : 'keywords',  content: `Kajimoto Music presents its offices`},
      { name : 'url', content:   `https://kajimotomusicparis.com/offices` }
    ]);

    
		this.imglogobr = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2FKajimoto_logo_red-black.jpg?alt=media&token=909a189c-8eb7-463f-bf45-2c2068ecb4e6";
		this.imglogokey = "-L3-ARQZ07dzuZa2xImh";
		this.imglogoname = "logo-kajimoto-aboutus.png";
		this.imglogourl = "https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830";		  	
    this.page = this.ps.getPageVariables('offices');

    this.ps.getPageVariables('offices').subscribe((data) => {
      this.page = data ;
      this.sendVariablesToOfficeArrayOnce(data);
      });
  }

  sendVariablesToOfficeArrayOnce(page) {
    if(this.onlyOnce) { 

      var officesFR = {}
      officesFR['initiales']  = 'fr';
      officesFR['country']    = page.countryfr ? page.countryfr : null;
      officesFR['city']       = page.cityfr ? page.cityfr : null;
      officesFR['link']       = page.linkfr ? page.linkfr : null;
      officesFR['url']        = page.urlfr ? page.urlfr : null;
      officesFR['address']    = page.addressfr ? page.addressfr : null;
      officesFR['zipcity']    = page.zipcityfr ? page.zipcityfr : null;
      officesFR['phone']      = page.phonefr ? page.phonefr : null;
      officesFR['fax']        = page.faxfr ? page.faxfr : null;

      var officesJP = {}
      officesJP['initiales']  = 'jp';      
      officesJP['country']    = page.countryjp ? page.countryjp : null;
      officesJP['city']       = page.cityjp ? page.cityjp : null;
      officesJP['link']       = page.linkjp ? page.linkjp : null;
      officesJP['url']        = page.urljp ? page.urljp : null;
      officesJP['address']    = page.addressjp ? page.addressjp : null;
      officesJP['zipcity']    = page.zipcityjp ? page.zipcityjp : null;
      officesJP['phone']      = page.phonejp ? page.phonejp : null;

      var officesCH = {}
      officesCH['initiales']  = 'ch';            
      officesCH['country']    = page.countrych ? page.countrych : null;
      officesCH['city']       = page.citych ? page.citych : null;
      officesCH['link']       = page.linkch ? page.linkch : null;
      officesCH['url']        = page.urlch ? page.urlch : null;
      officesCH['address']    = page.addressch ? page.addressch : null;
      officesCH['address2']   = page.address2ch ? page.address2ch : null;
      officesCH['zipcity']    = page.zipcitych ? page.zipcitych : null;
      officesCH['phone']      = page.phonech ? page.phonech : null;

      this.offices = [officesFR,officesJP,officesCH];
      this.onlyOnce = false;
    }
  }  


  sendPageField(page,o,field,value) {
    this.ps.updatePage(page,field + o.initiales, value);      
  }




}
