import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MemberListComponent } from '../member-list/member-list.component';
import { MemberFormComponent } from '../member-form/member-form.component';
import { MemberDetailComponent } from '../member-detail/member-detail.component';
import { AdminGuard } from '../../core/admin.guards';

const routes: Routes = [
    { path: 'members', component: MemberListComponent, canActivate: [AdminGuard] },
    { path: 'memberform', component: MemberFormComponent, canActivate: [AdminGuard] },  
    { path: 'member/:id', component: MemberDetailComponent, canActivate: [AdminGuard] },  
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }


