<!-- <pre>{{ events | async | json }}</pre> -->

<div *ngIf="events | async; let events" class="content fx-row fx-wrap">
	
	<div class="fx-100">
		<h4 
		[matBadge]="countevents"
		[matBadgeHidden]="!countevents"
		matBadgePosition="above before"
		matBadgeSize="small"
		matBadgeColor="warn">
			Events
		</h4>
	</div>

 	<div class="fx-100 fx-end-center fx-row fx-wrap event-tour"
 		*ngFor="let event of events">
		<div class="fx-20">
			<span *ngIf="event.date"><strong>{{ event.date | date }}  : {{ event.time }}</strong></span>
		</div>
		<div class="fx-60">{{ event.title }}</div>
		<div class="fx-20 fx-ai-end">
			<button mat-icon-button color="black"
	      matTooltip="Event Details" matTooltipPosition="before"			
	      [routerLink]="['./../../../event$/event', event.$key]">
	        <mat-icon>info</mat-icon>
	    </button>
			<button mat-icon-button color="black"
	      matTooltip="Remove Event From Tour" matTooltipPosition="before"								
				(click)="removeEventFromTour(event,countevents)">
	        <mat-icon>clear_circle</mat-icon>
	    </button>
		</div> 
	</div>

</div>

