import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { LogClass } from './../../shared/logclass';
import { AuthService } from '../../core/auth.service';
import { map } from 'rxjs/operators';


@Injectable()
export class PublicService {

  news:           Observable<any>;
  page:           Observable<any>;
  histories:      Observable<any>;
  contactsOffice: Observable<any>;
  logClass:       LogClass;

  topHomeNewsCollection: AngularFirestoreCollection<unknown>;
  downHomeNewsCollection: AngularFirestoreCollection<unknown>;
  pageCollection: AngularFirestoreCollection<unknown>;
  contactsCollection: AngularFirestoreCollection<unknown>;

  historiesCollection: AngularFirestoreCollection<unknown>;
  historiesInPageCollection: AngularFirestoreCollection<unknown>;

  publicToursDetailsInPageCollection: AngularFirestoreCollection<unknown>;
  publicToursDetailsCollection: AngularFirestoreCollection<unknown>;

  constructor(
    private router: Router,
    private afs: AngularFirestore,
    private auth: AuthService,
    private meta: Meta) {
    this.pageCollection = this.afs.collection('pages');
    this.contactsCollection = this.afs.collection('contacts');

    this.historiesCollection = this.afs.collection('histories');
    this.historiesInPageCollection = this.afs.collection('pages').doc('history')
                                     .collection('histories', ref => ref.orderBy('year'));

    this.publicToursDetailsInPageCollection = this.afs.collection('pages').doc('tours')
                                              .collection('publictoursdetails', ref => ref.orderBy('date'));
    this.publicToursDetailsCollection = this.afs.collection('publictoursdetails', ref => ref.orderBy('date', 'desc'));

    this.logClass = new LogClass(this.auth, this.afs);
  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////// G E T ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

  getPageVariables(page: string) {
    const pagePath = `pages/${page}`;
    this.page = this.afs.doc<any>(pagePath)
      .get().pipe(
        map(action => {
          const $key = action.id;
          const data = { $key, ...action.data() };
          return data;
        })
      );
    return this.page ;
  }

  getContactsOffice(office: string) {
    this.contactsOffice = this.afs.collection('pages').doc('contacts').collection(office, ref => ref.orderBy('fullname'))
      .get().pipe(
        map((arr) => {
          return arr.docs.map(snap => Object.assign(
            snap.data(),
            {$key: snap.id} ));
        })
      );
    return this.contactsOffice ;
  }

  getHistories() {
    return this.historiesInPageCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          {$key: snap.id} ));
      })
    );
  }

  getPublicToursDetails() {
    return this.publicToursDetailsCollection.get().pipe(
      map((arr: any) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { date : snap.data().date ? snap.data().date.toDate() : null},
          {$key: snap.id} ));
      })
    );
  }

  getfooterModal(key: string) {
    return this.afs.doc<any>(`pages/${key}`)
      .snapshotChanges().pipe(
        map(action => {
          const $key = action.payload.id;
          // const birthdate2
          const data = { $key, ...action.payload.data() };
          return data;
        })
      );
  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////// C R E A T E /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  createContact(contactForm: NgForm): void {

    const contactRef             = this.afs.firestore.collection('contacts').doc();
    const contactkey             = contactRef.id;

    const office = contactForm.value.pickedOffice ? contactForm.value.pickedOffice.title : null;

    const newContactData           = {};
    newContactData['timestamp']  = Date.now();
    newContactData['office']     = office;
    newContactData['fullname']   = contactForm.value.fullname ? contactForm.value.fullname : null;
    newContactData['position']   = contactForm.value.position ? contactForm.value.position : null;
    newContactData['jap']        = contactForm.value.jap ? contactForm.value.jap : null;
    newContactData['phone']      = contactForm.value.phone ? contactForm.value.phone : null;
    newContactData['email']      = contactForm.value.email ? contactForm.value.email : null;

    console.log(contactForm.value);
    console.log(newContactData);

    const batch = this.afs.firestore.batch();

    const officeRef = this.pageCollection.doc('contacts').collection(office).doc(contactkey).ref;
    batch.set(officeRef, newContactData);

    const contactRootRef = this.contactsCollection.doc(contactkey).ref;
    batch.set(contactRootRef, newContactData);

    batch.commit().then(function() {console.log('New Contact Recorded'); }).catch(function(err) { console.log(err); });

  }


  createPublicTourDetail(form: NgForm): void {

    const publicTourDetailsRef = this.afs.firestore.collection('publictoursdetails').doc();
    const key                  = publicTourDetailsRef.id;

    const newPublicTourData             = {};
    newPublicTourData['timestamp']    = Date.now();
    newPublicTourData['date']         = form.value.date ? form.value.date : null;
    newPublicTourData['titleen']      = form.value.titleen ? form.value.titleen : null;
    newPublicTourData['titlefr']      = form.value.titlefr ? form.value.titlefr : null;
    newPublicTourData['detailsen']    = form.value.detailsen ? form.value.detailsen : null;
    newPublicTourData['detailsfr']    = form.value.detailsfr ? form.value.detailsfr : null;
    newPublicTourData['countriesfr']  = form.value.countriesfr ? form.value.countriesfr : null;
    newPublicTourData['countriesen']  = form.value.countriesen ? form.value.countriesen : null;
    newPublicTourData['citiesfr']     = form.value.citiesfr ? form.value.citiesfr : null;
    newPublicTourData['citiesen']     = form.value.citiesen ? form.value.citiesen : null;

    const batch = this.afs.firestore.batch();

    const pagePtdRef = this.pageCollection.doc('tours').collection('publictoursdetails').doc(key).ref;
    batch.set(pagePtdRef, newPublicTourData);

    const ptdRef = this.publicToursDetailsCollection.doc(key).ref;
    batch.set(ptdRef, newPublicTourData);

    batch.commit().then(function() {console.log('New Public Tour Detail Recorded'); }).catch(function(err) { console.log(err); });


  }


  createHistory(historyForm: NgForm): void {

    const historyRef             = this.afs.firestore.collection('histories').doc();
    const historykey             = historyRef.id;

    const newHistoryData           = {};
    newHistoryData['timestamp']  = Date.now();
    newHistoryData['year']       = historyForm.value.year ? historyForm.value.year : null;
    newHistoryData['details']    = historyForm.value.details ? historyForm.value.details : null;
    newHistoryData['img']        = historyForm.value.img ? historyForm.value.img : null;

    const batch = this.afs.firestore.batch();

    const historiesRef = this.pageCollection.doc('history').collection('histories').doc(historykey).ref;
    batch.set(historiesRef, newHistoryData);

    const historyRootRef = this.historiesCollection.doc(historykey).ref;
    batch.set(historyRootRef, newHistoryData);

    batch.commit().then(function() {console.log('New History Recorded'); }).catch(function(err) { console.log(err); });

  }


  insertAllHistory(history, i, nb) {

    const historyRef             = this.afs.firestore.collection('histories').doc();
    const historykey             = historyRef.id;

    const newHistoryData           = {};
    newHistoryData['timestamp']  = Date.now();
    newHistoryData['year']       = history.date ? history.date : null;
    newHistoryData['details']    = history.txt ? history.txt : null;
    newHistoryData['img']        = history.img ? history.img : null;


    console.log(i + '/' + nb + ' : ' + newHistoryData);

    const batch = this.afs.firestore.batch();

    const historiesRef = this.pageCollection.doc('history').collection('histories').doc(historykey).ref;
    batch.set(historiesRef, newHistoryData);

    const historyRootRef = this.historiesCollection.doc(historykey).ref;
    batch.set(historyRootRef, newHistoryData);

    batch.commit().then(function() {console.log('New History Recorded' + i + '/' + nb); }).catch(function(err) { console.log(err); });

  }


/////////////////////////////////////////////////////////////////////////////////
////////////////////////// U P D A T E //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


  updatePage(page, field, value) {
    const pagekey = page.$key;
    const updateData = {};
    updateData[`${field}`] = value;
    this.pageCollection.doc(pagekey).update(updateData);
    this.logClass.createLog(this.afs, 'updatePage', pagekey );
  }


  addImageToHistory(historykey, url) {

    const updateData = {};
    updateData['img'] = url;

    const batch = this.afs.firestore.batch();

    const historyRef = this.historiesCollection.doc(historykey).ref;
    batch.update(historyRef, updateData);

    const historyInPageRef = this.pageCollection.doc('history').collection('histories').doc(historykey).ref;
    batch.update(historyInPageRef, updateData);

    batch.commit().then(function() {console.log('Image Stored in History'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'addImageToHistory', historykey );

    this.router.navigate(['/history/']);
  }


/////////////////////////////////////////////////////////////////////////////////
////////////////////////// D E L E T E //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////


  deleteContact(contact) {

    const contactkey = contact.$key;
    const office = contact.office;

    const batch = this.afs.firestore.batch();

    const contactRef = this.contactsCollection.doc(contactkey).ref;
    batch.delete(contactRef);

    const contactInPageRef = this.pageCollection.doc('contacts').collection(office).doc(contactkey).ref;
    batch.delete(contactInPageRef);

    batch.commit().then(function() {console.log('Contact Deleted'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'deleteContact', contactkey);

  }


  deleteHistory(history) {

    const key = history.$key;

    const batch = this.afs.firestore.batch();

    const hRef = this.historiesCollection.doc(key).ref;
    batch.delete(hRef);

    const hInPageRef = this.pageCollection.doc('history').collection('histories').doc(key).ref;
    batch.delete(hInPageRef);

    batch.commit().then(function() {console.log('History Deleted'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'deleteHistory', key);

  }


  deletePublicTourDetail(publictourdetail) {

    const key = publictourdetail.$key;

    const batch = this.afs.firestore.batch();

    const ptdRef = this.publicToursDetailsCollection.doc(key).ref;
    batch.delete(ptdRef);

    const ptdInPageRef = this.publicToursDetailsInPageCollection.doc('tours').collection('publictoursdetails').doc(key).ref;
    batch.delete(ptdInPageRef);

    batch.commit().then(function() {console.log('Public Tour Detail Deleted'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'deletePublicTourDetail', key);

  }


}

