import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { VenueService } from './../shared/venue.service';

@Component({
  selector: 'app-venue-form',
  templateUrl: './venue-form.component.html',
  styleUrls: ['./venue-form.component.scss']
})
export class VenueFormComponent implements OnInit {

	addVenue: boolean= true;
	addVenues: boolean= false;

  isSubmitting = false;
  successMessage: string | null = null;

  constructor(
    private router: Router,
  	private venueService: VenueService
  	) { }

  ngOnInit() {
  }

  // onSubmit(newPlaceForm: NgForm) {
  // 	this.venueService.createVenue(newPlaceForm);
  // }  

  onSubmit(newPlaceForm: NgForm) {
    if (this.isSubmitting || newPlaceForm.invalid) return;

    this.isSubmitting = true;
    
    this.venueService.createVenue(newPlaceForm).then(() => {
      this.successMessage = 'Venue ajoutée avec succès !';

      // Réinitialisation du formulaire
      newPlaceForm.resetForm();

      // Petite pause avant redirection
      setTimeout(() => {
        this.successMessage = null;
        this.router.navigate(['./venue$/venues']);
      }, 2000);

      this.isSubmitting = false;
    }).catch(error => {
      console.error('Erreur lors de l\'ajout:', error);
      this.isSubmitting = false;
    });
  }  

  importPlaces(venues) {
  	// this.venueService.importPlaces(venues);  	
  }
}
