<h1 mat-dialog-title [ngStyle]="{ 'font-size':'1.5em','line-height':'1em' }">{{ dialogTitle }}</h1>


<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>



<mat-dialog-content *ngIf="part=='terms'">
	
	<h2>A. Introduction</h2>

	<p>1. The privacy of our website visitors is very important to us, and we are committed to safeguarding it. This policy explains what we do with your personal information. <br><br>
	2. Consenting to our use of cookies in accordance with the terms of this policy when you first visit our website permits us to use cookies every time you visit our website.</p>

	<h2>B. Credit</h2>

	<p>1. This document was created using a template from SEQ Legal (<a href="http://seqlegal.com" target="_blank">seqlegal.com</a>) and modified by vpnMentor (<a href="http://www.vpnmentor.com" target="_blank">www.vpnmentor.com</a>)</p>

	<h2>C. How we collect your personal data</h2>

	<p>The following types of personal information may be collected, stored, and used: <br><br>
	1. Information about your computer including your IP address, geographical location, browser type and version, and operating system <br>
	2. Information about your visits to and use of this website including the referral source, length of visit, page views, and website navigation paths. <br>
	3. Information that you enter when you register with our website, such as your email website. <br>
	4. Information that you enter when you create a profile on our website. For example, your name, profile pictures, gender, birthday, relationship status, interests and hobbies, educational details, and employment details. <br>
	5. Information that you enter in order to set up subscription to our emails and/or newsletters. <br>
	6. Information that is generated while using our website, including when, how often, and under what circumstances you use it. <br>
	7. Information relating to anything you purchase, services you use, or transaction you make through our website, which includes your name, address, telephone number, email address, and credit card details. <br>
	8. Information that you post to our website with the intention of publishing it on the internet. <br>
	9. Any other personal information you send to us.</p>

	<h2>D. Using Personal Information</h2>

	<p>Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your personal information for the following: <br><br>
	1. Administering our website and business <br>
	2. Personalizing our website for you <br>
	3. Enabling your use of the services available on our website <br>
	4. Sending you goods purchased through our website <br>
	5. Supplying services purchased through our website <br>
	6. Sending statements, invoices, and payment reminders to you, and collecting payments from you. <br>
	7. Sending you on-marketing commercial communications <br>
	8. Sending you email notifications you have specifically requested. <br>
	9. Sending you our email newsletter if you signed up for it (you can unsubscribe at any time). <br>
	10. Sending you marketing communications relating to our business or the businesses of third parties which we think may be of interest to you. <br>
	11. Providing third parties with statistical information about our users. <br>
	12. Dealing with inquiries and complaints made by or about you relating to our website <br>
	13. Keeping our website secure and prevent fraud. <br>
	14. Verifying compliance with the terms and conditions governing the use of our website. <br>
	15. Other uses. <br><br>If you submit personal information for publication on our website, we will publish and otherwise use that information in accordance with the license you grant us. <br><br>Your privacy settings can be used to limit the publication of your information on our website and can be adjusted using privacy controls on the website. <br><br>We will not, without your expressed consent, supply your personal information to any third party for their or any other third party’s direct marketing.</p>

	<h2>E. Disclosing personal information</h2>

	<p>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy. <br><br>
	We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) as reasonably necessary for the purposes set out in this policy. <br><br>
	We may disclose your personal information: <br><br>
	1. to the extent that we are required to do so by law; <br>
	2. in connection with any ongoing or prospective legal proceedings; <br>
	3. in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk); <br>
	4. to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and <br>
	5. to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information. <br> <br>
	Except as provided in this policy, we will not provide your personal information to third parties.</p>

 	<h2>F. International data transfers</h2>

	<p>1. Information that we collect may be stored, processed in, and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy. <br><br>

	2. Information that we collect may be transferred to the following countries which do not have data protection laws equivalent to those in force in the European Economic Area: the United States of America, Russia, Japan, China, and India. <br><br>

	3. Personal information that you publish on our website or submit for publication on our website may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others. <br><br>

	4. You expressly agree to the transfers of personal information described in this Section F.</p>  

	<h2>G. Retaining personal information  </h2>

	<p>1. This Section G sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations regarding the retention and deletion of personal information. <br><br>

	2. Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes. <br><br>

	3. Without prejudice to article G-2, we will usually delete personal data falling within the categories set out below at the date/time set out below: personal data type will be deleted <br><br>

	4. Notwithstanding the other containing personal data: <br>
	a. to the extent that we are required to do so by law; <br>
	b. if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and <br>
	c. in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</p>

	<h2>H. Security of your personal information  </h2>

	<p>1. We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information.  <br>
	2. We will store all the personal information you provide on our secure (password- and firewall-protected) servers. <br>
	3. All electronic financial transactions entered into through our website will be protected by encryption technology. <br>
	4. You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet. <br>
	5. You are responsible for keeping the password you use for accessing our website confidential; we will not ask you for your password (except when you log in to our website).</p>

	<h2>I. Amendments</h2>

	<p>We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy. We may notify you of changes to this policy by email or through the private messaging system on our website.</p>

	<h2>J. Your rights</h2>

	<p>You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to the following: <br><br>

	1. the payment of a 50 € fee; and <br>

	2. the supply of appropriate evidence of your identity (a photocopy of your passport certified by a notary plus an original copy of a utility bill showing your current address).  <br><br> 

	We may withhold personal information that you request to the extent permitted by law. <br><br>

	You may instruct us at any time not to process your personal information for marketing purposes. <br><br>

	In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</p>

	<h2>K. Third party websites</h2>

	<p>Our website includes hyperlinks to, and details of, third party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.</p>

	<h2>L. Updating information</h2>

	<p>Please let us know if the personal information that we hold about you needs to be corrected or updated.</p>

	<h2>M. Cookies</h2>

	<p>Our website uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. We use only session cookies.  <br><br>

	1. The names of the cookies that we use on our website, and the purposes for which they are used, are set out below: <br><br>

	a. we use Google Analytics and Adwords on our website to recognize a computer when a user  visits the website to : improve the website’s usability / analyze the use of the website / administer the website / prevent fraud and improve the security of the website / personalize the website for each user; <br><br>

	2. Most browsers allow you to refuse to accept cookies—for example: <br><br>

	a. in Internet Explorer (version 10) you can block cookies using the cookie handling override settings available by clicking “Tools,” “Internet Options,” “Privacy,” and then “Advanced”; <br>

	b. in Firefox (version 24) you can block all cookies by clicking “Tools,” “Options,” “Privacy,” selecting “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and <br>

	c. in Chrome (version 29), you can block all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Content settings,” and then selecting “Block sites from setting any data” under the “Cookies” heading. <br>

	Blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use all the features on our website. <br><br>

	3. You can delete cookies already stored on your computer—for example: <br><br>

	a. in Internet Explorer (version 10), you must manually delete cookie files (you can find instructions for doing so at <a href="http://support.microsoft.com/kb/278835" target="_blank">http://support.microsoft.com/kb/278835</a> ); <br>

	b. in Firefox (version 24), you can delete cookies by clicking “Tools,” “Options,” and “Privacy”, then selecting “Use custom settings for history”, clicking “Show Cookies,” and then clicking “Remove All Cookies”; and <br>

	c. in Chrome (version 29), you can delete all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Clear browsing data,” and then selecting “Delete cookies and other site and plug-in data” before clicking “Clear browsing data.” <br><br>

	4. Deleting cookies will have a negative impact on the usability of many websites.</p>

	<h2>N- Contact Us </h2>

	<p>If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:inquiry@kajimotomusic.com">inquiry@kajimotomusic.com</a><br><br>
	
</mat-dialog-content>




<mat-dialog-content *ngIf="part=='cookies'">

	<p>Our website uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. We use only session cookies.  <br><br>

	1. The names of the cookies that we use on our website, and the purposes for which they are used, are set out below: <br><br>

	a. we use Google Analytics and Adwords on our website to recognize a computer when a user  visits the website to : improve the website’s usability / analyze the use of the website / administer the website / prevent fraud and improve the security of the website / personalize the website for each user; <br><br>

	2. Most browsers allow you to refuse to accept cookies—for example: <br><br>

	a. in Internet Explorer (version 10) you can block cookies using the cookie handling override settings available by clicking “Tools,” “Internet Options,” “Privacy,” and then “Advanced”; <br>

	b. in Firefox (version 24) you can block all cookies by clicking “Tools,” “Options,” “Privacy,” selecting “Use custom settings for history” from the drop-down menu, and unticking “Accept cookies from sites”; and <br>

	c. in Chrome (version 29), you can block all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Content settings,” and then selecting “Block sites from setting any data” under the “Cookies” heading. <br>

	Blocking all cookies will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use all the features on our website. <br><br>

	3. You can delete cookies already stored on your computer—for example: <br><br>

	a. in Internet Explorer (version 10), you must manually delete cookie files (you can find instructions for doing so at <a href="http://support.microsoft.com/kb/278835" target="_blank">http://support.microsoft.com/kb/278835</a> ); <br>

	b. in Firefox (version 24), you can delete cookies by clicking “Tools,” “Options,” and “Privacy”, then selecting “Use custom settings for history”, clicking “Show Cookies,” and then clicking “Remove All Cookies”; and <br>

	c. in Chrome (version 29), you can delete all cookies by accessing the “Customize and control” menu, and clicking “Settings,” “Show advanced settings,” and “Clear browsing data,” and then selecting “Delete cookies and other site and plug-in data” before clicking “Clear browsing data.” <br><br>

	4. Deleting cookies will have a negative impact on the usability of many websites.</p>	
</mat-dialog-content>



<mat-dialog-content *ngIf="part=='jobs'">
	<ul>
		<li><strong>Kajimoto Paris n'a pas de poste à pourvoir pour le moment.</strong></li>
	</ul>
</mat-dialog-content>



<mat-dialog-content *ngIf="part=='legal'">

	<p>Copyright <strong>© 2018 KAJIMOTO</strong> all rights reserved. <br>Images for promotional KAJIMOTO ONLY. </p>

	<address>
		Company Name : <strong>Kajimoto Paris Liaison Office</strong><br>
		5 rue Barbette <br>
		75003 Paris <br>
		439 491259 RCS Paris
	</address>

	<p>KAJIMOTO is a member of the International Artist Managers’ Association. </p>

	<p>The website « www.kajimotomusicparis.com» is build and design by <a href="http://www.faisceau.me" target="_blank">Faisceaume </a> <br></p>

	<address>
		Company Name : <strong>Faisceaume SAS</strong><br>
		231, rue Saint-Honoré <br>
		75001 Paris <br>
		749962056.00018 RCS Paris
	</address>
	
	<p>Publishing Director : <strong>Monique Devaux</strong><br>
		Logo and brand image: <strong>Kashiwa Sato</strong><br>
		Graphism: <strong>Gregoire Lafitte, Corentin Banzet</strong><br>
		Web Hosting Service Provider: <strong>Google Cloud Plateform.</strong> <a href="https://cloud.google.com/terms/" target="_blank">Google Cloud Platform (GCP) Terms of Service</a> are already covered by associated data processing terms, the <a href="https://cloud.google.com/terms/data-processing-terms" target="_blank">GCP Data Processing and Security Terms.</a></p>

</mat-dialog-content>




<mat-dialog-content *ngIf="part=='credits'">


	<button mat-fab 
	  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
	  (click)="editCredit = !editCredit"
	  class="edit-button edit-button-xs" color="black">  
	  <mat-icon *ngIf="!editCredit">create</mat-icon>
	  <mat-icon *ngIf="editCredit">close</mat-icon>
	</button>


	<div *ngIf="page | async as page; else loading">

		<div *ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&editCredit">
	    <mat-form-field class="fx-100">
			<mat-label>Text in english </mat-label>
	      <textarea matInput required rows="16"
	        [(ngModel)]="page.credits"
	        (ngModelChange)="updateCredit(page,'credits',page.credits)"></textarea>
	    </mat-form-field> 		
		</div>

		<div *ngIf="!editCredit">
			<p>
				<span class="angular-with-newlines">{{ page.credits }}</span>
			</p>
		</div>

	</div>

</mat-dialog-content>




<mat-dialog-content *ngIf="part=='newsletter'">

		<h2>Subscribe to our mailing list</h2>
		<a href="http://eepurl.com/dNPf-g" target="_blank">http://eepurl.com/dNPf-g</a>

</mat-dialog-content>



