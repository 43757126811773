import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {

  instrument: any;

  constructor(private db: AngularFirestore) { }

  getInstrument(key: string): Observable<any> {
    this.instrument = this.db.collection('instruments').doc(key).get().pipe(
      map(
        action => {
          const data = action.data() ? Object.assign(action.data(),
          { $key: action.id }) : null;
          return data;
        })
    );
    return this.instrument;
  }

  getInstruments() {
    return this.db.collection('instruments', ref => ref.orderBy('name')).get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }
        ));
      })
    );
  }

  createInstrument(instruForm: NgForm): void {
    const instruref = this.db.firestore.collection('instruments').doc();
    const instrukey = instruref.id;

    const instruData = {};
    instruData['timestamp']    = Date.now();
    instruData['name']         = instruForm.value.name ? instruForm.value.name : null;
    instruData['category']     = instruForm.value.category ? instruForm.value.category : null;
    instruData['nom']          = instruForm.value.nom ? instruForm.value.nom : null;
    instruData['categorie']    = instruForm.value.categorie ? instruForm.value.categorie : null;

    const batch                = this.db.firestore.batch();
    const instruCollectionRef  = this.db.collection('instruments').doc(instrukey).ref;
    batch.set(instruCollectionRef, instruData);

    batch.commit()
    .then(function() { console.log('Batch Commited'); })
    .catch(function(error) { console.error( 'Error adding document: ', error); });

  }

  updateInstrument(instru, field, value) {
    const instrukey = instru.$key ? instru.$key : null;
    const updateField = {};
    updateField[`${field}`] = value;

    const batch = this.db.firestore.batch();
    const instruRef = this.db.collection('instruments').doc(instrukey).ref;
    batch.update(instruRef, updateField);
    batch.commit()
    .then(function() {console.log(updateField); })
    .catch(function(err) { console.log(err); });
  }


  deleteInstrument(instrument) {
    console.log(instrument);
    this.db.collection('instruments').doc(instrument.$key).delete();
  }

}
