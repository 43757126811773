
<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs"><h2>We can not <strong>find</strong> <br>what you are looking for.</h2>

<div class="toast__container" *ngIf="show&&(auth.user | async)?.role =='pending'" class="fl-r1">
        <div class="toast__cell">
    
        <div class="toast toast--red add-margin">
          <div class="toast__icon">
        <svg version="1.1" class="toast__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 301.691 301.691" style="enable-background:new 0 0 301.691 301.691;" xml:space="preserve">
        <g>
          <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0  "></polygon>
          <rect x="130.563" y="261.168" width="40.525" height="40.523"></rect>
        </g>
            </svg>
          </div>
          <div class="toast__content">
            <p class="toast__type">INFO</p>
            <p class="toast__message">you are waiting for validation please wait in a few 
                                      minutes before enjoying the application normally..</p>
          </div>
          <div class="toast__close" (click)="close()">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
          <path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
        </svg>
          </div>
        </div>
        </div>
 </div>