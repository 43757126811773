import { AuthService } from './../../core/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/compat/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import {LogClass} from '../../../app/shared/logclass';
import { map } from 'rxjs/operators';
// import { deleteField, doc } from 'firebase/firestore';
import { deleteField } from 'firebase/firestore';

import { Event } from './event';
import { Firestore, doc, updateDoc, deleteDoc, writeBatch, docData } from '@angular/fire/firestore';

export interface Artist {
  $key: string;
}

@Injectable()
export class EventService {

  private memberPath     = '/member';
  private rolePath       = '/role';
  private instrumentPath = '/instrument';
  private eventPath      = '/events';

  artists: any;
  artist: Observable<any>;
  tour: Observable<any>;
  artistsEvent: Observable<any>;
  programkey: string;
  memberEmail: Observable<any>;
  importEventlastnamefromCSV: string;
  artistFound: any;
  artistFoundKey: any;

  venueNameFromCSVLine: any;
  venueFound: any;

  counteventsimported = 0;
  countvenuesimported = 0;
  countartistsimported = 0;

  now = new Date();
  dateForFilters = new Date( + this.now + this.now.getTimezoneOffset() * 60000);

  event: Observable<Event>;
  eventCollection: AngularFirestoreCollection<unknown>;
  publicEventCollection: AngularFirestoreCollection<unknown>;
  venueCollection: AngularFirestoreCollection<unknown>;
  tourCollection: AngularFirestoreCollection<unknown>;
  tourCollectionAfterToday: any;
  memberCollection: AngularFirestoreCollection<unknown>;;
  programCollection: AngularFirestoreCollection<unknown>;;
  artistCollection: AngularFirestoreCollection<unknown>;;
  genreCollection: AngularFirestoreCollection<unknown>;;
  EventSeasonCollection: AngularFirestoreCollection<unknown>;;
  allEventCollection: AngularFirestoreCollection<unknown>;;
  brokenEventsCollection: AngularFirestoreCollection<unknown>;;
  routingCollection: AngularFirestoreCollection<unknown>;;
  logClass: LogClass;
  EventArtistCollection: AngularFirestoreCollection<unknown>;;
  eventWithNoArtistkeyCollection: AngularFirestoreCollection<unknown>;;

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private eventsCache: Event[] | null = null;


  constructor(
    private newAfs: Firestore,
    private afs: AngularFirestore,
    private router: Router,
    private auth: AuthService) {
    this.programCollection     = this.afs.collection('programs');
    this.allEventCollection    = this.afs.collection('events');
    this.eventCollection       = this.afs.collection('events', ref => ref.where('date', '>', this.dateForFilters).orderBy('date'));

    this.eventWithNoArtistkeyCollection = this.afs.collection('events', ref => ref.where('artistkey', '==', null));

    // this.brokenEventsCollection = this.afs.collection('events', ref => ref.where('artistkey','==','null'));
    this.publicEventCollection = this.afs.collection('events', ref => ref.where(
                                        'date', '>', this.dateForFilters).where('isonline', '==', true));
    this.memberCollection      = this.afs.collection('members');
    this.venueCollection       = this.afs.collection('venues');
    this.tourCollection        = this.afs.collection('tours', ref => ref.orderBy('enddate'));
    this.tourCollectionAfterToday = this.afs.collection('tours', ref => ref.where(
                                        'startdate', '>', this.dateForFilters).orderBy('startdate'));
    this.routingCollection     = this.afs.collection('routings', ref => ref.orderBy('date'));
    this.logClass              = new LogClass(this.auth, this.afs);
  }


/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// G E T
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////


  getToursListAfterToday() {
    return this.tourCollectionAfterToday.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { startdate  : snap.payload.doc.data().startdate ? snap.payload.doc.data().startdate.toDate() : null},
          { enddate  : snap.payload.doc.data().enddate ? snap.payload.doc.data().enddate.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getToursList() {
    return this.tourCollection.get().pipe(
      map((arr) => {
        return arr.docs.map((snap: any) => Object.assign(
          snap.data(),
          { startdate  : snap.data().startdate ? snap.data().startdate.toDate() : null},
          { enddate    : snap.data().enddate ? snap.data().enddate.toDate() : null},
          // { tags       : snap.payload.doc.data().startdate ?
                          // snap.payload.doc.data().startdate.toDate() + ' ' +snap.payload.doc.data().artistlastname : null},
          { tags       : snap.data().startdate ?
                          snap.data().startdate.toDate().getFullYear() + ' ' +
                          this.monthNames[snap.data().startdate
                            .toDate().getMonth()] + ' ' + snap.data().artistlastname
                          : null},
          { $key: snap.id }) );
      })
    );
  }


  // getEventsList() {
  //   return this.eventCollection.get().pipe(
  //     map((arr) => {
  //       return arr.docs.map((snap: any) => Object.assign(
  //         snap.data(),
  //         { programs: snap.data().programs ? Object.values(snap.data().programs) : 0},
  //         { musicians: snap.data().artists ? Object.values(snap.data().artists) : 0},
  //         { date: snap.data().date ? snap.payload.doc.data().date.toDate() : null},
  //         { genre: snap.data().genre ?
  //                  snap.data().genre == 'Operaconcert' ? 'Opera Concert' :
  //                  snap.data().genre == 'Operastaged' ? 'Opera Staged' :
  //                  snap.data().genre : null},
  //         { $key: snap.id }) );
  //     })
  //   );
  // }

  getEventsList() {
    if (this.eventsCache) {
        return of(this.eventsCache); // Retourne le cache si disponible
    }
    return this.eventCollection.get().pipe(
        map((arr) => {
            this.eventsCache = arr.docs.map((snap: any) => ({
                ...snap.data(),
                programs: snap.data().programs ? Object.values(snap.data().programs) : 0,
                musicians: snap.data().artists ? Object.values(snap.data().artists) : 0,
                date: snap.data().date ? snap.data().date.toDate() : null,
                genre: snap.data().genre ?
                  snap.data().genre == 'Operaconcert' ? 'Opera Concert' :
                  snap.data().genre == 'Operastaged' ? 'Opera Staged' :
                  snap.data().genre : null,                
                $key: snap.id,
            }));
            return this.eventsCache;
        })
    );
  }



  getEventsListWithNoArtistkey() {
    return this.eventWithNoArtistkeyCollection.get().pipe(
      map((arr: any) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { $key: snap.id }) );
      })
    );
  }


  getPublicEventsList() {
    return this.publicEventCollection.get().pipe(
      map((arr) => {
        return arr.docs.map((snap: any) => Object.assign(
          snap.data(),
          { programs: snap.data().programs ? Object.values(snap.data().programs) : 0},
          { musicians: snap.data().artists ? Object.values(snap.data().artists) : 0},
          { date: snap.data().date ? snap.data().date.toDate() : null},
          { genre: snap.data().genre ?
                   // tslint:disable-next-line: triple-equals
                   snap.data().genre == 'Operaconcert' ? 'Opera Concert' :
                   // tslint:disable-next-line: triple-equals
                   snap.data().genre == 'Operastaged' ? 'Opera Staged' :
                   snap.data().genre : null},
          { $key: snap.id }) );
      })
    );
  }

  getGenre(genre) {
    this.genreCollection   = this.afs.collection('events', ref => ref.where('genre', '==', genre) );
    return this.genreCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getRoutingDetailsWithTourKey(key) {
    return this.afs.collection('tours').doc(key).collection('routings', ref => ref.orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getEventsWithMemberKey(key) {
    return this.afs.collection('members').doc(key).collection('events', ref => ref.orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );
  }


  getOnlineEventsWithMemberKeyAfterToday(key) {
    return this.afs.collection('members').doc(key)
    .collection('events', ref => ref.where('date', '>', this.dateForFilters)
    .where('isonline', '==', true))
    .snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { genre: snap.payload.doc.data().genre ?
                   snap.payload.doc.data().genre === 'Operaconcert' ? 'Opera Concert' :
                   snap.payload.doc.data().genre === 'Operastaged' ? 'Opera Staged' :
                   snap.payload.doc.data().genre : null},
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getOnlineEventsWithMemberKey(key) {
    return this.afs.collection('members').doc(key).collection('events', ref => ref
      .where('isonline', '==', true).orderBy('date')).snapshotChanges().pipe(
        map((arr: any) => {
          return arr.map(snap => Object.assign(
            snap.payload.doc.data(),
            { genre: snap.payload.doc.data().genre ?
                     snap.payload.doc.data().genre === 'Operaconcert' ? 'Opera Concert' :
                     snap.payload.doc.data().genre === 'Operastaged' ? 'Opera Staged' :
                     snap.payload.doc.data().genre : null},
            { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
            { $key: snap.payload.doc.id }) );
        })
      );
  }

  getProgramWithEventKey(key) {
    return this.afs.collection('events').doc(key).collection('program').snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getExistingProgramsList() {
    return this.programCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }) );
      })
    );
  }



  getEventWithKey(key: string): Observable<any> {
    const eventDocRef = doc(this.newAfs, `events/${key}`);
  
    return docData(eventDocRef, { idField: '$key' }).pipe(
      map(data => {
        if (!data) return null;
  
        return {
          ...data,
          genre: data.genre ?
                 data.genre === 'Chamber' ? 'Chamber Music' :
                 data.genre === 'Operaconcert' ? 'Opera Concert' :
                 data.genre === 'Operastaged' ? 'Opera Staged' :
                 data.genre : null,
          date: data.date ? data.date.toDate() : null
        };
      })
    );
  }


  getTourWithKey(key: string): Observable<Event> {
    const tourPath = `tours/${key}`;
    this.tour = this.afs.doc<any>(tourPath)
      .get().pipe(
        map(action => {
          const data = action.data() ? Object.assign(action.data(),
          {
            startdate  : action.data() ? action.data().startdate.toDate() : {},
            enddate    : action.data() ? action.data().enddate.toDate() : {},
            $key       : action.id
          }) : null;
          return data;
        })
      );
    return this.tour ;
  }




  getCompositionsProgramWithEventKey(key: string) {
    return this.afs.collection('events').doc(key).collection('program', ref => ref.orderBy('timestamp')).get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );
  }

  getCompositionsProgramWithProgramKey(key: string) {
    return this.afs.collection('programs').doc(key).collection('compositions').get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );
  }


  getArtistsEvent(key: string) {
    return this.afs.collection('events').doc(key).collection('artists', ref => ref.limit(1)).get().pipe(
      map((arr) => {
        return arr.docs.map((snap: any) => Object.assign(snap.data(), { $key: snap.id }) );
      })
    );
  }

  getEventsTour(key: string) {
    return this.afs.collection('tours').doc(key).collection('events', ref => ref.orderBy('date')).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { $key: snap.payload.doc.id }
        ));
      })
    );
  }



  getEventWithSeason(season?, early?, end?) {

    let starTime; let endTime;

    if (season === 'Automne') {
      starTime =  new Date('2019-09-23');
      endTime  =  new Date('2019-12-20');
    } else if (season === 'Hiver') {
      starTime = new Date('2019-12-21');
      endTime  = new Date('2020-03-19');
    } else if (season === 'Printemps') {
      starTime = new Date('2020-03-20');
      endTime  = new Date('2020-06-20');
    } else if (season === 'Été') {
      starTime = new Date('2020-06-21');
      endTime  = new Date('2020-09-22');
    }
   // console.log(starTime,endTime);

   this.EventSeasonCollection = this.afs.collection('events', ref => ref.where('date', '>', starTime ? starTime : early)
                                                                       .where('date', '<', endTime ? endTime : end));
    return this.EventSeasonCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { genre: snap.payload.doc.data().genre ?
                    snap.payload.doc.data().genre === 'Operaconcert' ? 'Opera Concert' :
                    snap.payload.doc.data().genre === 'Operastaged' ? 'Opera Staged' :
                    snap.payload.doc.data().genre : null},
          { $key: snap.payload.doc.id }) );
      })
    );
  }



  getEventWithYearOrderByArtist(key, year) {
    const starTime =  new Date( `${year}` + '-01-01');
    const endTime  =  new Date(`${year}` + '-12-31');
   this.EventArtistCollection = this.afs.collection('members').doc(key)
                                        .collection('events', ref => ref.where('date', '>', starTime)
                                                                       .where('date', '<', endTime));
    return this.EventArtistCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          // console.log(snap.payload.doc.data().date.toDate())
          { date: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { dates: snap.payload.doc.data().date ? snap.payload.doc.data().date.toDate() : null},
          { genre: snap.payload.doc.data().genre ?
                    snap.payload.doc.data().genre === 'Operaconcert' ? 'Opera Concert' :
                    snap.payload.doc.data().genre === 'Operastaged' ? 'Opera Staged' :
                    snap.payload.doc.data().genre : null},
          { $key: snap.payload.doc.id }) );
      })
    );
    
  }




/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// C R E A T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////



  createTour(newTourForm: NgForm): void {

    const tourref               = this.afs.firestore.collection('tours').doc();
    const tourkey               = tourref.id;

    const newTourData             = {};
    newTourData['artistemail']  = newTourForm.value.selectedArtist.email ? newTourForm.value.selectedArtist.email : null;
    newTourData['artistlastname']  = newTourForm.value.selectedArtist.lastname ? newTourForm.value.selectedArtist.lastname : null;
    newTourData['artistfirstname'] = newTourForm.value.selectedArtist.firstname ? newTourForm.value.selectedArtist.firstname : null;
    newTourData['artistfullname']  = newTourForm.value.selectedArtist.fullname ? newTourForm.value.selectedArtist.fullname : null;
    newTourData['artistkey']    = newTourForm.value.selectedArtist.$key ? newTourForm.value.selectedArtist.$key : null;
    newTourData['phone']        = newTourForm.value.selectedArtist.phone ? newTourForm.value.selectedArtist.phone : null;
    newTourData['isrouting']    = false;
    newTourData['enddate']      = newTourForm.value.newTourEndDate ? newTourForm.value.newTourEndDate : null;
    newTourData['startdate']    = newTourForm.value.newTourStarDate ? newTourForm.value.newTourStarDate : null;
    newTourData['timestamp']    = Date.now();
    newTourData['title']        = newTourForm.value.newTourTitle ? newTourForm.value.newTourTitle : null;

    // console.log(newTourForm.value);
    // console.log(newTourData);

    this.tourCollection.doc(tourkey).set(newTourData)
    .then(function(docRef) { console.log(newTourData); })
    .catch(function(error) { console.error('Error adding document: ', error); });
    this.logClass.createLog(this.afs, 'createEvent', tourkey);

    this.router.navigate(['/event$/tour/' + tourkey]);
  }


  addTourEvent(event, tour) {

    const eventkey               = event.$key;
    const artistkey              = event.artistkey ? event.artistkey : null;
    const tourkey                = tour.viewModel ? tour.viewModel.$key : tour.$key;
    const tourtitle              = tour.viewModel ? tour.viewModel.title : tour.title;
    const countevents            = tour.countevents ? (tour.countevents + 1) : 1;
    const date                   = event.date ? event.date : null;
    const artistlastname         = event.artistlastname ? event.artistlastname : null;
    const artistfirstname        = event.artistfirstname ? event.artistfirstname : null;

    const eventData                = {};
    eventData['artistchef']      = event.artistchef ? event.artistchef : null;
    eventData['artistorchestre'] = event.artistorchestre ? event.artistorchestre : null;
    eventData['artistsoliste']   = event.artistsoliste ? event.artistsoliste : null;
    eventData['artistothers']    = event.artistothers ? event.artistothers : null;
    eventData['artistkey']       = artistkey;
    eventData['date']            = date;
    eventData['genre']           = event.genre ? event.genre : null;
    eventData['origine']         = event.origine ? event.origine : null;
    eventData['programkey']      = event.programkey ? event.programkey : null;
    eventData['producername']    = event.producer ? event.producer : null;
    eventData['produceremail']   = event.produceremail ? event.produceremail : null;
    eventData['producerphone']   = event.producerphone ? event.producerphone : null;
    eventData['produceraddress'] = event.produceraddress ? event.produceraddress : null;
    eventData['title']           = event.title ? event.title : null;
    eventData['time']            = event.time ? event.time : null;
    eventData['tourkey']         = tourkey;
    eventData['tourtitle']       = tourtitle;
    eventData['venuekey']        = event.venuekey ? event.venuekey : null;

    const batch = this.afs.firestore.batch();

    const eventRef = this.eventCollection.doc(eventkey).ref;
    batch.update(eventRef, { ['tourkey'] : tourkey });
    batch.update(eventRef, { ['tourtitle'] : tourtitle });

    if (tour.artistkey) {
      const artistEventRef = this.memberCollection.doc(tour.artistkey).collection('events').doc(eventkey).ref;
      batch.update(artistEventRef, { ['tourkey'] : tourkey });
      batch.update(artistEventRef, { ['tourtitle'] : tourtitle });
    }

    const tourRef = this.tourCollection.doc(tourkey).ref;
    batch.update(tourRef, { ['countevents'] : countevents });
    if (!tour.artistlastname && artistlastname) { batch.update(tourRef, { ['artistlastname'] : event.artistlastname }); }
    if (!tour.artistfirstname && artistfirstname) { batch.update(tourRef, { ['artistfirstname'] : event.artistfirstname }); }
    if (!tour.artistkey) {
      batch.update(tourRef, { ['artistkey'] : artistkey });

      const artistEventRef = this.memberCollection.doc(event.artistkey).collection('events').doc(eventkey).ref;
      batch.update(artistEventRef, { ['tourkey'] : tourkey });
      batch.update(artistEventRef, { ['tourtitle'] : tourtitle });
    }

    const eventTourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;
    batch.set(eventTourRef, eventData);

    batch.commit().then(() => {console.log(eventData); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'addTourEvent', eventkey, 'tourdata');


  }


  createEventFromTour(newEventForm: NgForm): void {

    const eventref            = this.afs.firestore.collection('events').doc();
    const eventkey            = eventref.id;
    const tourkey             = newEventForm.value.tourkey ? newEventForm.value.tourkey : null;
    const tourtitle           = newEventForm.value.tourtitle ? newEventForm.value.tourtitle : null;
    const countevents         = newEventForm.value.countevents ? newEventForm.value.countevents++ : 1;
    const eventInTourRef      = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;
    const date                = newEventForm.value.newEventDate ? newEventForm.value.newEventDate : null;

    const newEventData          = {};
    newEventData['timestamp'] = Date.now();
    newEventData['title']     = newEventForm.value.newEventTitle ? newEventForm.value.newEventTitle : null;
    newEventData['comment']   = newEventForm.value.newEventComment ? newEventForm.value.newEventComment : null;
    newEventData['statut']    = '0';
    newEventData['tourkey']   = tourkey;
    newEventData['tourtitle'] = tourtitle;
    newEventData['date']      = date;
    newEventData['countevents'] = countevents;

    const batch = this.afs.firestore.batch();
    batch.set(eventref, newEventData);
    batch.set(eventInTourRef, newEventData);

    batch.commit().then(function() {console.log('Event Created on Tour'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'createEventFromTour', eventkey, 'tourdata');
    this.router.navigate(['/event$/event/' + eventkey]);
  }




  createEvent(newEventForm, artist?, programs?) {
    const eventref                      = this.afs.firestore.collection('events').doc();
    const eventkey                      = eventref.id;
    const batch                           = this.afs.firestore.batch();
    // Create event from form event
    if (newEventForm.value) {
      this.insertInEventCollectionFromEventForm(newEventForm, eventkey, batch);
    } else {
      // duplicate event
      this.insertInEventCollectionFromEventDetailsOrPublicEvents(newEventForm, eventkey, batch, artist, programs);
      this.logClass.createLog(this.afs, 'createEvent', eventkey);
      return this.router.navigate(['/event$/event/' + eventkey]);
    }
  }

   // remplacer ceci:
   insertInEventCollectionFromEventForm(dataForm, eventkey, batch) {
      const newEventData                 = {};
      const artistData                   = {};
      newEventData['timestamp']          = Date.now();
      newEventData['eventkey']          = eventkey;
      newEventData['title']              = dataForm.value.newEventTitle ? dataForm.value.newEventTitle : null;
      newEventData['comment']            = dataForm.value.newEventComment ? dataForm.value.newEventComment : null;
      newEventData['statut']             = '0';
      newEventData['date']               = dataForm.value.newEventDate ? dataForm.value.newEventDate : null;
      // add artist
      if (dataForm.value.artist) {
          newEventData['genre']             = dataForm.value.gender ? dataForm.value.gender : null;
          newEventData['artistkey']           = dataForm.value.artist.$key;
          newEventData['artistfirstname']     = dataForm.value.artist.firstname ? dataForm.value.artist.firstname : null;
          newEventData['artistlastname']      = dataForm.value.artist.lastname ? dataForm.value.artist.lastname : null;
          if (dataForm.value.tourkey) {
            const eventInTourRef        = this.tourCollection.doc(dataForm.value.tourkey).collection('events').doc(eventkey).ref;
            newEventData['tourkey']     = dataForm.value.tourkey ? dataForm.value.tourkey : null;
            newEventData['tourtitle']   = dataForm.value.tourtitle ? dataForm.value.tourtitle : null;
            newEventData['countevents'] = dataForm.value.countevents ? dataForm.value.countevents++ : 1;
            batch.set(eventInTourRef, newEventData);
          }
          this.affiliateArtistToEvent(dataForm.value.artist, eventkey, batch, dataForm);
      }
      this.logClass.createLog(this.afs, 'createEvent', eventkey);
      this.eventCollection.doc(eventkey).set(newEventData)
      .then(function(docRef) { console.log(eventkey); })
      .catch(function(error) { console.error('Error adding document: ', error); });
      return this.router.navigate(['/event$/event/' + eventkey]);
   }

   insertInEventCollectionFromEventDetailsOrPublicEvents(newEventForm, eventkey, batch, artist, programs) {
     console.log('Do we have the artist data ? ', artist);
      const newEventData               = {};
      let artistData: any ;
      let programData: any ;
      newEventData['timestamp']        = newEventForm.timestamp ? newEventForm.timestamp : null;
      newEventData['title']            = newEventForm.title ? newEventForm.title + ' copie' : null;
      newEventData['comment']          = newEventForm.comment ? newEventForm.comment : null;
      newEventData['statut']           = newEventForm.statut ? newEventForm.statut : null;
      newEventData['date']             = newEventForm.date ? newEventForm.date : null;
      newEventData['artistkey']        = artist.key ? artist.key : null;
      newEventData['artistfirstname']  = artist.firstname ? artist.firstname : null;
      newEventData['artistlastname']   = artist.lastname ? artist.lastname : null;
      newEventData['artistorchestre']  = artist.artistorchestre ? artist.artistorchestre : null;
      newEventData['artistsoliste']    = artist.artistsoliste ? artist.artistsoliste : null;
      newEventData['eventkey']         = eventkey;
      newEventData['genre']            = newEventForm.genre ? newEventForm.genre : null;
      newEventData['isonline']         = newEventForm.isonline ? newEventForm.isonline : null;
      newEventData['link']             = newEventForm.link ? newEventForm.link : null;
      newEventData['producer']         = newEventForm.producer ? newEventForm.producer : null;
      newEventData['programkey']       = newEventForm.programkey ? newEventForm.programkey : null;
      newEventData['time']             = newEventForm.time ? newEventForm.time : null;
      if (newEventForm.venue) {
        const venuekey                   = newEventForm.venuekey ? newEventForm.venuekey : null;
        const venueData                  = {};
        venueData['venueaddress']      = newEventForm.venue.venueaddress ? newEventForm.venue.venueaddress : null;
        venueData['venuekey']          = newEventForm.venue.venuekey ? newEventForm.venue.venuekey : null;
        venueData['venuename']         = newEventForm.venue.venuename ? newEventForm.venue.venuename : null;
        venueData['venueurl']          = newEventForm.venue.venueurl ? newEventForm.venue.venueurl : null;
        newEventData['venuekey']       = venuekey;
        newEventData['venue']          = venueData;
        const venueRootRef             =  this.afs.collection('venues').doc(venuekey)
                                                  .collection('events').doc(eventkey).ref;
        batch.set(venueRootRef, newEventData);
      }
      if (artist) {
        artistData = this.denormalizeEveventInArtistCollection(artist, newEventData, eventkey, batch) ;
      }
      if (programs) {
        programData = this.denormalizeEventInProgramCollection(programs, eventkey, batch);
      }

      console.log(newEventData);

      const eventRootRef = this.eventCollection.doc(eventkey).ref;
      batch.set(eventRootRef, newEventData);
      batch.commit().then(function() {console.log(newEventData, artistData, programData); })
       .catch(function(err) { console.log(err); });
  }

  denormalizeEveventInArtistCollection(artist, newEventData, eventkey, batch) {
    const artistData = {};
    if (artist) {
      const artistkey = artist.$key;
      artistData['firstname'] = artist.firstname ? artist.firstname : null;
      artistData['instrument'] = artist.instrument ? artist.instrument : null;
      artistData['key'] = artist.$key;
      artistData['lastname'] = artist.lastname ? artist.lastname : null;
      const artistRootRef =  this.afs.collection('events').doc(eventkey)
                                      .collection('artists').doc(artistkey).ref;
      batch.set(artistRootRef, artistData);
      const eventInArtistRootRef =  this.afs.collection('members').doc(artistkey)
                                            .collection('events').doc(eventkey).ref;
      batch.set(eventInArtistRootRef, newEventData);
      return artistData ;
    }
  }

  denormalizeEventInProgramCollection(programs, eventkey, batch) {
    const programData = {} ;
    if (programs.length) {
      for (let i = 0; i < programs.length; i++) {
          const programkey = programs[i].$key;
          programData['composerfirstname'] = programs[i].composerfirstname ? programs[i].composerfirstname : null;
          programData['composerkey'] = programs[i].composerkey ? programs[i].composerkey : null;
          programData['composerlastname'] = programs[i].composerlastname ? programs[i].composerlastname : null;
          programData['compositionkey'] = programs[i].compositionkey;
          programData['compositiontitle'] = programs[i].compositiontitle ? programs[i].compositiontitle : null;
          programData['eventkey'] = eventkey;
          programData['eventtitle'] = programs[i].eventtitle ? programs[i].eventtitle : null;
          programData['programkey'] = programkey;
          programData['timestamp'] = programs[i].timestamp ? programs[i].timestamp : null;
          const artistRootRef =  this.afs.collection('events').doc(eventkey)
                                        .collection('program').doc(programkey).ref;
          batch.set(artistRootRef, programData);
      }
      console.log(programData);
      return programData ;
   }
  }

  affiliateArtistToEvent(data, eventkey, batch, dataForm) {
    // console.log('data : '+data);
    if (data) {
    const newEventData                  = {};
    const artistData                    = {};
    const arti$t                        = data ;
    const artistkey                       = arti$t.$key ? arti$t.$key : null;

    newEventData['artistkey']           = artistkey;
    newEventData['artistfirstname']     = arti$t.firstname ? arti$t.firstname : null;
    newEventData['artistlastname']      = arti$t.lastname ? arti$t.lastname : null;
    newEventData['timestamp']          = Date.now();
    newEventData['title']              = dataForm.value.newEventTitle ? dataForm.value.newEventTitle : null;
    newEventData['comment']            = dataForm.value.newEventComment ? dataForm.value.newEventComment : null;
    newEventData['statut']             = '0';
    newEventData['date']               = dataForm.value.newEventDate ? dataForm.value.newEventDate : null;
    newEventData['tourkey']            = dataForm.value.tourkey ? dataForm.value.tourkey : null;
    newEventData['tourtitle']          = dataForm.value.tourtitle ? dataForm.value.tourtitle : null;
    newEventData['countevents']        = dataForm.value.countevents ? dataForm.value.countevents++ : 1;

    artistData['firstname']             = arti$t.firstname ? arti$t.firstname : null;
    artistData['instrument']            = arti$t.instrument ? arti$t.instrument : null;
    artistData['key']                   = artistkey;
    artistData['lastname']              = arti$t.lastname ? arti$t.lastname : null;

    const eventRootRef                  = this.afs.collection('events').doc(eventkey).ref;
    batch.set(eventRootRef, newEventData);

    const artistRootRef                 = this.afs.collection('events').doc(eventkey)
                                                   .collection('artists').doc(artistkey).ref;
    batch.set(artistRootRef, artistData);


    const eventInArtistRootRef     =  this.afs.collection('members').doc(artistkey)
                                              .collection('events').doc(eventkey).ref;
    batch.set(eventInArtistRootRef, newEventData);

    batch.commit().then(() => {console.log(newEventData, artistData); }).catch(function(err) { console.log(err); });
      console.log(data);
    }
  }












  addCompositionToProgram(event, composer, composition, progrkeyfromcopy) {

    console.log('composition', composition);
    console.log( progrkeyfromcopy);

    const eventkey                   = event.$key;
    const eventtitle                 = event.title ? event.title : null;

    // If coming from new program or copy
    const compositionkey             = composition.viewModel ? composition.viewModel.$key : composition.$key;
    const compositiontitle           = composition.viewModel ? composition.viewModel.title : composition.title;
    const composerkey                = composer.$key ? composer.$key : composer.key;

    if (progrkeyfromcopy) {
      this.programkey = progrkeyfromcopy ? progrkeyfromcopy : null;
    } else {
      const programkeyfromevent       = event.programkey ? event.programkey : null;
      const programref                = this.afs.firestore.collection('programs').doc();
      this.programkey                 = programkeyfromevent ? programkeyfromevent : programref.id;
    }

    const programData                  = {};
    programData['composerlastname']  = `${composer.lastname}`;
    programData['composerfirstname'] = `${composer.firstname}`;
    programData['composerkey']       = composerkey;
    programData['timestamp']         = Date.now();
    programData['compositiontitle']  = compositiontitle;
    programData['compositionkey']    = compositionkey;
    programData['eventkey']          = eventkey;
    programData['eventtitle']        = eventtitle;
    programData['programkey']        = this.programkey ? this.programkey : null;

    const eventData                    = {};
    eventData['eventtitle']          = eventtitle;
    eventData['eventkey']            = eventkey;


    const batch = this.afs.firestore.batch();

    const programRootRef = this.programCollection.doc(this.programkey).ref;
    batch.set(programRootRef, eventData);

    const eventRef = this.eventCollection.doc(eventkey).ref;
    batch.update(eventRef, { ['programkey'] : this.programkey });

    const programRef = this.programCollection.doc(this.programkey).collection('compositions').doc(compositionkey).ref;
    batch.set(programRef, programData);

    const eventRefInProgram = this.eventCollection.doc(eventkey).collection('program').doc(compositionkey).ref;
    batch.set(eventRefInProgram, programData);

    batch.commit().then(function() {console.log(programData); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'addCompositionToProgram', compositionkey, 'programdata');
    this.router.navigate(['/event$/event/' + eventkey]);
  }

  duplicateProgramEvent(oldprogram, event) {

    const programref                 = this.afs.firestore.collection('programs').doc();
    const programkey                 = programref.id;

    if (oldprogram.length) {
      for (let i = 0; i < oldprogram.length; i++) {

        const composerData          = {};
        composerData['lastname']  = oldprogram[i].composerlastname ? oldprogram[i].composerlastname : null;
        composerData['firstname'] = oldprogram[i].composerfirstname ? oldprogram[i].composerfirstname : null;
        composerData['key']       = oldprogram[i].composerkey ? oldprogram[i].composerkey : null;

        const compositionData       = {};
        compositionData['title']  = oldprogram[i].compositiontitle ? oldprogram[i].compositiontitle : null;
        compositionData['key']    = oldprogram[i].compositionkey ? oldprogram[i].compositionkey : null;

        this.addCompositionToProgram(event, composerData, compositionData, programkey);
      }
    }
  }

  addArtistToEvent(event, artist) {

    const eventkey                 = event.$key;
    const venuekey                 = event.venuekey ? event.venuekey : null;
    const programkey               = event.programkey ? event.programkey : null;
    const tourkey                  = event.tourkey ? event.tourkey : null;
    const artistkey                = artist.$key ? artist.$key : null;
    const otherartists             = event.artists ? event.artists : 0;
    const artistlastname           = artist.lastname ? artist.lastname : null;
    const artistfirstname          = artist.firstname ? artist.firstname : null;

    const eventData                = {};
    eventData['timestamp']       = Date.now();
    eventData['title']           = event.title ? event.title : null;
    eventData['genre']           = event.genre ? event.genre : null;

    eventData['artistchef']      = event.artistchef ? event.artistchef : null;
    eventData['artistorchestre'] = event.artistorchestre ? event.artistorchestre : null;
    eventData['artistsoliste']   = event.artistsoliste ? event.artistsoliste : null;
    eventData['artistothers']    = event.artistothers ? event.artistothers : null;
    eventData['date']            = event.date ? event.date : null;
    eventData['eventkey']        = eventkey;
    eventData['isonline']        = event.isonline ? event.isonline : null;
    eventData['origine']         = event.origine ? event.origine : null;
    eventData['producer']        = event.producer ? event.producer : null;
    eventData['producername']    = event.producername ? event.producername : null;
    eventData['produceremail']   = event.produceremail ? event.produceremail : null;
    eventData['producerphone']   = event.producerphone ? event.producerphone : null;
    eventData['produceraddress'] = event.produceraddress ? event.produceraddress : null;
    eventData['programkey']      = programkey;
    eventData['status']          = '1';
    eventData['time']            = event.time ? event.time : null;
    eventData['tourkey']         = tourkey;
    eventData['tourtitle']       = event.tourtitle ? event.tourtitle : null;
    eventData['venuekey']        = venuekey;

    const artistData               = {};
    artistData['firstname']      = artist.firstname ? artist.firstname : null;
    artistData['lastname']       = artist.lastname ? artist.lastname : null;
    artistData['key']            = artistkey;
    artistData['instrument']     = artist.instrument ? artist.instrument : null;

    const venueData                = {};
    venueData['address']         = event.venue ? event.venue.venueaddress ? event.venue.venueaddress : null : null;
    venueData['name']            = event.venue ? event.venue.venuename ? event.venue.venuename : null : null;
    venueData['url']             = event.venue ? event.venue.venueurl ? event.venue.venueurl : null : null;
    venueData['venuekey']        = venuekey;

    eventData['venue']           = venueData;

    const batch        = this.afs.firestore.batch();

    const eventRef = this.allEventCollection.doc(eventkey).ref;
    batch.update(eventRef, { artistlastname: artistlastname });
    batch.update(eventRef, { artistfirstname: artistfirstname });

    if (artistkey) {
      batch.update(eventRef, { artistkey: artistkey });

      const artistRef    = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;
      batch.set(artistRef, eventData);

      const eventArtistRef     = this.allEventCollection.doc(eventkey).collection('artists').doc(artistkey).ref;
      batch.set(eventArtistRef, artistData );
    } else { console.error('No artistkey'); }

    if (programkey) {
      const programRef = this.programCollection.doc(programkey).collection('artists').doc(artistkey).ref;
      batch.set(programRef, artistData);
    }

    // if (venuekey) {
    //   const venueRef   = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
    //   batch.set(venueRef, artistData);
    // }

    if (tourkey) {
      const tourRef    = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
      batch.set(tourRef, artistData);
    }

    batch.commit().then(() => {console.log(eventData, artistData); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'addArtistToEvent', eventkey, 'artistdata');
    this.router.navigate(['/event$/event/' + eventkey]);
  }


  addDetailsRouting(tourkey, artistkey, newTourDetailForm) {

    const newRoutingRef       = this.afs.firestore.collection('routings').doc();
    const newRoutingkey       = newRoutingRef.id;
    const date                = newTourDetailForm.value.date ? newTourDetailForm.value.date : null;
    const hour                = newTourDetailForm.value.hour = ! 0 ? newTourDetailForm.value.hour : '00';
    const minute              = newTourDetailForm.value.minute = ! 0 ? newTourDetailForm.value.minute : '00';
    let newdate               = date.setHours(hour, minute);
    newdate                   = new Date(newdate);
    const hourformat          = hour.toString().length === 1 ? '0' + hour.toString() : hour.toString();
    const minuteformat        = minute.toString().length === 1 ? '0' + minute.toString() : minute.toString();

    const detailsData         = {};
    detailsData['timestamp']  = Date.now();
    detailsData['date']       = newdate;
    detailsData['details']    = newTourDetailForm.value.details ? newTourDetailForm.value.details : null;
    detailsData['time']       = hourformat + ':' + minuteformat;
    detailsData['artistkey']  = artistkey ? artistkey : null;
    detailsData['tourkey']    = tourkey;
    detailsData['routingkey'] = newRoutingkey;

    if (newRoutingkey && tourkey) {
      const batch = this.afs.firestore.batch();

      const routingRef = this.routingCollection.doc(newRoutingkey).ref;
      batch.set(routingRef, detailsData);

      const tourDetailsref = this.tourCollection.doc(tourkey).collection('routings').doc(newRoutingkey).ref;
      batch.set(tourDetailsref, detailsData);

      batch.commit().then(() => {console.log('New Routing Details Recorded'); }).catch(function(err) { console.log(err); });
      this.logClass.createLog(this.afs, 'addDetailsRouting', newRoutingkey);
    } else { console.error('Key not provided.'); }

  }



/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// U P D A T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////



  updateEventFieldWithBatch(artists, event, field, value, user?, redirect = true, activeAsync = false) {
    const eventkey          = event?.$key ? event.$key : null;
    const artistkey         = event?.artistkey ? event.artistkey : null;
    const tourkey           = event?.tourkey ? event.tourkey : null;
    // const artistlastname    = event?.artistlastname ? event.artistlastname : null;    
    // const venuekey          = event?.venuekey ? event.venuekey : null;

    if (!eventkey) return;

    const updateField: any = {
      [field]: value,
      lastupdate: {
        date: Date.now(),
        user: user ? user.displayName : '',
        uid: user ? user.uid : ''
      }
    };

    const batch = writeBatch(this.newAfs);

    const eventRef = doc(this.newAfs, `events/${eventkey}`);
    batch.update(eventRef, updateField);

    // const eventRef = this.eventCollection.doc(eventkey).ref;
    // batch.update(eventRef, updateField );

    if (tourkey) {
      const tourRef = doc(this.newAfs, `tours/${tourkey}/events/${eventkey}`);
      batch.update(tourRef, updateField);
    }

    if (artistkey) {
      const artistRef = doc(this.newAfs, `members/${artistkey}/events/${eventkey}`);
      batch.update(artistRef, updateField);
    }

    if(activeAsync) return batch.commit();

    batch.commit()
        .then(() => {
          console.log(updateField); 
          // if(field=='isonline') window.location.reload();
          if(redirect) this.router.navigate(['/event$/event/' + eventkey]);
        })
        .catch((err) => { console.log(err); });
        this.logClass.createLog(this.afs, 'updateEventFieldWithBatch', eventkey, field);
  }




  addArtistkeyToEvent(event, field, value): void {

    const eventkey          = event.$key ? event.$key : null;
    const artistkey         = value ? value : null;
    const artistlastname    = event.artistlastname ? event.artistlastname : null;
    const tourkey           = event.tourkey ? event.tourkey : null;
    const venuekey          = event.venuekey ? event.venuekey : null;

    const updateField       = {};
    updateField[`${field}`] = value;

    const batch = this.afs.firestore.batch();

    const eventRef = this.eventCollection.doc(eventkey).ref;
    batch.update(eventRef, updateField );

    if (tourkey) {
      const tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;
      batch.update(tourRef, updateField);
    }

    if (artistkey) {
      const artistRef = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref ?
                        this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref : null;
      batch.update(artistRef, updateField);
    }


    batch.commit()
        .then(() => {console.log(updateField); })
        .catch((err) => { console.log(err); });

        this.logClass.createLog(this.afs, 'addArtistkeyToEvent', artistkey);
  }





  updateTourField(tour, field, value, user?): void {
    const tourkey              = tour.$key ? tour.$key : null;
    const updateField          = {};
    updateField[`${field}`]  = value;
    updateField['lastupdate'] = {
      date: Date.now(),
      user: user ? user.displayName : '',
      uid: user ? user.uid : ''
    };

    console.log(user);

    const tourRef              = this.tourCollection.doc(tourkey).ref;
    if (field && value) {
      const batch              = this.afs.firestore.batch();
      batch.update(tourRef, updateField );
      batch.commit()
          .then(function() {console.log(updateField); })
          .catch(function(err) { console.log(err); });

          this.logClass.createLog(this.afs, 'updateTourField', tourkey);
    }
  }

  dropEmailInTour(tourkey, email) {
    const updateField             = {};
    updateField[`artistemail`]  = email;
    const tourRef                 = this.tourCollection.doc(tourkey).ref;
    const batch                   = this.afs.firestore.batch();
    batch.update(tourRef, updateField );
    batch.commit().then(function() {console.log(updateField); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'dropEmailInTour', tourkey);
  }



  addVenueToEvent(event, venue, artistKey?) {

    console.log('Add Venue to Event', event, venue, artistKey);

    const eventkey              = event.$key;
    const eventtitle            = event.title;
    const oldvenuekey           = event.venue ? event.venue.venuekey : null;
    const venuekey              = venue.$key;

    debugger

    const eventData             = {};
    eventData['venuename']    = venue.name;
    eventData['venueaddress'] = venue.address;
    eventData['venueurl']     = venue.url;
    eventData['venuekey']     = venuekey;

    const updateVenueEvents = {};
    updateVenueEvents[`${eventkey}`] = eventtitle;

    const batch = this.afs.firestore.batch();

    const eventRef = this.eventCollection.doc(eventkey).ref;
    batch.update(eventRef, { venue: eventData });
    batch.update(eventRef, { venuekey: venuekey });

    // const venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref;
    // batch.set(venueRef, event);

    // const memberRef = this.afs.collection('members').doc(artistKey).collection('events').doc(eventkey).ref;
    // batch.update(memberRef, { venue: eventData });
    // batch.update(memberRef, { venuekey: venuekey });
    // console.log(eventkey, artistKey,venuekey);
    // Suppression de l'ancienne Venue
    // if (oldvenuekey) {
    //   const deleteVenueRef = this.venueCollection.doc(oldvenuekey)
    //                            .collection('events').doc(eventkey).ref;
    //   batch.delete(deleteVenueRef);
    // }

    batch.commit().then(() => {
      console.log('Batching'); 
      this.router.navigate(['/event$/event/' + eventkey]);      
    });

   // this.logClass.createLog(this.afs,'addVenueToEvent',venuekey);
  }








/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// D E L E T E
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////


  removeRoutingDetails(routing) {
    const routingkey = routing.$key ? routing.$key : null;
    const tourkey    = routing.tourkey ? routing.tourkey : null;

    if (routingkey && tourkey) {
      const batch = this.afs.firestore.batch();

      const deleteRoutingTourRef = this.tourCollection.doc(tourkey).collection('routings').doc(routingkey).ref;
      batch.delete(deleteRoutingTourRef);

      const deleteRoutingRef = this.routingCollection.doc(routingkey).ref;
      batch.delete(deleteRoutingRef);

      batch.commit().then(function() {console.log('Routing Delete'); });
      this.logClass.createLog(this.afs, 'removeRoutingDetails', routingkey);
    } else { console.error('Keys not provided.'); }


  }

  deleteVenueFromEvent(event) {

    const eventkey              = event.$key;
    const venuekey              = event.venue.venuekey;

    if (!eventkey || !venuekey) return; // Vérifie que les clés existent

    const eventRef = doc(this.newAfs, `events/${eventkey}`);

    // const batch = this.newAfs.firestore.batch();
    const batch = writeBatch(this.newAfs);

    batch.update(eventRef, { venue: null, venuekey: null }); // Supprime la venue de l'event

    // const batch = this.newAfs.firestore.batch();
    // const eventRef = this.eventCollection.doc(eventkey).ref;
    // batch.update(eventRef, { venue: null });
    // batch.update(eventRef, { venuekey: null });

    // const deleteVenueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref;
    // batch.delete(deleteVenueRef);

    batch.commit().then(() => {
      console.log('Venue supprimée de l’événement avec succès !');
      this.logClass.createLog(this.afs, 'deleteVenueFromEvent', venuekey);
      // window.location.reload();
    })
    .catch(error => console.error('Erreur lors de la suppression de la venue:', error));


  }


  deleteEventWithNoArtist(event): void {

    const eventkey     = event.$key;
    const tourkey      = event.tourkey ? event.tourkey : null;
    const venuekey     = event.venuekey ? event.venuekey : null;
    const programkey   = event.programkey ? event.programkey : null;

    const batch = this.afs.firestore.batch();

    console.log('Event Key', eventkey);

    const eventRef = this.eventCollection.doc(eventkey).ref;
    batch.delete(eventRef);

    if (venuekey)  { const deleteVenueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).ref;
    batch.delete(deleteVenueRef);   }

    if (programkey) { const programRef = this.programCollection.doc(programkey).ref;
      batch.delete(programRef);
    }

    if (tourkey) {
      const tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;
      batch.delete(tourRef);
    }

    batch.commit().then(()=> {console.log('Batch Delete'); });
    this.logClass.createLog(this.afs, 'deleteEventWithNoArtist', venuekey);
    this.router.navigate(['/events']);
  }


  deleteCompositionInProgram(event, composition) {

    const eventkey       = event.$key;
    const compositionkey = composition.$key;
    const tourkey      = event.tourkey ? event.tourkey : null;
    const venuekey     = event.venuekey ? event.venuekey : null;
    const programkey   = event.programkey ? event.programkey : null;

    const batch = this.afs.firestore.batch();

    const eventRef = this.eventCollection.doc(eventkey).collection('program').doc(compositionkey).ref;
    batch.delete(eventRef);

    if (venuekey) {
      const deleteVenueRef = this.venueCollection.doc(venuekey)
                              .collection('events').doc(eventkey)
                              .collection('program').doc(compositionkey).ref;
      batch.delete(deleteVenueRef);
    }

    if (programkey) {
      const programRef = this.programCollection.doc(programkey).collection('compositions').doc(compositionkey).ref;
      batch.delete(programRef);
    }

    if (tourkey) {
      const tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('program').doc(compositionkey).ref;
      batch.delete(tourRef);
    }

    batch.commit().then(() =>{
      console.log('Batch Delete'); 
      window.location.reload();
    }).catch((err) =>{ console.log(err); });
    this.logClass.createLog(this.afs, 'deleteCompositionInProgram', compositionkey);
  }


  removeArtistFromEvent(event, artist) {

    const eventkey                 = event.$key;
    const venuekey                 = event.venuekey ? event.venuekey : null;
    const programkey               = event.programkey ? event.programkey : null;
    const tourkey                  = event.tourkey ? event.tourkey : null;
    const artistkey                = artist.$key ? artist.$key : artist;

    const keyData                  = {};
    keyData['eventkey']          = eventkey;
    keyData['venuekey']          = venuekey;
    keyData['programkey']        = programkey;
    keyData['tourkey']           = tourkey;
    keyData['artistkey']         = artistkey;

    const batch = this.afs.firestore.batch();

    const eventRef = this.allEventCollection.doc(eventkey).ref;
    batch.update(eventRef, {artistlastname: firebase.default.firestore.FieldValue.delete()});
    batch.update(eventRef, {artistkey: firebase.default.firestore.FieldValue.delete()});

    const eventArtistRef = this.allEventCollection.doc(eventkey).collection('artists').doc(artistkey).ref;
    batch.delete(eventArtistRef);

    const artistRef = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;
    batch.delete(artistRef);

    // if (programkey) {
    //   const programRef = this.programCollection.doc(programkey).collection('artists').doc(artistkey).ref;
    //   batch.delete(programRef);
    // }

    // if (venuekey) {
    //   const venueRef = this.venueCollection.doc(venuekey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
    //   batch.delete(venueRef);
    // }

    if (tourkey) {
      const tourRef = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).collection('artists').doc(artistkey).ref;
      batch.delete(tourRef);
    }

    batch.commit().then(() => {console.log('Batch Delete'); }).catch((err)=> { console.log(err); });
    this.logClass.createLog(this.afs, 'removeArtistFromEvent', eventkey);
  }

  removeAllArtistsAndDeleteEvent(event, artist) {
    if (artist) {
      const artistkey = artist.$key ? artist.$key : null;
      this.removeArtistFromEvent(event, artistkey);
    }
    this.deleteEventWithNoArtist(event);
  }

  deleteTour(tour): void {
    const tourkey = tour.$key;
    const tourPath = `tours/${tourkey}`;

    const batch = this.afs.firestore.batch();
    const tourRef = this.tourCollection.doc(tourkey).ref;
    batch.delete(tourRef);
    batch.commit().then(function() {console.log('Batch Delete'); }).catch(function(err) { console.log(err); });
    this.router.navigate(['/event$/tours/']);
    this.logClass.createLog(this.afs, 'deleteTour', tourkey);
  }

  removeTourFromEvent(event, eventsnb) {
    const tourkey    = event.tourkey ? event.tourkey : null;
    const eventkey   = event.$key ? event.$key : null;
    const artistkey  = event.artistkey ? event.artistkey : null;
    const countevents = eventsnb ? (eventsnb - 1) : 0;

    const batch = this.afs.firestore.batch();

    const tourRef         = this.tourCollection.doc(tourkey).ref;
    const eventRef        = this.eventCollection.doc(eventkey).ref;
    const tourEventRef    = this.tourCollection.doc(tourkey).collection('events').doc(eventkey).ref;

    batch.delete(tourEventRef);
    batch.update(tourRef, {['countevents'] : countevents});
    batch.update(eventRef, {['tourkey'] : firebase.default.firestore.FieldValue.delete()});
    batch.update(eventRef, {['tourtitle'] : firebase.default.firestore.FieldValue.delete()});

    if (artistkey) {
      const eventArtistRef  = this.memberCollection.doc(artistkey).collection('events').doc(eventkey).ref;
      batch.update(eventArtistRef, {['tourtitle'] : firebase.default.firestore.FieldValue.delete()});
      batch.update(eventArtistRef, {['tourkey'] : firebase.default.firestore.FieldValue.delete()});
    }

    batch.commit().then(function() {console.log('Batch Delete'); }).catch(function(err) { console.log(err); });
    this.logClass.createLog(this.afs, 'removeTourFromEvent', tourkey);
  }

  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }



  /////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  ////////// REPAIR EVENT
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
  async checkDenormalizeVenueAndArtist(tourKey: string, venueKey: string, eventKey: string): Promise<boolean> {
    let venue = false;
    let tour = false;
    if(venueKey) {
      const venueRef = await this.venueCollection.doc(venueKey).collection('events').doc(eventKey).ref.get();
      if(venueRef.exists) console.log('Exist');
      else {
        console.log('Not existe event in venue');
        venue = true;
      };
    }

    if(tourKey) {
      const tourRef = await this.tourCollection.doc(tourKey).collection('events').doc(eventKey).ref.get();
      if(tourRef.exists) console.log('Exist');
      else {
        console.log('Not exist event in tour');
        tour = true;
      }
    }

    return tour || venue;
  }


  repairEvent(event: any) {
      const batch = this.afs.firestore.batch();
      // if(event.venuekey) {
      //   const venueRef = this.venueCollection.doc(event.venuekey).collection('events').doc(event.$key).ref;
      //   batch.set(venueRef, event);
      // }
      if(event.tourkey) {
        const tourRef = this.tourCollection.doc(event.tourkey).collection('events').doc(event.$key).ref;
        batch.update(tourRef, event);
      }
      
      return batch.commit();
  }

}
