<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<ng-template #noArtist>        
	<div class="artist-not-found">
		<h1>
			<span class="material-icons">warning</span>
			<span>Artist not found!</span>
		</h1>
		<p>This artist can't be find in the database. It could have been deleted by a user.</p>
	</div>	
</ng-template>

<div *ngIf="artist | async; let artist; else (loading && noArtist) " class="fx-100 fx-row fx-wrap" >

	<div class="artits-info fx-row fx-wrap fx-50 fx-100-xs fx-gap-5"
		[ngStyle.xs]="{'font-size':'1em'}">
			<div class="fx-100">
				<h3>{{ artist.lastname }} {{ artist.firstname}}</h3>
				<span [ngStyle]="{'font-weight':'400', 'color':'#B3B3B3','font-size':'0.8em'}">{{ artist.instrument.name }}</span>
			</div>
			<div
				*ngIf="artist.email"
				class="fx-100 fx-start-end">
					<div class="grey-but" (click)="gotoMailto(artist.email)">
						<mat-icon class="mat-icon-but">mail_outline</mat-icon> {{ artist.email }}
					</div>
			</div>
			<div 
				*ngIf="artist.phone"
				class = " fx-100 fx-start-start	">
					<div class="grey-but" (click)="gotoCall(artist.phone)">
						<mat-icon class="mat-icon-but">smartphone</mat-icon> {{ artist.phone }}
					</div>
			</div>
	</div>

	<div 
		class="fx-50 fx-100-xs fx-row fx-wrap fx-start-start fx-gap-5" 
		[ngStyle.xs]="{'font-size':'1em', 'padding':'20px 0 20px','margin':'0'}">
			<div class="fx-100 fx-ai-end fx-ai-end" 
				*ngIf="artist.kajimotomanagementparisname"
				[ngStyle]="{'font-size':'1em','color':'#999999','padding-top':'20px'}">
				<span>Manager : {{ artist.kajimotomanagementparisname }}</span>
			</div>
			<div class="fx-100 fx-start-center"
				*ngIf="artist.kajimotomanagementemail">
					<div class="grey-but" (click)="gotoMailto(artist.kajimotomanagementemail)">
						<mat-icon class="mat-icon-but">mail_outline</mat-icon> {{ artist.kajimotomanagementemail }}
					</div>
			</div>
			<div class="fx-100 fx-start-center" 
				*ngIf="artist.kajimotomanagementphonemobile">
					<div class="grey-but" (click)="gotoCall(artist.kajimotomanagementphonemobile)">
						<mat-icon class="mat-icon-but">smartphone</mat-icon> {{ artist.kajimotomanagementphonemobile }}
					</div>
			</div>
			<div class="fx-100 fx-start-center"
				*ngIf="artist.kajimotomanagementphoneoffice">
					<div class="grey-but" (click)="gotoCall(artist.kajimotomanagementphoneoffice)">
						<mat-icon class="mat-icon-but">smartphone</mat-icon> {{ artist.kajimotomanagementphoneoffice }}
					</div>
			</div>
	</div>

</div>
