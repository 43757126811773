<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<h2>Agents List & Artists Management</h2>

<div *ngIf="(auth.user | async)?.role == 'admin'">
	
	<div *ngFor="let member of members | async; let i = index" class="fx-row fx-start-start agent">
						
		<div class="fx-10"> 
			<span class="agent-name"
			[routerLink]="['./../member$/member', member.$key]">{{member.firstname}} {{member.lastname}}</span>
		</div>
		
		<div class="fx-90">
			<app-management-child [agentData]="member"></app-management-child>
		</div>
		
  </div>

</div>