import { Component, OnInit } from '@angular/core';


import { UploadService } from './../shared/upload.service';
import { FileUpload } from './../shared/file-upload';

@Component({
  selector: 'upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent {

  selectedFiles: FileList
  currentFileUpload: FileUpload
  progress: {percentage: number} = {percentage: 0}
 
  constructor(private uploadService: UploadService) {}

  ngOnInit() {
  }


  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
 
  upload() {
    const file = this.selectedFiles.item(0)
    this.currentFileUpload = new FileUpload(file);

    // if (file.type.match('image/jpeg') && (file.size<1000)) {
      this.uploadService.pushFileToStorage(this.currentFileUpload, this.progress)
    // }
    // else {
      // alert('Invalid File Format or Size File too heavy. Image must be a jpeg file and its size must be < 1Ko. ');       
    // }
    
  }

}
