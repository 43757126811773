import { AuthService } from './../../core/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { FirebaseApp } from 'angularfire2';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Press } from './press'; 
import { Member } from './../../members/shared/member';

import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { LogClass } from './../../shared/logclass';
import { map } from 'rxjs/operators';

@Injectable()
export class PressService {

  press: Observable<any>;
  pressarticle: Observable<any>;
  artist: Observable<any>; 
  logClass: LogClass; 
  // firestore
  artistCollection: AngularFirestoreCollection<unknown>;
  pressCollection : AngularFirestoreCollection<unknown>;
  constructor(
              private afs: AngularFirestore,
              private router: Router,
              private auth: AuthService)
  { 
    // firestore collection
    this.artistCollection       = this.afs.collection('members');
    this.pressCollection        = this.afs.collection('press');
    this.logClass               = new LogClass(this.auth, this.afs);
  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////  G E T  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  // getArtistWithKey(key: string): Observable<Member> {
  //   const memberPath = `member/${key}`;
  //   this.artist = this.afs.doc<any>(memberPath)
  //     .snapshotChanges().map(action => {
  //       const $key = action.payload.id;
  //       const data = { $key, ...action.payload.data() };
  //       return data;
  //     });
  //   return this.artist
  // }



  getPressArticleWithKey(key: string): Observable<Press> {
    const pressPath = `press/${key}`;
    this.pressarticle = this.afs.doc<any>(pressPath)
      .get().pipe(
        map((action) => {
          const data = Object.assign(action.data(),
          { 
            $key: action.id,
            date  : action.data().date ? action.data().date.toDate() : null,                     
          });
          return data;
        })
      );
    return this.pressarticle
  }



  getPressList(query?) {
    return this.pressCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(),{ $key: snap.payload.doc.id }))
      }) 
    );
       
  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// C R E A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  createPress(newPressForm: NgForm,artistkey: string): void {
    console.log(newPressForm.value);
    const db  = this.afs.firestore;
    var batch = this.afs.firestore.batch();
    var newPressData          = {}
    newPressData['timestamp'] = Date.now();    
    newPressData['title']     = newPressForm.value.titlePress?newPressForm.value.titlePress:null;
    newPressData['content']   = newPressForm.value.contentPress?newPressForm.value.contentPress:null;
    newPressData['info']      = newPressForm.value.infoPress?newPressForm.value.infoPress:null;
    newPressData['date']      = newPressForm.value.datePress?newPressForm.value.datePress:null;
    newPressData['artistkey'] = artistkey;

    const newPressKey  = db.collection('press').doc().id;
    const pressRef     = this.pressCollection.doc(newPressKey).ref;
    batch.set(pressRef, newPressData);

    newPressData['presskey']   = newPressKey;
    const artistRef = this.artistCollection.doc(artistkey).collection('press').doc(newPressKey).ref;
    
    batch.set( artistRef, newPressData);

    batch.commit()
    .then(function() {
      console.log('Batch Commited')
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs,'createPress', newPressKey);
    console.log(newPressData);
     this.router.navigate(['/member$/member/'+artistkey]);    
  }



////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// U P D A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

  updatePress(press, field, value) {      
    var presskey = press.$key;
    var artistkey = press.artistkey ? press.artistkey 
                    : press.artistKey ? press.artistKey 
                    : null;

    var updateData = {};
    updateData[`${field}`] = value;
    if(press.artistKey) { updateData['artistkey'] = press.artistKey; }

    var batch = this.afs.firestore.batch();

    var pressRef = this.pressCollection.doc(presskey).ref;
    batch.update(pressRef, updateData);

    var pressInArtistRef = this.artistCollection.doc(artistkey).collection('press').doc(presskey).ref;
    batch.update(pressInArtistRef, updateData);

    batch.commit()
        .then(function() {console.log(updateData)})
        .catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs, 'updatePress', presskey );
  }

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// D E L E T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  // firestore
  deletePress(press): void {
    console.log(press);
    
    const artistkey          = press.artistkey ? press.artistkey :
                                                 press.artistKey ? press.artistKey : null;
    const presskey           = press.presskey;
    
    const memberRef = this.afs.collection('members').doc(artistkey).collection('press').doc(presskey).ref;
    const pressRef  = this.afs.collection('press').doc(presskey).ref;
    const db        = this.afs.firestore;
    const batch     = this.afs.firestore.batch();

    batch.delete(memberRef);
    batch.delete( pressRef);

    batch.commit()
    .then(function() {
      console.log('Data delete');
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs, 'deletePress', presskey);
  }




}
