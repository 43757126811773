import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthService } from '../../core/auth.service';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

// FIREBASE
// import { AngularFirestoreModule } from 'angularfire2/firestore';

import { NgxWigModule } from 'ngx-wig';

import { MemberService } from './member.service';
import { MemberListComponent } from './../member-list/member-list.component';
import { MemberFormComponent } from './../member-form/member-form.component';
import { MemberDetailComponent } from './../member-detail/member-detail.component';
import { MemberManagementComponent } from './../member-management/member-management.component';
import { MemberRoutingModule } from './member-routing.module';





@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}



@NgModule({
  declarations: [
    MemberListComponent,
    MemberFormComponent,
    MemberDetailComponent,
    MemberManagementComponent,
    SafePipe
  ],
  imports: [
    NgxWigModule,
    RouterModule,
    CommonModule,
    FormsModule,ReactiveFormsModule,
    AngularMaterialModule,
    AngularMaterialModule,
    MemberRoutingModule      
  ],
  providers: [
    MemberService,
    AuthService
  ]
})
export class MemberModule { }