import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

import { NewsService } from './../shared/news.service';
import { MemberService } from './../../members/shared/member.service';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit {

	artists: any;
	artist:any;

  constructor(
    private newsService: NewsService,
    private memberService: MemberService,
  	private location: Location
 	) { }

  ngOnInit() {
  	this.artists = this.memberService.getArtistNameList();
  }

  onSubmit(newsForm: NgForm) {
  	this.newsService.createNews(newsForm);
  }

  goBack(): void {
    this.location.back();
  }

}
