
<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="editpage = !editpage"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!editpage">create</mat-icon>
  <mat-icon *ngIf="editpage">close</mat-icon>
</button>

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<div *ngIf="page ; else loading" class="bounds">     
  <div class="content fx-row fx-wrap fx-gap-10 fx-col-xs fx-fill">
       <div class="sec2-xs fx-start-start fx-10 hide-desktop"
        fxShow.xs="true"        >
          <h1 class="mobile-title">KAJIMOTO OFFICES</h1>        
      </div>      
      <div class="sec1 fx-as-col fx-10 hide-mobile">
      		<h1 class="grey">
      			<span class="yellow">O</span>f<span class="yellow">f</span>i<span class="yellow">c</span>e<span class="yellow">s</span>
      		</h1>
      </div>

      <div class="sec2 fx-25 office fx-as-col style-xs"
        *ngFor="let o of offices"
        [ngStyle.xs]="{ 'margin':'10px','padding':'10px' }">
        <h2 class="lightblue h2-style-xs" [ngStyle.xs]="{ 'font-size':'2em','margin':'30px 0 10px 0' }">{{ o.country }}</h2>
        <h3 class="h3-style-xs" [ngStyle.xs]="{ 'margin':'10px 0', 'color':'#333333' }">{{ o.city }}</h3>
        <a href="{{ o.link }}" target="_blank">
          <img src="{{ o.url }}" class="img-map" matTooltip="See address on Google Map" matTooltipPosition="left">
        </a>
        <address *ngIf="!editpage">
          {{ o.address }}<br>
          <span *ngIf="o.address2">{{ o.address2 }} </span>
          {{ o.zipcity }}<br>
          <a class="phone-number" href="tel:+{{ o.phonelink }}"><span>Tel: {{ o.phone }}</span></a><br>
          <a *ngIf="o.fax" class="phone-number" href="tel:+{{ o.fax }}"><span>Fax: {{ o.fax }} </span></a>
        </address>
        <div *ngIf="editpage" class="fx-col" [ngStyle]="{'padding':'20px 0 0'}">
          <mat-form-field class="fx-100">
            <mat-label> Map Image Url </mat-label>
            <input matInput required
              [(ngModel)]="o.url"
              (ngModelChange)="sendPageField(page,o,'url',o.url)">
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label> Map Link </mat-label>
            <input matInput required
              [(ngModel)]="o.link"
              (ngModelChange)="sendPageField(page,o,'link',o.link)">
          </mat-form-field>          
          <mat-form-field class="fx-100">
            <mat-label> Address </mat-label>
            <input matInput required
              [(ngModel)]="o.address"
              (ngModelChange)="sendPageField(page,o,'address',o.address)">
          </mat-form-field> 
          <mat-form-field class="fx-100">
            <mat-label> Address Second Line </mat-label>
            <input matInput required
              [(ngModel)]="o.address2"
              (ngModelChange)="sendPageField(page,o,'address2',o.address2)">
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label> Zip Code & City </mat-label>
            <input matInput required
              [(ngModel)]="o.zipcity"
              (ngModelChange)="sendPageField(page,o,'zipcity',o.zipcity)">
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label> Phone </mat-label>
            <input matInput required
              [(ngModel)]="o.phone"
              (ngModelChange)="sendPageField(page,o,'phone',o.phone)">
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label> Fax </mat-label>
            <input matInput required
              [(ngModel)]="o.fax"
              (ngModelChange)="sendPageField(page,o,'fax',o.fax)">
          </mat-form-field>                                             
        </div>
      </div>


      <div class="sec5 fx-as-col fx-10 hide-mobile">
         <img src="{{ imglogourl }}" alt="{{ imglogoname.substring(0, imglogoname.indexOf('.')) }}">
      </div>
   </div>
</div>    

<br><br>

<footer-nav></footer-nav> 

