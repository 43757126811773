import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';
// FIREBASE

import { NgxWigModule } from 'ngx-wig';

import { NewsFormComponent } from './../news-form/news-form.component';
import { NewsListComponent } from './../news-list/news-list.component';
import { NewsDetailComponent } from './../news-detail/news-detail.component';

import { DropZoneDirective } from './drop-zone.directive';

// import { SingleNews } from './news';
import { NewsService } from './news.service';
import { NewsRoutingModule } from './news-routing.module';

@NgModule({
  imports: [
    NgxWigModule,
    CommonModule,
  RouterModule,
    FormsModule, ReactiveFormsModule,
    AngularMaterialModule,
    AngularMaterialModule,
    NewsRoutingModule
  ],
  declarations: [
  NewsFormComponent,
    NewsDetailComponent,
  NewsListComponent,
    DropZoneDirective
  ],
  providers: [
    NewsService,
    AuthService
  ]
})
export class NewsModule { }
