// import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, Inject } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { Observable, combineLatest } from 'rxjs';
import { MemberService } from './../../members/shared/member.service';
import { PublicService } from '../shared/public.service';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';



import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

// import { Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';


export interface Members {
  instrument: string;
}

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss'],
  animations: [
    trigger('artists', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('100ms', [
          animate('.1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('.1s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class ArtistsComponent implements OnInit {

  notesCollection: AngularFirestoreCollection<any>;
  noteDocument:   AngularFirestoreDocument<any>;
  instruments: any;
  instrumentSelected: string;
  artist$: Observable<Members[]>;
  instruFilter$: BehaviorSubject<string|null>;
  // INSTRUMENTS = [
  //                 {key:1, title:'Piano'},
  //                 {key:13,title:'Composer'},
  //                 {key:3,title:'Conductor'},
  //                 {key:2,title:'Violin'},
  //                 {key:5,title:'Viola'},
  //                 {key:6,title:'Cello'},
  //                 {key:0,title:'Harp'}
  //                ];
 CATEGORIES = [
    {key: 1, title: 'conductor'},
    {key: 2, title: 'composer'},
    {key: 3, title: 'piano'},
    {key: 4, title: 'instrumentalist'},
    // {key: 5, title: 'singer'}
  ];
  notes: any;
  categorySelected: any;

  constructor(
    private titleService: Title,
    private afs: AngularFirestore,
    private meta: Meta,
    private _sanitizer: DomSanitizer) {

    this.notesCollection = this.afs.collection('members');


    this.instruFilter$ = new BehaviorSubject<string|null>(null);
    this.artist$ = combineLatest(
      this.instruFilter$
    ).pipe(switchMap(([category]) => 
      afs.collection<Members>('members', ref => {
        let query: firebase.default.firestore.Query = ref;
        if (category) {
          query =  query.where('instrumentcat', 'array-contains', category).where('isonline', '==', true);
        } else {
          query = query.where('role', '==', 'Artist').where('isonline', '==', true).orderBy('lastname');
        }
        return query;
      }).get()
      .pipe(map(arr => {
          return arr.docs.map(snap => Object.assign(
            snap.data(),
            { $key: snap.id }) )
      })
    )));
  }

  ngOnInit() {
    this.notes = this.getData();
    this.titleService.setTitle('Kajimoto Music Paris | All Artists');
    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/artists` },
      { name: 'og:title', content: 'Kajimoto Music Paris | All Artists'},
      { name: 'og:description', content: 'Kajimoto Music Paris presents all its artists'}, 
      { name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e`},
      { name : 'keywords',  content: `All artists`},
      { name : 'url', content: `https://kajimotomusicparis.com/artists`}
    ]);
  }
  // this.image = this.sanitization.bypassSecurityTrustStyle(`url(${element.image})`);

  filterByCategory(CATEGORIES: any|null) {
    this.instruFilter$.next(CATEGORIES.title);
    console.log(CATEGORIES.title);
    this.categorySelected = CATEGORIES.title;
  }

  getBackground(image) {
    // tslint:disable-next-line:max-line-length
    return this._sanitizer.bypassSecurityTrustStyle(`url('${image}')`);
  }

  gotoExternalHref(url) {
    window.open(url, '_blank');
  }

  getData(): Observable<any[]> {
    // ['added', 'modified', 'removed']
    return this.notesCollection.get().pipe(
      map((actions) => {
        return actions.docs.map((a) => {
          const data = a.data();
          return { id: a.id, ...data };
        });
      })
    );
  }

}
