<button mat-fab color="warn" class="button-lang button-lang-xs"
	[ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInEn" 
	(click)="
		txtInFr=true;txtInEn=false;
		titleInFr=true;titleInEn=false
		">
  FR
</button>

<button mat-fab color="warn" class="button-lang button-lang-xs"
	[ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInFr" 
	(click)="
		txtInFr=false;txtInEn=true;
		titleInFr=false;titleInEn=true
		">
  EN
</button>


<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="addTouringForm = !addTouringForm"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!addTouringForm">edit</mat-icon>
  <mat-icon *ngIf="addTouringForm">close</mat-icon>
</button>


<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>


<div class="bounds">
  <div class="content fx-row fx-start-start fx-col-xs" 
      >      
      <div class="hide-mobile sec1">
    		<h1 class="grey" *ngIf="titleInEn">
    			<span class="red">t</span>o<span class="red">u</span>r<span class="red">s</span> a<span class="red">n</span>d <span class="red">p</span>r<span class="red">o</span>j<span class="red">e</span>c<span class="red">t</span>s
    		</h1>
    		<h1 class="grey" *ngIf="titleInFr">
    			<span class="red">t</span>o<span class="red">u</span>r<span class="red">n</span>é<span class="red">e</span>s<span class="red"> e</span>t<span class="red"> p</span>r<span class="red">o</span>j<span class="red">e</span>t<span class="red">s</span>
    		</h1>    		
      </div>
			<div class="fx-10 fx-center-start sec2-xs hide-desktop">
					<h1 class="mobile-title">tours and projects</h1>				
      		</div>
   
      <div style="padding-left: 10px" class="progyear fx-70 sec2 fx-row fx-wrap">
      	<div class="fx-90 fx-col" *ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addTouringForm">
		      <form class="fx-col addform" #publicTourForm="ngForm" (ngSubmit)="onSubmit(publicTourForm)">		       
		        
		        <div class="fx-row fx-wrap fx-gap-5px">          

		        	<h4 class="fx-60">New Tour Info</h4>
							
							<mat-form-field class="fx-30">
								<mat-label> Select date </mat-label>
							  <input matInput readonly placeholder="Date" required
							  	[matDatepicker]="datepicker"
							  	ngModel #date="ngModel" name="date">
							  <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
							  <mat-datepicker #datepicker></mat-datepicker>
							</mat-form-field>

		          <mat-form-field class="fx-30">
					<mat-label> Title (english) </mat-label>
		            <input matInput required
		              ngModel #titleen="ngModel"
		              name="titleen">
		          </mat-form-field>

		          <mat-form-field class="fx-30">
					<mat-label> Countries (english) </mat-label>
		            <input matInput required
		              ngModel #countriesen="ngModel"
		              name="countriesen">
		          </mat-form-field>

		          <mat-form-field class="fx-30">
					<mat-label> Cities (english) </mat-label>
		            <input matInput 
		              ngModel #citiesen="ngModel"
		              name="citiesen">
		          </mat-form-field>		          

		          <mat-form-field class="fx-100">
					<mat-label> Details (artists names with instrument)... </mat-label>
								<textarea matInput rows="2" ngModel	#detailsen="ngModel" name="detailsen">
								</textarea>		              
		          </mat-form-field>  

		          <hr class="fx-100">

		         	<mat-form-field class="fx-30">
						<mat-label> Titre (français) </mat-label>
		            <input matInput required
		              ngModel #titlefr="ngModel"
		              name="titlefr">
		          </mat-form-field>		          

		          <mat-form-field class="fx-30">
					<mat-label> Pays (français) </mat-label>
		            <input matInput required
		              ngModel #countriesfr="ngModel"
		              name="countriesfr">
		          </mat-form-field>

		          <mat-form-field class="fx-30">
					<mat-label> Villes (français) </mat-label>
		            <input matInput 
		              ngModel #citiesfr="ngModel"
		              name="citiesfr">
		          </mat-form-field>	

		          <mat-form-field class="fx-100">
					<mat-label> Details (nom et instrument des artistes)... </mat-label>
								<textarea matInput rows="3" ngModel	#detailsfr="ngModel" name="detailsfr">
								</textarea>		              
		          </mat-form-field>  		                             

		          <button mat-fab type="submit" color="warn"
		            [disabled]="!publicTourForm.form.valid">
		            <mat-icon>save</mat-icon>
		          </button>           
		        </div>

		      </form>                             
      	</div>

				<div class="fx-col fx-100" [ngStyle.xs]="{'padding-left':'10px'}"
					*ngIf="publicToursDetails | async; let ptd; else loading">
						<div *ngFor="let event of ptd; let i=index;" [ngClass]="{ 'line-break':isNewYear(ptd[i-1],ptd[i]) }">
							<div *ngIf="i>0; else firstyear">
								<h2 *ngIf="ptd[i-1].date.getYear()!=ptd[i].date.getYear()"
									ngClass="lightblue">
									<!-- [ngClass]="{'lightblue':(event.date | date:'yyyy') % 2 == 1}" -->
										{{ event.date | date:'yyyy' }}
								</h2>
							</div>
							<ng-template #firstyear>
								<h2 class="lightblue">{{ event.date | date:'yyyy' }}</h2>
							</ng-template>							
							<div *ngIf="i>0; else firstmonth">
								<h3 *ngIf="ptd[i-1].date.getMonth()!=ptd[i].date.getMonth()" class="blue">{{ event.date | date:'LLLL' }} {{ event.date | date:'yyyy' }}</h3>
							</div>
							<ng-template #firstmonth>
								<h3 class="blue">{{ event.date | date:'LLLL' }}</h3>
							</ng-template>							
							<p>
								<span>
									<strong>{{ lg(event.titleen,event.titlefr) }}</strong>
									<span *ngIf="event.detailsfr||event.detailsen" class="angular-with-newlines"><br>{{ lg(event.detailsen,event.detailsfr) }}</span><br>
								</span>
			    			<span class="country">{{ lg(event.countriesen,event.countriesfr) }}</span>
			    			<span class="city">{{ event.citiesfr }}</span>								
							</p>
							<button mat-button  type="button" color="warn"
									*ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addTouringForm"
									(click)="deletePublicTourDetail(event)">
									<mat-icon>delete</mat-icon> Delete Tour
					  	</button>							
						</div>
				</div>

	      <div *ngIf="progyear&&show_2018" class="progyear sec3 fx-col fx-100" 
	      	[ngStyle.xs]="{'padding-left':'10px'}" >
	      		<h2 class="lightgreen" [ngStyle.xs]="{'font-size':'3em','padding-top':'10px'}">2018</h2>
	      		<h3 class="green">{{ lg("January 2018","Janvier 2018") }}</h3>
						<p>
							<strong>Wiener Ring-Ensemble</strong><br>
							<span class="country">{{ lg("Japan – Taiwan – China","Japon, Taiwan, Chine") }}</span>
							<span class="city">{{ lg("Nagoya, Osaka, Tokyo, Matsuyama, Fukuoka, Saitama, Taïpei, Shanghai","Nagoya, Osaka, Tokyo, Matsuyama, Fukuoka, Saitama, Taïpei, Shanghai") }}</span><br>
						</p>
	      		<h3 class="green">{{ lg("March 2018","Mars 2018") }}</h3>
	      		<p>
							<strong>LSO Percussion Sextet</strong><br>
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Osaka</span><br>
							
							<strong>{{ lg("Orchestre philharmonique de New York","New York Philharmonic Orchestra") }}</strong><br>
							Yuja Wang, piano <br>
							Ryu Goto, {{ lg("violin","violon") }}<br>
							Jaap van Zweden, {{ lg("conductor","direction") }}
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Kyoto, Tokyo, Nagoya</span><br>
							
							<strong>Orchestre National du Capitole de Toulouse</strong><br>
							Emmanuel Pahud, flute <br> 
							Akiko Suwanai, {{ lg("violin","violon") }} <br>
							Tugan Sokhiev, {{ lg("conductor","direction") }} 
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Fukui, Hyogo</span><br>
						</p>
	      		<h3 class="green">{{ lg("May","Mai") }} 2018</h3>
						<p>
							<strong>{{ lg("La Folle Journée Japan","La Folle Journée Japon") }}</strong><br>
							{{ lg("14th Edition","14ème édition") }}
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Biwako, Niigata</span><br>
						</p>
	      		<h3 class="green">{{ lg("June","Juin") }}  2018</h3>
	      		<p>
							<strong>{{ lg("Bamberger Symphoniker","Orchestre symphonique de Bamberg") }}</strong><br>
							Yulianna Avdeeva, piano <br>
							Gerhild Romberger, contralto <br>
							Jakub Hrusa, {{ lg("conductor","direction") }}
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Yokohama</span><br>
						</p>
	      		<h3 class="green">{{ lg("September","Septembre") }}  2018</h3>
						<p>
							<strong>Japonismes</strong><br>
							Festival Japon, Philharmonie de Paris <br>
							<span class="country">France</span>
							<span class="city">Paris</span><br>

							<strong>{{ lg("NHK Symphony Orchestra","Orchestre symphonique de la NHK") }}</strong><br>
							<span class="country">Vietnam</span><br>

							<strong>London Symphony Orchestra</strong><br>
							Krystian Zimerman, piano <br>
							Janine Jansen, {{ lg("violin","violon") }} <br>
							Simon Rattle, {{ lg("conductor","direction") }} <br>
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Osaka, Tokyo Yokohama</span><br>

							<strong>Maurizio Pollini,</strong> piano <br>Recital Tour
							<span class="country">{{ lg("China, Japan","Chine, Japon") }}</span>
							<span class="city">Beijing, Tokyo</span><br>

							<strong>Hamburg Ballett</strong><br>
							Garrett Keast, {{ lg("Conductor","Chef d'Orchestre") }} <br>
							<span class="country">{{ lg("Germany","Allemagne") }}</span>
							<span class="city">Baden-Baden, Hamburg</span>
						</p>
	      		<h3 class="green">{{ lg("October 2018","Octobre 2018") }}</h3>
	      		<p>
							<strong>Japonismes</strong><br>
							{{ lg("Japanese festival","Festival Japon") }} <br>
							<span class="country">France</span>
							<span class="city">Paris</span><br><br>

							<strong>{{ lg("Libera, boys chor","Libera, choeur de garçons") }}</strong><br>
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Osaka</span><br><br>

							<strong>NDR Elbphilharmonie Orchestra</strong><br>
							Hélène Grimaud, piano <br>
							Alan Gilbert, {{ lg("conductor","direction") }} <br>
							<span class="country">{{ lg("China, Japan","Chine, Japon") }}</span>
							<span class="city">Shanghai, Kyoto, Tokyo, Nagoya</span><br>

							<strong>Lukas Geniušas</strong><br>
							{{ lg("Recitals","Récitals") }} <br>
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Tokyo, Takasaki, Ueda</span>
						</p>
	      		<h3 class="green">{{ lg("November 2018","Novembre 2018") }}</h3>
	      		<p>
							<strong>Ivo Pogorelich, </strong>piano<br>
							{{ lg("Recital Tour","Récitals") }} <br>
							{{ lg("China, Taiwan, Japan","Chine, Taiwan, Japon") }} <br>
							{{ lg("Xi’an, Beijing, Taipei, Kagoshima, Osaka","Xi’an, Pékin, Taipei, Kagoshima, Osaka") }} <br><br>

							<strong>NDR Elbphilharmonie Orchester</strong><br>
							Hélène Grimaud, piano <br>
							Alan Gilbert, {{ lg("conductor","direction") }} <br>
							<span class="country">{{ lg("China, Japan","Chine, Japon") }}</span>
							<span class="city">Shanghai, Kyoto, Tokyo, Kamakura, Nagoya</span><br><br>

							<strong>Haochen Zhang,</strong> piano <br>
							{{ lg("Recital Tour","Récitals") }} <br>
							<span class="country">{{ lg("Germany, France, Switzerland","Allemagne, France, Suisse") }}</span>
							<span class="city">{{ lg("Berlin, Paris, Bourges, Luzern","Berlin, Paris, Bourges, Lucerne") }}, </span><br><br>

							<strong>{{ lg("Munich Philharmonic Orchestra", "Orchestre philharmonique de Munich") }}</strong><br>
							Yuja Wang, piano <br>
							Yekwon Sunwoo, piano {{ lg("(only Korea)","(Corée seule)") }}  <br>
							Valery Gergiev, {{ lg("conductor","direction") }} <br>
							<span class="country">{{ lg("Korea, China, Japan","Corée, Chine, Japon") }}</span>
							<span class="city">{{ lg("Seoul, Wuhan, Hangzhou, Fukuoka, Nagoya, Osaka, Tokyo","Séoul, Wuhan, Hangzhou, Fukuoka, Nagoya, Osaka, Tokyo") }}</span>
						</p>
		  			<h3 class="green">{{ lg("December 2018","Décembre 2018") }}</h3>
		  			<p>
							<strong>Ivo Pogorelich,</strong>  piano <br>
							{{ lg("recitals","récitals") }} <br>
							<span class="country">{{ lg("China, Taiwan, Japan","Chine, Taiwan, Japon") }}</span><br><br>

							<strong>Haochen Zhang,</strong> piano <br>
							{{ lg("Recital Tour","Récitals") }} <br>
							<span class="country">{{ lg("Belgium","Belgique") }}</span>
							<span class="city">{{ lg("Brussels","Bruxelles") }}</span><br>

							<strong>{{ lg("Munich Philharmonic Orchestra", "Orchestre philharmonique de Munich") }}</strong><br>
							Yuja Wang, piano <br>
							Yekwon Sunwoo, piano {{ lg("(only Korea)","(Corée seule)") }}  <br>
							Valery Gergiev, {{ lg("conductor","direction") }} <br>
							<span class="country">{{ lg("Korea, China, Japan","Corée, Chine, Japon") }}</span>
							<span class="city">{{ lg("Seoul, Wuhan, Hangzhou, Fukuoka, Nagoya, Osaka, Tokyo","Séoul, Wuhan, Hangzhou, Fukuoka, Nagoya, Osaka, Tokyo") }}</span><br>

							<strong>Orchestre de Paris</strong><br>
							Isabelle Faust, {{ lg("violin","violon") }} <br>
							Daniel Harding, {{ lg("conductor","direction") }}
							<span class="country">{{ lg("Japan","Japon") }}</span>
							<span class="city">Sapporo,Tottori,Tokyo, Kyoto, Osaka</span>
						</p>
	      </div>


	      <div *ngIf="progyear">
	    		<h2 class="lightblue">2017</h2>
	    		<h3 class="blue">September 2017</h3>
	    		<p>
	    			<strong>Daniil Trifonov</strong>, piano <br>Recitals
	    			<span class="country">China</span>
	    			<span class="city">Beijing, Shenzhen, Hong Kong, Shanghai</span>
	    		</p>

	    		<h3 class="blue">September-October 2017</h3>
	    		<p>
	    			<strong>Anna Netrebko</strong>, soprano <br><strong>Yusif Eyvazov</strong>, tenor <br><strong>Elchin Azizov</strong>, baritone <br>Tokyo Philharmonic <br>Orchestra KansaiPhilharmonic Orchestra <br>Mikhal Tatarnikov, conductor 
	    			<span class="country">Japan</span>
	    			<span class="city">Tokyo, Osaka</span>
	    		</p>

	    		<h3 class="blue">October 2017</h3>
					<p>
						<strong>Lucerne Festival Orchestra</strong><br>Riccardo Chailly, conductor
						<span class="country">Japan – Korea – China</span>
						<span class="city">Tokyo, Kawasaki, Seoul, Beijing</span><br>
						<strong>Kathleen Battle</strong>, soprano <br>Recitals
						<span class="country">Japan</span>
						<span class="city">Osaka, Tokyo</span><br>
						<strong>Ivo Pogorelich</strong>, piano <br>
						Guangzhou Symphonic Orchestra
						<span class="country">Japan</span>
						<span class="city">Tokyo</span>
					</p>
	    		<h3 class="blue">November 2017</h3>
					<p>
						<strong>Ivo Pogorelich</strong>, piano <br>Guangzhou Symphonic Orchestra
						<span class="country">China</span>
						<span class="city">Guangzhou</span><br>
						<strong>Wiener Singverein</strong><br>Shanghai Symphonic Orchestra <br>Andris Poga, conductor <br>Leipzig Gewandhaus Orchestra <br>Herbert Blomstedt, conductor 
						<span class="country">China – Japan</span>
						<span class="city">Shanghai, Tokyo</span><br>
						<strong>Leipzig Gewandhaus Orchestra</strong>Leonidas Kavakos, violin <br>Herbert Blomstedt, conductor <br>Wiener Singeverein
						<span class="country">Japan</span>
						<span class="city">Sapporo, Yokohama, Tokyo</span><br>
						<strong>Munich Philharmonic Orchestra</strong><br>Denis Matsuev, piano <br>Valery Gergiev, conductor 
						<span class="country">China</span>
						<span class="city">Nanjing, Xi’an, Tianjin, Beijing</span><br>
						<strong>Royal Concertgebouw Orchestra</strong><br>Frank Peter Zimmermann, violin <br>Julia Kleiter, soprano <br>Tatjana Vassiljeva, cello <br>Daniele Gatti, conductor <br>
						<span class="country">Japan</span>
						<span class="city">Kyoto, Kawazaki, Tokyo, Nagasaki, Osaka</span>
					</p>
	      </div>
		</div>
				
      <div class="hide-mobile sec4 fx-10">
        	<img src="{{ imglogourl }}" alt="{{ imglogoname.substring(0, imglogoname.indexOf('.')) }}">
      </div>
   </div>
</div>

<br><br>

<footer-nav></footer-nav>
