<!-- <pre>{{ tour | async | json }}</pre> -->
<!-- {{ tourkey }} -->

<ng-template #loading>        
  <br><br><br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<mat-card appearance="outlined" *ngIf="tour | async; let tour; else loading" class="noshadow">
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>today</mat-icon>
		</div>			
		<mat-card-title>
			<strong>{{ tour.title }}</strong> 
		</mat-card-title>
		<mat-card-subtitle>
			<i> from {{ tour.startdate | date }} to {{ tour.enddate | date }}</i><br>
			Add an event to this tour
		</mat-card-subtitle>		
	</mat-card-header>

	<mat-card-content>
		<form #tourEventForm="ngForm" (ngSubmit)="onSubmit(tourEventForm)">

			<mat-radio-group color="warn" required name="gender" ngModel required #gender="ngModel" >
				<mat-radio-button color="warn" value="Ballet">Ballet</mat-radio-button>
				<mat-radio-button color="warn" value="Chamber">Chamber Music</mat-radio-button>
				<mat-radio-button color="warn" value="Concerto">Concerto</mat-radio-button>
				<mat-radio-button color="warn" value="Opera Staged">Opera Staged</mat-radio-button>
				<mat-radio-button color="warn" value="Opera Concert">Opera Concert</mat-radio-button>
				<mat-radio-button color="warn" value="Recital">Recital</mat-radio-button>
				<mat-radio-button color="warn" value="Symphonic">Symphonic</mat-radio-button>
				<mat-radio-button color="warn" value="Vocals">Vocals</mat-radio-button>
			</mat-radio-group>
			<br>
			<br>
			<mat-form-field class="full-width">
				<mat-label> New Event Title </mat-label>
				<input matInput required
					ngModel #newEventTitle="ngModel"
					name="newEventTitle">
			</mat-form-field> 
			
			<input type="text" hidden name="tourkey" #tourkey="ngModel" ngModel="{{ tour.$key }}">
			<input type="text" hidden name="tourtitle" #tourtitle="ngModel" ngModel="{{ tour.title }}">
			<input type="text" hidden name="countevents" #tourtitle="ngModel" ngModel="{{ tour.countevents }}">

			<h3>Comments</h3>
			<mat-form-field class="full-width">
				<mat-label> Write comment or notes about this event : Date Options, Venue, ... </mat-label>
				<textarea matInput class="full-width"
									rows="10" 
									name="newEventComment"
									ngModel #newEventComment="ngModel"></textarea>
			</mat-form-field>
			<h3>Add Kajimoto Artist</h3>
						<div>
							<mat-form-field class="full-width">
								<mat-label> Select Artist </mat-label>
							  <mat-select class="full-width"  name="artist" ngModel required #artist="ngModel">
								<mat-option *ngFor="let newartist of allartists | async" 
									[value]="newartist">
									{{ newartist.firstname }} {{ newartist.lastname }}
								</mat-option>
							  </mat-select>						  
							</mat-form-field>								
						</div>							
			<br>		
			<button mat-fab (click)="goBack()" type="button" color="black">
				<mat-icon>keyboard_arrow_left</mat-icon>
			</button>
			<button mat-fab type="submit" color="warn"
				[disabled]="!tourEventForm.form.valid">
				<mat-icon>save</mat-icon>
			</button>			
		</form>		
	</mat-card-content>
</mat-card>
