<!-- <pre>{{ artist | async | json  }}</pre> -->


<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">


<div [ngStyle]="{'padding':'40px 10px'}">

	<div *ngIf="artist | async as artist">
		<h1>{{ artist.firstname }} {{ artist.lastname }}</h1>
		<h2>{{ pagesubtitle }}</h2>
	</div>

	<div>

		<!------------------------Champs pour l'ajout d'un disque------------------->
		<div *ngIf="fileType=='discogs'">
			<mat-form-field class="full-width">
				<mat-label> Disc Link </mat-label>
				<input matInput
					[(ngModel)]="linkDisc">
			</mat-form-field>
		</div>

		<!----------Champs pour l'ajout d'une photo au carousel--------------------->
		<div *ngIf="fileType=='carousel'">
			<mat-form-field class="full-width">
				<mat-label> Photo Credit </mat-label>
				<input matInput
					[(ngModel)]="imgCredit">
			</mat-form-field>
		</div>

	</div>

	<div class="dropzone" 
		 *ngIf="showDropzone&&!errors"
     dropZone
     (hovered)="toggleHover($event)"
     (dropped)="uploadFile($event)"
     [class.hovering]="isHovering">
	     <h4>Drag and Drop the <strong>{{ pictitle }}</strong> file</h4>
	     <h5>
	     	Format : <strong>.jpeg</strong><br>
	     	Dimensions : <strong>{{ dimensioninfo }}</strong><br>
	     	Size: < <strong>{{ (maxsize/1000) }} Ko</strong>
	     </h5>
	     <div class="file">
	     	<label class="file-label">
			  	<input class="file-input" type="file" (change)="uploadFile($event)">
	      </label>
	     </div>
	</div>

	<div *ngIf="uploadPercent | async; let uploadPercent">
		<div *ngIf="!errors&&uploadPercent!=100">
			<div>
				<div class="centpourcent">
					<progress class="progress is-info centpourcent" [value]="uploadPercent" max="100">{{ uploadPercent }}</progress>
				</div>		
		</div>
	</div>

	<div *ngIf="downloadURL | async as url">
	  <img [src]="url" class="max-width">
	</div> 

	</div>

	<div *ngIf="errors" class="errors">
		<h4>{{ errorMessage }}<br><br></h4>
	</div>

	<br>

	<div>

		<button *ngIf="showRefreshBut" mat-fab (click)="tryAgain()" type="button" mat-raised-button color="warn">
			<mat-icon>refresh</mat-icon>
		</button>

		<div *ngIf="historykey">
			<button mat-fab type="button" color="warn" class="ml-15"
				*ngIf="downloadURL | async as url"
				(click)="saveHistory(historykey,url)">
				<mat-icon>save</mat-icon>
			</button>
		</div>

		<div *ngIf="artist | async as artist">

			<button mat-fab 
				*ngIf="artist"
				[routerLink]="['./../../../member', artist.$key]" type="button" color="black" class="ml-15">
				<mat-icon>clear</mat-icon>
			</button>

			<button mat-fab type="button" color="warn" class="ml-15"
				*ngIf="downloadURL | async as url"
				[disabled]="!enableSaveButton" 
				(click)="savePictureFile(artist,fileType,url,imgCredit,linkDisc)">
				<mat-icon>save</mat-icon>
			</button>

		</div>

	</div>	


</div>




