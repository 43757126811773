import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

// import 'hammerjs';
// import { NgxCarouselModule } from 'ngx-carousel';
import { InViewportModule } from 'ng-in-viewport';
// import { NgStickyModule, NgStickyDirective } from 'ng-sticky';

// FIREBASE
// import { AngularFireModule } from 'angularfire2';
// import { AngularFireAuthModule } from 'angularfire2/auth';

// DEV MODULES
import { PublicService } from './public.service';
import { AboutusComponent } from './../aboutus/aboutus.component';
import { HistoryComponent } from './../history/history.component';
import { ContactComponent } from './../contact/contact.component';
import { HomepageComponent } from './../homepage/homepage.component';
import { TouringComponent } from './../touring/touring.component';
import { OfficesComponent } from './../offices/offices.component';
import { ArtistsComponent } from './../artists/artists.component';
import { ArtistComponent } from './../artist/artist.component';
import { ArtistsChinaComponent } from './../artists-china/artists-china.component';
import { ArtistBiographyComponent } from './../artist-biography/artist-biography.component';
import { ArtistNewsComponent } from './../artist-news/artist-news.component';
import { EventComponent } from './../event/event.component';
import { EventsComponent, DialogFilterEvents, DialogTours, DialogDuplicateEvents } from './../events/events.component';
import { ArtistEventComponent } from './../artist-event/artist-event.component';
import { ArtistPressComponent } from './../artist-press/artist-press.component';
import { EventsArtistPrintComponent } from './../events-artist-print/events-artist-print.component';
import { ProgramEventComponent } from './../program-event/program-event.component';
import { FooterNavComponent, DialogFooter } from '../footer-nav/footer-nav.component';
import { FilterPipe } from './../artists/filter';
import { MemberService } from './member.service';
import { EventService } from './event.service';
import { PublicRoutingModule } from './public-routing.module';


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        AngularMaterialModule,
        AngularMaterialModule,
        InViewportModule,
        PublicRoutingModule,
    ],
    declarations: [
        FooterNavComponent,
        ArtistsComponent,
        ArtistComponent,
        ArtistsChinaComponent,
        ArtistEventComponent,
        ArtistPressComponent,
        ArtistNewsComponent,
        ProgramEventComponent,
        AboutusComponent,
        HistoryComponent,
        ContactComponent,
        HomepageComponent,
        TouringComponent,
        OfficesComponent,
        ArtistBiographyComponent,
        EventComponent,
        EventsComponent,
        EventsArtistPrintComponent,
        DialogFilterEvents,
        DialogFooter, DialogTours,
        DialogDuplicateEvents,
        SafePipe,
        FilterPipe
    ],
    providers: [
        PublicService,
        MemberService,
        EventService,
    ]
})
export class PublicModule { }
