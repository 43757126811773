import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { EventService } from './../shared/event.service';
import { MemberService } from './../../members/shared/member.service';

@Component({
  selector: 'routing-details',
  templateUrl: './routing-details.component.html',
  styleUrls: ['./routing-details.component.scss']
})
export class RoutingDetailsComponent implements OnInit {

	  @Input() tourkey      : string;
    @Input() artistkey    : string;
    @Input() artistemail  : string;
    @Input() artistphone  : string;
	  @Input() startdate    : string;
	  @Input() enddate      : string;

	artist                : any;
    routingDetails        : {};
	showAddDetailsForm	  : boolean=false;
	showAddDetailsButton  : boolean=true;
    launchTheDropArtistDataFunctionOnce: boolean = true;

    minutes= []; hours= [];


  constructor(
      private eventService: EventService,
      private memberService: MemberService,
      ) { }

  ngOnInit() {
      this.routingDetails = this.eventService.getRoutingDetailsWithTourKey(this.tourkey);

      this.minuteArray();
      this.hoursArray();

      if(!this.artistemail) {
          console.log('Put Artist Email in Tour');
          this.artist = this.memberService.getMemberWithKey(this.artistkey);
      }
  }

 minuteArray(){
    for(let i = 0 ; i < 60 ; i++) {
      this.minutes.push(i);
    }
  }

  hoursArray(){
    for(let i = 0 ; i < 24 ; i++) {
      this.hours.push(i);
    }
  }

  onSubmit(newTourDetailForm: NgForm) {
    this.showAddDetailsForm=false;
	this.showAddDetailsButton=true;  	
    this.eventService.addDetailsRouting(this.tourkey,this.artistkey,newTourDetailForm);
  }  

  removeRoutingDetails(details) {
    this.eventService.removeRoutingDetails(details);
  }  

  dropArtistEmailInTour(email) {
    if(this.launchTheDropArtistDataFunctionOnce&&email) {
        this.eventService.dropEmailInTour(this.tourkey, email);
    }    
    this.launchTheDropArtistDataFunctionOnce = false;
  }

}