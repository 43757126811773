import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../shared/event.service';


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

	@Input() name: string;
	@Input() eventkey: string;

	getevent:any;program:any;
	seeProgramDetail:boolean=false;

  constructor(
  	private eventService: EventService
  	) { }

  ngOnInit() {
		this.getevent = this.eventService.getEventWithKey(this.eventkey);

		this.program  = this.eventService.getProgramWithEventKey(this.eventkey);

		// this.program = this.eventService.getEventWithKey(this.eventkey);


  }

}
