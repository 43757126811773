<h1>Filter Events</h1>


<div class="fx-row fx-wrap fx-col-xs fx-start-start fx-fill"
	 *ngIf="!isgenre">
	<div *ngFor="let artist of allartists | async" >
		<button mat-button [mat-dialog-close]="artist">{{ artist.firstname }} {{ artist.lastname }}</button>					
	</div>
</div>


<hr>

<!-- <mat-chip-listbox>
	<mat-chip>Published</mat-chip>
	<mat-chip>Sent to Routing</mat-chip>
	<mat-chip>Online</mat-chip>
	<mat-chip>Dates</mat-chip>	
	<mat-chip>Tours</mat-chip>	
	<mat-chip (click) = "getGenre()">Genre</mat-chip>	
</mat-chip-listbox> -->