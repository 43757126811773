<div *ngIf="events | async; let events" class="content fx-row fx-wrap" >
	
	<div class="fx-100">
		<h4 [ngStyle.xs]="{'font-size':'1.1em'}">
			Events
		</h4>
	</div>

 	<div [ngStyle]="{'padding-bottom':'30px'}" 
 		class="fx-33 fx-100-xs fx-row fx-wrap fx-start-start"  
 		*ngFor="let event of events">

 		<events-routing-events-event [eventkey]="event.$key"></events-routing-events-event>

		<!-- 		
		<div
			class="icon-event fx-100 fx-row fx-start-start fx-gap-5"
			[ngStyle]="{'padding':'10px 0 0'}">
				<mat-icon *ngIf="event.venueaddress" (click)="gotoGoogleMapAddress(event.venueaddress)">location_on</mat-icon>
		</div> 
		-->

	</div>

</div>


