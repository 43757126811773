import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';
import { AuthService } from '../../core/auth.service';
import { PublicService } from './../shared/public.service';
import { NgForm } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('contacts', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('100ms', [
          animate('.1s ease-in', keyframes([
            style({opacity: 0, transform: 'translateX(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateX(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('.1s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ])
  ]
})
export class ContactComponent implements OnInit {


	offices = [ {key: 1, title: 'Tokyo'}, {key: 2, title: 'Paris'}, {key: 3, title: 'Beijing'}];

	contactsParis: any;
	contactsTokyo: any;
	contactsBeijing: any;

	imglogokey: string; imglogoname: string; imglogourl: string;
	imglogobr: string;

	imgmapchina: string;
	imgmapfrance: string;
	imgmapjapan: string;

	txtInEn = true;
	txtInFr = false;
	titleInFr = false;
	titleInEn = true;

	addContactForm = false;
	page: any;
	contactOffices: any;

	contacts: any;
	contactsShown: any;
	officeSelected: any;


	constructor(public  auth: AuthService,
				private ps: PublicService,
				private titleService: Title, private meta: Meta) {

		this.page = this.ps.getPageVariables('contacts');
		this.contactOffices = this.offices;

		this.contactsParis = this.ps.getContactsOffice('Paris');
		this.contactsTokyo = this.ps.getContactsOffice('Tokyo');
		this.contactsBeijing = this.ps.getContactsOffice('Beijing');

		this.contactsShown = this.contactsTokyo;

  }


  ngOnInit() {

    this.titleService.setTitle('Kajimoto Music Paris | Contacts');

		this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `https://kajimotomusicparis.com/contact` },
      { name: 'og:title', content: 'Kajimoto Music Paris | Contacts'},
      { name: 'og:description', content: 'Kajimoto Music presents its team'}, 
			{ name: 'og:image', content: `https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830`},
      { name : 'keywords',  content: `contacts`},
      { name : 'url', content: `https://kajimotomusicparis.com/contact`}
    ]);
		
    this.officeSelected = 'JP';
		this.imglogobr = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2FKajimoto_logo_red-black.jpg?alt=media&token=909a189c-8eb7-463f-bf45-2c2068ecb4e6';
		this.imglogokey = '-L3-ARQZ07dzuZa2xImh';
		this.imglogoname = 'logo-kajimoto-aboutus.png';
		this.imglogourl = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogos-Kajimoto-Vertical-Violet-Jaune.png?alt=media&token=c26d5494-bf55-408d-a231-b236f6d2a830';		  	
		this.imgmapchina = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fkajimoto-office-china.png?alt=media&token=bc0e2b5f-6e4a-4765-80e5-d1e6f6257882';
		this.imgmapfrance = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fkajimoto-office-france.png?alt=media&token=bd5f087b-0ccc-4c31-b1c7-5d2adeddaac2';
		this.imgmapjapan = 	'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-dev.appspot.com/o/uploads%2Fkajimoto-office-japan.png?alt=media&token=f58d294b-7c05-4087-af95-bc47ca18fb89';	
  }


  onSubmit(contactForm: NgForm) {
  	this.ps.createContact(contactForm);
  	this.addContactForm = false;
  }

  deleteContact(contact) {
  	this.ps.deleteContact(contact);
  }


  seeContacts(x) {
  	if (x === 'FR') {
  		this.contactsShown = this.contactsParis;
  		this.officeSelected = x;
  	}
  	else if (x === 'CH') {
  		this.contactsShown = this.contactsBeijing;  		
  		this.officeSelected = x;
  	}
  	else if (x === 'JP') {
  		this.contactsShown = this.contactsTokyo;  		  		
  		this.officeSelected = x;
  	}
  }


  lang_word(en, fr) {
  	if (this.txtInEn) { return en; }
  	else { return fr; }
  }






}
