import { Component, OnInit, Input } from '@angular/core';
import { PressService } from './../../press/shared/press.service';
import { PublicService } from '../shared/public.service';

@Component({
  selector: 'artist-press',
  templateUrl: './artist-press.component.html',
  styleUrls: ['./artist-press.component.scss']
})
export class ArtistPressComponent implements OnInit {

	@Input() presskey : string;
	press             : any;
	nbCar             : any;
	nbCarShown        : any;
	getOnce           : boolean=true;

	constructor(
		private pressService: PressService,
		private ps: PublicService) { }

  ngOnInit() {
  	this.pressService.getPressArticleWithKey(this.presskey).subscribe((press: any) => {
		this.press = press;
		console.log('INFORMATION : ', press.content)
		if(press && press.content) {
			console.log('ENTER IN ERROR')
			this.getNumberOfCaracterInArticleContent(press.content.length);
		}
	  });
	this.nbCarShown = 300;
  }

  getNumberOfCaracterInArticleContent(n) {
  	if(this.getOnce) {
  		this.nbCar    = n;
  	}
  	this.getOnce    = false;
  }

  changeNbCarShown(nbCarShown,nbCarTotal) {
  	this.nbCarShown = (nbCarShown == nbCarTotal) ? 300 : nbCarTotal;
  }

}
