import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'tour-events',
  templateUrl: './tour-events.component.html',
  styleUrls: ['./tour-events.component.scss']
})
export class TourEventsComponent implements OnInit {

	@Input() tourkey: string;
  @Input() countevents: any;

	events:any;

  constructor(private eventService: EventService) { }

  ngOnInit() {
  	this.events 	= this.eventService.getEventsTour(this.tourkey);
  }

  removeEventFromTour(event,countevents) {
  	this.eventService.removeTourFromEvent(event,countevents);
  }

}
