<mat-form-field	class="full-width">
  <input class="full-width"
      matInput 
      placeholder="{{dataSelected}}" aria-label="Search" 
      name="newEventData"
      ngModel
      [matAutocomplete]="auto" 
      [formControl]="dataCtrl">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
    <mat-option *ngFor="let data of filteredData | async" (click) = "link(data)"
      [value]="data">
      <img src="{{data.profilepic}}" class="profilepic">
      <span class="artist-name">{{ data.firstname }} {{ data.lastname }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix *ngIf="!showSearchLoader" class="icon-but">search</mat-icon>
  <mat-spinner matSuffix *ngIf="showSearchLoader" class="loader fx-row" ></mat-spinner>	  	
</mat-form-field>  
