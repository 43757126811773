
 <div class="fx-start-start fx-row fx-wrap select-filter h50">
		<div class="fx-25 fx-50-xs fx-gap-1"
		    *ngFor="let artist of artistsList | async" >
		    <img src="{{ artist.profilepic }}" class="profile-pic">		
		    <button mat-button (click)="insertAgentInMember(artist)">
		        {{ (artist.lastname.length>10) ? (artist.lastname | slice:0:10)+'...':(artist.lastname) }} 			
		    </button>
		</div>

   <button mat-button (click)="insertAllArtistInMember(artists)" *ngIf="artistsList | async as artists"
            class="select-all">
            select all	
   </button>
</div>