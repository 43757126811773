import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { ComposerService } from './../shared/composer.service';
import { Compositions } from '../shared/compositions';

@Component({
  selector: 'composition-list',
  templateUrl: './composition-list.component.html',
  styleUrls: ['./composition-list.component.scss']
})
export class CompositionListComponent implements OnInit {

  compositions: Observable<any[]>;	
  dev:boolean= false;
  title: string;

  constructor(
  	private router: Router,
  	private composerService: ComposerService,
  	private location: Location) { }

  ngOnInit() {
  	this.compositions = this.composerService.getCompositionsList();
  }

  deleteComposition(composition) {
    this.composerService.deleteCompositionFromCompositionList(composition);
  }

  // changeComposer(composition) {
  //   this.composerService.changeComposer(composition);
  // }

  // restructureIfNotFlat(composition) {
  //   // if(composition) {
  //     // console.log(composition);
  //   // }
  //   if(composition.composer) {
  //     // console.log(composition.composer);
  //     this.composerService.restructureCompositionIfNotFlat(composition);
  //   }
  // }

  goBack(): void {
  	this.location.back();
	}

}
