import { LogClass } from './../../shared/logclass';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/compat/app';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
 import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Upload } from './upload';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable()
export class UploadService {

  disc: Observable<any>;
  artist: Observable<any>;  

  private basePath = '/uploads';
  private discogPath = '/uploads';

  uploads: Observable<Upload[]>;

  logClass : LogClass ;

  constructor(private afs: AngularFirestore,private router: Router,private store: AngularFireStorage) {
  }
  

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////  G E T  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////





////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// U P L O A D ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  // ROOTS IMAGES

  pushFileToStorage(fileUpload: Upload, progress: {percentage: number}) {
   
    const storageRef        = this.store.storage.ref()
    const timestamp         = Date.now();
 
    const title             = fileUpload.file.name;
    const newFileName       = `${title.replace(/[^A-Z0-9]+/ig, "_")}_${timestamp}.jpg`;

    const uploadTask        = storageRef.child(`${this.basePath}/${newFileName}`).put(fileUpload.file);
    
    uploadTask.on('state_changed',
      (snapshot) => {
        // in progress
        const snap          = snapshot //as firebase.default.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
      },
      (error) => {
        // fail
        console.log(error)
      },
      () => {
        // success
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: any) => {
          fileUpload.url      = downloadURL;
      });
        
        fileUpload.name     = title;
      }
    );
  }



////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// C R E A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////



//// FIRESTORE

  addMediaArtist(artistkey, fullname, title, link):void {

    const mediaData            = {};
    mediaData['timestamp']     = Date.now();
    mediaData['title']         = title;
    mediaData['link']          = link;
    mediaData['artistkey']     = artistkey;  
    mediaData['fullname']      = fullname;
    mediaData['srce']          = 'youtube';
    const batch = this.afs.firestore.batch();

    // console.log(mediaData);

    const mediakey = this.afs.firestore.collection('medias').doc().id;
    const mediaRef = this.afs.collection('medias').doc(mediakey).ref;
    batch.set(mediaRef, mediaData) ;
    const mediaMemberRef = this.afs.collection('members').doc(artistkey).collection('media').doc(mediakey).ref;
    batch.set(mediaMemberRef, mediaData);

    batch.commit()
    .then(function() {
      console.log('Batch Commited')
    })
    .catch(function(err) {
      console.error(err);
    });
    this.logClass.createLog(this.afs, 'addMediaArtist', artistkey);
    this.router.navigate(['/member/'+ artistkey]);    

  }


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// D E L E T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////






  deleteDisk(disk,artistkey) {

    const uploadkey  = disk.uploadkey;
    const uploadname = disk.name;       

    const batch = this.afs.firestore.batch();
    const diskFileInMemberRef = this.afs.collection('members').doc(artistkey).ref;                                                                         
    const diskFileRef = this.afs.collection('uploads').doc(uploadkey).ref;                                                                         
    
    batch.delete(diskFileRef);
    batch.update(diskFileInMemberRef, {['discographie.' + uploadkey]:  firebase.default.firestore.FieldValue.delete()}); 


    const storageRef = this.store.storage.ref();
    const deleteRef  = storageRef.child(`discogs/${uploadname}`);    

    batch.commit()
    .then(function() {console.log('Batch Commited')})
    .catch(function(err) {console.error(err);});

    this.logClass.createLog(this.afs,'deleteDisk', uploadkey);

  }



}