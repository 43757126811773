<button mat-fab class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInEn" 
	(click)="
		txtInFr=true;txtInEn=false;
		titleInFr=true;titleInEn=false
		">
  FR
</button>

<button mat-fab class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInFr" 
	(click)="
		txtInFr=false;txtInEn=true;
		titleInFr=false;titleInEn=true
		">
  EN
</button>

<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="editpage = !editpage"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!editpage">create</mat-icon>
  <mat-icon *ngIf="editpage">close</mat-icon>
</button>

<div *ngIf="page | async as page">
  <h1 
  	*ngIf="titleInEn"
    class="h1-style-xs"
  	[ngStyle.xs]="{'font-size.px': 24, 'padding-left': '100px'}" 
    innerHTML="{{ page.titleen }}">
  </h1>

  <h1 
  	*ngIf="titleInFr"
    class="h1-style-xs"
  	[ngStyle.xs]="{'font-size.px': 24, 'padding-left': '100px'}"
    innerHTML="{{ page.titlefr }}">
  </h1>
</div>

<ng-template #loading>        
  <br><br><br><br>
  <mat-spinner color="warn"></mat-spinner>
  <br><br>
</ng-template>

<div class="bounds" *ngIf="page | async as page; else loading">

  <div class="content fx-row fx-end-start fx-col-xs fx-fill">
      <div class="sec1 fx-35 fx-100-xs"
          [ngStyle.xs]="{ 'margin-top':'7px' }">
        <img src="{{ page.img }}" alt="{{ imgname.substring(0, imgname.indexOf('.')) }}">
        <span class="credit-photo">© {{ page.creditphoto }}</span>

        <div *ngIf="editpage" class="fx-row fx-wrap"  [ngStyle]="{'margin':'20px 0 0','color':'black'}">
          <mat-form-field class="fx-100">
            <mat-label>Image Url </mat-label>
            <input matInput  required
              [(ngModel)]="page.img"
              (ngModelChange)="updatePageField(page,'img',page.img)">
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label>Credit Photo </mat-label>
            <input matInput  required
              [(ngModel)]="page.creditphoto"
              (ngModelChange)="updatePageField(page,'creditphoto',page.creditphoto)">
          </mat-form-field> 
        </div>        

      </div>
      <div class="sec2 fx-50 fx-100-xs sec2-style-xs" >
				<p class="txt-1" *ngIf="txtInFr && !editpage" innerHTML="{{ page.txtfr }}"></p>
				<p class="txt-1" *ngIf="txtInEn && !editpage" innerHTML="{{ page.txten }}"></p>

        <!-- When edit page -->
        <div *ngIf="editpage" class="fx-row fx-wrap" >
          <mat-form-field class="fx-100">
            <mat-label>Titre en français </mat-label>
            <input matInput required
              [(ngModel)]="page.titlefr"
              (ngModelChange)="updatePageField(page,'titlefr',page.titlefr)">
          </mat-form-field>    
          <mat-form-field class="fx-100">
            <mat-label>Title in english </mat-label>
            <input matInput required
              [(ngModel)]="page.titleen"
              (ngModelChange)="updatePageField(page,'titleen',page.titleen)">
          </mat-form-field> 
          <mat-form-field class="fx-100">
            <mat-label> Texte en français</mat-label>
            <textarea matInput required rows="16"
              [(ngModel)]="page.txtfr"
              (ngModelChange)="updatePageField(page,'txtfr',page.txtfr)"></textarea>
          </mat-form-field>
          <mat-form-field class="fx-100">
            <mat-label> Text in english</mat-label>
            <textarea matInput required rows="16"
              [(ngModel)]="page.txten"
              (ngModelChange)="updatePageField(page,'txten',page.txten)"></textarea>
          </mat-form-field>          
        </div>

      </div>
      <div class="fx-15 sec3 hide-mobile">
         <img src="{{ imglogourl }}" alt="{{ imglogoname.substring(0, imglogoname.indexOf('.')) }}">
      </div>
  </div>

</div>

<br><br>

<footer-nav></footer-nav>

