<!-- <pre *ngIf="selectedComposer">{{ selectedComposer.compositions | json  }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<mat-card appearance="outlined" *ngIf="eventView | async as event" class="noshadow">

	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<span>
				<mat-icon matListIcon>today</mat-icon>
			</span>					
		</div>	
		<mat-card-title>{{ event.title }}</mat-card-title>
		<mat-card-subtitle>Add compositions to event program<br>
			<span class="keystyle">{{ event.$key }}</span>
		</mat-card-subtitle>
	</mat-card-header>



	<mat-form-field	class="full-width" 
		*ngIf="	showSearchComposersField
						&&!selectedComposer">
						<mat-label> Search Composer </mat-label>
	  <input class="full-width"
	  		matInput 
	  		aria-label="Composer" 
	  		name="newEventComposer"
	  		ngModel
	  		[matAutocomplete]="auto" 
	  		[formControl]="composerCtrl">
	  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
	    <mat-option *ngFor="let composer of filteredComposers | async" 
	    	[value]="composer">
	      <span>{{ composer.lastname }} {{ composer.firstname }}</span>
	    </mat-option>
	  </mat-autocomplete>
	  <mat-icon matSuffix *ngIf="!showSearchLoader" class="icon-but">search</mat-icon>
	  <mat-icon matSuffix *ngIf="!showSearchLoader" class="icon-but"
	  	(click)="addComposer=true; showSearchComposersField=false">add</mat-icon>	  	
	  <mat-icon matSuffix *ngIf="!showSearchLoader" class="icon-but"
	  	[routerLink]="['./../../../event$/event', event.$key]">clear</mat-icon>
		<mat-spinner matSuffix *ngIf="showSearchLoader" class="loader fx-row" ></mat-spinner>  		  		  	
	</mat-form-field>


	<div *ngIf="selectedComposer">
		<strong>Composer: </strong>
		<!-- {{ selectedComposer.$key }}  -->
		{{ selectedComposer.firstname }} {{ selectedComposer.lastname }}
		<mat-icon class="cursor-pointer"
			(click)="	selectedComposer=false;
								showSearchComposersField=true">clear</mat-icon>
	</div>

	<app-event-program-child *ngIf="selectedComposer&&showCompositions" 
		[composerkey]="selectedComposer.$key"
		[eventView] = "event"
		[selectedComposer] = "selectedComposer">
  </app-event-program-child>

<!-- partie ‡ bosser -->

	<!-- <div *ngIf="selectedComposer&&showCompositions">
		<mat-form-field *ngIf="compositions" class="full-width">
			<mat-label> Select Composition(s) </mat-label>
		  <mat-select required class="full-width"
		  	ngModel #selectedCompositions="ngModel" name="selectedCompositions"
		  >
		    <mat-option 
		    	*ngFor="let composition of compositions | async" 
		    	[value]="composition">
		    	{{ composition.title }}
		    </mat-option>
		  </mat-select>
		</mat-form-field>
	</div> -->

 	
	<button *ngIf="showSearchComposersFieldButton" type="button" mat-button 
		(click)="	showSearchComposersField=true;
							showSearchComposersFieldButton=false;
							selectedComposer=false">
		<mat-icon>visibility</mat-icon>
		See Composers Search Field
	</button>
	
	<button *ngIf="showAddCompositionButton" type="button" mat-button 
		(click)="
			addCompoField=true;
			showAddCompositionButton=false;
			saveEventButton=false;
			showCompositions=false
			">
		<mat-icon>add</mat-icon>
		Add a composition to this composer
	</button> 

	<mat-form-field *ngIf="addCompoField" class="full-width">
		<mat-label> Add Composition to Composer </mat-label>
	  <input matInput required
	  	ngModel #newCompoForComposer="ngModel"
			name="newCompoForComposer">
		<mat-icon matSuffix class="icon-but" 
			(click)="
  		addCompositionToComposer(event,newCompoForComposer,selectedComposer);
  		saveEventButton=true;
  		addCompoField=false;
  		showAddCompositionButton=false;
  		showSearchComposersFieldButton=true;
			showSearchComposersField=false;
			compositions=false">add</mat-icon>

		<mat-icon matSuffix class="icon-but" 
			(click)="addCompoField=false;
  		showAddCompositionButton=false;
  		saveEventButton=true;
  		selectedComposer=false;
  		showSearchComposersFieldButton=false
  		">clear</mat-icon>	
	</mat-form-field>
</mat-card>



<mat-card appearance="outlined" *ngIf="addComposer">
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>person_add</mat-icon>
		</div>			
		<mat-card-title>Add Composer</mat-card-title>
		<mat-card-subtitle>Add Composer in Kajimotomusic Database</mat-card-subtitle>		
	</mat-card-header>

	<mat-card-content>
	<form #newComposerForm="ngForm" (ngSubmit)="onSubmit(newComposerForm)">
		<mat-form-field>
			<mat-label> First name </mat-label>
			<input matInput
				required
				ngModel #newComposerfirstname="ngModel"
				name="newComposerfirstname">
		</mat-form-field>
		<mat-form-field>
			<mat-label> Last name </mat-label>
			<input matInput
				required
				ngModel #newComposerlastname="ngModel"
				name="newComposerlastname">
		</mat-form-field>
		<button mat-fab type="submit" color="warn"
			[disabled]="!newComposerForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>
		<button mat-fab type="button" color="black"
			(click)="addComposer=false;showSearchComposersField=true">
			<mat-icon>clear</mat-icon>
		</button>					
	</form>
	</mat-card-content>
</mat-card>