<!-- <pre>{{ venue | async | json }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<mat-card appearance="outlined" *ngIf="venue | async as venue" class="noshadow">
	<br>
	<mat-card-header>	
		<mat-card-title><strong>{{ venue.name }}</strong></mat-card-title>
		<mat-card-subtitle>Edit venue details
		</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content>

		<mat-form-field class="full-width">
			<input matInput venueholder="Venue name" required
				[(ngModel)]="venue.name"
				(ngModelChange)="updateField(venue,'name',venue.name)">
		</mat-form-field>

		<mat-form-field class="full-width">
			<input matInput venueholder="Venue Address" required
				[(ngModel)]="venue.address"
				(ngModelChange)="updateField(venue,'address',venue.address)">
		</mat-form-field>		

		<mat-form-field class="full-width">
			<input matInput venueholder="Venue Url" required
				[(ngModel)]="venue.url"
				(ngModelChange)="updateField(venue,'url',venue.url)">
		</mat-form-field>		

	</mat-card-content>	
 	<mat-card-footer>
		<button  *ngIf="event | async as event;else noEvent" mat-fab (click)="goBack(event,venue)" type="button" color="black" class="ml-15">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<ng-template #noEvent>
			<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15">
				<mat-icon>keyboard_arrow_left</mat-icon>
			</button>
		</ng-template>	

		<button *ngIf="!eventkey" mat-fab [routerLink]="['./../../venues']" type="button" color="black">
			<mat-icon>list</mat-icon>
		</button>
		<button *ngIf="!eventkey" mat-fab class="ml" [routerLink]="['./../../venueform']" type="button" color="black">
			<mat-icon>add</mat-icon>
		</button> 
		<button *ngIf="!eventkey" mat-fab (click)="deleteVenue(venue)" type="button" color="warn">
			<mat-icon>delete_forever</mat-icon>
		</button>
	</mat-card-footer>
</mat-card>
