
<div *ngIf="event | async  ; let event" [ngStyle]="{'text-align':'center'}">
    <h3>Do you really want to remove this event ?</h3> 
    <div *ngIf="artists | async ; let artist">
        <button mat-button  *ngIf="artist?.length!=0&&artist ;"
            (click)="removeAllArtistsAndDeleteEvent(event,artist);" 
            cdkFocusInitial>YES
        </button>	
        <button mat-button *ngIf="artist?.length == 0"
            (click)="deleteEventWithNoArtist(event);" 
            cdkFocusInitial>YES
        </button>		        
        <button mat-button (click)="onNoClick()">NO</button>
    </div>
    <br>	
</div>	



