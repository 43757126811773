import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './../../ui/angularmaterial.module';

import { EventService } from './event.service';

import { ScrollableDirective } from './scrollable.directive';

import { EventDetailComponent, ConfirmeDeleteEventComponent } from './../event-detail/event-detail.component';
import { EventFormComponent } from './../event-form/event-form.component';
import { EventListComponent, DialogFilterEvents, DialogDuplicateEvents } from './../event-list/event-list.component';

import { EventProgramComponent } from './../event-program/event-program.component';
import { EventtourFormComponent } from './../eventtour-form/eventtour-form.component';
import { EventVenueComponent } from './../event-venue/event-venue.component';
import { EventsRoutingComponent } from './../events-routing/events-routing.component';
import { EventArtistComponent } from './../event-artist/event-artist.component';
import { EventProgramChildComponent } from './../event-program-child/event-program-child.component';
import { TourEventsComponent } from './../tour-events/tour-events.component';
import { TourDetailComponent, ConfirmationEmailComponent, DeleteTourComponent } from './../tour-detail/tour-detail.component';
import { EventsRoutingArtistComponent } from './../events-routing-artist/events-routing-artist.component';
import { EventsRoutingEventsComponent } from './../events-routing-events/events-routing-events.component';
import { EventsRoutingProgramComponent } from './../events-routing-program/events-routing-program.component';
import { ProgramEventComponent } from './../program-event/program-event.component';
import { TourListComponent } from './../tour-list/tour-list.component';
import { RoutingDetailsComponent } from './../routing-details/routing-details.component';
import { RoutingHistoryComponent } from './../routing-history/routing-history.component';
import { EventsRoutingEventsEventComponent } from './../events-routing-events-event/events-routing-events-event.component';
import { EventRepairComponent } from './../event-repair/event-repair.component';
import { EventRoutingModule } from './event-routing.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // BrowserModule,
        FormsModule, ReactiveFormsModule,
        AngularMaterialModule,
        // AngularFireDatabaseModule,
        // BrowserAnimationsModule,
        AngularMaterialModule,
        EventRoutingModule
    ],
    declarations: [
        EventRepairComponent,
        EventDetailComponent,
        EventFormComponent,
        EventListComponent,
        EventProgramComponent,
        EventtourFormComponent,
        EventVenueComponent,
        TourListComponent,
        EventsRoutingComponent,
        DialogFilterEvents,
        DialogDuplicateEvents,
        EventArtistComponent,
        EventProgramChildComponent,
        ScrollableDirective,
        TourEventsComponent,
        TourDetailComponent,
        EventsRoutingArtistComponent,
        EventsRoutingEventsComponent,
        EventsRoutingProgramComponent,
        ProgramEventComponent,
        RoutingDetailsComponent,
        RoutingHistoryComponent,
        EventsRoutingEventsEventComponent,
        ConfirmeDeleteEventComponent,
        ConfirmationEmailComponent,
        DeleteTourComponent
    ],
    providers: [
        EventService,
        AuthService
    ]
})
export class EventModule { }
