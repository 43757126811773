import { AuthService } from './../../core/auth.service';
import { LogClass } from './../../shared/logclass';
import { Instrument } from './../../public/shared/instrument';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



import * as firebase from 'firebase/compat/app';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Role } from './role';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { catchError, map, switchMap, timestamp, distinct } from 'rxjs/operators';
import { UploadService } from './../../upload/shared/upload.service';
import { deleteField } from 'firebase/firestore';

@Injectable()
export class MemberService {

  members              : Observable<any[]>; 
  member               : Observable<any>;   
  agent                : Observable<any>;
  changedMember        : Observable<any>;
  roles                : Observable<any>;
  role                 : Observable<any>;
  instruments          : Observable<any>;
  instrument           : Observable<any>;

  artistsKeys          : AngularFirestoreCollection<unknown>;
  memberCollection     : AngularFirestoreCollection<unknown>;
  uploadCollection     : AngularFirestoreCollection<unknown>;
  userCollection       : AngularFirestoreCollection<unknown>;
  pressCollection      : AngularFirestoreCollection<unknown>;
  mediaCollection      : AngularFirestoreCollection<unknown>;
  artistCollection     : AngularFirestoreCollection<unknown>;
  agentCollection      : AngularFirestoreCollection<unknown>;
  liveMemberCollection : AngularFirestoreCollection<unknown>;
  liveArtistsCollection: AngularFirestoreCollection<unknown>;
  logClass             : LogClass;

  constructor(
    private afs: AngularFirestore,
    private uploadService : UploadService, 
    private router: Router,
    private auth  : AuthService) 
  { 
    this.userCollection         = this.afs.collection('users');
    this.uploadCollection       = this.afs.collection('uploads');
    this.memberCollection       = this.afs.collection('members', ref => ref.orderBy('lastname'));
    this.mediaCollection        = this.afs.collection('medias');
    this.pressCollection        = this.afs.collection('press', ref => ref.orderBy('lastname'));
    this.artistCollection       = this.afs.collection('members', ref => ref.where('role','==','Artist').orderBy('firstname'));
    this.liveArtistsCollection  = this.afs.collection('members', ref => ref.where('role','==','Artist').where('isonline', '==' ,true).orderBy('lastname'));
    
    this.liveMemberCollection   = this.afs.collection('members',ref => ref.where('isonline', '==' ,'1'));
    
    // this.agentCollection        = this.afs.collection('members', ref => ref.where('role','==','Agent').orderBy('firstname'));
    this.agentCollection        = this.afs.collection('members', ref => ref.where('role','==','Agent').where('ismember','==',true).orderBy('firstname'));
    this.logClass = new LogClass(this.auth, this.afs);
  }



  
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
///////////////////  G E T  ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

  getMembersList() {
    return this.memberCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),{ $key: snap.payload.doc.id }) )
      })
    );
  }

  getUsersList() {
    return this.userCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),{ $key: snap.payload.doc.id }) )
      })
    );
  }  


  getMediaList(query?) {
    return this.mediaCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(),{ $key: snap.payload.doc.id }))
      })
    );    
  }

  getArtistNameList(query?) {
    return this.artistCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          // snap.payload.val(),
          { artistdefaultimg: snap.payload.doc.data().imgcarrousel1 },
          { fullname: `${ snap.payload.doc.data().firstname} ${ snap.payload.doc.data().lastname}`},
          { $key: snap.payload.doc.id }
        ) )
      })
    );
  }

  getListOfArtisForTour(query?) {
    return this.artistCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          // snap.payload.val(),
          { fullname: `${ snap.payload.doc.data().firstname} ${ snap.payload.doc.data().lastname}`},
          { firstname: snap.payload.doc.data().firstname },
          { lastname: snap.payload.doc.data().lastname },
          { phone: snap.payload.doc.data().phone ? snap.payload.doc.data().phone : null },
          { email: snap.payload.doc.data().email ? snap.payload.doc.data().email : null },
          { $key: snap.payload.doc.id }
        ) )
      })
    );
  }

  getLiveArtistsList(query?) {
    return this.liveMemberCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { isagents: snap.payload.doc.data().agents?Object.values(snap.payload.doc.data().agents):0 },
          { isinstruments: snap.payload.doc.data().instruments?Object.values(snap.payload.doc.data().instruments):0 },
          // { agentsval: Object.values(snap.payload.val().agents)?Object.values(snap.payload.val().agents):''},
          { $key: snap.payload.doc.id }) )
      })
    );
  }


  getMemberKeyInUrl(firstname,lastname: string) {
    return this.afs.collection('members', ref => ref.where('lastname','==',lastname)
                   .where('firstname', '==', firstname).limit(1)).get().pipe(
                    map((arr) => {
                      return arr.docs.map(snap => Object
                        .assign(snap.data(), 
                                {$key: snap.id},
                                ));
                    })
                   );
  }



  getPressInMember(key: string) {
    return this.afs.collection('members').doc(key)
                    .collection('press', ref => ref.orderBy('date','desc')).get().pipe(
                      map((arr) => {
                        return arr.docs.map(snap => Object
                          .assign(snap.data(), 
                                  {$key: snap.id},
                                  {date: snap.data().date ? snap.data().date.toDate() : null},
                                  ))
                      })
                    );
  }

  getNewsInMember(key: string) {
    return this.afs.collection('members').doc(key)
                    .collection('news', ref => ref.orderBy('date','desc')).get().pipe(
                      map((arr) => {
                        return arr.docs.map(snap => Object.assign(
                          snap.data(), 
                          {
                            $key: snap.id,
                            date: snap.data().date ? snap.data().date.toDate() : null,
                          }))
                      })
                    );
  }  

  getMediaInMember(key: string) {
    return this.afs.collection('members').doc(key).collection('media').get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(snap.data(), {$key: snap.id}))
      })
    );
  }

  getArtistsWithEventKey(key: string) {
    return this.afs.collection('events').doc(key).collection('artists').snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(), {$key: snap.payload.doc.id}))
      })
    );
  }




  getMemberWithKey(key: string): Observable<Role> {
    const memberPath = `members/${key}`;
    this.member =this.afs.doc<any>(memberPath)
      .snapshotChanges().pipe(
        map(action => {
          const $key = action.payload.id;
          // const birthdate2
          const data = { $key, ...action.payload.data() };
          return data;
        })
      );
    return this.member
  }

  getImageCarrousel(...image: string[]) {
    let imageToReturn = image.filter(el => {if(typeof el !== 'undefined' && el !== null) return true;});
    return imageToReturn;
  }

  getImageCarrouselCredit(...imageCredit: string[]) {
    let credit = imageCredit.filter(el => {if(typeof el !== 'undefined' && el !== null) return true;});
    return credit;
  }

  getArtistWithKey(key: string): Observable<any> {
    const eventPath = `members/${key}`;
    this.member = this.afs.doc<any>(eventPath)
      .get().pipe(
        map(action => {
          const data = Object.assign(action.data(), 
          { 
            birthdate: action.data().birthdate ? action.data().birthdate.toDate() : null,
            date:      action.data().date ? action.data().date.toDate() : null,
            arrdiscog: action.data().discographie?Object.values(action.data().discographie):null,
            $key:      action.id,
            imgcarrousel: this.getImageCarrousel(action.data().imgcarrousel1, action.data().imgcarrousel2, action.data().imgcarrousel3, action.data().imgcarrousel4, action.data().imgcarrousel5, action.data().imgcarrousel6, action.data().imgcarrousel7, action.data().imgcarrousel8, action.data().imgcarrousel9, action.data().imgcarrousel10),
            imgcarrouselcredit: this.getImageCarrouselCredit(action.data().imgcarrousel1credit, action.data().imgcarrousel2credit, action.data().imgcarrousel3credit, action.data().imgcarrousel4credit, action.data().imgcarrousel5credit, action.data().imgcarrousel6credit, action.data().imgcarrousel7credit, action.data().imgcarrousel8credit, action.data().imgcarrousel9credit, action.data().imgcarrousel10credit)

          });
          return data;
        })
      );
    return this.member
  }

  getAgentsList() {
    return this.agentCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(snap.payload.doc.data(), { $key: snap.payload.doc.id }) )
      })
    );
  }

  getArtistsList() {
    return this.liveArtistsCollection.get().pipe(
      map((arr) => {
        return arr.docs.map(snap => Object.assign(
          snap.data(),
          { $key: snap.id }) )
      })
    );
  }

  getAllArtistsList() {
    return this.artistCollection.snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { $key: snap.payload.doc.id }) )
      })
    );
  }

  getArtistInAgentList(agentKey) {
    return this.afs.collection('members', ref => ref.where('role', '==', 'Artist')
    .where('agentkeys', 'array-contains', agentKey) ).snapshotChanges().pipe(
      map((arr: any) => {
        return arr.map(snap => Object.assign(
          snap.payload.doc.data(),
          { $key: snap.payload.doc.id }) );
      })
    );
  }

  getYearFromMembersEvent(memberkey) {
    const users$ = this.afs.collection('members').doc(memberkey).collection('events', ref => ref.orderBy('date')).valueChanges();
    const timestamp$ = [];
    return users$.pipe(
     map((array) => {
        array.forEach((value,key) => {
          if(value.date) { timestamp$.push(new Date(value.date.seconds * 1000).getFullYear()) }
         });
         const distinctTimestamp = Array.from(new Set(timestamp$)) ;
        return distinctTimestamp;
     }),
    );
  }

  
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// C R E A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

   importMemberFromJson(member,num,totalmember) {
      var batch = this.afs.firestore.batch();
      var membersList   = {};
      var agentList     = {};
      var agents        = member.agents?member.agents:null;
      var birthday      = member.birthday?member.birthday:null;
      var discographie  = member.discographie?member.discographie:null;
      var discoList     = {};
      var pressList     = {};
      var press         = member.press? member.press:null;
      const MEMBER_REF  = this.afs.firestore.collection('members').doc();
      const MEMBER_KEY  = MEMBER_REF.id;
      var   medias       = member.youtube?member.youtube:null ;
      var   mediaList    = {} ;
      // console.log('memberkey',MEMBER_KEY);

      if(agents) {
        // tslint:disable-next-line:forin
        for ( let agent in agents) {
            agentList[agent] =  agents[agent];
        }
        membersList['agents']  = agentList;
      }
      membersList['birthday']          =  birthday;
      membersList['biolonghtmlen']     =  member.biolonghtmlen?member.biolonghtmlen:null;
      membersList['biolonghtmlfr']     =  member.biolonghtmlfr?member.biolonghtmlfr:null;
      membersList['bioshorthtmlen']    =  member.bioshorthtmlen?member.bioshorthtmlen:null;
      membersList['bioshorthtmlfr']    =  member.bioshorthtmlfr?member.bioshorthtmlfr:null;
      membersList['city']              =  member.city?member.city:null;
      if(discographie){
         // tslint:disable-next-line:forin
         for (const disco in discographie) {
            discoList[disco] = discographie[disco];
         }
      }
      membersList['discographie']           = discoList ;
      membersList['email']                  = member.email?member.email:null;
      membersList['firstname']              = member.firstname?member.firstname:null;
      membersList['generalmanagement']      = member.generalmanagement?member.generalmanagement:null;
      membersList['generalmanagementemail'] = member.generalmanagementemail?member.generalmanagementemail:null;
      membersList['generalmanagementname']  = member.generalmanagementname?member.generalmanagementname:null;
      membersList['hireslink']              = member.hireslink?member.hireslink:null;
      membersList['img']                        = member.img?member.img:null;
      membersList['imgcarrousel1']              = member.imgcarrousel1?member.imgcarrousel1:null;
      membersList['imgcarrousel1credit']        = member.imgcarrousel1credit?member.imgcarrousel1credit:null;
      membersList['imgcarrousel2']              = member.imgcarrousel2? member.imgcarrousel2:null;
      membersList['imgcarrousel2credit']        = member.imgcarrousel2credit?member.imgcarrousel2credit:null;
      membersList['imgcarrousel3']              = member.imgcarrousel3?member.imgcarrousel3:null;
      membersList['imgcarrousel3credit']        = member.imgcarrousel3credit?member.imgcarrousel3credit:null;
      membersList['imgcarrousel4']              = member.imgcarrousel4?member.imgcarrousel4:null;
      membersList['imgcarrousel4credit']        = member.imgcarrousel4credit?member.imgcarrousel4credit:null;
      membersList['imgcredit']                  = member.imgcredit?member.imgcredit:null;
      membersList['isonline']                   = member.isonline?member.isonline:null;
      membersList['ismedia']                    = member.ismedia?member.ismedia:null;
      membersList['isevents']                   = member.isevents?member.isevents:null;
      membersList['instrument']                 = member.instrument?member.instrument:null;
      membersList['instruments']                = member.instruments?member.instruments:null;
      membersList['kajimotomanagement']               = member.kajimotomanagement?member.kajimotomanagement:null;
      membersList['kajimotomanagementbeijingemail']   = member.kajimotomanagementbeijingemail?member.kajimotomanagementbeijingemail:null;
      membersList['kajimotomanagementbeijingname']    = member.kajimotomanagementbeijingname?member.kajimotomanagementbeijingname:null;
      membersList['kajimotomanagementemail']          = member.kajimotomanagementemail?member.kajimotomanagementemail:null;
      membersList['kajimotomanagementparisname']      = member.kajimotomanagementparisname?member.kajimotomanagementparisname:null;
      membersList['kajimotomanagementphone']          = member.kajimotomanagementphone?member.kajimotomanagementphone:null;
      membersList['kajimotomanagementphonemobile']    = member.kajimotomanagementphonemobile?member.kajimotomanagementphonemobile:null;
      membersList['kajimotomanagementphoneoffice']    = member.kajimotomanagementphoneoffice?member.kajimotomanagementphoneoffice:null;
      membersList['kajimotomanagementrepresentation'] = member.kajimotomanagementrepresentation?member.kajimotomanagementrepresentation:null;
      membersList['kajimotomanagementtokyoemail']     = member.kajimotomanagementtokyoemail?member.kajimotomanagementtokyoemail:null;
      membersList['kajimotomanagementtokyoname']      = member.kajimotomanagementtokyoname?member.kajimotomanagementtokyoname:null;
      membersList['lastname']                         = member.lastname?member.lastname:null;
      membersList['office']                           = member.office?member.office:null;
      membersList['phone']                            = member.phone?member.phone:null;
      membersList['profilepic'] = member.profilepic?member.profilepic:null;
      membersList['role']       = member.role?member.role:null;
      membersList['timestamp']  = Date.now();
      membersList['street']     = member.street?member.street:null;
      membersList['website']    = member.website?member.website:null;
      membersList['zip']        = member.zip?member.zip:null;
     // console.log(mediaList);
     // console.log(member);
      if(membersList){
          this.memberCollection.doc(MEMBER_KEY)
          .set(membersList)
          .then(function() {console.log("Document successfully written!", num,' / ',totalmember,member.lastname, membersList);})
          .catch(function(error) {console.error("Error writing document: ", error);});

          if (press){
            // tslint:disable-next-line:forin
            for(let presse in press){
    
              const PRESS_REF  = this.afs.firestore.collection('press').doc();
              const PRESS_KEY  = PRESS_REF.id;
    
              var value:any = press[presse];
              var pressData = {
                  artistKey : MEMBER_KEY,
                  content   : value.content,
                  date      : value.date,
                  info      : value.info,
                  presskey  : PRESS_KEY,
                  timestamp : value.timestamp,
                  title     : value.title };
                  pressList[PRESS_KEY] = pressData;
                  // batch.set(PRESS_REF,pressList);
                  this.pressCollection.doc(PRESS_KEY)
                  .set(pressData)
                  .then(function() {console.log("Document successfully written!", pressData);})
                  .catch(function(error) {console.error("Error writing document: ", error);});

                  this.afs.firestore.collection('members').doc(MEMBER_KEY).collection('press').doc(PRESS_KEY)
                   .set(pressData)
                   .then(function() {console.log("Document successfully written!");})
                   .catch(function(error) {console.error("Error writing document: ", error);});
            }
           //  membersList['press'] = pressList;
          }

           if (medias){
            // tslint:disable-next-line:forin
            for(let media in medias){

              const MEDIA_REF  = this.afs.firestore.collection('medias').doc();
              const MEDIA_KEY  = MEDIA_REF.id;

              var value:any = medias[media];
              var mediaData = {
                  artistKey : MEMBER_KEY,
                  fullname  : member.lastname+'  '+ member.firstname,
                  link      : value,
                  timestamp : Date.now(),
                  src       : 'youtube',
                };
                  mediaList[MEDIA_KEY] = value;
                //  batch.set(MEDIA_REF,mediaData);
                this.mediaCollection.doc(MEDIA_KEY)
                .set(mediaData)
                .then(function() {console.log("Document successfully written!", mediaData);})
                .catch(function(error) {console.error("Error writing document: ", error);});

                this.afs.firestore.collection('members').doc(MEMBER_KEY).collection('media').doc(MEDIA_KEY)
                .set(mediaData)
                .then(function() {console.log("Document successfully written!");})
                .catch(function(error) {console.error("Error writing document: ", error);});
            }
          }
      }
   }





  createMember(newMemberForm: NgForm, createandbind?) {
    const db = this.afs.firestore;
    var batch = this.afs.firestore.batch();
    var roleKey = newMemberForm.value.pickedMemberRole.$key;
    var roleTitle = newMemberForm.value.pickedMemberRole.title;
    var newMemberData           = {}
    newMemberData['timestamp']  = Date.now();
    newMemberData['ismember']   = false ;
    newMemberData['firstname']  = newMemberForm.value.newMemberfirstname ?newMemberForm.value.newMemberfirstname:null;
    newMemberData['lastname']   = newMemberForm.value.newMemberlastname ?newMemberForm.value.newMemberlastname:null;
    newMemberData['email']      = newMemberForm.value.newMemberemail ?newMemberForm.value.newMemberemail:null;
    newMemberData['phone']      = newMemberForm.value.newMemberphone ?newMemberForm.value.newMemberphone:null;
    newMemberData['instrument'] = newMemberForm.value.selectedInstrus ?newMemberForm.value.selectedInstrus.title:null;
    newMemberData['role']       = newMemberForm.value.pickedMemberRole ?newMemberForm.value.pickedMemberRole.title:null;
    newMemberData['instruments']= newMemberForm.value.selectedInstrus ?[newMemberForm.value.selectedInstrus.title]:null ;

    // IF ARTIST
    if(roleKey=="1") 
    {

      // Publish Variables
      newMemberData['isonline'] = false;
      newMemberData['ismedia'] = false;
      newMemberData['isevent'] = false;

      // Instrument(s)
      // var nbInstrument = newMemberForm.value.selectedInstrus?newMemberForm.value.selectedInstrus.length:0;
      // if(nbInstrument) {
      //   var memberInstruments = {};
      //   for(var i=0;i<nbInstrument;i++){
      //     var keyInstru = newMemberForm.value.selectedInstrus[i].$key;
      //     var memberInstrument = {};
      //     memberInstrument['key'] = keyInstru;
      //     memberInstrument['title'] = newMemberForm.value.selectedInstrus[i].title;
      //     memberInstruments[keyInstru] = {};
      //     memberInstruments[keyInstru] = memberInstrument;
      //     // newMemberData['instruments'] = { memberInstruments }
      //   }
      //   newMemberData['instruments'] = memberInstruments;
      // }
      // Agent(s)
      var nbAgent = newMemberForm.value.selectedAgents?newMemberForm.value.selectedAgents.length:0;
      if(nbAgent) {
        var memberAgents = {};
        for(var i=0;i<nbAgent;i++) {
          var keyagent = newMemberForm.value.selectedAgents[i].$key;
          var memberAgent = {};
          memberAgent['key'] = keyagent;
          memberAgent['fullname'] = `${newMemberForm.value.selectedAgents[i].firstname} ${newMemberForm.value.selectedAgents[i].lastname}`;
          memberAgents[keyagent] = {}
          memberAgents[keyagent] = memberAgent;
         // newMemberData['agents'] = { memberAgents } ;
        }
         newMemberData['agents'] = memberAgents;
      }
    }
    // INSERT MEMBER
    const newMemberKey = db.collection('members').doc().id;
    const memberRef = this.afs.collection('members').doc(newMemberKey).ref;
    batch.set(memberRef,newMemberData);

    // INSERT MEMBER IN ROLE TAB
    // const roleMemberRef = this.afs.collection('role')
    //                     .doc(roleKey)
    //                     .collection('members')
    //                     .doc(newMemberKey).ref;
    //  batch.set(roleMemberRef,newMemberData) ;
     // INSERT IN LOOK UP TAB MEMBER-ROLE
     // const lookUpRoleMemberRef = this.afs.collection('lookUpRoleMember')
     //                            .doc(roleKey).ref;
     // batch.set(lookUpRoleMemberRef, {[newMemberKey]:true}) ;
     // INSERT MEMBER AS ARTIST IN INSTRUMENT AND AGENT NODE
     // if(roleKey=="1") {
      // if(nbInstrument) {
      //   for( var i = 0;i<nbInstrument;i++) {
      //      var keyInstru = newMemberForm.value.selectedInstrus[i].$key;
      //      const artistInInstruRef = this.afs.collection('instrument')
      //                               .doc(keyInstru)
      //                               .collection('members')
      //                               .doc(newMemberKey).ref;
      //      const artistInstruLookUpathRef = this.afs.collection('lookUpInstrumentMembers')
      //                                       .doc(keyInstru).ref;
      //     batch.set( artistInInstruRef,newMemberData) ;
      //     batch.set( artistInstruLookUpathRef, {[newMemberKey]:true}) ;
      //   }
      // }
    if(roleKey=="1") {
      if(nbAgent) 
      {
        for(var i=0;i<nbAgent;i++) {
          var keyagent = newMemberForm.value.selectedAgents[i].$key;
            const artistInAgentRef = this.afs.collection('members')
                                    .doc(keyagent)
                                    .collection('members')
                                    .doc(newMemberKey).ref;
            batch.set( artistInAgentRef,newMemberData) ;
        }
      }
    }
    batch.commit()
    .then(function() {
      console.log('Batch Commited')
    })
    .catch(function(err) {
      console.error(err);
    });
    this.logClass.createLog(this.afs,'createMember',newMemberKey);


    if(!createandbind) { this.router.navigate(['/member/'+newMemberKey]); }
    return newMemberKey ;

  }


  addInstruTableInMember(member) {
    var batch = this.afs.firestore.batch();
    var memberkey          = member.$key?member.$key:null;
    var instrument         = member.instrument?member.instrument : null;
    var updateField        = {} ;
    var table              = [];
    table.push(instrument);
    updateField['instruments'] = table;
    const memberRef = this.afs.collection('members').doc(memberkey ).ref;
    batch.update(memberRef, updateField);
    batch.commit()
    .then(function() {
      console.log('Batch Commited')
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs,'addInstruTableInMember',memberkey,'instruments');
  }






 


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////// D E L E T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


 removeArtistInMember(member,artist) {
    const agentKey            = member.$key;
    const userKey             = member.userId;
    const artistKey           = artist.$key;
    const batch               = this.afs.firestore.batch();
    const updateField         = {} ;
    const updateFieldAgent    = {} ;
    const artistRef           = this.afs.collection('members').doc(agentKey)
                                        .collection('artists').doc(artistKey ).ref;
    const agentRef            = this.afs.collection('members').doc(artistKey)
                                        .collection('agents').doc(agentKey).ref;
    const rootAgentRef        = this.afs.collection('members').doc(agentKey).ref;
    const rootArtistRef       = this.afs.collection('members').doc(artistKey).ref;
    const userRef             = this.afs.collection('users').doc(userKey).ref;
    const artistkeys          = member.artistkeys;
    const agentkeys           = artist.agentkeys;
  
    var indexArtist;
    var indexAgent;
    indexArtist               = artistkeys.indexOf(artistKey);
    indexAgent                = agentkeys.indexOf(agentKey);
    artistkeys.splice(indexArtist,1); 
    agentkeys.splice(indexAgent,1);  
    updateField['artistkeys'] = artistkeys ;
    updateFieldAgent['agentkeys'] = agentkeys ;  

    batch.delete(artistRef );
    batch.delete(agentRef );
    batch.set(rootAgentRef, updateField,{merge:true});
    // batch.set(rootArtistRef, updateFieldAgent,{merge:true});
    batch.set(rootArtistRef, updateFieldAgent,{merge:true});
    batch.set(userRef,updateField,{merge:true});
   // batch.update( rootArtistRef, {['agentkeys']: firebase.firestore.FieldValue.delete()}); 
    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)});
    this.logClass.createLog(this.afs,'removeArtistInMember',artistKey);
  }



  removeUser(agent,artist) {
    if(agent.artistkeys.length === 1 ) {
        this.unbindUser(agent,artist);
        this.removeArtistInMember(agent,artist) ;

    } else {
        this.removeArtistInMember(agent,artist) ;
    }
  }



  unbindUser(agent,artist) {
    const batch   = this.afs.firestore.batch();
    const userkey  = agent.userId;
    const agentkey = agent.$key ;
    const updateFieldUser   = {} ;
    const updateFieldAgent   = {} ;
    updateFieldUser['role'] = 'pending';
    updateFieldAgent['ismember'] = false;
    const userRef = this.afs.collection('users').doc(userkey).ref;
    const agentRef = this.afs.collection('members').doc(agentkey).ref;
    batch.update( userRef, {['memberkey']: firebase.default.firestore.FieldValue.delete()});
    batch.update( agentRef, {['userId']: firebase.default.firestore.FieldValue.delete()});
    batch.set(userRef,updateFieldUser,{merge:true}) ; 
    batch.set(agentRef,updateFieldAgent,{merge:true}) ; 
    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err) { console.log(err)});
    this.logClass.createLog(this.afs,'unbindUser',userkey);
  }



  removeMediaFromArtist(media,memberkey) {
    var mediakey = media.$key;
    const batch = this.afs.firestore.batch();
    
    const mediaCollectionRef = this.afs.collection('medias').doc(mediakey).ref;                                                                         
    const mediaInMemberCollectionRef = this.afs.collection('members')
                                        .doc(memberkey).collection('media')
                                        .doc(mediakey).ref;
    batch.delete(mediaCollectionRef);
    batch.delete(mediaInMemberCollectionRef);
     
    batch.commit()
    .then(function() {console.log('Batch Commited')})
    .catch(function(err) {console.error(err);});

    this.logClass.createLog(this.afs,'removeMediaFromArtist',mediakey);
  }



  deleteMember(member): void {
    console.log(member);
    const db        = this.afs.firestore;
    const batch     = this.afs.firestore.batch();
    var memberkey   = member.$key
    const memberRef = this.memberCollection.doc(memberkey).ref;
    batch.delete( memberRef);
    batch.commit()
    .then(function() {
      console.log('Data delete')
    })
    .catch(function(err) {
      console.error(err);
    });

    this.logClass.createLog(this.afs,'deleteMember',memberkey);

    this.router.navigate(['/dashboard']);
  }



  removeInstruInMember(member){

    const memberKey            = member.$key;
    var instrument             = member.instrument?member.instrument : null;
    const batch                = this.afs.firestore.batch();
    const memberRef            = this.afs.collection('members').doc(memberKey).ref;   
    const updateField          = {} ; 
    var table                  = [];
    table.push(instrument);
    updateField['instruments'] = table; 
    batch.update( memberRef, {['instruments ']: firebase.default.firestore.FieldValue.delete()}); 
    batch.update(memberRef, updateField); 

    batch.commit().then(function() {console.log('Batch Delete')}).catch(function(err){ console.log(err)}); 
    this.logClass.createLog(this.afs,'removeInstruInMember',memberKey);
   
  }










////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////// U P D A T E ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


  addArtist(artist, agent, batch) {
    const artistKey = artist.$key;
    const agentKey  = agent.$key;
    const agentUserId = agent.userId;
    const updateArtistField = {} ;
    const setArtistField = {} ;
    const artistKeys = agent.artistkeys ? agent.artistkeys :[] ;
    const agentKeys = artist.agentkeys ? artist.agentkeys : [] ;
    if(artistKeys.indexOf(artistKey) === -1) {
      artistKeys.push(artistKey);
      updateArtistField['artistkeys']  = artistKeys;
    }
    if(agentKeys.indexOf(agentKey) === -1) {
         agentKeys.push(agentKey);
         setArtistField['agentkeys']  = agentKeys;
    }
    const agentRef = this.afs.collection('members').doc(agentKey).collection('artists').doc(artistKey).ref;
    const rootAgentRef = this.afs.collection('members').doc(agentKey).ref;
    const rootArtistRef = this.afs.collection('members').doc(artistKey).ref;
    const userRef = this.afs.collection('users').doc(agentUserId).ref;
    const artistRef = this.afs.collection('members').doc(artistKey).collection('agents').doc(agentKey).ref;
    batch.set(agentRef, artist, {merge:true});
    batch.set(rootArtistRef,setArtistField, {merge:true});
    batch.set(artistRef, agent, {merge:true});
    batch.set(rootAgentRef,updateArtistField , {merge:true});
    batch.set(userRef,updateArtistField , {merge:true});
    this.logClass.createLog(this.afs,'addArtist',artistKey);
  }



  addOneArtistInAgentCollection(artist, agent) {
    const batch   = this.afs.firestore.batch();
    this.addArtist(artist, agent, batch);
    batch.commit().then(function() {console.log('Batch Commited'); });
  }

  addAllartistInAgentCollection(artists, agent) {
    const batch   = this.afs.firestore.batch();
    for(let i = 0; i < artists.length ; i++) {
      this.addArtist(artists[i], agent, batch);
   }
   batch.commit().then(function() {console.log('Batch Commited: All data inserted'); });
  }



  isMember(member) {
    var batch               = this.afs.firestore.batch();
    var memberkey           = member.$key?member.$key:null;
    var instrument          = member.instrument?member.instrument : null;
    var updateField         = {} ;
    var table               = [];
    table.push(instrument);
    // updateField['instruments'] = table;
    updateField['ismember'] = false;
    const memberRef         = this.afs.collection('members').doc(memberkey ).ref;
    batch.set(memberRef, updateField,{merge:true});
    batch.commit()
    .then(function() {
      console.log('Batch Commited')
    })
    .catch(function(err) {
      console.error(err);
    });
    this.logClass.createLog(this.afs,'isMember',memberkey,'ismember' );
  }

  getMembersIsNotUserList() {
    return this.afs.collection('members', ref => ref.where('ismember','==', false)).snapshotChanges().pipe(map(arr => {
      return arr.map(snap => Object.assign(
        snap.payload.doc.data(),{ $key: snap.payload.doc.id }) )
    }));
  }

  getAgentsIsNotUserList() {
    return this.afs.collection('members', ref => ref.where('role','==','Agent').where('ismember','==', false)).snapshotChanges().pipe(map(arr => {
      return arr.map(snap => Object.assign(
        snap.payload.doc.data(),{ $key: snap.payload.doc.id }) )
    }));
  }


  bindUserMember(member, user) {
    const batch   = this.afs.firestore.batch();
    const memberKey = member.$key;
    const userKey  = user.$key;
    const memberRef = this.afs.collection('members').doc(memberKey).ref;
    const usertRef = this.afs.collection('users').doc(userKey).ref;
    const updateUserField   = {} ;
    const updateMemberField = {} ;
    updateUserField['memberkey'] = memberKey;
    updateUserField['memberfullname'] = `${member.firstname} ${member.lastname}`;
    updateUserField['role'] = member.role.toLowerCase();
    updateMemberField['userId']  = userKey;
    updateMemberField['ismember']  = true;
    batch.set(usertRef, updateUserField, {merge:true});
    batch.set(memberRef,updateMemberField, {merge:true});
    batch.commit().then(function() {console.log('Batch Commited'); });
    this.logClass.createLog(this.afs,'bindUserMember',userKey);
  }




  // UPDATE MEMBER FIELD
  updateMember(member, field, value) {
    const batch   = this.afs.firestore.batch();
    const db      = this.afs.firestore;
    var memberKey = member.$key;
    var agentKey  = member.role.agents?member.role.agents.key:0;
    var artistKey = member.role.artists?member.role.artists.key:0;
    var roleKey   = member.role.key;
    var instruKey = member.instrument?member.instrument.key:0;
    var instrument = member.instrument?member.instrument:0;
    var table     = [instrument];
    // console.log(instrument);
    console.log(value);

    const memberRef        = db.collection('members').doc(memberKey);
    // const memberInRoleRef  = db.collection('role').doc(roleKey).collection('members').doc(memberKey);
    // const artistInAgentPath     = `${this.memberPath}/${agentKey}/role/artists/${memberKey}/${field}`;
    // const artistInAgentRolePath = `${this.rolePath}/3/members/${agentKey}/role/artists/${memberKey}/${field}`;
    // const artistInInstruPath    = `${this.instrumentPath}/${instruKey}/members/${memberKey}/${field}`;
    if(field === 'instruments') {
      table.push(value);
      value = table;
    }
    console.log(value);
    batch.update(memberRef, `${field}`, value);
    // batch.update(memberInRoleRef, `${field}`, value);
    // IF ARTIST
    if(roleKey=='1')
    {
      // Update Member Field in Instruments Nodes
      var instruments = member.instruments?member.instruments:0;
      if(instruments) {
        // tslint:disable-next-line:forin
        // for (var instrukey in instruments) {
        //   const memberInInstruRef  = db.collection('instrument').doc(instrukey).collection('members').doc(memberKey);
        //   batch.update(memberInInstruRef,`${field}`, value);
        // }
      }
      // Update Member Field in Agents Nodes
      var agents = member.agents?member.agents:0;
      if(agents) {
        // tslint:disable-next-line:forin
        for (var agentkey in agents) {
           const  memberInAgentRef  = db.collection('member').doc(agentkey).collection('members').doc(memberKey);
           batch.update(memberInAgentRef,`${field}`, value);
        }
      }
    }
     batch.commit().then(function() {console.log('Batch Commited')});
     this.logClass.createLog(this.afs,'updateMember',memberKey,field);
  }







  // ADD PICTURE FILE TO MEMBER
  addImageFileToMember(member, type, credit, url, path:number) {

    const db                  = this.afs.firestore;
    const batch               = this.afs.firestore.batch();
    const memberImgCollection = this.afs.collection('members').doc(member.$key).ref;
    const uploadRef           = this.afs.firestore.collection('uploads').doc();
    const uploadkey           = uploadRef.id;

    var emptypath =  path ? path : 1;
    if(emptypath<5) { var nextemptypath = emptypath + 1; }
    else { var nextemptypath=1; }

    const newUploadFile       = {};
    newUploadFile['type']     = 'Album Cover';
    newUploadFile['url']      = url;
    newUploadFile['uploadkey']= uploadkey;
    newUploadFile['timestamp']= Date.now();;    

    batch.set(uploadRef, newUploadFile);

    if(type=='carousel') {
      var urlPath             =  emptypath && url ? url : null;
      var urlImgCredit        =  credit ? credit : null;

      batch.update(memberImgCollection,`imgcarrousel${emptypath}`, urlPath);
      batch.update(memberImgCollection,`imgcarrousel${emptypath}credit`, urlImgCredit);
      batch.update(memberImgCollection,`imgcarouempty`, nextemptypath);
    }
    if(type=='profile') {
        batch.update(memberImgCollection,`profilepic`, url);
    }  
    if(type) {
        batch.commit().then(function() {console.log('image uploaded')})
        .catch(function(err) {console.error(err);});

        this.logClass.createLog(this.afs,'addImageFileToMember', member.$key);
    }
    this.router.navigate(['./../../member/' + member.$key ]);
  }

  // ADD PICTURE FILE TO MEMBER
  addDiscFileToMember(member, name, link, url) {

    const db                  = this.afs.firestore;
    const batch               = this.afs.firestore.batch();
    const memberImgCollection = this.afs.collection('members').doc(member.$key).ref;

    const uploadRef           = this.afs.firestore.collection('uploads').doc();
    const uploadkey           = uploadRef.id;

    const newDiskData         = {};
    newDiskData['link']       = link;
    newDiskData['srce']       = 'Album Cover';
    newDiskData['name']        = name;
    newDiskData['url']        = url;
    newDiskData['uploadkey']  = uploadkey;
    newDiskData['timestamp']  = Date.now();

    batch.set(uploadRef, newDiskData);
    batch.update(memberImgCollection,`discographie.${uploadkey}`, newDiskData);
    batch.commit().then(function() {console.log('image uploaded')}).catch(function(err) {console.error(err);});
    this.logClass.createLog(this.afs,'addDiscFileToMember', member.$key);
    this.router.navigate(['./../../member/' + member.$key ]);
  }








  // REMOVE CAROUSEL PICTURE
  removeCarouselPictureWithLegend(member,nb,path) {
    console.log(member,nb);

    const memberkey = member.$key;

    const batch = this.afs.firestore.batch();
    
    const memberCollectionRef = this.afs.collection('members').doc(memberkey).ref;                                                                         

    for(var i=nb;i<=5;i++)
    // for(var i=0;i<=44;i++)
    {
      batch.update(memberCollectionRef, {['imgcarrousel' + i] : null}); 
      batch.update(memberCollectionRef, {['imgcarrousel' + i + 'credit'] : null}); 
    }
    batch.update(memberCollectionRef,`imgcarouempty`, nb);     
    
    batch.commit()
    .then(function() {console.log('Batch Commited')})
    .catch(function(err) {console.error(err);});

    this.logClass.createLog(this.afs,'removeCarouselPictureWithLegend', memberkey);

  }


//// RTDB




  // Default error handling for all actions
  private handleError(error) {
    console.log(error)
  }




}
