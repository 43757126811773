<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<h1>LOGS LIST</h1>

<div [ngStyle]="{'padding':'50px 10px'}">

    <mat-form-field>
        <mat-label> Filter</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" >
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Color Column -->
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> {{ row.date | date  }} {{ row.date | date : "HH:mm:ss"}} </td>
        </ng-container>
        
        <ng-container matColumnDef="userdisplayname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
            <td mat-cell *matCellDef="let row"> {{row.userdisplayname}} </td>
        </ng-container>
        <!-- Progress Column -->
        <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Function </th>
            <td mat-cell *matCellDef="let row"> {{row.function}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="idcollection">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Document </th>
            <td mat-cell *matCellDef="let row"> {{row.idcollection}} </td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> URL </th>
            <td mat-cell *matCellDef="let row">
                <a href="{{ row.url  }}"> {{ row.url  }}</a> </td>
        </ng-container>        

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>