<!-- <pre>{{ artist | async | json  }}</pre> -->

<img src="https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_kajimoto_yellow_blue_png_1523548360314.jpg?alt=media&token=a93f1f55-8d9e-438a-93ba-c2c8c4a50a2e" class="logo-bo logo-bo-xs">

<!-- <mat-card *ngIf="dev"> -->
<mat-card appearance="outlined" *ngIf="artist | async as artist">

	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<span>
				<mat-icon matListIcon>video_library</mat-icon>
			</span>					
		</div>	
		<mat-card-title><strong>{{ artist.firstname }} {{ artist.lastname }}</strong> </mat-card-title>
		<mat-card-subtitle>Ajouter un media Youtube à l'artiste {{ artist.fullname}}<br>
			<!-- <span class="keystyle">{{ artist.$key }}</span> -->
		</mat-card-subtitle>
	</mat-card-header>


	<form #newMediaForm="ngForm" (ngSubmit)="onSubmit(newMediaForm)">

	<mat-card-content>

		<input style="display:none" #fullname="ngModel" name="fullname" ngModel="{{artist.fullname}}">

		<mat-form-field class="full-width">
			<mat-label> Media Title </mat-label>
			<input matInput required
				ngModel #titlemedia="ngModel"
				name="titlemedia">
		</mat-form-field>

		<br>

		<mat-form-field class="full-width">
			<mat-label> Youtube Link </mat-label>
			<input matInput required
				ngModel #youtubelinkMedia="ngModel"
				name="youtubelinkMedia">
		</mat-form-field>

		<br>

	</mat-card-content>


	<mat-card-actions>

		<button mat-fab (click)="goBack()" type="button" color="black" class="ml-15">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>

		<button mat-fab type="submit" color="warn" class="ml-15"
			[disabled]="!newMediaForm.form.valid">
			<mat-icon>save</mat-icon>
		</button>

	</mat-card-actions>	

	</form>

	<br><br>
	
	<iframe id="videoObject" type="text/html" width="500" height="265" frameborder="0" allowfullscreen></iframe>


  <mat-grid-list *ngIf="artist.arrmedia" cols="4" rowHeight="1:1">
    <mat-grid-tile class="arist-discog"
      *ngFor="let media of artist.arrmedia">
      <mat-grid-tile-header>
	      <mat-icon (click)='deleteMedia(media,artist.$key)'>clear</mat-icon>    	
      </mat-grid-tile-header>
      <a href="{{ media.link }}" target="_blank">
        <img src="{{ media.url }}" class="responsiveimg discog-img">
      </a>
    </mat-grid-tile>
  </mat-grid-list> 
</mat-card>






