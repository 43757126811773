import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberService } from './../shared/member.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Dialog } from '@angular/cdk/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  styleUrls: ['./confirmation-dialog.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public title: string = "";
  public informations: string = "";
  public confirmButton: string = "";
  public cancelButton: string = "";
  members: Observable<any[]>;
  public member : any;

  constructor(private location :Location,
      public dialogRef : MatDialogRef<ConfirmationDialogComponent>,
      @Inject(MAT_DIALOG_DATA)
        public data: {informations: string, 
                      confirmButton: string,
                      cancelButton: string,
                      title: string,
                      membe: any},
    private memberService: MemberService) {
      this.members = this.memberService.getMembersList();
    }

  ngOnInit(): void {
    this.informations = this.data.informations ?? "";
    this.confirmButton = this.data.confirmButton ?? "";
    this.cancelButton = this.data.cancelButton ?? "";
    this.title = this.data.title ?? "";
    this.member = this.data.membe ?? "";
  }


  deleteMember(member$) {
    this.memberService.deleteMember(member$); 
    this.closeDialog();
  }

  closeDialog(){
    this.dialogRef.close();
  }



}