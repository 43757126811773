<button mat-fab color="warn" class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInEn" 
	(click)="
		txtInFr=true;txtInEn=false;
		titleInFr=true;titleInEn=false
		">
  FR
</button>

<button mat-fab color="warn" class="button-lang button-lang-xs"
  [ngStyle.xs]="{ 'top':'0px','left':'0px' }"
	*ngIf="txtInFr" 
	(click)="
		txtInFr=false;txtInEn=true;
		titleInFr=false;titleInEn=true
		">
  EN
</button>

<button mat-fab 
  *ngIf="(auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent'" 
  (click)="addContactForm = !addContactForm"
  class="edit-button edit-button-xs" color="black">  
  <mat-icon *ngIf="!addContactForm">edit</mat-icon>
  <mat-icon *ngIf="addContactForm">close</mat-icon>
</button>

<div class="content fx-row fx-wrap fx-col-xs">
    <div class="sec1 fx-col hide-mobile fx-10">
    		<h1 class="grey">
    			<span class="yellow">C</span>o<span class="yellow">n</span>t<span class="yellow">a</span>c<span class="yellow">t</span>s
    		</h1>
    </div>
    <div class="sec2 contacts fx-row fx-wrap fx-start-start fx-80">
      <div class="fx-100 contact-header-xs fx-col fx-wrap-xs" 
        [ngStyle]="{
                        'padding-top': '15px',
                        'padding-left': '30px'
                      }">
        <h2 (click)="seeContacts('JP')" 
          [ngClass]="{'office-selected':officeSelected=='JP'}">Contact <span>Tokyo</span></h2>
        <h2 (click)="seeContacts('FR')"
          [ngClass]="{'office-selected':officeSelected=='FR'}">Contact <span>Paris</span></h2>
        <h2 (click)="seeContacts('CH')"
          [ngClass]="{'office-selected':officeSelected=='CH'}">Contact <span>Beijing</span></h2>
      </div>

      <div *ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addContactForm" class="fx-100" class="fx-col" class="contacform">
        <form class="fx-col" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)">
          <h4>Create new contact for an office</h4>
          <mat-radio-group required ngModel name="pickedOffice">
            <mat-radio-button class="office-radio" color="warn"
              *ngFor="let office of contactOffices" 
              [value]="office">
              {{office.title}}
            </mat-radio-button>
          </mat-radio-group>            
          <div class="fx-row fx-wrap fx-gap-5">          
            <mat-form-field>
              <mat-label> Full Name </mat-label>
              <input matInput required
                ngModel #fullname="ngModel"
                name="fullname">
            </mat-form-field> 
            <mat-form-field>
              <mat-label> Full Name - Japon </mat-label>
              <input matInput
                ngModel #jap="ngModel"
                name="jap">
            </mat-form-field>
            <mat-form-field>
              <mat-label> Job position </mat-label>
              <input matInput required
                ngModel #position="ngModel"
                name="position">
            </mat-form-field>                      
            <mat-form-field>
              <mat-label> Phone </mat-label>
              <input matInput
                ngModel #phone="ngModel"
                name="phone">
            </mat-form-field> 
            <mat-form-field>
              <mat-label> Email </mat-label>
              <input matInput
                ngModel #email="ngModel"
                name="email">
            </mat-form-field>
            <button mat-fab type="submit" color="warn"
              [disabled]="!contactForm.form.valid">
              <mat-icon>save</mat-icon>
            </button>              
          </div>        
        </form>                             
      </div>

      <div *ngIf="contactsShown | async as contactsShown" class="fx-100 fx-row fx-wrap fx-start-start">
        <div *ngFor="let contact of contactsShown" class="fx-25 fx-45-xs fx-col contact-cell contact-cell-xs"
          fxFlex.xl="20">
            <span>
              <strong class="fx-80">{{ contact.fullname }}</strong>
              <button mat-icon-button
                *ngIf="((auth.user | async)?.role=='admin'||(auth.user | async)?.role=='agent')&&addContactForm" 
                (click)="deleteContact(contact)" color="warn">  
                <mat-icon>delete</mat-icon>
              </button>
            </span>
            <span *ngIf="contact.jap">{{ contact.jap }}</span>
            <span *ngIf="contact.position" >{{ contact.position }}</span>
            <span *ngIf="contact.phone"><a class="pm-link pm-link-xs" href="tel:+{{ contact.phone }}"><span>{{ contact.phone }}</span></a></span>
            <span *ngIf="contact.email"><a class="pm-link pm-link-xs" href="mailto:{{contact.email}}">
              <span>
                {{ 
                  (contact.email.indexOf('@')!=0) ? 
                  (contact.email.replace('@','[at]')) : contact.email
                }}
                </span>
              </a>
            </span>    
        </div>
      </div>


      <div class="fx-100">
        <br><br><br><br><br>
      </div>
    </div>
    <div class=" fx-ai-end fx-10 hide-mobile"
      [ngStyle]="{'padding-top': '185px'}">
       <img src="{{ imglogourl }}" alt="{{ imglogoname.substring(0, imglogoname.indexOf('.')) }}">
    </div>
</div>
 
 <br><br>

<footer-nav></footer-nav>   

