import { Component, OnInit, Inject, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { trigger,style,transition,animate,keyframes,query,stagger, state } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MemberService } from '../shared/member.service';
import { EventService } from '../shared/event.service';
import { PublicService } from '../shared/public.service';
import { Title, Meta } from '@angular/platform-browser';

import { DOCUMENT } from '@angular/common';
import { timer } from 'rxjs';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss'],
  animations: [
    trigger('artist', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), {optional: true}),
        query(':enter', stagger('300ms', [
          animate('.6s ease-in', keyframes([
            style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
          ]))]), {optional: true}),
        query(':leave', stagger('300ms', [
          animate('.6s ease-out', keyframes([
            style({opacity: 1, transform: 'translateY(0)', offset: 0}),
            style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
            style({opacity: 0, transform: 'translateY(-75%)',     offset: 1.0}),
          ]))]), {optional: true})
      ])
    ]),
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ])

  ]     
})
export class ArtistComponent implements OnInit, OnDestroy, AfterViewInit {
  
  id: string;
	artist:any; 
  artistkey: string;
  dev:boolean=false;
  events:any;
  press:any;
  news:any;
  videos:any;
  imglogourl:string;imglogostring:string;imglogopiano:string;imglogocomposer:string;

  checkkey: string;

  darkClass: string; lightClass: string; linkClass: string; hoverClass: string;bgClass: string;

  txtInEn:boolean= true;
  txtInFr:boolean= false;
  titleInFr:boolean=false;
  titleInEn:boolean=true;

  firstname: string;
  lastname: string;
  key: string;

  showimg = [true, false, false, false, false, false, false, false, false, false];
  eventOnScrollOneTime:boolean=true;

  butmorepress: boolean=true;
  butlesspress: boolean=false;

  butmorenews: boolean=true;
  butlessnews: boolean=false;

  butmoreevents: boolean=true;
  butlessevents: boolean=false;

  cutbioFRonce: boolean;
  cutbioENonce: boolean;
  biofr : string;
  bioen : string;

  nbpress: number = 3;
  nbnews: number = 2;
  nbevents: number = 4;


  imgs = [];
  imagesContainer = [];
  imageToDisplay = [];

  slideSubscription: Subscription = null;
  slideShown = 1;
  nbslides:number;

  menuForMobileVersion = {
    'padding': '10px',
    'font-size': '11pt',
    'display': 'inline-block'
  };

  menuForMobileVersionUL = {

  };

  constructor(  	
    private titleService: Title,
    private meta: Meta,
  	private memberService: MemberService,
    private eventService: EventService,
  	private route: ActivatedRoute,
    public  auth: AuthService, 
    private router: Router,
  ) 
  {
    console.log("Ok ok")
      this.cutbioFRonce = true;
      this.cutbioENonce = true;    
      this.biofr = '';
      this.biofr = '';
      this.id            = this.route.snapshot.paramMap.get('id');
      this.firstname     = this.id.toString().split('_')[0];
      this.lastname      = this.id.toString().split('_')[1];
      var indexOfFirst   = this.id.indexOf('_');

      if (indexOfFirst === -1) {
        this.generateTagsAndGetData(this.id);
        if (this.id.length === 20) {
          this.generateTagsAndGetData(this.id);
        } else {
           this.router.navigate(['**']);
        }
      } else {
          this.memberService.getMemberKeyInUrl(this.firstname, this.lastname).subscribe((key) => {
          if (key.length !== 0) {
            this.key = key[0].$key ;
            this.generateTagsAndGetData(this.key);
          } else {
            this.router.navigate(['**']);
        }});
      }
      
      // charger conteneur image
    for (let index = 0; index < 10; index++) {
      this.imagesContainer[index] = new Image();
    }
  }



  ngOnInit() {

    this.imglogourl      = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogo-Kajimoto-Vertical-Conductor.png?alt=media&token=00143197-e0f2-4804-a6f6-30ce0a12d742';        
    this.imglogostring   = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2FLogo-Kajimoto-Vertical-Strings.png?alt=media&token=fd6b9887-e20b-4cb1-b7b2-5f750a5bb244';
    this.imglogopiano    = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_Kajimoto_Piano_png_1523564203571.jpg?alt=media&token=642e5e9f-795e-42a3-a6fb-8b32fc10bd3f';
    this.imglogocomposer = 'https://firebasestorage.googleapis.com/v0/b/kajimotomusic-prod.appspot.com/o/uploads%2Flogo_Kajimoto_Composer_png_1523565706911.jpg?alt=media&token=43b4a3e8-a70e-43f1-bfd8-e16da8bc4112';
    setTimeout(() => this.slideFunction(), 2500);
  }

  ngOnDestroy() {
    this.slideSubscription.unsubscribe();
  }

  getInstrumentWithArtistkey(artistkey) {

  }

  generateTagsAndGetData(key) {

    this.memberService.getArtistWithKey(key).subscribe((data) => {
      this.setTitle(`Kajimoto Music Paris | ${data.lastname} ${data.firstname}`);

      this.meta.addTags([
        { name: 'twitter:card', content: 'summary' },
        { name: 'og:url', content: `https://kajimotomusicparis.com/artist/${data.lastname}_${data.firstname}` },
        { name: 'og:title', content: `Kajimoto Music | ${data.lastname} ${data.firstname}`},
        { name: 'og:description', content: `${data.lastname} ${data.firstname} News at Kajimoto Music Paris Office`}, 
        { name: 'og:image', content:  `${data.profilepic}`},
        { name: 'description', content: `${data.lastname} ${data.firstname} News at Kajimoto Music Paris Office`},
        { name: 'title', content: `Kajimoto Music | ${data.lastname} ${data.firstname}` },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: 'Kajimoto Music' },
        { name: 'twitter:title', content: `Kajimoto Music | ${data.lastname} ${data.firstname}` },
        // tslint:disable-next-line:max-line-length
        { name: 'twitter:description', content: `${data.lastname} ${data.firstname} News at Kajimoto Music Paris Office` },
        // tslint:disable-next-line:max-line-length
        { name: 'twitter:image', content:  `${data.profilepic}` },
        { name: 'twitter:url', content: `https://kajimotomusicparis.com/artist/${data.lastname}_${data.firstname}`},
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'msapplication-starturl', content: '/' },
        { name: 'theme-color', content: '#f48c5b' },
        { name : 'keywords',  content: `${data.lastname} ${data.firstname}`},
        { name : 'url',  content: `https://kajimotomusicparis.com/artist/${data.lastname}_${data.firstname}`}
      ]);
      this.instruClass(data.instrument.name);
    });
    this.artist    =    this.memberService.getArtistWithKey(key) as Observable<Artist>;
    this.artist.subscribe(data => {
      this.imgs = data.imgcarrousel;

      // Optimize displaying for low connection
      for (let index = 0; index < this.imgs.length; index++) {
        this.imagesContainer[index].scr = this.imgs[index];
        this.imageToDisplay[index] = this.imagesContainer[index].scr;
      }
      this.nbslides = this.imgs.length;
    });
    this.press     =    this.memberService.getPressInMember(key);
    this.news      =    this.memberService.getNewsInMember(key);
    this.events    =    this.eventService.getOnlineEventsWithMemberKeyAfterToday(key);
    this.videos    =    this.memberService.getMediaInMember(key);
    
  }

  setTitle( newTitle: string) { this.titleService.setTitle( newTitle ); }

  sendBioAndCutIfTooLong(s,l,lg) {

    if(this.cutbioFRonce || this.cutbioENonce) {
      
      var openTag = 0, closeTag = 0, i = 0;

      for(i; i<l;i++)
      {
        if (s[i] === '<') { openTag++; }
        if (s[i] === '>') { closeTag++; }
      }
      if (openTag > closeTag) {
        while (s[i] !== '>') { i++; }
      }

      if (lg === 'fr') {
        this.biofr = s.substring(0, (i + 1));
        this.cutbioFRonce = false;
        console.log( this.biofr.length, this.biofr);
      }
      if (lg === 'en') {
        this.bioen = s.substring(0, (i + 1));
        this.cutbioENonce = false;
        console.log( this.bioen.length, this.bioen);
      }

    }
  }

  checkIfArtistManagedByagent(artistkeys: any, agentkey): Boolean {
    artistkeys =  artistkeys ? artistkeys : ['no artist'];
     const compteur = artistkeys.indexOf(agentkey);
     if (compteur !== -1) {
       return true;
     } else {
       return false;
     }
  }

  lg(en, fr) {
    if (this.txtInEn) { return en; } else { return fr; }
  }

  instruClass(instru: string): void {
      switch (instru) {
        case 'cello':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-cello';
          break;
        case 'composer':
          this.darkClass  = 'compogrey';
          this.lightClass = 'compopink';
          this.linkClass  = 'compolink';
          this.hoverClass = 'compohover';
          this.bgClass    = 'bg-compo';
          break;
        case 'conductor':
          this.darkClass  = 'darkblue';
          this.lightClass = 'green2';
          this.linkClass  = 'comdu-link';
          this.hoverClass = 'hover-condu';
          this.bgClass    = 'bg-green2';
          break;
        case 'harp':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-violin';
          break;
        case 'piano':
          this.darkClass  = 'pianobrown';
          this.lightClass = 'pianoblue';
          this.linkClass  = 'pianolink';
          this.hoverClass = 'pianohover';
          this.bgClass    = 'bg-piano';
          break;
        case 'viola':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-viola';
          break;
        case 'violin':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-violin';
          break;
        case 'trio':
            this.darkClass  = 'marron';
            this.lightClass = 'bleuciel';
            this.linkClass  = 'cello-link';
            this.hoverClass = 'hover-cello';
            this.bgClass    = 'bg-violin';
          break;          
        case 'sheng':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-violin';
          break;
        case 'percussions':
          this.darkClass  = 'marron';
          this.lightClass = 'bleuciel';
          this.linkClass  = 'cello-link';
          this.hoverClass = 'hover-cello';
          this.bgClass    = 'bg-violin';
          break;
        case 'narrator':
          this.darkClass  = 'darkblue';
          this.lightClass = 'green2';
          this.linkClass  = 'comdu-link';
          this.hoverClass = 'hover-condu';
          this.bgClass    = 'bg-green2';
          break;          
        default:
          console.log('No Instru Detected');
          this.darkClass  = 'black';
          this.lightClass = 'grey';
          this.linkClass  = 'blue';
          this.bgClass    = 'black';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.scrollY > 95) {
      let element = document.getElementById('nav-bar');
      if(element !== null && typeof element !== 'undefined') {
        element.classList.add('is-sticky-top', 'is-sticky-top-for-mobile');
      } else {
        console.log('oui oui oui')
      }
      
    } else {
      let element = document.getElementById('nav-bar');
      element.classList.remove('is-sticky-top', 'is-sticky-top-for-mobile');
    }
     
  }

  // Hide image carousel except image carousel selected
  putShowToFalse(number) {
    number--;
    for (let index = 0; index < this.imgs.length; index++) {
      if(index !== number) {
        this.showimg[index] = false;
      } else {
        this.showimg[index] = true;
      }
    }
  }

  slideFunction() {
    const numbers = timer(8000, 8000);
    this.slideSubscription = numbers.subscribe(x => {
      this.slideShown = (this.slideShown + 1 <= this.nbslides) ? (this.slideShown + 1) : 1;
      switch (this.slideShown) {
        case 1: this.putShowToFalse(1); break;
        case 2: this.putShowToFalse(2); break;
        case 3: this.putShowToFalse(3); break;
        case 4: this.putShowToFalse(4); break;
        case 5: this.putShowToFalse(5); break;
        case 6: this.putShowToFalse(6); break;
        case 7: this.putShowToFalse(7); break;
        case 8: this.putShowToFalse(8); break;
        case 9: this.putShowToFalse(9); break;
        case 10: this.putShowToFalse(10); break;
        default: break;
      }
    });
  }

  changeSlide(s, t) {
    console.log('Change Slide : ', s , ' / ', t)
    this.slideShown = (s <= t) ? s : 1;
    switch (this.slideShown) {
      case 1: this.putShowToFalse(1); break;
      case 2: this.putShowToFalse(2); break;
      case 3: this.putShowToFalse(3); break;
      case 4: this.putShowToFalse(4); break;
      case 5: this.putShowToFalse(5); break;
      case 6: this.putShowToFalse(6); break;
      case 7: this.putShowToFalse(7); break;
      case 8: this.putShowToFalse(8); break;
      case 9: this.putShowToFalse(9); break;
      case 10: this.putShowToFalse(10); break;
      default: break;
    }

    this.slideSubscription.unsubscribe();
    this.slideFunction();
  }

  ngAfterViewInit() {
    
  }

  // Click event handler function for Scrolling
  scrollToDiv(target:HTMLElement){
    
    // Scroll to the target element smoothly
    target.scrollIntoView({behavior: "smooth", block: "start"});
  }

  scrollToTop(){
    scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

}

class Artist {
  firstname: string;
  lastname: string;
  agentkeys: string[];
  $key: string;
  profilepic: string;
  imgcarrousel1: string;
  imgcarrousel2: string;
  imgcarrousel?: string[]
  imgcarrouselcredit?: string[];
  hireslink: string;
  bioshorthtmlen: string;
  bioshorthtmlfr: string;
  website: string;
  ismedia: boolean;
  isevent: boolean;
  isnews: boolean;
  ispress: boolean;
  isdisco: boolean;
  arrdiscog: Discographie[];
  kajimotomanagementrepresentation: string;
  kajimotomanagementtokyoname: string;
  kajimotomanagementtokyoemail: string;
  kajimotomanagementemail: string;
  kajimotomanagementphoneoffice: string;
  kajimotomanagementphonemobile: string;
  kajimotomanagementbeijingemail: string;
  generalmanagementname: string;
  instrument: Instrument | string;

}

class Discographie {
  link: string;
  name: string;
  updateload: string;
  url: string;
}

class Instrument {
  nom: string;
  name: string;
  categorie: string;
  category: string;
  $key?: string;
}


