import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { EmailValidator } from '@angular/forms';
import { InstrumentService } from './../../instruments/shared/instrument.service';
import { MemberService } from './../shared/member.service';

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})
export class MemberFormComponent implements OnInit {

  pickedMemberRole: string;
  memberRole: any;
  pickedMemberInstrument: string;
  memberInstrument: any;
  agents: any;
  showInstrumentList = false;
  bioFormInput = false;
  butVisOn = true;
  butVisOff = false;
  instruments: any;
  instrumentsCtrl = new UntypedFormControl();
  agentsCtrl = new UntypedFormControl();

  roles = [
    {$key: 1, key: 1, title: 'Artist'},
    {$key: 2, key: 2, title: 'Trainee'},
    {$key: 3, key: 3, title: 'Agent'}
  ];

  // instrument = [
  //   {$key: 1, key: 1, title: 'Piano',     category: 'Piano'}, 
  //   {$key: 2, key: 2, title: 'Violin',    category: 'Instrumentalist'},
  //   {$key: 3, key: 3, title: 'Conductor', category: 'Direction'},
  //   {$key: 5, key: 5, title: 'Viola',     category: 'Instrumentalist'},
  //   {$key: 6, key: 6, title: 'Cello',     category: 'Instrumentalist'},
  //   {$key: 13, key: 13, title: 'Composer', category: 'Compositeur'},
  //   {$key: 7, key: 7, title: 'Harp',      category: 'Instrumentalist'}
  // ];

  constructor(
        private location: Location,
        private instruService: InstrumentService,
        private memberService: MemberService) {
          this.memberRole = this.roles;
          // this.memberInstrument = this.instrument;
          this.agents = this.memberService.getAgentsList();
  }

  ngOnInit() {
    this.instruments = this.instruService.getInstruments();
  }

  onSubmit(newMemberForm: NgForm) {
    this.memberService.createMember(newMemberForm);
  }

  goBack(): void {
    this.location.back();
  }

  activateInstrument(roleKey: number) {
    if (roleKey === 1) { this.showInstrumentList = true; } else { this.showInstrumentList = false; }
  }

}
