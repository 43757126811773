import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberService } from './../shared/member.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog';
@Component({
  selector: 'app-members-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

  members: Observable<any[]>;
  memb : any;

  constructor(private memberService: MemberService,
              private matDialog : MatDialog) 
  {
    this.members = this.memberService.getMembersList();
  }

  ngOnInit() {
  }

  updateField(member,field,value) {
    this.memberService.updateMember(member,field,value);
  }

  deleteMember(member) {
    this.memberService.deleteMember(member);
    this.memb = member;
    console.log(this.memb);
  }

  addInstruInMember(member){
    this.memberService.addInstruTableInMember(member); 
  }

  isMember(member){
      this.memberService.isMember(member); 
  }

  openConfirmationDialog(member): void {
    let title = "Attention";
    let informations = "Confirmer la suppression";
    let confirmButton = "Confirmer";
    let cancelButton = "Annuler";
    let membe = member;
    const dialogRef = this.matDialog.open( ConfirmationDialogComponent, {
      width: '350px',
      data: {
        title,
        informations,
        confirmButton,
        cancelButton,
        membe,
      },
    });
  }


}
