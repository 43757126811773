import { Component, OnInit } from '@angular/core';
import { EventService } from './../../events/shared/event.service';


@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.scss']
})
export class RepairComponent implements OnInit {

  brokenevents: any;

  constructor(private es: EventService) {
    this.brokenevents = this.es.getEventsListWithNoArtistkey();
   }

  ngOnInit() {
  }

}
