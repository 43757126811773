import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HistoryComponent } from '../history/history.component';
import { AboutusComponent } from '../aboutus/aboutus.component';
import { ContactComponent } from '../contact/contact.component';
import { HomepageComponent } from '../homepage/homepage.component';
import { TouringComponent } from '../touring/touring.component';
import { OfficesComponent } from '../offices/offices.component';
import { ArtistsChinaComponent } from '../artists-china/artists-china.component';
import { ArtistsComponent } from '../artists/artists.component';
import { ArtistComponent } from '../artist/artist.component';
import { ArtistBiographyComponent } from '../artist-biography/artist-biography.component';
import { EventsComponent } from '../events/events.component';


const routes: Routes = [  
  { path: 'history', component: HistoryComponent },
  { path: 'words', component: AboutusComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', component: HomepageComponent },
  { path: 'home', component: HomepageComponent },
  { path: 'touring', component: TouringComponent, data: { animation: 'touring'} },  
  { path: 'offices', component: OfficesComponent },  
  { path: 'artistschina', component: ArtistsChinaComponent },  
  { path: 'artists', component: ArtistsComponent, data: { animation: 'artists'} },  
  { path: 'artist/:id', component: ArtistComponent, data: { animation: 'artist'} },
  { path: 'bio/:id', component: ArtistBiographyComponent},
  { path: 'events', component: EventsComponent},
  { path: 'events/:id/:year/:profil/:fullname', component: EventsComponent},
  { path: 'events/:key', component: EventsComponent},
  { path: 'events/:key/:key2', component: EventsComponent},  
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }


