<mat-card appearance="outlined">
	<mat-card-header>
		<div mat-card-avatar class="example-header-image">
			<mat-icon matListIcon>add</mat-icon>
		</div>
		<mat-card-title>Add New File</mat-card-title>
		<mat-card-subtitle>Upload a new file on website</mat-card-subtitle>
	</mat-card-header>
	
	<div *ngIf="currentFileUpload" class="progress" style="width:400px">
		<div class="progress-bar progress-bar-info progress-bar-striped"
			role="progressbar" attr.aria-valuenow="{{progress.percentage}}"
			aria-valuemin="0" aria-valuemax="100"
			[ngStyle]="{width:progress.percentage+'%'}">
			{{progress.percentage}}%
		</div>
	</div>
 
	<label>
		<input type="file" (change)="selectFile($event)">
	</label>
 
	<button mat-raised-button
		[disabled]="!selectedFiles"
		(click)="upload()">Upload
	</button>
</mat-card>

