import { Component, OnInit, Input } from '@angular/core';
import { EventService } from './../../events/shared/event.service';

@Component({
  selector: 'app-program-event',
  templateUrl: './program-event.component.html',
  styleUrls: ['./program-event.component.scss']
})
export class ProgramEventComponent implements OnInit {
	
	@Input() eventkey: string;

	getevent:any;program:any;
	seeProgramDetail:boolean=false;
  
  constructor(
  	private eventService: EventService
  	) { }

  ngOnInit() {
		this.getevent = this.eventService.getEventWithKey(this.eventkey);
		this.program  = this.eventService.getProgramWithEventKey(this.eventkey);  	
  }

  checkNextComposer(compo,prevcompo) {
  	if(prevcompo) {
  		if(compo.composerlastname == prevcompo.composerlastname) return false;
  		else return true;
		}
  	else return true;
  }

}
