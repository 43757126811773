
<div [ngStyle]="{'text-align':'center'}">
    <h3>Confirm your subscription ?</h3>
    <button mat-button color="black" (click)="signUpWithGoogle()">
        <mat-icon>check</mat-icon>
        <span>Yes</span>
    </button>
    &nbsp;
    <button mat-button color="black" (click)="onNoClick()">
        <mat-icon>clear</mat-icon>
        <span>No</span>
    </button>
</div>