import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from './../../core/auth.service';
import { PressService } from './../../press/shared/press.service';
import { MemberService } from './../../members/shared/member.service';

@Component({
  selector: 'app-press-detail',
  templateUrl: './press-detail.component.html',
  styleUrls: ['./press-detail.component.scss']
})
export class PressDetailComponent implements OnInit {

	press: Observable<any>;

  constructor(
    private auth          : AuthService,
    private pressService  : PressService,
  	private memberService : MemberService,
  	private route         : ActivatedRoute,
    private router        : Router,
  	private location      : Location
  	) { }

  ngOnInit() {
    this.press = this.route.paramMap
      .pipe(switchMap((params: ParamMap) =>
        this.pressService.getPressArticleWithKey(params.get('id'))));  	
  }

  deleteVenue(venue) {
    this.pressService.deletePress(venue);
  }

  updateField(press,field,value) {
    this.pressService.updatePress(press,field,value);
  }

  savePressOnClick(press,field,value) {
  	console.log(press);
  	console.log(value);
   	this.pressService.updatePress(press,field,value);
   	this.goBack();
  }

  goBack(): void {
   	this.location.back();
  }

}
