import { AuthService } from './../../core/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MemberService } from '../../members/shared/member.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})

export class ManagementComponent implements OnInit {
  users : Observable<any[]>;
  members : Observable<any[]>;
  showAddButton: Boolean = true;
  artists: Observable<any[]>;
  selectedMember: any;
  showSelect: boolean;

  
  constructor(public auth: AuthService,
              private router: Router,
              private memberService: MemberService) {

  }

  ngOnInit() {
    this.members = this.memberService.getAgentsList();
  }
}





